import {zodResolver} from '@hookform/resolvers/zod';
import {useCallback, useEffect, useState} from 'react';
import {FieldError, FieldErrorsImpl, Merge, useForm} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types/fields';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';
import {ApiError} from '../../api';
import i18n from '../../i18n';
import {ConstraintViolationError, isRequestAborted} from '../../utils/api';
import {useSnackbar} from '../SnackbarHelper';

export function useAppForm<T extends FieldValues>(schema: z.Schema<any, any>, initialData: T) {
    const {t} = useTranslation();
    const showSnackbarMessage = useSnackbar();
    const form = useForm<T>({
        resolver: zodResolver(schema),
        defaultValues: initialData as any,
        mode: 'onBlur',
        // mode: 'all',
        shouldFocusError: true,
    });

    const [isApiError, setIsApiError] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        if (hasError) {
            scrollToTheFirstErrorField();
            setHasError(false);
        }
    }, [hasError]);

    const setError = form.setError;

    const wrapPromiseCallback = useCallback(<P extends unknown>(promise: Promise<P>, errorFieldPathMapper?: (path: string) => string | undefined) => {
        setIsApiError(false);
        return promise
                .then(value => {
                    setIsApiError(false);
                    return value;
                })
                .catch(reason => {
                    if (isRequestAborted(reason)) {
                        console.error('form wrapper aborted', reason);
                    } else if (reason instanceof ApiError) {
                        if (reason.status === 400) {
                            console.error('form wrapper api error', reason);
                            setIsApiError(true);
                            showSnackbarMessage({
                                message: t('application.form.globalError'),
                                severity: 'error',
                            });
                            const validationErrors = reason.body as ConstraintViolationError;
                            validationErrors.errors?.forEach(fieldError => {
                                const path = errorFieldPathMapper?.(fieldError.path) ?? fieldError.path;
                                setError(path as any, {
                                    type: 'custom',
                                    message: t(fieldError.errorCode as any, fieldError.parameters ?? {}),
                                }, {shouldFocus: false});   // we must not focus field on setting error because it removes the error message from the field
                            });
                            setHasError((validationErrors.errors?.length ?? 0) > 0);
                        }
                    }
                    throw reason;
                });
    }, [setIsApiError, showSnackbarMessage, setError, t]);

    return {
        ...form,
        errors: form.formState.errors,
        isValid: form.formState.isValid && !isApiError,
        isSubmitted: form.formState.isSubmitted || isApiError,
        isValidating: form.formState.isValidating,
        wrapPromise: wrapPromiseCallback,
    };
}

export function getErrorLabel(field: FieldError | Merge<FieldError, FieldErrorsImpl<{}>> | undefined) {
    if (field === undefined) {
        return null;
    }
    if (field.message !== undefined && field.message?.length > 0) {
        return i18n.t(field.message as any);
    }
    switch (field.type) {
        case 'required':
            return 'This is required';
        case 'minLength':
            return 'Min length not reached';
        default:
            return 'Error in this field';
    }
}

function scrollToTheFirstErrorField() {
    setTimeout(() => {
        const invalidElements = document.querySelectorAll('[aria-invalid="true"]');
        console.log('scrollToTheFirstErrorField', invalidElements);
        const invalidElement = invalidElements[0];
        invalidElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        // @ts-ignore
        invalidElement?.focus({preventScroll: true});
    }, 200);    // we need this timeout to make sure that all `aria-invalid` attributes are set (especially for address picker)
}
