import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import {z} from 'zod';
import {zodI18nMap} from 'zod-i18n-map';
import zodEn from 'zod-i18n-map/locales/en/zod.json';
import {Language} from './api';
import de from './translations/de/app.json';
import zodDe from './translations/de/zod.json';
import en from './translations/en/app.json';
import fr from './translations/fr/app.json';
import zodFr from './translations/fr/zod.json';

export const defaultNS = 'translation';
export const resources = {
    en: {
        translation: en,
        zod: zodEn,
    },
    de: {
        translation: de,
        zod: zodDe,
    },
    fr: {
        translation: fr,
        zod: zodFr,
    }
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        defaultNS: defaultNS,
        fallbackLng: 'en',
        interpolation: {
            skipOnVariables: false,
        }
    });
z.setErrorMap(zodI18nMap);

export default i18n;

export function getI18nLanguage() {
    return i18n.language.split('-')[0];
}

export function getCurrentLanguage(): Language {
    const languageCode = getI18nLanguage().toUpperCase();
    switch (languageCode) {
        case 'EN':
            return Language.EN;
        case 'FR':
            return Language.FR;
    }
    return Language.DE;
}
