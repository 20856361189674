import Keycloak from 'keycloak-js';
import AppConfig from './config';

const keycloak = new Keycloak({
    url: AppConfig.keycloakUrl,
    realm: AppConfig.keycloakRealm,
    clientId: AppConfig.keycloakClientId,
});
export default keycloak;

