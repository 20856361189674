import {AccountCircle, Home} from '@mui/icons-material';
import {AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Stack, Toolbar, Typography} from '@mui/material';
import {styled} from '@mui/material/styles';
import {useKeycloak} from '@react-keycloak/web';
import React from 'react';
import {useTranslation} from 'react-i18next';
import bannerBackgroundImage from '../domain/application/img/bannerBackground.svg';
import hornbachLogo from '../domain/application/img/hornbachLogo.svg';
import {useUserContext} from '../domain/user/UserProvider';
import {ROUTES} from '../router';
import {LanguageChooser} from './LanguageChooser';
import {DBPopulator} from './DBPopulator';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import {Link, useNavigate} from 'react-router-dom';
import {Country} from '../api';
import {hasAnyRole, isAdmin} from '../domain/user/utils';
import {UserRole} from '../domain/user/model';

const Banner = styled(Box)(props => ({
    backgroundColor: props.theme.palette.primary.main,
    backgroundImage: `url(${bannerBackgroundImage})`,
    backgroundSize: 'auto 100%',
    height: '4.375rem',
    '& svg': {
        width: '100%',
    },
    paddingTop: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(1),
}));

const HornbachLogoButton = styled(Button)(props => ({
    backgroundColor: props.theme.palette.primary.main,
    backgroundImage: `url(${hornbachLogo})`,
    backgroundRepeat: 'no-repeat',
    height: '46px',
    width: '181px',
    float: 'left',
}));

export function TopBar() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useUserContext();

    return (<>
            <AppBar>
                <Banner> {/* Branded Top Banner */}
                    <Container>
                        <IconButton size="large" color="inherit" sx={{float:'left'}} onClick={() => navigate('/')}>
                            <Home/>
                        </IconButton>
                       <HornbachLogoButton onClick={() => navigate('/')}/>
                        <Typography variant="h6"  sx={{float: 'right', color: 'white', pt: '10px'}}>
                            {t('application.topBar.appName')}
                        </Typography>
                     </Container>
                </Banner>
                <Toolbar> {/* Navigation, Language and User controls */}
                    <Container>
                        <Stack direction="row" alignItems="center">
                            {hasAnyRole(user, [UserRole.HORNBACH, UserRole.HORNBACH_CENTRAL, UserRole.KNISTR]) &&
                            <Link to={ROUTES.applications.list}>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1, float: 'left', color: 'white', mr: '30px', '&:hover': {textDecoration: 'underline'}}}>
                                    <FileCopyOutlinedIcon sx={{mb:-0.5, mr:1}}/>{t('application.topBar.applications')}</Typography></Link>}
                            {isAdmin(user) &&
                            <Link to={ROUTES.users.list}>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1, float: 'left', color: 'white', mr: '30px', '&:hover': {textDecoration: 'underline'}}}>
                                    <PeopleAltOutlinedIcon sx={{mb:-0.5, mr:1}}/>{t('application.topBar.users')}</Typography></Link>}
                            {(hasAnyRole(user, [UserRole.HORNBACH_CENTRAL, UserRole.KNISTR]) || isAdmin(user)) &&
                            <Link to={ROUTES.reports}>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1, float: 'left', color: 'white', mr: '30px', '&:hover': {textDecoration: 'underline'}}}>
                                    <AssessmentOutlinedIcon sx={{mb:-0.5, mr:1}}/>{t('application.topBar.reports')}</Typography></Link>}
                            <Box sx={{flexGrow: 1}}></Box> {/*reset box layout with 'flow'*/}
                            <Box alignContent={'end'}>
                                <DBPopulator/>
                                <LanguageChooser/>
                                <UserAccount/>
                            </Box>
                        </Stack>
                    </Container>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{mb: 8.8}}/>
        </>
    );
}

function UserAccount() {
    const {t} = useTranslation();
    const {keycloak} = useKeycloak();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const {user} = useUserContext();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        keycloak.logout();
        handleClose();
    };

    const handlePasswordChange = () => {
        function determineLocale() {
            if (user.countryDe) {
                return Country.DE;
            } else if (user.countryLu) {
                return Country.LU;
            } else if (user.countryCh) {
                return Country.CH;
            } else {
                return undefined;
            }
        }

        const locale = determineLocale();

        keycloak.login({
            action: 'UPDATE_PASSWORD',
            locale: locale,
        });
        handleClose();
    };

    return (<>
        <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
        >
            <Typography variant={'body1'}>
                {user.firstName}&nbsp;
                {user.lastName}&nbsp;
                ({user.userName}, {user.role})&nbsp;
            </Typography>
            <AccountCircle/>
        </IconButton>
        <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={handlePasswordChange}>{t('user.changePassword.button')}</MenuItem>
            <MenuItem onClick={handleLogout}>{t('user.logout.button')}</MenuItem>
        </Menu>
    </>);
}
