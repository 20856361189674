import {GridSize} from '@mui/material/Grid/Grid';
import {TextFieldPropsSizeOverrides} from '@mui/material/TextField/TextField';
import {OverridableStringUnion} from '@mui/types';
import React, {PropsWithChildren} from 'react';
import {FieldPath} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {ApplicationStatusDto} from '../../api';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {capitalizeFirstLetter} from '../../utils/utils';

interface ApplicationStatusSelectFieldProps<TFieldValues extends FieldValues = FieldValues> {
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    disabled?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
}

export function ApplicationStatusSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ApplicationStatusSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    const statuses: string[] = Object.keys(ApplicationStatusDto);

    return (<AppAutocompleteField<string, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                        options={statuses}
                                                        getLabel={option => capitalizeFirstLetter(t('application.status.' + option as any)) ?? option}
                                                        getId={option => option}
                                                        fieldPath={props.fieldPath}
                                                        disabled={props.disabled}
                                                        label={t('application.list.header.status')}
                                                        size={props.size}
    />);

}
