import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    AdditionalProfiCardApplication,
    createEmptyAdditionalProfiCardLuApplication
} from '../../domain/application/profiCard/additional/model';
import {
    AdditionalProfiCardApplicationForm
} from '../../domain/application/profiCard/additional/AdditionalProfiCardApplicationForm';

export default function NewAdditionalProfiCardLuApplicationPage() {
    return (<NewApplicationPage<AdditionalProfiCardApplication>
            renderApplicationForm={AdditionalProfiCardApplicationForm}
            createEmptyApplication={createEmptyAdditionalProfiCardLuApplication}/>);
}

