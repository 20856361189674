import {CardContent, Grid} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {AppCard} from '../../../../components/SectionCard';
import {formatAmountWithCurrency} from '../../../../utils/utils';
import {getProductName} from '../../../../pages/application/ApplicationsListPage';
import {AdditionalProjektWeltApplication, isAdditionalProjektWeltApplication} from './model';

interface AdditionalProjektWeltApplicationOverviewSectionProps {
    application: AdditionalProjektWeltApplication;
}

export const AdditionalProjektWeltApplicationOverviewSection: FC<AdditionalProjektWeltApplicationOverviewSectionProps> = ({application}) => {
    const {t} = useTranslation();

    if (!isAdditionalProjektWeltApplication(application)) {
        throw new Error('Application is not of type Additional Card');
    }

    return (<AppCard>
        <CardContent>
            <Grid container item xs={6}>
                <LabelAndValue label={<strong>{t('application.crefo.details.row.productType')}</strong>}
                               value={getProductName(application.applicationType)}/>
                <LabelAndValue label={<strong>{t('application.form.cardHolder.main.sectionTitle')}</strong>}
                               value={[application.mainCard?.salutation?.value, application.mainCard?.title?.value, application.mainCard?.firstName, application.mainCard?.lastName].filter(txt => !!txt).join(' ')}/>
                <LabelAndValue
                        label={<strong>{t('application.form.additionalCard.mainCard.search.cardNumber')}</strong>}
                        value={application.mainCard?.mainCardNumber}/>
                <LabelAndValue
                        label={<strong>{t('application.form.additionalCard.mainCard.accountLimit.label')}</strong>}
                        value={formatAmountWithCurrency(application.mainCard?.cardLimit)}/>
                <LabelAndValue label={<strong>{t('application.overview.additionalCard.limit')}</strong>}
                               value={formatAmountWithCurrency(application.accountLimit)}/>
                <LabelAndValue label={<strong>{t('application.accountNumber')}</strong>}
                               value={<strong>{application.accountNumber ?? '-'}</strong>}/>
                <LabelAndValue label={<strong>{t('application.cardNumber')}</strong>}
                               value={<strong>{application.cardNumber ?? '-'}</strong>}/>
            </Grid>
        </CardContent>
    </AppCard>);
};
