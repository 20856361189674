import {Box, Table, TableBody, TableHead} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {PropsWithChildren, ReactNode} from 'react';
import {MandatoryChildren} from '../utils/utils';

const StyledTableContainer = styled(Box)({
    border: '1px solid #ccc',
    overflowY: 'auto',
});

const StyledTableHeader = styled(TableHead)({
    backgroundColor: '#e5e5e5',
    position: 'sticky',
    top: 0,
    zIndex: 99,
    '& th': {
        borderBottom: '1px solid #ccc',
        fontWeight: 'bold',
    }
});

const StyledTableBody = styled(TableBody)({
    '& tr': {
        transition: 'background-color 300ms',
        backgroundColor: '#fff',
    },
    // css classes: rowA and rowB are for complex tables with row/column grouping, see: AddressPicker.tsx/TableRowWithVerifiedAddress
    '& tr:not(.rowA):not(.rowB):nth-of-type(even)': {
        backgroundColor: '#f8f8f8',
    },
    '& tr:not(.rowA):not(.rowB):hover': {
        backgroundColor: '#ddd',
    },
    '& tr.rowA, & tr.rowA + tr.rowB': {
        backgroundColor: '#f8f8f8',
    },
    '& tr.rowA:nth-of-type(4n+1), & tr.rowA:nth-of-type(4n+1) + tr.rowB': {
        backgroundColor: '#fff !important',
    },
});

interface StickyHeaderTableProps extends MandatoryChildren {
    headerRow: ReactNode;
    maxHeight?: string | number;
}

export function StickyHeaderTable({
                                      headerRow,
                                      maxHeight,
                                      children,
                                  }: PropsWithChildren<StickyHeaderTableProps>) {

    return (<StyledTableContainer sx={{maxHeight}}>
        <Table>
            <StyledTableHeader>
                {headerRow}
            </StyledTableHeader>
            <StyledTableBody>
                {children}
            </StyledTableBody>
        </Table>
    </StyledTableContainer>);
}
