import {z} from 'zod';
import {DictionaryValueDto} from '../../api';
import {NoUndefined} from '../util';

export const ANY_VALUE_KEY = '-1';

export const optionEntrySchema = z.object({
    key: z.string().min(1),
    value: z.string(),
})
    .optional()
    .nullable()
    .refine(
        optionEntry => optionEntry !== undefined && optionEntry !== null,
        'common.form.autocomplete.required'
    );
export type OptionEntry = NoUndefined<NonNullable<z.infer<typeof optionEntrySchema>>>;

export const isOptionEntry = (obj: DictionaryValueDto | undefined): obj is OptionEntry => {
    if (!obj) {
        return false;
    }
    return typeof obj.key === 'string' && typeof obj.value === 'string';
};

export const optionalOptionEntry = optionEntrySchema.optional().nullable();
export type OptionalOptionEntry = z.infer<typeof optionalOptionEntry>;

export function getOptionEntryLabel(option: OptionEntry) {
    return option.value;
}

export function getOptionEntryId(option: OptionEntry) {
    return option.key;
}
