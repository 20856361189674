import {useTranslation} from 'react-i18next';
import {usePageLoaderContext} from '../../../../components/PageLoader';
import {useSnackbar} from '../../../../components/SnackbarHelper';
import {CardActions, CardContent, CardHeader, Typography} from '@mui/material';
import {KeyboardDoubleArrowRightRounded} from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import React, {FC} from 'react';
import {AppCard} from '../../../../components/SectionCard';
import {AppFormButton} from '../../../../components/form/AppFormButton';
import {processCreditCheck, saveApplication} from '../../service';
import {scrollToTop} from '../../../../utils/utils';
import {BaseApplication} from '../../model';
import {useProcessModificationResponseError} from '../../processResponseError';

type ApplicationWithIgnoreDuplicates = BaseApplication & { ignoreDuplicates: boolean | undefined };

interface DuplicateApplicationProblemSectionProps {
    application: ApplicationWithIgnoreDuplicates;
}

export const DuplicateApplicationProblemSection: FC<DuplicateApplicationProblemSectionProps> = ({application}) => {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const showSnackbar = useSnackbar();
    const processModificationResponseError = useProcessModificationResponseError();

    const proceedWithDuplicate = () => {
        const applicationNumber = application.applicationNumber;
        if (applicationNumber === undefined) {
            throw new Error('Application number cannot be empty');
        }
        setIsLoading(true);
        saveApplication({...application, ignoreDuplicates: true})
                .then((updatedApplication) => processCreditCheck(updatedApplication))
                .then(() => {
                    showSnackbar({
                        message: t('application.overview.duplicate.action.ignore.success'),
                        severity: 'success',
                    });
                    scrollToTop();
                })
                .catch((reason) => processModificationResponseError(reason, applicationNumber))
                .finally(() => {
                    setIsLoading(false);
                });
    };

    return (<AppCard>
        <CardHeader title={
            <Typography variant={'h5'}
                        sx={theme => ({color: theme.palette.error.main})}>
                <ErrorIcon/><span> {t('application.overview.duplicate.title')}</span>
            </Typography>}
        />
        <CardContent>
            <Typography variant={'subtitle1'}
                        sx={theme => ({color: theme.palette.common.black})}>
                <span>{t('application.overview.duplicate.message')}</span>
            </Typography>
        </CardContent>
        <CardActions>
            <AppFormButton outlined={false}
                           color="secondary"
                           onClick={() => proceedWithDuplicate()}
                           icon={
                               <KeyboardDoubleArrowRightRounded/>}>{t('application.overview.duplicate.action.ignore.button')}
            </AppFormButton>
        </CardActions>
    </AppCard>);
};
