import React, {useEffect, useState} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {getOptionEntryId, OptionEntry} from './model';
import {ReferenceSelectFieldProps} from './ReferenceSelectFieldProps';
import {useReferences} from './service';
import {useFormContext} from 'react-hook-form';
import {Originator} from '../../utils/OriginatorProvider';
import {BaseApplication} from '../application/model';

interface MasterMarketSelectFieldProps<TFieldValues extends FieldValues = FieldValues> extends ReferenceSelectFieldProps<TFieldValues> {
}

export function MasterMarketSelectField<TFieldValues extends FieldValues = FieldValues>(props: MasterMarketSelectFieldProps<TFieldValues>) {
    const {t} = useTranslation();
    const {getShops, isLoading} = useReferences((Originator.current!!));
    const {getValues} = useFormContext<BaseApplication>();
    const [shops, setShops] = useState<OptionEntry[]>();

    useEffect(() => {
        if (shops !== undefined || getShops === undefined) {
            return;
        }
        const applicationType = getValues('applicationType');
        setShops(getShops?.(applicationType));
    }, [getValues, getShops, shops]);

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 3}
                                                             options={shops}
                                                             isLoading={isLoading}
                                                             getLabel={getMarketLabelAndId}
                                                             getId={getOptionEntryId}
                                                             fieldPath={props.fieldPath}
                                                             disabled={props.disabled}
                                                             required={props.required}
                                                             label={t('application.form.marketData.masterStore')}
                                                             readOnly={props.readOnly}
    />);
}

export function getMarketLabelAndId(option: OptionEntry) {
    return option.value + ' - ' + option.key;
}
