import {GridSize} from '@mui/material/Grid/Grid';
import {TextFieldPropsSizeOverrides} from '@mui/material/TextField/TextField';
import {OverridableStringUnion} from '@mui/types';
import React, {PropsWithChildren} from 'react';
import {FieldPath} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {ApplicationTypeDto} from '../../api';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {capitalizeFirstLetter} from '../../utils/utils';

interface ApplicationTypeSelectFieldProps<TFieldValues extends FieldValues = FieldValues> {
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    disabled?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
}

export function ApplicationTypeSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ApplicationTypeSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    const types: ApplicationTypeDto[] = Object.keys(ApplicationTypeDto).map(key => key as ApplicationTypeDto);

    return (<AppAutocompleteField<ApplicationTypeDto, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                                    options={types}
                                                                    getLabel={option => capitalizeFirstLetter(t('application.type.' + option as any)) ?? option}
                                                                    getId={option => option}
                                                                    fieldPath={props.fieldPath}
                                                                    disabled={props.disabled}
                                                                    label={t('application.list.header.applicationType')}
                                                                    size={props.size}
    />);

}
