import {FieldValues} from 'react-hook-form/dist/types';
import {FieldPath} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {FormRow} from '../../components/form/FormRow';
import {AppTextField} from '../../components/form/AppTextField';
import React, {PropsWithChildren} from 'react';

interface ContactFormProps<TFieldValues extends FieldValues = FieldValues> {
    basePath: FieldPath<TFieldValues>;
    readOnly?: boolean;
    disabled?: boolean;
}

export function ContactForm<TFieldValues extends FieldValues = FieldValues>({
                                                                                basePath,
                                                                                readOnly,
                                                                                disabled,
                                                                            }: PropsWithChildren<ContactFormProps<TFieldValues>>) {
    const {t} = useTranslation();

    return (<>
        <FormRow>
            <AppTextField breakpoint={6}
                          required={true}
                          label={t('common.form.contact.email')}
                          fieldPath={basePath + '.email'}
                          readOnly={readOnly}
                          disabled={disabled}/>
        </FormRow>
        <FormRow>
            <AppTextField breakpoint={3}
                          required={true}
                          label={t('common.form.contact.cellPhoneNumber')}
                          fieldPath={basePath + '.cellPhoneNumber'}
                          readOnly={readOnly}
                          disabled={disabled}/>
            <AppTextField breakpoint={3}
                          label={t('common.form.contact.phoneNumber')}
                          fieldPath={basePath + '.phoneNumber'}
                          readOnly={readOnly}
                          disabled={disabled}/>
        </FormRow>
    </>);
}
