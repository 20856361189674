import {Country, Language, OriginatorDto, Role, User as UserDto, UserOperationRequest} from '../../api';
import {getReferences} from '../reference/service';
import {User, UserRole} from './model';
import {hasRole} from './utils';

// TODO handle undefined userDto
export async function mapUserDtoToModel(userDto: UserDto): Promise<User> {
    const references = await getReferences(OriginatorDto.PROFI_CARD_GERMANY);

    const allShops = references.getShops(OriginatorDto.PROFI_CARD_GERMANY)
        .concat(references.getShops(OriginatorDto.PROFI_CARD_LUXEMBURG))
        .concat(references.getShops(OriginatorDto.PROFI_CARD_SWITZERLAND));

    const userStore = allShops.find(item => item.key === (''+userDto.storeId));

    const userRole = userDto?.roles?.find(role => isUserRole(role)) as UserRole | undefined;

    function isUserRole(role: Role): boolean {
        return Object.values(UserRole).includes(role as unknown as UserRole);
    }

    return {
        uid: userDto?.uid ?? undefined,
        userName: userDto?.userName ?? '',
        emailAddress: userDto?.emailAddress ?? '',
        firstName: userDto?.name ?? '',
        lastName: userDto?.familyName ?? '',
        role: userRole,
        store: userStore,
        hornbachUserCountry: userRole === UserRole.HORNBACH ? userDto?.countries?.[0] : undefined,
        countryDe: userDto.countries?.includes(Country.DE),
        countryLu: userDto.countries?.includes(Country.LU),
        countryCh: userDto.countries?.includes(Country.CH),
        preferredLanguage: userDto?.preferredLanguage ?? undefined,
        isActive: userDto.active,
        userAdminRights: userDto.roles?.includes(Role.HRB_SERVICE_TOOL),
    };
}

export function mapUserToUserOperationRequest(user: User): UserOperationRequest {
    const roles = Object.values(UserRole)
        .filter(role => hasRole(user, role)) as unknown as Role[];

    if (user.userAdminRights && !roles.includes(Role.HRB_SERVICE_TOOL)) {
        roles.push(Role.HRB_SERVICE_TOOL);
    }

    const countries = [];
    if (user.countryDe) {
        countries.push(Country.DE);
    }
    if (user.countryLu) {
        countries.push(Country.LU);
    }
    if (user.countryCh) {
        countries.push(Country.CH);
    }

    return {
        uid: user.uid,
        userName: user.userName,
        emailAddress: user.emailAddress ?? '',
        name: user.firstName,
        familyName: user.lastName,
        roles: roles,
        countries: countries,
        storeId: user.store ? Number(user.store.key) : undefined,
        preferredLanguage: user.preferredLanguage as Language,
        active: user.isActive,
    };
}
