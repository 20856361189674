import {AlertColor} from '@mui/material/Alert/Alert';
import React, {useMemo} from 'react';
import {MandatoryChildren} from '../utils/utils';
import {closeSnackbar, enqueueSnackbar, MaterialDesignContent, SnackbarProvider} from 'notistack';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from '@emotion/styled';
import ErrorSharpIcon from '@mui/icons-material/ErrorSharp';

export interface SnackbarData {
    message: React.ReactNode;
    severity?: AlertColor;
    autoHideDuration?: number;
}

export type SnackbarHelperContextType = {
    show(data: SnackbarData): void;
};

export const SnackbarHelperContext = React.createContext<SnackbarHelperContextType>({} as SnackbarHelperContextType);

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent > div:first-of-type': {
        maxWidth: '90%',
    },
    '&.notistack-MuiContent > div:first-of-type > svg': {
        marginRight: '1rem'
    },
    '&.notistack-MuiContent > div:nth-of-type(2)': {
        '&:hover': {
            cursor: 'pointer'
        }
    },
}));

export const SnackbarHelperContextProvider: React.FC<MandatoryChildren> = ({children}) => {

    const snackbarContextValue = useMemo(() => ({
        show: (data: SnackbarData) => {
            enqueueSnackbar({
                message: data.message,
                variant: data.severity,
                action: (key) => <CancelIcon data-test-id="snackbar-close-button" onClick={() => closeSnackbar(key)}/>
            });
        }
    }), []);

    return (<SnackbarHelperContext.Provider value={snackbarContextValue}>
        <SnackbarProvider maxSnack={3}
                          autoHideDuration={6000}
                          anchorOrigin={{horizontal: 'center', vertical: 'bottom'}}
                          iconVariant={{
                              error: <ErrorSharpIcon/>,
                          }}
                          Components={{
                              success: StyledMaterialDesignContent,
                              error: StyledMaterialDesignContent,
                              info: StyledMaterialDesignContent,
                              warning: StyledMaterialDesignContent,
                              default: StyledMaterialDesignContent,
                          }}/>
        {children}
    </SnackbarHelperContext.Provider>);
};

export function useSnackbar() {
    return React.useContext(SnackbarHelperContext).show;
}
