import React, {FC, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    MainProfiCardDeApplicationForm
} from '../../domain/application/profiCard/main/de/MainProfiCardDeApplicationForm';
import {ROUTES} from '../../router';
import {CreatedLegitimizedApplicationRouterState} from './NewApplicationPage';
import {
    AdditionalProfiCardApplicationForm
} from '../../domain/application/profiCard/additional/AdditionalProfiCardApplicationForm';
import {
    MainProfiCardLuApplicationForm
} from '../../domain/application/profiCard/main/lu/MainProfiCardLuApplicationForm';
import {
    MainProfiCardChApplicationForm
} from '../../domain/application/profiCard/main/ch/MainProfiCardChApplicationForm';
import {isMainProfiCardDeApplication} from '../../domain/application/profiCard/main/de/model';
import {isMainProfiCardLuApplication} from '../../domain/application/profiCard/main/lu/model';
import {isMainProfiCardChApplication} from '../../domain/application/profiCard/main/ch/model';
import {isAdditionalProfiCardApplication} from '../../domain/application/profiCard/additional/model';
import {BaseApplication} from '../../domain/application/model';
import {isMainProjektWeltApplication} from '../../domain/application/projektWelt/main/model';
import {MainProjektWeltApplicationForm} from '../../domain/application/projektWelt/main/MainProjektWeltApplicationForm';
import {isAdditionalProjektWeltApplication} from '../../domain/application/projektWelt/additional/model';
import {
    AdditionalProjektWeltApplicationForm
} from '../../domain/application/projektWelt/additional/AdditionalProjektWeltApplicationForm';

interface ApplicationEditTabProps {
    applicationNumber: string;
    application: BaseApplication | undefined;
}

export const ApplicationEditTab: FC<ApplicationEditTabProps> = ({applicationNumber, application}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as CreatedLegitimizedApplicationRouterState | undefined;
    const shouldShowLegitimizedDialog = locationState?.showLegitimizedDialog;

    useEffect(() => {
        // this is for disabling showing legitimize dialog on refreshing page (by pressing F5 in browser) - it clears the location's state.
        if (!shouldShowLegitimizedDialog) {
            return;
        }
        navigate(ROUTES.applications.edit(applicationNumber), {replace: true});
    }, [shouldShowLegitimizedDialog, applicationNumber, navigate]);

    if (application === undefined) {
        return null;
    }

    return (
            <>
                {isMainProfiCardDeApplication(application) &&
                        <MainProfiCardDeApplicationForm application={application}
                                                        showLegitimizeDialog={shouldShowLegitimizedDialog}/>}

                {isMainProfiCardLuApplication(application) &&
                        <MainProfiCardLuApplicationForm application={application}
                                                        showLegitimizeDialog={shouldShowLegitimizedDialog}/>}

                {isMainProfiCardChApplication(application) &&
                        <MainProfiCardChApplicationForm application={application}
                                                        showLegitimizeDialog={shouldShowLegitimizedDialog}/>}

                {isAdditionalProfiCardApplication(application) &&
                        <AdditionalProfiCardApplicationForm application={application}
                                                            showLegitimizeDialog={shouldShowLegitimizedDialog}/>}
                {isMainProjektWeltApplication(application) &&
                        <MainProjektWeltApplicationForm application={application}
                                                        showLegitimizeDialog={shouldShowLegitimizedDialog}/>}
                {isAdditionalProjektWeltApplication(application) &&
                        <AdditionalProjektWeltApplicationForm application={application}
                                                              showLegitimizeDialog={shouldShowLegitimizedDialog}/>}
            </>
    );
};
