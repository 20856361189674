import {FieldValues} from 'react-hook-form/dist/types';
import {FieldPath, useFormContext} from 'react-hook-form';
import {Address} from '../auxiliary/model';
import React, {PropsWithChildren, useCallback} from 'react';
import {FormRow} from '../../components/form/FormRow';
import {AddressPicker} from '../auxiliary/AddressPicker';
import {DictionaryValueDto} from '../../api';

interface AddressFormProps<TFieldValues extends FieldValues = FieldValues> {
    basePath: FieldPath<TFieldValues>;
    disableCountry?: boolean;
    availableCountries?: DictionaryValueDto[];
    showAdditionalLine?: boolean;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
}

export function AddressForm<TFieldValues extends FieldValues = FieldValues>({
                                                                                basePath,
                                                                                disableCountry,
                                                                                availableCountries,
                                                                                showAdditionalLine,
                                                                                required,
                                                                                readOnly,
                                                                                disabled,
                                                                            }: PropsWithChildren<AddressFormProps<TFieldValues>>) {
    const {watch, setValue} = useFormContext<TFieldValues>();
    const address = watch(basePath) as Address;
    const onAddressSelect = useCallback((selectedAddress: Address) => {
        setValue(basePath, selectedAddress as any, {shouldDirty: true});
    }, [setValue, basePath]);

    return (<FormRow>
        <AddressPicker breakpoint={6}
                       required={required}
                       id={basePath}
                       fieldPath={basePath}
                       address={address}
                       onAddressSelect={onAddressSelect}
                       disableCountry={disableCountry}
                       availableCountries={availableCountries}
                       showAdditionalLine={showAdditionalLine}
                       readOnly={readOnly}
                       disabled={disabled}
        />
    </FormRow>);
}
