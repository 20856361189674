import {Button} from '@mui/material';
import {ApplicationStatusDto, DatabasePopulatorResourceService} from '../api';
import {ROUTES} from '../router';
import React from 'react';
import {usePageLoaderContext} from './PageLoader';
import {useSnackbar} from './SnackbarHelper';


export function DBPopulator() {
    const {setIsLoading} = usePageLoaderContext();
    const showSnackbarMessage = useSnackbar();

    return(<>
        {process.env.NODE_ENV === 'development' &&
                <Button variant={'outlined'} color={'secondary'} sx={{mr:'15px'}} onClick={() => {
                    setIsLoading(true);
                    DatabasePopulatorResourceService.resetDatabase()
                            .then(() => DatabasePopulatorResourceService.populateApplications(5))
                            .then(() => DatabasePopulatorResourceService.populateApplications(5, ApplicationStatusDto.LEGITIMIZED))
                            .then(() => {
                                setIsLoading(false);
                                showSnackbarMessage({
                                    message: 'Populated!',
                                    severity: 'success',
                                });
                                // @ts-ignore
                                window.location = ROUTES.applications.list;
                            })
                            .catch(() => {
                                setIsLoading(false);
                                showSnackbarMessage({
                                    message: 'Error while populating!',
                                    severity: 'error',
                                });
                            });
                }}>
                    Populate DB
                </Button>}
        </>
    );
}
