import {Grid, InputLabel} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import {TextFieldPropsSizeOverrides} from '@mui/material/TextField/TextField';
import {OverridableStringUnion} from '@mui/types';
import {DatePicker} from '@mui/x-date-pickers';
import moment, {Moment} from 'moment';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {Controller, FieldError, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {createInvalidOption, InvalidOption, isInvalidOption} from '../../utils/invalidOptionSchema';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId, markLabelAsMandatory} from './utils';

interface AppDatePickerFieldProps<TFieldValues extends FieldValues = FieldValues> {
    fieldPath: FieldPath<TFieldValues>;
    label: React.ReactNode;
    helperText?: React.ReactNode;
    breakpoint?: boolean | GridSize;
    disableFuture?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
}

export function AppDatePickerField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<AppDatePickerFieldProps<TFieldValues>>) {
    const {control} = useFormContext();

    return (
            <Grid item xs={props.breakpoint}>
                <Controller control={control}
                            name={props.fieldPath}
                            render={({field: {value, onChange, ...fields}, fieldState: {error}}) => {
                                return <DatePickerInternal {...props}
                                                           id={fieldPathToId(props.fieldPath)}
                                                           value={value}
                                                           onChange={onChange}
                                                           error={error}
                                                           fields={fields}
                                                           readOnly={props.readOnly}
                                                           disabled={props.disabled}/>;
                            }}/>
            </Grid>
    );
}

interface DatePickerInternalProps<TFieldValues extends FieldValues = FieldValues> extends Omit<AppDatePickerFieldProps<TFieldValues>, 'breakpoint' | 'fieldPath'> {
    id: string;
    error?: FieldError;
    value: Date | InvalidOption;
    readOnly?: boolean;
    disabled?: boolean;

    onChange(date: Date | InvalidOption | null): void;

    fields: { ref: any, name: any, onBlur: any },
}

function DatePickerInternal<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<DatePickerInternalProps<TFieldValues>>) {
    const [value, setValue] = useState<Moment | null>(null);
    const valueFromProps = props.value;
    useEffect(() => {
        const newValue = (valueFromProps !== undefined && valueFromProps !== null) ? moment(getValueForMoment(valueFromProps)) : null;
        setValue(newValue);
    }, [valueFromProps]);

    const hasError = props.error !== undefined;

    const onChange = (momentDate: moment.Moment | null) => {
        props.onChange(momentDate === null ? null : convertToDateOrCreateInvalidOption(momentDate));
        props.fields.onBlur();
    };

    return <>
        <InputLabel error={hasError}
                    htmlFor={props.id}>{(typeof props.label === 'string' || props.label === null || props.label === undefined) ? markLabelAsMandatory(props.label, props.required) : props.label}</InputLabel>
        <DatePicker
                value={value}
                format={'DD.MM.YYYY'}
                disableFuture={props.disableFuture}
                onChange={onChange}
                readOnly={props.readOnly}
                disabled={props.disabled}
                slotProps={{
                    textField: {
                        ...props.fields,
                        id: props.id,
                        inputRef: props.fields.ref,
                        variant: 'outlined',
                        size: props.size,
                        fullWidth: true,
                        error: hasError,
                        disabled: props.disabled,
                        helperText: hasError ? getErrorLabel(props.error) : props.helperText,
                    }
                }}
        />
    </>;
}

function convertToDateOrCreateInvalidOption(momentDate: Moment) {
    return momentDate.isValid() ? momentDate.toDate() : createInvalidOption('invalidDate');
}

function getValueForMoment(date: Date | InvalidOption) {
    if (isInvalidOption(date)) {
        return (date as InvalidOption).value;
    }
    return date as Date;
}
