import DoneIcon from '@mui/icons-material/Done';
import {Button, CardContent, CardHeader, Grid, TableCell, TableRow, Typography} from '@mui/material';
import React, {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import {CrefoDetailsDto, CrefoDetailsStatusDto, CrefoInteractionDto} from '../../../../api';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {Progress} from '../../../../components/Loader';
import {usePageLoaderContext} from '../../../../components/PageLoader';
import {AppCard} from '../../../../components/SectionCard';
import {useSnackbar} from '../../../../components/SnackbarHelper';
import {StickyHeaderTable} from '../../../../components/StickyHeaderTable';
import {formatAmountWithCurrency, formatDateTimeWithSeconds} from '../../../../utils/utils';
import {RetryCrefoButton} from '../../ActionButtons';
import {retrieveCrefoHitDetails, selectCrefoDetails, useGetCrefoInteractions} from '../../service';
import {ApplicationStatus, BaseApplication} from '../../model';
import {BaseMainProfiCardApplicationUnion} from '../model';
import {isMainProfiCardApplication} from '../utils';
import {useProcessModificationResponseError} from '../../processResponseError';

interface CrefoLatestInteractionSectionProps {
    application: BaseApplication;
    additionalRow?: ReactNode;
}

export const CrefoLatestInteractionSection: FC<CrefoLatestInteractionSectionProps> = ({
                                                                                          application,
                                                                                          additionalRow
                                                                                      }) => {
    const {crefoInteractions, ...crefoQuery} = useGetCrefoInteractions(application.applicationNumber!!);

    if (!isMainProfiCardApplication(application)) {
        throw new Error('Application is not of type Main Card');
    }

    const lastCrefoInteraction = crefoInteractions?.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))[0];
    if (lastCrefoInteraction === undefined) {
        return null;
    }
    if (!crefoQuery.isFetched) {
        return <Progress size={28}/>;
    }
    return <CrefoInteractionSection application={application} crefoInteraction={lastCrefoInteraction}
                                    additionalRow={additionalRow}/>;
};

interface CrefoInteractionSectionProps {
    application: BaseMainProfiCardApplicationUnion;
    crefoInteraction: CrefoInteractionDto;
    additionalRow?: ReactNode;
}

const CrefoInteractionSection: FC<CrefoInteractionSectionProps> = ({
                                                                       application,
                                                                       crefoInteraction,
                                                                       additionalRow
                                                                   }) => {
    const {t} = useTranslation();

    const state = crefoInteraction.state;
    return (<AppCard>
        <CardHeader title={t('application.crefo.interaction.title')}
                    action={application.status === ApplicationStatus.NEEDS_MANUAL_INTERVENTION ?
                            <RetryCrefoButton application={application}/> : undefined}/>
        <CardContent>
            <Grid container>
                <Grid item xs={4}>
                    <LabelAndValue label={t('application.crefo.interaction.searchDate')}
                                   value={formatDateTimeWithSeconds(crefoInteraction.timestamp)}/>
                </Grid>
                <Grid item xs={4}>
                    <LabelAndValue label={t('application.crefo.interaction.stateLabel')}
                                   value={
                                       <strong>{t('application.crefo.interaction.state.' + (state ?? 'unknown') as any)}</strong>}/>
                </Grid>
            </Grid>
            <CrefoInteractionResultsTable application={application}
                                          crefoInteraction={crefoInteraction}
                                          additionalRow={additionalRow}
            />
        </CardContent>
    </AppCard>);
};

interface CrefoInteractionResultsTableProps {
    application: BaseMainProfiCardApplicationUnion;
    crefoInteraction: CrefoInteractionDto;
    additionalRow?: ReactNode;
}

const CrefoInteractionResultsTable: FC<CrefoInteractionResultsTableProps> = ({
                                                                                 application,
                                                                                 crefoInteraction,
                                                                                 additionalRow,
                                                                             }) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const detailsList = crefoInteraction.details;
    const applicationNumber = application.applicationNumber!!;
    const isReadOnlyMode = [ApplicationStatus.READY, ApplicationStatus.SUCCESSFUL_COMPLETED, ApplicationStatus.WITHDRAWN, ApplicationStatus.REJECTED].includes(application.status);

    const selectedCrefoDetailsId = crefoInteraction.selectedCrefoDetails?.id;

    const onRetrieveCrefoDetails = (crefoDetails: CrefoDetailsDto) => {
        setIsLoading(true);

        retrieveCrefoHitDetails(applicationNumber, crefoDetails.id!!)
                .then((crefoDetails) => {
                    if (crefoDetails.status === CrefoDetailsStatusDto.ERROR) {
                        throw new Error('Error to retrieve crefo details');
                    }
                    showSnackbar({
                        message: t('application.crefo.interaction.row.retrieve.success'),
                        severity: 'success',
                    });
                })
                .catch(() => {
                    showSnackbar({
                        message: t('application.crefo.interaction.row.retrieve.failed'),
                        severity: 'error',
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    const onSelectCrefoDetails = (crefoDetails: CrefoDetailsDto) => {
        setIsLoading(true);
        selectCrefoDetails(application, crefoDetails.id!!)
                .then(() => {
                    showSnackbar({
                        message: t('application.crefo.interaction.row.select.success'),
                        severity: 'success',
                    });
                })
                .catch((reason) => {
                    processModificationResponseError(reason, applicationNumber);
                    showSnackbar({
                        message: t('application.crefo.interaction.row.select.failed'),
                        severity: 'error',
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                });
    };

    return (<StickyHeaderTable headerRow={
        <TableRow>
            <TableCell>{t('application.crefo.interaction.table.header.crefoId')}</TableCell>
            <TableCell>{t('application.crefo.interaction.table.header.companyName')}</TableCell>
            <TableCell>{t('application.crefo.interaction.table.header.companyAddress')}</TableCell>
            <TableCell>{t('application.crefo.interaction.table.header.city')}</TableCell>
            <TableCell
                    sx={{minWidth: '300px'}}>{t('application.crefo.interaction.table.header.crefoInformaction')}</TableCell>
            <TableCell></TableCell>
        </TableRow>}>
        {detailsList?.map(crefoDetails => {
                    const isSelected = selectedCrefoDetailsId === crefoDetails.id;
                    const isSelectedOtherRow = selectedCrefoDetailsId !== undefined && !isSelected;
                    const crefoDataAvailable = crefoDetails.status === CrefoDetailsStatusDto.RETRIEVED; // crefoDetails.limit || crefoDetails.solvencyIndex;
                    const rowSx = isSelected ? {fontWeight: 'bold'} : undefined;

                    return (<TableRow key={crefoDetails.id}>
                        <TableCell sx={rowSx}>{crefoDetails.crefoNumber}</TableCell>
                        <TableCell sx={rowSx}>{crefoDetails.crefoName}</TableCell>
                        <TableCell
                                sx={rowSx}>{crefoDetails.crefoAddress?.street} {crefoDetails.crefoAddress?.houseNumber}</TableCell>
                        <TableCell
                                sx={rowSx}>{crefoDetails.crefoAddress?.zipCode} {crefoDetails.crefoAddress?.city} {crefoDetails.crefoAddress?.countryIsoCode}</TableCell>
                        {crefoDataAvailable && <TableCell sx={rowSx}>
                            <CrefoDetailsInformation crefoDetails={crefoDetails}/>
                        </TableCell>}
                        {!crefoDataAvailable && <TableCell sx={rowSx}>
                            <Typography variant={'body2'}
                                        sx={theme => ({color: theme.palette.warning.main})}>{t('application.crefo.details.row.noRetrievedYet')}</Typography>
                        </TableCell>}
                        <TableCell sx={rowSx}>
                            {!isReadOnlyMode && !isSelected && !isSelectedOtherRow && !crefoDataAvailable &&
                                    <Button variant={'outlined'} onClick={() => onRetrieveCrefoDetails(crefoDetails)}>
                                        {t('application.crefo.details.row.queryCrefoButton')}
                                    </Button>}
                            {!isReadOnlyMode && !isSelected && !isSelectedOtherRow && crefoDataAvailable &&
                                    <Button variant={'outlined'} onClick={() => onSelectCrefoDetails(crefoDetails)}>
                                        {t('application.crefo.details.row.selectButton')}
                                    </Button>}
                            {isSelected && <Typography variant={'body1'}
                                                       sx={{fontWeight: 'bold'}}><DoneIcon/>{t('application.crefo.details.row.selected')}
                            </Typography>}
                        </TableCell>
                    </TableRow>);
                }
        )}
        {additionalRow}
    </StickyHeaderTable>);
};

interface CrefoDetailsInformationProps {
    crefoDetails: CrefoDetailsDto;
}

const CrefoDetailsInformation: FC<CrefoDetailsInformationProps> = ({crefoDetails}) => {
    const {t} = useTranslation();

    return (<>
        <LabelAndValue xsLabel={8} label={t('application.crefo.details.row.limit')}
                       value={formatAmountWithCurrency(crefoDetails.limit)}/>
        <LabelAndValue xsLabel={8} label={t('application.crefo.details.row.solvencyIndex')}
                       value={crefoDetails.solvencyIndex ?? '-'}/>
        <LabelAndValue xsLabel={8} label={t('application.crefo.details.row.paymentMode')}
                       value={crefoDetails.paymentMode ?? '-'}/>
        <LabelAndValue xsLabel={8} label={t('application.crefo.details.row.resultCode')}
                       value={crefoDetails.resultCode ?? '-'}/>
        <LabelAndValue xsLabel={8} label={t('application.crefo.details.row.productType')}
                       value={crefoDetails.productType ?? '-'}/>
    </>);
};
