import React from 'react';
import {useTranslation} from 'react-i18next';
import {AppContent} from '../../components/AppContent';
import {Grid, Stack, Typography} from '@mui/material';
import {z} from 'zod';
import {formatLocalDate, getDateSchema, maxToday} from '../../utils/utils';
import {useAppForm} from '../../components/form/useAppForm';
import {AppDatePickerField} from '../../components/form/AppDatePickerField';
import {FormSection} from '../../components/form/FormSection';
import {FormRow} from '../../components/form/FormRow';
import {AppFormButton} from '../../components/form/AppFormButton';
import {determineFormActionType} from '../../components/form/utils';
import {FormProvider} from 'react-hook-form';
import {getDetailedReport, getSummaryReport} from './service';
import {downloadFile} from '../../utils/downloadFile';
import {useSnackbar} from '../../components/SnackbarHelper';

enum FormActionType {
    CREFO_SUMMARY_REPORT = 'CREFO_SUMMARY_REPORT',
    CREFO_DETAILED_REPORT = 'CREFO_DETAILED_REPORT',
}

const reportFormSchema = z.object({
    from: getDateSchema(true, zodDate => zodDate.max(maxToday())),
    to: getDateSchema(true, zodDate => zodDate.max(maxToday())),
});

type ReportForm = z.infer<typeof reportFormSchema>;

function subtractMonthAndMinDate(date: Date, months: number) {
    const newMonthNumber = date.getMonth() - months;
    date.setMonth(newMonthNumber);
    date.setHours(0, 0, 0, 0);
    return date;
}

export function ReportsPage() {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const formData = useAppForm<ReportForm>(reportFormSchema, {
        from: subtractMonthAndMinDate(maxToday(), 1),
        to: maxToday(),
    });

    const handleSubmit = formData.handleSubmit;
    const wrapPromiseToProcessForm = formData.wrapPromise;
    const processSubmit = (data: ReportForm, event?: React.BaseSyntheticEvent) => {
        const actionType = determineFormActionType(event);

        function getReport(from: Date, to: Date) {
            switch (actionType) {
                case FormActionType.CREFO_SUMMARY_REPORT:
                    return getSummaryReport(from, to)
                            .then(blob => downloadFile(blob, `HRB-Crefo-Report-Summenliste-${formatLocalDate(from)}--${formatLocalDate(to)}.xlsx`));
                case FormActionType.CREFO_DETAILED_REPORT:
                    return getDetailedReport(from, to)
                            .then(blob => downloadFile(blob, `HRB-Crefo-Report-Detailliste-${formatLocalDate(from)}--${formatLocalDate(to)}.xlsx`));
                default:
                    throw new Error('Unsupported form action: ' + actionType);
            }
        }

        wrapPromiseToProcessForm(getReport(data.from as Date, data.to as Date))
                .then(() => showSnackbar({severity: 'success', message: t('reports.download.success')}))
                .catch(() => showSnackbar({severity: 'error', message: t('reports.download.error')}));
    };

    return (<AppContent label={<Typography variant="h4">{t('reports.title')}</Typography>}>
                <FormProvider {...formData}>
                    <form onSubmit={handleSubmit(processSubmit)}>
                        <FormSection title={''}>
                            <FormRow>
                                <AppDatePickerField<ReportForm> breakpoint={3}
                                                                fieldPath={'from'}
                                                                label={t('reports.dateFrom.label')}
                                                                required={true}
                                                                disableFuture={true}/>
                                <AppDatePickerField<ReportForm> breakpoint={3}
                                                                fieldPath={'to'}
                                                                label={t('reports.dateTo.label')}
                                                                required={true}
                                                                disableFuture={true}/>
                            </FormRow>
                            <Grid container spacing={2} sx={theme => ({
                                marginTop: theme.spacing(1),
                            })}>
                                <Grid item xs={6}>
                                    <Stack spacing={2} direction="column">
                                        <AppFormButton name={FormActionType.CREFO_SUMMARY_REPORT}>
                                            {t('reports.summary.button')}
                                        </AppFormButton>
                                        <AppFormButton name={FormActionType.CREFO_DETAILED_REPORT}>
                                            {t('reports.detailed.button')}
                                        </AppFormButton>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </FormSection>
                    </form>
                </FormProvider>
            </AppContent>
    );
}
