import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {ReferenceSelectFieldProps} from '../reference/ReferenceSelectFieldProps';
import {UserRole} from './model';

export function UserRoleSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const roles: string[] = Object.keys(UserRole);

    return (<AppAutocompleteField<string, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                        options={roles}
                                                        getLabel={option => option}
                                                        getId={option => option}
                                                        fieldPath={props.fieldPath}
                                                        disabled={props.disabled}
                                                        label={t('user.management.form.role')}
                                                        required={props.required}
                                                        size={props.size}
                                                        readOnly={props.readOnly}
    />);

}
