import {FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import {SelectProps} from '@mui/material/Select/Select';
import React, {PropsWithChildren} from 'react';
import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId} from './utils';

interface SelectOption<TOption> {
    value: TOption;
    label: string;
}

interface AppRadioButtonFieldProps<TOption = string, TFieldValues extends FieldValues = FieldValues> extends SelectProps<TOption> {
    options: SelectOption<TOption>[];
    fieldPath: FieldPath<TFieldValues>;
    label?: string;
    helperText?: React.ReactNode;
    breakpoint?: boolean | GridSize;
    readOnly?: boolean;
}

export function AppRadioButtonField<TOption, TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<AppRadioButtonFieldProps<TOption, TFieldValues>>) {
    const {control} = useFormContext();

    const labelId = fieldPathToId(props.fieldPath) + '-label';

    return (
        <Grid item xs={props.breakpoint}>
            <Controller control={control}
                        name={props.fieldPath}
                        render={({field, fieldState: {error}}) => {
                            const hasError = error !== undefined;
                            const selectOption = props.options.find(option => option.value === field.value);
                            return (<FormControl error={hasError}>
                                <FormLabel error={hasError} id={labelId}>{props.label}</FormLabel>
                                <RadioGroup
                                    {...field}
                                    value={selectOption?.label ?? null}
                                    onChange={(_, value) => {
                                        const selectOption = props.options.find(option => option.label === value);
                                        field.onChange(selectOption?.value ?? null);
                                        field.onBlur();
                                    }}
                                    row
                                    aria-labelledby={labelId}
                                    name={props.fieldPath}
                                >
                                    {props.options.map(option => {
                                        return (<FormControlLabel key={option.label}
                                                                  value={option.label}
                                                                  control={<Radio disabled={props.readOnly}/>}
                                                                  label={option.label}/>);
                                    })}
                                </RadioGroup>
                                {(hasError || props.helperText !== undefined) &&
                                    <FormHelperText>{hasError ? getErrorLabel(error) : props.helperText}</FormHelperText>}
                            </FormControl>);
                        }}
            />
        </Grid>
    );
}
