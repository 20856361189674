import styled from '@emotion/styled';
import {ThumbUpAltOutlined, PanToolOutlined} from '@mui/icons-material';
import {Chip} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

const StyledChip = styled(Chip)`
    text-transform: uppercase;
`;

interface UserStatusLabelProps {
    isActive?: boolean,
    size?: 'small' | 'medium';
}

export const UserStatusLabel: FC<UserStatusLabelProps> = ({isActive, size = 'medium'}) => {
    const {t} = useTranslation();
    const text = isActive ? t('user.status.active') : t('user.status.blocked');

    if (isActive) {
        return <StyledChip label={text}
                           variant="outlined"
                           color="success"
                           icon={<ThumbUpAltOutlined color="success"/>}
                           size={size}
        />;
    }

    return <StyledChip label={text}
                       variant="outlined"
                       color="error"
                       icon={<PanToolOutlined color="error"/>}
                       size={size}
    />;
};
