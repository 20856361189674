import {FieldPath} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import React from 'react';
import {mapEnum} from '../../utils/utils';

export function fieldPathToId<TFieldValues extends FieldValues = FieldValues>(fieldPath: FieldPath<TFieldValues>) {
    const str = fieldPath as string;
    return str.replaceAll('.', '_');
}

export function markLabelAsMandatory(label: string | undefined | null, isRequired: boolean | undefined) {
    if (label) {
        return isRequired ? label.concat(' *') : label;
    }
}

export function determineFormActionType<T>(event?: React.BaseSyntheticEvent): T | undefined {
    const submitterName = (event?.nativeEvent as any)?.submitter?.name;
    return mapEnum(submitterName);
}
