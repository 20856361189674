/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OriginatorDto {
    PROFI_CARD_GERMANY = 'PROFI_CARD_GERMANY',
    PROFI_CARD_LUXEMBURG = 'PROFI_CARD_LUXEMBURG',
    PROFI_CARD_SWITZERLAND = 'PROFI_CARD_SWITZERLAND',
    PROJEKT_WELT_GERMANY = 'PROJEKT_WELT_GERMANY',
}
