import React, {FC, useMemo} from 'react';
import {ApplicationStatus} from '../../domain/application/model';
import {StickyHeaderTable} from '../../components/StickyHeaderTable';
import {TableCell, TableRow, Typography} from '@mui/material';
import {useGetApplicationHistoryByApplicationNumber} from '../../domain/application/service';
import {Progress} from '../../components/Loader';
import {formatDateTime, MandatoryChildren} from '../../utils/utils';
import 'react-json-pretty/themes/monikai.css';
import {HistoryActionDto, HistoryDiffDto, MoneyDto, Operation} from '../../api';
import JSONPretty from 'react-json-pretty';
import {Trans, useTranslation} from 'react-i18next';
import {styled} from '@mui/material/styles';
import {ApplicationStatusLabel} from '../../domain/application/ApplicationStatusLabel';
import {tryTranslate} from '../../utils/tryTranslate';
import PrintMoney from '../../domain/common/components/PrintMoney';
import EmptyValue from '../../domain/common/components/EmptyValue';

interface ApplicationHistoryTabProps {
    applicationNumber: string;
}

export const ApplicationHistoryTab: FC<ApplicationHistoryTabProps> = ({applicationNumber}) => {
    const {history, isFetching} = useGetApplicationHistoryByApplicationNumber(applicationNumber);

    if (isFetching) {
        return <Progress size={28}/>;
    }

    return (<StickyHeaderTable headerRow={<TableRow>
        <TableCell>When</TableCell>
        <TableCell>Who</TableCell>
        <TableCell>Property</TableCell>
        <TableCell>Old value</TableCell>
        <TableCell>New value</TableCell>
    </TableRow>}>
        {history?.items?.map(item =>
                <HistoryOperationRows key={item.eventTimestamp} historyAction={item}/>
        )}
    </StickyHeaderTable>);
};

interface HistoryOperationRowsProps {
    historyAction: HistoryActionDto;
}

const HistoryOperationRows: FC<HistoryOperationRowsProps> = ({historyAction}) => {
    const historyItems = useMemo(() => historyAction.diffs?.map(item => ({
        ...item,
        id: `${item.path}-${new Date().getTime()}`
    })), [historyAction]);

    return (<>
        {historyItems?.map(item =>
                <TableRow key={item.id}>
                    <StyledHistoryCell>{formatDateTime(historyAction.eventTimestamp)}</StyledHistoryCell>
                    <StyledHistoryCell>{historyAction.changedBy?.displayName ?? historyAction.changedBy?.username ?? '-'}</StyledHistoryCell>
                    <HistoryOperationRow operation={item}/>
                </TableRow>
        )}
    </>);
};

interface HistoryOperationRowProps {
    operation: HistoryDiffDto;
}

const HistoryOperationRow: FC<HistoryOperationRowProps> = ({operation}) => {
    const {t} = useTranslation();
    const {op, path, value: valueRaw, fromValue: fromValueRaw} = operation;

    const value = valueRaw;
    const fromValue = fromValueRaw;

    if (op === Operation.REPLACE && path?.trim() === '' && value) {
        if (value.id && value.status && value.applicationType && value.applicationNumber) {
            return <HistoryOperationCreatedObject operation={operation}/>;
        }
    }
    if (path === '/status') {
        return <HistoryOperationStatusChanged operation={operation}/>;
    }
    if (path === '/processingErrorReason') {
        return <HistoryOperationErrorProcessing operation={operation}/>;
    }
    if (value?.amount && value?.currency) {
        return <HistoryOperationMoneyChanged operation={operation}/>;
    }
    if (value === '' || value === null || value === undefined || op === Operation.REMOVE) {
        return (<>
            <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
            <StyledOldValueCell><PrintValue value={value}/></StyledOldValueCell>
            <StyledTableCell><StyledPropertyRemoved>{t('application.history.removed')}</StyledPropertyRemoved></StyledTableCell>
        </>);
    }
    if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
        return (<>
            <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
            <StyledOldValueCell><PrintValue value={fromValue}/></StyledOldValueCell>
            <StyledNewValueCell><PrintValue value={value}/></StyledNewValueCell>
        </>);
    }
    if (op === Operation.ADD) {
        return (<>
            <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
            <StyledOldValueCell><PrintValue value={fromValue}/></StyledOldValueCell>
            <StyledNewValueCell><PrintValue value={value}/></StyledNewValueCell>
        </>);
    }

    return <TableCell colSpan={3}><JSONPretty data={operation}/></TableCell>;
};

interface PrintValueProps {
    value: any;
}

const PrintValue: FC<PrintValueProps> = ({value}) => {
    const {t} = useTranslation();

    if (value === true) {
        return <>{t('application.history.checked')}</>;
    }
    if (value === false) {
        return <>{t('application.history.unchecked')}</>;
    }
    if (value === undefined || value === null || value === '') {
        return <EmptyValue/>;
    }
    if (value.amount && value.currency) {
        return <PrintMoney money={value}/>;
    }
    if (value.key && value.value) {
        return <>{value.key}: {value.value}</>;
    }
    if (typeof value === 'string') {
        const text = tryTranslate(t, 'application.form.additionalCard.cardDispatch.', value) ??
                tryTranslate(t, 'application.form.bankDetails.paymentMethodOptions.', value) ??
                tryTranslate(t, 'application.history.limitAssignmentPath.', value) ??
                value;
        return <>{text}</>;
    }
    if (typeof value === 'object') {
        return <JSONPretty data={value}/>;
    }
    return <>{value}</>;
};

const HistoryOperationMoneyChanged: FC<HistoryOperationItem> = ({operation: {op, path, value, fromValue}}) => {
    const {t} = useTranslation();
    const money = value as MoneyDto;

    if (op === Operation.REMOVE || value === null || value === undefined) {
        return (<>
            <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
            <StyledOldValueCell><PrintMoney money={money}/></StyledOldValueCell>
            <StyledNewValueCell>{t('application.history.removed')}</StyledNewValueCell>
        </>);
    }
    return (<>
        <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
        <StyledOldValueCell><PrintMoney money={fromValue as MoneyDto}/></StyledOldValueCell>
        <StyledNewValueCell><PrintMoney money={money}/></StyledNewValueCell>
    </>);
};

interface HistoryOperationItem {
    operation: HistoryDiffDto;
}

interface PrintPropertyProps {
    path: string | undefined;
}

const PrintProperty: FC<PrintPropertyProps> = ({path}) => {
    const {t} = useTranslation();

    const pathText = useMemo(() => translatePath(t, path), [t, path]);

    return <span title={path?.slice(1).replaceAll('/', ' / ')}>{pathText ?? '-'}</span>;
};

function translatePath(t: Function, path: string | undefined): string | undefined {
    if (path === undefined) {
        return '-';
    }

    const pathText = tryTranslate(t, 'application.history.property.', path);
    if (pathText !== undefined) {
        return pathText;
    }

    function tryToGetLabel(pathPart: string) {
        return tryTranslate(t, 'application.form.', pathPart + '.sectionTitle') ??
                tryTranslate(t, 'application.form.', pathPart + '.main.sectionTitle') ??
                tryTranslate(t, 'application.form.', pathPart + '.additional.sectionTitle') ??
                tryTranslate(t, 'common.form.contact.', pathPart) ??
                tryTranslate(t, 'common.form.address.', pathPart) ??
                tryTranslate(t, 'common.form.person.', pathPart) ??
                tryTranslate(t, 'application.form.', pathPart) ??
                tryTranslate(t, 'application.form.', pathPart + '.label') ??
                tryTranslate(t, 'application.form.additionalCard.', pathPart + '.title') ??
                tryTranslate(t, 'application.form.additionalCard.', pathPart) ??
                tryTranslate(t, 'application.history.property.', pathPart) ??
                tryTranslate(t, 'address.picker.preview.', pathPart) ??
                tryTranslate(t, 'application.form.marketData.', pathPart) ??   // to support pinForWebshop in additionalProfiCard
                undefined;
    }

    const result = path.slice(1).split('/')
            .filter((value, index, array) => {
                return array.indexOf(value, index + 1) === -1;  // remove two equal elements next to each other, eg: [a,a,b] -> [a,b]
            })
            .map(pathPart => {
                const label = tryToGetLabel(pathPart);
                return {label, pathPart};
            });

    // try to get label by combining translation key from the previous element and the current one
    for (let i = 1; i < result.length; i++) {
        const currentItem = result[i];
        if (currentItem.label === undefined) {
            const previousItem = result[i - 1];
            currentItem.label = tryToGetLabel(previousItem.pathPart + '.' + currentItem.pathPart);
        }
    }

    return result
            .filter(r => r.label !== '')
            .map(r => r.label ?? r.pathPart)
            .join(' / ');
}

const HistoryOperationErrorProcessing: FC<HistoryOperationItem> = ({operation: {op, path, fromValue, value}}) => {
    const {t} = useTranslation();
    if (op === Operation.REMOVE) {
        return (<>
            <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
            <StyledOldValueCell>{t('application.history.processingErrorReason.' + (value ?? 'empty') as any)}</StyledOldValueCell>
            <StyledNewValueCell>{t('application.history.processingErrorReason.empty')}</StyledNewValueCell>
        </>);
    }
    return (<>
        <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
        <StyledOldValueCell>{t('application.history.processingErrorReason.' + (fromValue ?? 'empty') as any)}</StyledOldValueCell>
        <StyledNewValueCell>{t('application.history.processingErrorReason.' + (value ?? 'empty') as any)}</StyledNewValueCell>
    </>);
};

const HistoryOperationStatusChanged: FC<HistoryOperationItem> = ({operation: {path, fromValue, value}}) => {
    return (<>
        <StyledPropertyNameCell><PrintProperty path={path}/></StyledPropertyNameCell>
        <StyledOldValueCell>
            <ApplicationStatusLabel status={fromValue as ApplicationStatus}/>
        </StyledOldValueCell>
        <StyledNewValueCell>
            <ApplicationStatusLabel status={value as ApplicationStatus}/>
        </StyledNewValueCell>
    </>);
};

const HistoryOperationCreatedObject: FC<HistoryOperationItem> = ({operation: {value}}) => {
    return (<TableCell colSpan={3}>
        <HistoryEntryText>
            <Trans i18nKey="application.history.applicationCreated"
                   components={[
                       <StyledPropertyName key="object"/>,
                       <StyledPropertyNewValue key="status"/>
                   ]}/>
        </HistoryEntryText>
        <JSONPretty data={value}/>
    </TableCell>);
};

const HistoryEntryText: FC<MandatoryChildren> = ({children}) => {
    return <Typography>{children}</Typography>;
};

const StyledTableCell = styled(TableCell)({
    verticalAlign: 'top',
    paddingTop: '24px',
});

const StyledHistoryCell = styled(StyledTableCell)({
    verticalAlign: 'top',
});

const StyledPropertyName = styled('em')({
    fontWeight: 'bold',
});

const StyledPropertyNameCell = styled(StyledTableCell)({
    fontWeight: 'bold',
});

const StyledOldValueCell = styled(StyledTableCell)({
    color: '#9fa19f',
    fontWeight: 'bold',
    textDecorationLine: 'line-through',
});

const StyledPropertyNewValue = styled('em')({
    color: '#008800',
    fontWeight: 'bold',
});

const StyledNewValueCell = styled(StyledTableCell)({
    color: '#008800',
    fontWeight: 'bold',
});

const StyledPropertyRemoved = styled('em')({
    color: '#ec0f0f',
    fontWeight: 'bold',
});
