import {CardHolderDto, MainCardDto, OriginatorDto} from '../../../api';
import {Originator} from '../../../utils/OriginatorProvider';
import {formatLocalDate, parseLocalDate} from '../../../utils/utils';
import {mapToMoneyDto, mapToMoneyModel} from '../../money/mapper';
import {findOptionEntryByKey, getReferences} from '../../reference/service';
import {CardHolder, MainCard} from './model';

export function mapModelToMainCardDto(mainCard: MainCard | undefined | null): MainCardDto {
    return {
        mainCardNumber: mainCard?.mainCardNumber ?? '',
        expiryDate: {
            year: mainCard?.expiryDate?.year ?? undefined,
            month: mainCard?.expiryDate?.month ?? undefined,
        },
        cardLimit: mapToMoneyDto(mainCard?.cardLimit),
        companyName: mainCard?.companyName,
        salutation: mainCard?.salutation ?? undefined,
        title: mainCard?.title ?? undefined,
        firstName: mainCard?.firstName,
        lastName: mainCard?.lastName,
        email: mainCard?.email,
        cellPhoneNumber: mainCard?.cellPhoneNumber,
        phoneNumber: mainCard?.phoneNumber,
        dateOfBirth: formatLocalDate(mainCard?.dateOfBirth),
        address: {
            additionalLine: mainCard?.address?.additionalLine,
            street: mainCard?.address?.street,
            houseNumber: mainCard?.address?.houseNumber,
            zipCode: mainCard?.address?.zipCode,
            city: mainCard?.address?.city,
            country: mainCard?.address?.country ?? undefined,
        }
    };
}

export async function mapDtoToAdditionalMainCardModel(mainCard: MainCardDto | undefined | null, originator?: OriginatorDto): Promise<MainCard> {
    const {salutations, titles, countryCodes} = await getReferences(originator ?? Originator.current!!);
    return {
        mainCardNumber: mainCard?.mainCardNumber ?? '',
        expiryDate: {
            year: mainCard?.expiryDate?.year,
            month: mainCard?.expiryDate?.month,
        },
        cardLimit: mapToMoneyModel(mainCard?.cardLimit),
        companyName: mainCard?.companyName ?? '',
        salutation: findOptionEntryByKey(salutations, mainCard?.salutation?.key),
        title: findOptionEntryByKey(titles, mainCard?.title?.key),
        firstName: mainCard?.firstName ?? '',
        lastName: mainCard?.lastName ?? '',
        email: mainCard?.email ?? '',
        cellPhoneNumber: mainCard?.cellPhoneNumber ?? '',
        phoneNumber: mainCard?.phoneNumber ?? '',
        dateOfBirth: parseLocalDate(mainCard?.dateOfBirth),
        address: {
            additionalLine: mainCard?.address?.additionalLine ?? '',
            street: mainCard?.address?.street ?? '',
            houseNumber: mainCard?.address?.houseNumber ?? '',
            zipCode: mainCard?.address?.zipCode ?? '',
            city: mainCard?.address?.city ?? '',
            country: findOptionEntryByKey(countryCodes, mainCard?.address?.country?.key),
        }
    };
}

export function mapModelToCardHolderDto(mainCard: CardHolder | undefined | null): CardHolderDto {
    return {
        salutation: mainCard?.salutation ?? undefined,
        title: mainCard?.title ?? undefined,
        firstName: mainCard?.firstName,
        lastName: mainCard?.lastName,
        email: mainCard?.email,
        cellPhoneNumber: mainCard?.cellPhoneNumber,
        dateOfBirth: formatLocalDate(mainCard?.dateOfBirth),
    };
}

export async function mapDtoToAdditionalCardHolderModel(cardHolder: CardHolderDto | undefined | null, originator?: OriginatorDto): Promise<CardHolder> {
    const {salutations, titles} = await getReferences(originator ?? Originator.current!!);
    return {
        salutation: findOptionEntryByKey(salutations, cardHolder?.salutation?.key),
        title: findOptionEntryByKey(titles, cardHolder?.title?.key),
        firstName: cardHolder?.firstName ?? '',
        lastName: cardHolder?.lastName ?? '',
        email: cardHolder?.email ?? '',
        cellPhoneNumber: cardHolder?.cellPhoneNumber ?? '',
        dateOfBirth: parseLocalDate(cardHolder?.dateOfBirth),
    };
}

