import {SnackbarData} from '../../components/SnackbarHelper';
import {ApiError} from '../../api';
import i18n from '../../i18n';
import {User, UserRole} from './model';

export function processUserModificationResponseError(username: string | undefined, showSnackbar: (data: SnackbarData) => void) {
    return (reason: ApiError) => {
        if (reason.status === 404) {
            showSnackbar({
                message: i18n.t('translation:user.management.error.notFound', {username}),
                severity: 'error',
            });
        } else if (reason.status === 400) {
            // bad request -> nothing to do here, errors will be presented on the form
        } else {
            showSnackbar({
                message: i18n.t('translation:application.error.otherError'),
                severity: 'error',
            });
        }
        throw reason;
    };
}

export function hasRole(user: User, role: UserRole) {
    return user.role === role;
}

export function hasAnyRole (user: User, roles: UserRole[]) {
    const userRole = user.role;
    return userRole && roles.includes(userRole);
}


export function isAdmin(user: User) {
    if(user.userAdminRights === null || user.userAdminRights === undefined) {
        return false;
    }
    return user.userAdminRights;
}

export function hasOnlyAdminRole(user: User){
    return isAdmin(user) && !hasRole(user, UserRole.KNISTR);
}
