import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {ReferenceSelectFieldProps} from '../reference/ReferenceSelectFieldProps';
import {UserSearchStatus} from './model';

export function UserStatusSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    const statuses = Object.values(UserSearchStatus);

    return (<AppAutocompleteField<UserSearchStatus, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                                  options={statuses}
                                                                  getLabel={option => t(`user.status.${option.toLowerCase() as 'active' | 'blocked' | 'all'}`)}
                                                                  getId={option => option}
                                                                  fieldPath={props.fieldPath}
                                                                  disabled={props.disabled}
                                                                  label={t('user.status.status')}
                                                                  required={props.required}
                                                                  size={props.size}
                                                                  readOnly={props.readOnly}
            />
    );
}
