import {Grid, InputAdornment, InputLabel, TextField} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import React, {PropsWithChildren} from 'react';
import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {NumericFormat} from 'react-number-format';
import {Currency} from '../../api';
import {Money} from '../../domain/money/model';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId, markLabelAsMandatory} from './utils';

export interface AppAmountFieldProps<TFieldValues extends FieldValues = FieldValues> {
    label: string;
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    helperText?: React.ReactNode;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    defaultCurrency?: Currency;
}

export function AppAmountField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<AppAmountFieldProps<TFieldValues>>) {
    const {control} = useFormContext();
    return (
        <Grid item xs={props.breakpoint}>
            <Controller control={control}
                        name={props.fieldPath}
                        render={({field, fieldState: {error}}) => {
                            const hasError = error !== undefined;
                            const specificError = (error as any)?.amount ?? (error as any)?.currency ?? error;

                            const money = field.value as Money;
                            const currency = money?.currency ?? props.defaultCurrency ?? Currency.EUR;
                            return (
                                <>
                                    <InputLabel error={hasError}
                                                htmlFor={fieldPathToId(props.fieldPath)}>{markLabelAsMandatory(props.label, props.required)}</InputLabel>
                                    <NumericFormat
                                        id={fieldPathToId(props.fieldPath)}
                                        value={money?.amount ?? null}
                                        disabled={props.disabled ?? false}
                                        onChange={event => {
                                            const normalizedTextNumber = event.target.value
                                                .replaceAll(' ', '')
                                                .replaceAll(',', '.');
                                            if (normalizedTextNumber !== '') {
                                                field.onChange({
                                                    amount: parseFloat(normalizedTextNumber),
                                                    currency,
                                                });
                                            } else {
                                                field.onChange(null);
                                            }
                                        }}
                                        onBlur={field.onBlur}
                                        getInputRef={field.ref}
                                        name={field.name}
                                        thousandSeparator={' '}
                                        decimalSeparator={','}
                                        decimalScale={0}
                                        fixedDecimalScale={true}
                                        customInput={TextField}
                                        sx={{
                                            textAlign: 'right'
                                        }}
                                        error={hasError}
                                        helperText={hasError ? getErrorLabel(specificError) : props.helperText}
                                        variant="outlined"
                                        fullWidth={true}
                                        InputProps={{
                                            readOnly: props.readOnly,
                                            endAdornment: <InputAdornment sx={{position: 'relative', top: '8px'}}
                                                                          position="end">{getCurrencySymbol(currency)}</InputAdornment>
                                        }}
                                    />
                                </>);
                        }}
            />
        </Grid>
    );
}

export function getCurrencySymbol(currency: Currency | undefined) {
    if (currency === undefined) {
        return '-';
    }
    switch (currency) {
        case Currency.EUR:
            return '€';
        case Currency.CHF:
            return '₣';
        default:
            // @ts-ignore -- fallback: print the name of the Currency
            return currency.toString();
    }
}
