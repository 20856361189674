import {z} from 'zod';
import {ApplicationTypeDto, CorrespondenceLanguageDto, Currency} from '../../../../../api';
import AppConfig from '../../../../../config';
import {CountryUtils} from '../../../../country';
import {User} from '../../../../user/model';
import {BaseApplication} from '../../../model';
import {baseMainProfiCardApplicationSchema, createEmptyBaseMainProfiCardApplication} from '../model';

export const mainProfiCardChApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_CH_MAIN),
    correspondenceLanguage: z.nativeEnum(CorrespondenceLanguageDto).optional().nullable(),
})
    .merge(baseMainProfiCardApplicationSchema.sourceType());
export type MainProfiCardChApplication = z.infer<typeof mainProfiCardChApplicationSchema>;

export function isMainProfiCardChApplication(application: BaseApplication | undefined): application is MainProfiCardChApplication {
    return application?.applicationType === ApplicationTypeDto.PROFI_CARD_CH_MAIN;
}

export const mainProfiCardChLegitimizationFormSchema = z.object({
    companySignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    principalCardholderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    employeeSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type MainProfiCardChLegitimizationForm = z.infer<typeof mainProfiCardChLegitimizationFormSchema>;

export async function createEmptyMainProfiCardChApplication(user: User): Promise<MainProfiCardChApplication> {
    const applicationType = ApplicationTypeDto.PROFI_CARD_CH_MAIN;
    const baseMainProfiCardApplicationPromise = await createEmptyBaseMainProfiCardApplication(user, applicationType, {
        key: CountryUtils.CH.code,
        value: 'Schweiz',
    });
    return {
        ...baseMainProfiCardApplicationPromise,
        desiredLimit: {
            amount: 2000,
            currency: Currency.CHF,
        },
        applicationType: applicationType,
        marketData: {
            ...baseMainProfiCardApplicationPromise.marketData,
            pinForWebShop: AppConfig.pinForWebShopEnabledForCH,
        },
    };
}
