import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AppFormButton} from '../../components/form/AppFormButton';
import NotStartedRoundedIcon from '@mui/icons-material/NotStartedRounded';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import {activateUser, blockUser, sendPasswordUpdate} from './service';
import {usePageLoaderContext} from '../../components/PageLoader';
import {useSnackbar} from '../../components/SnackbarHelper';
import {processUserModificationResponseError} from './utils';
import {User} from './model';
import MailLockIcon from '@mui/icons-material/MailLock';

interface UserStatusButtonProps {
    user: User;
}

export function UserStatusButton(props: UserStatusButtonProps) {
    return props.user.isActive
            ? <BlockUserButton user={props.user}/>
            : <ActivateUserButton user={props.user}/>;
}

export const BlockUserButton: FC<UserStatusButtonProps> = (props) => {
    const uid = props.user.uid;
    if (!uid) {
        throw new Error('User UID must be set');
    }
    const {t} = useTranslation();
    const showSnackbarMessage = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const [isBlockUserInProgress, setIsBlockUserInProgress] = useState(false);

    const handleBlockUser = () => {
        setIsLoading(true);
        setIsBlockUserInProgress(true);
        blockUser(uid)
                .then(user => {
                    showSnackbarMessage({
                        message: t('user.management.block.success'),
                        severity: 'success',
                    });
                    return user;
                })
                .catch(processUserModificationResponseError(props.user.userName, showSnackbarMessage))
                .finally(() => {
                    setIsBlockUserInProgress(false);
                    setIsLoading(false);
                });
    };

    return (
            <AppFormButton
                    type={'button'}
                    icon={<StopCircleIcon/>}
                    onClick={handleBlockUser}
                    inProgress={isBlockUserInProgress}
                    color={'primary'}>{t('user.management.button.block')}</AppFormButton>
    );
};

export const ActivateUserButton: FC<UserStatusButtonProps> = (props) => {
    const uid = props.user.uid;
    if (!uid) {
        throw new Error('User UID must be set');
    }
    const {t} = useTranslation();
    const showSnackbarMessage = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const [isActivatingUserInProgress, setIsActivatingUserInProgress] = useState(false);

    const handleActivateUser = () => {
        setIsLoading(true);
        setIsActivatingUserInProgress(true);
        activateUser(uid)
                .then((user) => {
                    showSnackbarMessage({
                        message: t('user.management.activate.success'),
                        severity: 'success'
                    });
                    return user;
                })
                .catch(processUserModificationResponseError(props.user.userName, showSnackbarMessage))
                .finally(() => {
                    setIsActivatingUserInProgress(false);
                    setIsLoading(false);
                });
    };

    return (
            <AppFormButton
                    type={'button'}
                    icon={<NotStartedRoundedIcon/>}
                    onClick={handleActivateUser}
                    inProgress={isActivatingUserInProgress}
                    color={'inherit'}>{t('application.overview.actions.reactivate.button')}</AppFormButton>
    );
};

interface ResendInitialPasswordButtonProps {
    user: User;
}

export const SendPasswordUpdateButton: FC<ResendInitialPasswordButtonProps> = ({user}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const showSnackbarMessage = useSnackbar();
    const uid = user.uid;

    if (!uid) {
        throw new Error('Illegal operation: a password challenge can be send only to persisted users');
    }

    const handleResendEmail = () => {
        setInProgress(true);
        sendPasswordUpdate(uid)
                .then((response) => {
                    if (response.initialPasswordEmailSend) {
                        showSnackbarMessage({
                            message: t('user.management.sendPasswordUpdate.success'),
                            severity: 'success',
                        });
                    } else {
                        return Promise.reject(response);
                    }
                })
                .catch(() => {
                    showSnackbarMessage({
                        message: t('user.management.sendPasswordUpdate.error'),
                        severity: 'error',
                    });
                })
                .finally(() => setInProgress(false));
    };

    return (<AppFormButton
            type={'button'}
            icon={<MailLockIcon/>}
            onClick={handleResendEmail}
            inProgress={inProgress}>{t('user.management.sendPasswordUpdate.label')}</AppFormButton>);
};
