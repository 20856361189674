import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import {User} from '../../../user/model';
import {SmartSignUp} from './SmartSignUpForm';
import {ActiveBox} from '../../../../components/ActiveBox';
import {ApplicationForm} from '../../ApplicationForm';
import {ApplicationFormProps} from '../../../../pages/application/NewApplicationPage';
import {z} from 'zod';
import {BaseMainProfiCardApplicationUnion} from '../model';

interface SmartSignUpWithApplicationProps<T extends BaseMainProfiCardApplicationUnion> extends ApplicationFormProps<T> {
    applicationSchema: z.Schema<any, any>;

    createEmptyMainProfiCardApplication(user: User): Promise<T>;

    renderLegitimizationCheckList(application: T): ReactNode;

    renderFields(isReadOnly: boolean): React.ReactNode;

    legitimizationFormSchema(application: T): z.Schema<any, any>;
}

export function SmartSignUpWithApplication<T extends BaseMainProfiCardApplicationUnion>(props: PropsWithChildren<SmartSignUpWithApplicationProps<T>>) {
    const applicationFromProps = props.application;
    const [isSmartSignUpEnabled, setIsSmartSignUpEnabled] = useState(!applicationFromProps.applicationNumber);
    const [application, setApplication] = useState(applicationFromProps);

    useEffect(() => {
        setApplication(applicationFromProps);
    }, [applicationFromProps]);

    return (<>
        {isSmartSignUpEnabled
                && <SmartSignUp onReturnToRegularForm={(searchTerm) => {
                    setIsSmartSignUpEnabled(false);
                    setApplication(prev => ({
                        ...prev,
                        company: {
                            ...prev.company,
                            companyName: searchTerm,
                        }
                    }));
                }}
                                createEmptyMainProfiCardApplication={props.createEmptyMainProfiCardApplication}/>}
        <ActiveBox isActive={isSmartSignUpEnabled}>
            <ApplicationForm<T>
                    application={application}
                    applicationSchema={props.applicationSchema}
                    afterDraftSaved={props.afterDraftSaved}
                    afterLegitimized={props.afterLegitimized}
                    renderFields={props.renderFields}
                    showLegitimizeDialog={props.showLegitimizeDialog}
                    legitimizationFormSchema={props.legitimizationFormSchema}
                    renderLegitimizationCheckList={props.renderLegitimizationCheckList}
            />
        </ActiveBox>
    </>);
}
