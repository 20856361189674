import React, {useMemo} from 'react';
import {Container} from '@mui/material';
import {UserForm} from './UserForm';
import {UserPageLabel} from './UserPageLabel';
import {useOriginatorContext} from '../../utils/OriginatorProvider';
import {ApplicationTypeDto} from '../../api';
import {User} from './model';

const initialUser: Partial<User> = {
    isActive: true,
};

export default function NewUserPage() {
    const {setContextApplicationType} = useOriginatorContext();
    setContextApplicationType(ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL);
    const user = useMemo(() => initialUser as User, []);

    return (<Container>
        <UserPageLabel user={user}/>
        <UserForm user={user}/>
    </Container>);
}

