import React, {useMemo} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {getOptionEntryId, getOptionEntryLabel, isOptionEntry, OptionEntry} from './model';
import {ReferenceSelectFieldProps} from './ReferenceSelectFieldProps';
import {useReferences} from './service';
import {Originator} from '../../utils/OriginatorProvider';
import {DictionaryValueDto} from '../../api';

export function CountrySelectField<TFieldValues extends FieldValues = FieldValues>(props: ReferenceSelectFieldProps<TFieldValues> & {
    availableCountries?: DictionaryValueDto[]
}) {
    const {t} = useTranslation();
    const {countryCodes, isLoading} = useReferences(Originator.current!!);
    const countryOptions = props.availableCountries ?? countryCodes;
    const options = useMemo(() => countryOptions?.reduce<OptionEntry[]>((acc, curr) => {
        if (!isOptionEntry(curr)) {
            return acc;
        }
        return [...acc, curr];
    }, []), [countryOptions]);

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                             options={options}
                                                             isLoading={isLoading}
                                                             getLabel={getOptionEntryLabel}
                                                             getId={getOptionEntryId}
                                                             fieldPath={props.fieldPath}
                                                             disabled={props.disabled}
                                                             label={t('common.form.address.country')}
                                                             required={props.required}
                                                             readOnly={props.readOnly}
                                                             size={props.size}
    />);

}
