import {z} from 'zod';
import {ApplicationTypeDto} from '../../../../../api';
import AppConfig from '../../../../../config';
import {CountryUtils} from '../../../../country';
import {ANY_VALUE_KEY} from '../../../../reference/model';
import {User} from '../../../../user/model';
import {BaseApplication} from '../../../model';
import {baseMainProfiCardApplicationSchema, createEmptyBaseMainProfiCardApplication, Legitimization} from '../model';

export const mainProfiCardLuApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_DE_MAIN),
})
    .merge(baseMainProfiCardApplicationSchema.sourceType());

export type MainProfiCardLuApplication = z.infer<typeof mainProfiCardLuApplicationSchema>;

export function isMainProfiCardLuApplication(application: BaseApplication | undefined): application is MainProfiCardLuApplication {
    return application?.applicationType === ApplicationTypeDto.PROFI_CARD_LU_MAIN;
}

export const mainProfiCardLuLegitimizationFormSchema = z.object({
    companySignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    principalCardholderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    employeeSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type MainProfiCardLuLegitimizationForm = z.infer<typeof mainProfiCardLuLegitimizationFormSchema>;

export async function createEmptyMainProfiCardLuApplication(user: User): Promise<MainProfiCardLuApplication> {
    const applicationType = ApplicationTypeDto.PROFI_CARD_LU_MAIN;
    const baseMainProfiCardApplicationPromise = await createEmptyBaseMainProfiCardApplication(user, applicationType, {
        key: CountryUtils.LU.code,
        value: 'Luxemburg',
    });
    const legitimization = baseMainProfiCardApplicationPromise.legitimization as Legitimization;
    return {
        ...baseMainProfiCardApplicationPromise,
        applicationType: applicationType,
        legitimization: {
            ...legitimization,
            placeOfRegistration: {
                key: ANY_VALUE_KEY,
                value: '',
            }
        },
        marketData: {
            ...baseMainProfiCardApplicationPromise.marketData,
            pinForWebShop: AppConfig.pinForWebShopEnabledForLU,
        },
    };
}
