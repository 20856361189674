import React, {FC, useCallback, useMemo} from 'react';
import {ApplicationTypeDto, OriginatorDto} from '../api';
import {MandatoryChildren} from './utils';

export const Originator: { current: OriginatorDto | null } = {
    current: null,
};

export function mapApplicationTypeToOriginator(applicationType: ApplicationTypeDto) {
    switch (applicationType) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
            return OriginatorDto.PROFI_CARD_GERMANY;

        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
            return OriginatorDto.PROFI_CARD_LUXEMBURG;

        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
            return OriginatorDto.PROFI_CARD_SWITZERLAND;

        case ApplicationTypeDto.PROJEKT_WELT_MAIN:
        case ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL:
            return OriginatorDto.PROJEKT_WELT_GERMANY;
    }
    throw new Error('Unsupported type: ' + applicationType);
}

export type OriginatorType = {
    setContextApplicationType(applicationType: ApplicationTypeDto): void;
};

export const OriginatorContext = React.createContext<OriginatorType>({} as OriginatorType);

export const OriginatorProvider: FC<MandatoryChildren> = ({children}) => {
    const setContextApplicationType = useCallback((applicationType: ApplicationTypeDto) => {
        Originator.current = mapApplicationTypeToOriginator(applicationType);
    }, []);

    const originatorContextValue = useMemo(() => ({setContextApplicationType}), [setContextApplicationType]);

    return (<OriginatorContext.Provider value={originatorContextValue}>
        {children}
    </OriginatorContext.Provider>);
};

export function useOriginatorContext(): OriginatorType {
    return React.useContext(OriginatorContext);
}
