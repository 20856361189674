import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {IconButton, Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {FONT_PROMINENT} from '../../App';

export const BackButton = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    return (
            <IconButton
                size="small"
                onClick={() => navigate(-1)}
                sx={{ pt: 1.75}}>
                <ArrowBackIcon/>
                <Typography sx={{fontFamily: FONT_PROMINENT}}>{t('common.nav.back')}</Typography>
            </IconButton>
    );
};
