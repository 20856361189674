import {MainCard} from './model';
import {Originator} from '../../../utils/OriginatorProvider';
import {ApiError, OriginatorDto} from '../../../api';
import {useTranslation} from 'react-i18next';
import {usePageLoaderContext} from '../../../components/PageLoader';
import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useSnackbar} from '../../../components/SnackbarHelper';
import {useAppForm} from '../../../components/form/useAppForm';
import {MainCardSearch, mainCardSearchSchema} from '../../auxiliary/model';
import {searchMainCard} from '../../auxiliary/service';
import {FormProvider} from 'react-hook-form';
import {Alert} from '@mui/material';
import {FormSection} from '../../../components/form/FormSection';
import {FormRow} from '../../../components/form/FormRow';
import {AppTextField} from '../../../components/form/AppTextField';
import {AppFormButton} from '../../../components/form/AppFormButton';
import {FormActionType} from '../ApplicationForm';
import SearchIcon from '@mui/icons-material/Search';
import {BaseApplication} from '../model';

function getAdditionalCardPrefix() {
    switch (Originator.current) {
        case OriginatorDto.PROFI_CARD_GERMANY:
        case OriginatorDto.PROJEKT_WELT_GERMANY:
            return '60038801';
        case OriginatorDto.PROFI_CARD_LUXEMBURG:
            return '60038803';
        case OriginatorDto.PROFI_CARD_SWITZERLAND:
            return '60038806';
    }
    throw new Error('Unsupported originator to get additional card prefix: ' + Originator.current);
}

function removeCardPrefix(cardNumber: string, cardPrefix: string) {
    if (cardNumber.length >= 16 && cardNumber.startsWith(cardPrefix)) {
        return cardNumber.substring(cardPrefix.length);
    }
    return cardNumber;
}

interface MainCardSearchSectionProps<T extends BaseApplication> {
    onMainCardDataRetrieved(mainCard: MainCard): Promise<T>;
}

export function MainCardSearchSection<T extends BaseApplication>(props: PropsWithChildren<MainCardSearchSectionProps<T>>) {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const [isCardSearchInProgress, setIsCardSearchInProgress] = useState(false);
    const [alertErrorMessage, setAlertErrorMessage] = useState<string>();
    const [serverErrorMessage, setServerErrorMessage] = useState<string>();
    const showSnackbar = useSnackbar();

    const cardNumberPrefix = getAdditionalCardPrefix();
    const formData = useAppForm<MainCardSearch>(mainCardSearchSchema, {
        cardNumberPrefix: cardNumberPrefix,
        cardNumber: '',
    });

    const handleSubmit = formData.handleSubmit;
    const isValid = formData.isValid;
    const wrapPromiseToProcessForm = formData.wrapPromise;
    const setValue = formData.setValue;

    const cardNumber = formData.watch('cardNumber');

    useEffect(() => {
        setValue('cardNumber', removeCardPrefix(cardNumber, cardNumberPrefix));
    }, [cardNumber, cardNumberPrefix, setValue]);
    const processSubmit = (data: MainCardSearch) => {
        setIsLoading(true);
        setIsCardSearchInProgress(true);
        setAlertErrorMessage(undefined);
        setServerErrorMessage(undefined);
        wrapPromiseToProcessForm(searchMainCard(data))
                .then(mainCard => {
                    showSnackbar({
                        message: t('application.form.additionalCard.mainCard.search.found'),
                        severity: 'success',
                    });
                    return props.onMainCardDataRetrieved(mainCard);
                })
                .catch((reason: any) => {
                    const {status, body} = reason as ApiError;
                    const isNotFound = status === 404;
                    const hasValidationError = status === 400;
                    const has500Error = status === 500;

                    if (isNotFound) {
                        formData.setError('cardNumber', {
                            type: 'custom',
                            message: t('application.form.additionalCard.mainCard.search.notFound'),
                        });
                        const errorMessage = t('application.form.additionalCard.mainCard.search.notFoundSnackbar', {cardNumber: data.cardNumberPrefix + data.cardNumber});
                        showSnackbar({
                            message: errorMessage,
                            severity: 'error',
                        });
                        setAlertErrorMessage(errorMessage);
                    }
                    if (hasValidationError && body?.errors?.length > 0) {
                        const errorCode = body.errors[0].errorCode;
                        const errorMessage = t(errorCode);
                        showSnackbar({
                            message: errorMessage,
                            severity: 'error',
                        });
                        setAlertErrorMessage(errorMessage);
                    }
                    if (has500Error) {
                        const errorMessage = t('common.error.serverError');
                        showSnackbar({
                            message: errorMessage,
                            severity: 'error',
                        });
                        setServerErrorMessage(errorMessage);
                    }
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsCardSearchInProgress(false);
                });
    };

    return (
            <FormProvider {...formData}>
                <form onSubmit={handleSubmit(processSubmit)}>
                    {!isValid && alertErrorMessage &&
                            <Alert severity="error">{alertErrorMessage}</Alert>}
                    {serverErrorMessage &&
                            <Alert severity="error">{serverErrorMessage}</Alert>}
                    <FormSection title={t('application.form.additionalCard.mainCard.search.sectionTitle')}>
                        <FormRow>
                            <AppTextField<MainCardSearch> breakpoint={2}
                                                          disabled={true}
                                                          required={true}
                                                          label={t('application.form.additionalCard.mainCard.search.cardNumberPrefix')}
                                                          fieldPath={'cardNumberPrefix'}/>
                            <AppTextField<MainCardSearch> breakpoint={3}
                                                          required={true}
                                                          label={t('application.form.additionalCard.mainCard.search.cardNumber')}
                                                          helperText={t('application.form.additionalCard.mainCard.search.cardNumberSuffixOnly')}
                                                          fieldPath={'cardNumber'}/>
                            <AppFormButton name={FormActionType.SUBMIT}
                                           outlined={false}
                                           sx={{
                                               marginLeft: '15px',
                                               marginTop: '39px',
                                               height: '55px'
                                           }}
                                           color={'secondary'}
                                           inProgress={isCardSearchInProgress}
                                           icon={
                                               <SearchIcon/>}>{t('application.button.search')}</AppFormButton>
                        </FormRow>
                    </FormSection>
                </form>
            </FormProvider>);
}
