import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppTextField} from '../../../../components/form/AppTextField';
import {DateOfBirthPickerField} from '../../../../components/form/DateOfBirthPickerField';
import {FormRow} from '../../../../components/form/FormRow';
import {FormSection} from '../../../../components/form/FormSection';
import {trimAndCapitalizeFirstLetter} from '../../../../utils/utils';
import {PersonTitleSelectField} from '../../../reference/PersonTitleSelectField';
import {SalutationSelectField} from '../../../reference/SalutationSelectField';
import {MainProjektWeltApplication} from './model';

interface AdditionalProjektWeltApplicationFieldsProps {
    readOnly: boolean;
}

export const AdditionalProjektWeltApplicationSection: FC<AdditionalProjektWeltApplicationFieldsProps> = ({
                                                                                                             readOnly
                                                                                                         }) => {
    const {t} = useTranslation();

    return (<FormSection title={t('application.form.projektWelt.additionalCard.sectionTitle')}
                         optional={{
                             optionalFieldPath: 'hasAdditionalCardData',
                             optionalCheckboxLabel: t('application.form.projektWelt.additionalCard.inputLabel'),
                             readOnly: readOnly,
                         }}>
        <FormRow>
            <SalutationSelectField<MainProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'additionalCardData.salutation'}
                    readOnly={readOnly}/>
            <PersonTitleSelectField<MainProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'additionalCardData.title'}
                    readOnly={readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.firstName')}
                                                      fieldPath={'additionalCardData.firstName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={readOnly}/>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.lastName')}
                                                      fieldPath={'additionalCardData.lastName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<MainProjektWeltApplication> breakpoint={3}
                                                                readOnly={readOnly}
                                                                fieldPath={'additionalCardData.dateOfBirth'}/>
        </FormRow>
        <FormRow>
            <AppTextField<MainProjektWeltApplication> breakpoint={6}
                                                      label={t('common.form.contact.email')}
                                                      fieldPath={'additionalCardData.email'}
                                                      readOnly={readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      label={t('common.form.contact.cellPhoneNumber')}
                                                      fieldPath={'additionalCardData.cellPhoneNumber'}
                                                      readOnly={readOnly}/>
        </FormRow>
    </FormSection>);
};
