import {Typography} from '@mui/material';
import React, {FC, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppTextField} from '../../../components/form/AppTextField';
import {FormRow} from '../../../components/form/FormRow';
import {setBankDetailsToCache, useIbanValidator} from '../../auxiliary/service';
import {MainProfiCardDeApplication} from '../profiCard/main/de/model';
import {addIbanPrefix, PaymentMethod, removeIbanPrefix} from './model';

interface IbanFieldProps<TFieldValues extends FieldValues = FieldValues> {
    readOnly?: boolean;
}

export const IbanField: FC<IbanFieldProps> = ({readOnly}) => {
    const {t} = useTranslation();
    const {getValues, watch, clearErrors, setValue} = useFormContext<MainProfiCardDeApplication>();
    const application = getValues();
    const iban = watch('bankDetails.iban');

    const initialBankDetails = useMemo(() => ({
        iban,
        bic: application.bankDetails?.bic,
        bankName: application.bankDetails?.bankName,
        bankCity: application.bankDetails?.bankCity,
        success: true,
    }), [application.bankDetails?.bankCity, application.bankDetails?.bankName, application.bankDetails?.bic, iban]);

    useEffect(() => {
        if (initialBankDetails.iban && initialBankDetails.bic && initialBankDetails.bankName && initialBankDetails.bankCity) {
            setBankDetailsToCache(initialBankDetails.iban, initialBankDetails);
        }
    }, [initialBankDetails]);

    const {bankDetails, isFetching, isLoadingError} = useIbanValidator(addIbanPrefix(iban));
    const isIbanValid = !!bankDetails?.success;
    const isDisabled = application.bankDetails?.paymentMethod !== PaymentMethod.DEBIT_CHARGE;

    useEffect(() => {
        if (isDisabled || isLoadingError) {
            clearErrors('bankDetails.iban');
        }
    }, [clearErrors, isDisabled, isLoadingError]);

    useEffect(() => {
        setValue('bankDetails.bic', bankDetails?.bic ?? '');
        setValue('bankDetails.bankName', bankDetails?.bankName ?? '');
        setValue('bankDetails.bankCity', bankDetails?.bankCity ?? '');
    }, [bankDetails, setValue]);

    useEffect(() => {
        setValue('bankDetails.iban', removeIbanPrefix(iban));
    }, [iban, setValue]);

    const helperText = isDisabled ? '' : <IbanHelperText isIbanValid={isIbanValid} isFetching={isFetching}
                                                         isLoadingError={isLoadingError}/>;

    return (<FormRow>
        <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                  readOnly={readOnly}
                                                  disabled={isDisabled}
                                                  required={!isDisabled}
                                                  label={t('application.form.bankDetails.iban')}
                                                  helperText={helperText}
                                                  isLoading={isFetching}
                                                  startAdornment={!isDisabled ? 'DE' : undefined}
                                                  startAdornmentSx={{marginRight: '1px', marginTop: '-1px'}}
                                                  fieldPath={'bankDetails.iban'}/>

        <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                  disabled={true}
                                                  label={t('application.form.bankDetails.swiftOrBic')}
                                                  isLoading={isFetching}
                                                  fieldPath={'bankDetails.bic'}/>

        <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                  disabled={true}
                                                  label={t('application.form.bankDetails.creditInstitution')}
                                                  isLoading={isFetching}
                                                  fieldPath={'bankDetails.bankName'}/>

        <AppTextField<MainProfiCardDeApplication> breakpoint={3}
                                                  disabled={true}
                                                  label={t('application.form.bankDetails.bankCity')}
                                                  isLoading={isFetching}
                                                  fieldPath={'bankDetails.bankCity'}/>
    </FormRow>);
};

interface IbanHelperTextProps {
    isIbanValid: boolean;
    isFetching: boolean;
    isLoadingError: boolean;
}

const IbanHelperText: FC<IbanHelperTextProps> = ({isIbanValid, isFetching, isLoadingError}) => {
    const {t} = useTranslation();

    if (isIbanValid) {
        return <Typography variant={'caption'}
                           color={theme => theme.palette.success.main}>{t('application.form.bankDetails.ibanHelperTextValid')}</Typography>;
    }
    if (isFetching) {
        return <span>{t('application.form.bankDetails.ibanHelperTextLoading')}</span>;
    }
    if (isLoadingError) {
        return <Typography variant={'caption'}
                           color={theme => theme.palette.error.main}>{t('application.form.bankDetails.ibanHelperTextApiError')}</Typography>;
    }
    return <span>{t('application.form.bankDetails.ibanHelperText')}</span>;
};
