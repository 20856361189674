import {
    ArrowCircleUpOutlined,
    CreateOutlined,
    PanToolOutlined,
    ThumbDownAltOutlined,
    Warning
} from '@mui/icons-material';
import ReplayIcon from '@mui/icons-material/Replay';
import {
    Button,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import React, {FC, useState} from 'react';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';
import {ApiError, ApplicationProcessingErrorReasonDto, ApplicationTypeDto, Currency} from '../../api';
import {AppAmountField} from '../../components/form/AppAmountField';
import {AppFormButton} from '../../components/form/AppFormButton';
import {FormRow} from '../../components/form/FormRow';
import {useAppForm} from '../../components/form/useAppForm';
import {usePageLoaderContext} from '../../components/PageLoader';
import {AppCard} from '../../components/SectionCard';
import {useSnackbar} from '../../components/SnackbarHelper';
import {
    isProcessCreditCheckFailureResult,
    processCreditCheck,
    ProcessCreditCheckFailureResult,
    reactivateApplication,
    rejectApplication,
    setAccountLimit,
    withdrawApplication
} from './service';
import {ApplicationStatus, BaseApplication} from './model';
import {Money} from '../money/model';
import {isProfiCardApplication} from './profiCard/utils';
import {useProcessModificationResponseError} from './processResponseError';

interface ActionButtonsSectionProps {
    application: BaseApplication;
}

export const ActionButtonsSection: FC<ActionButtonsSectionProps> = ({application}) => (
        <AppCard>
            <CardActions>
                <WithdrawButton application={application}/>
                <RejectButton application={application}/>
                <ReactivateButton application={application}/>
                <LimitButton application={application}/>
            </CardActions>
        </AppCard>);

export interface GenericActionButtonProps {
    application: BaseApplication;
}

const WithdrawButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const processModificationResponseError = useProcessModificationResponseError();
    const {setIsLoading} = usePageLoaderContext();
    const [isActionLoading, setIsActionLoading] = useState(false);
    const [isWithdrawDialogOpen, setIsWithdrawDialogOpen] = useState(false);

    const enabledStates = [ApplicationStatus.NEEDS_MANUAL_INTERVENTION];
    const disabled = !enabledStates.includes(application.status);

    const onClick = () => {
        setIsWithdrawDialogOpen(true);
    };

    const onWithdrawConfirmed = () => {
        setIsLoading(true);
        setIsActionLoading(true);
        if (application.applicationNumber === undefined) {
            throw new Error('Application number must be defined');
        }
        withdrawApplication(application)
                .then(() => {
                    showSnackbar({
                        severity: 'success',
                        message: t('application.overview.actions.withdraw.successMessage'),
                    });
                })
                .catch((reason) => {
                    processModificationResponseError(reason, application.applicationNumber);
                    showSnackbar({
                        severity: 'error',
                        message: t('application.overview.actions.withdraw.errorMessage'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };

    const handleWithdrawDialogClose = (result: WithdrawDialogResult) => {
        setIsWithdrawDialogOpen(false);
        if (result === WithdrawDialogResult.WITHDRAW) {
            onWithdrawConfirmed();
        }
    };

    return (
            <><AppFormButton disabled={disabled}
                             inProgress={isActionLoading}
                             icon={<PanToolOutlined/>}
                             color={'warning'}
                             onClick={onClick}>{t('application.overview.actions.withdraw.button')}</AppFormButton>
                {isWithdrawDialogOpen &&
                        <WithdrawDialog open={isWithdrawDialogOpen} onClose={handleWithdrawDialogClose}/>}</>
    );
};

enum WithdrawDialogResult {
    WITHDRAW = 'WITHDRAW',
    CANCEL = 'CANCEL',
}

interface WithdrawDialogProps {
    open: boolean;

    onClose(result: WithdrawDialogResult): void;
}

const WithdrawDialog: FC<WithdrawDialogProps> = (props) => {
    const {t} = useTranslation();
    return (
            <Dialog open={props.open} onClose={() => props.onClose(WithdrawDialogResult.CANCEL)}>
                <DialogContent>
                    <DialogContentText>{t('application.overview.actions.withdraw.dialog.content')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(WithdrawDialogResult.CANCEL)}
                            autoFocus>{t('common.dialog.cancel')}</Button>
                    <Button onClick={() => props.onClose(WithdrawDialogResult.WITHDRAW)}
                            variant={'contained'}>{t('application.overview.actions.withdraw.button')}</Button>
                </DialogActions>
            </Dialog>
    );
};

const RejectButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const [isActionLoading, setIsActionLoading] = useState(false);
    const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);

    const enabledStates = [ApplicationStatus.NEEDS_MANUAL_INTERVENTION];
    const disabled = !enabledStates.includes(application.status);

    const onClick = () => {
        setIsRejectDialogOpen(true);
    };

    const onRejectConfirmed = () => {
        setIsActionLoading(true);
        if (application.applicationNumber === undefined) {
            throw new Error('Application number must be defined');
        }
        rejectApplication(application)
                .then(() => {
                    showSnackbar({
                        severity: 'success',
                        message: t('application.overview.actions.reject.successMessage'),
                    });
                })
                .catch((reason) => {
                    processModificationResponseError(reason, application.applicationNumber);
                    showSnackbar({
                        severity: 'error',
                        message: t('application.overview.actions.reject.errorMessage'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };

    const handleRejectDialogClose = (result: RejectDialogResult) => {
        setIsRejectDialogOpen(false);
        if (result === RejectDialogResult.REJECT) {
            onRejectConfirmed();
        }
    };

    return (
            <><AppFormButton disabled={disabled}
                             inProgress={isActionLoading}
                             icon={<ThumbDownAltOutlined/>}
                             color={'error'}
                             onClick={onClick}>{t('application.overview.actions.reject.button')}</AppFormButton>
                {isRejectDialogOpen &&
                        <RejectDialog open={isRejectDialogOpen} onClose={handleRejectDialogClose}/>}</>
    );
};

enum RejectDialogResult {
    REJECT = 'REJECT',
    CANCEL = 'CANCEL',
}

interface RejectDialogProps {
    open: boolean;

    onClose(result: RejectDialogResult): void;
}

const RejectDialog: FC<RejectDialogProps> = (props) => {
    const {t} = useTranslation();
    return (
            <Dialog open={props.open} onClose={() => props.onClose(RejectDialogResult.CANCEL)}>
                <DialogContent>
                    <DialogContentText>{t('application.overview.actions.reject.dialog.content')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(RejectDialogResult.CANCEL)}
                            autoFocus>{t('common.dialog.cancel')}</Button>
                    <Button onClick={() => props.onClose(RejectDialogResult.REJECT)}
                            variant={'contained'}>{t('application.overview.actions.reject.button')}</Button>
                </DialogActions>
            </Dialog>
    );
};

const ReactivateButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const [isActionLoading, setIsActionLoading] = useState(false);

    const enabledStates = [ApplicationStatus.REJECTED, ApplicationStatus.WITHDRAWN];
    const disabled = !enabledStates.includes(application.status);
    const applicationNumber = application.applicationNumber;

    const onClick = () => {
        setIsLoading(true);
        setIsActionLoading(true);
        if (applicationNumber === undefined) {
            throw new Error('Application number must be defined');
        }
        reactivateApplication(application)
                .then(() => {
                    showSnackbar({
                        severity: 'info',
                        message: t('application.overview.actions.reactivate.successMessage'),
                    });
                })
                .catch((reason) => {
                    processModificationResponseError(reason, application.applicationNumber);
                    showSnackbar({
                        message: t('application.overview.actions.reactivate.errorMessage'),
                        severity: 'error',
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };

    return (<AppFormButton disabled={disabled}
                           inProgress={isActionLoading}
                           icon={<ArrowCircleUpOutlined/>}
                           color={'success'}
                           onClick={onClick}>{t('application.overview.actions.reactivate.button')}</AppFormButton>);
};

export const RetryDuplicateCheckButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    return <RetryGenericProcessingButton application={application}
                                         buttonLabel={t('application.overview.duplicateCheckServiceOutage.action.button')}
                                         successMessage={t('application.overview.duplicateCheckServiceOutage.action.success')}
    />;
};

export const RetryCrefoButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    return <RetryGenericProcessingButton application={application}
                                         buttonLabel={t('application.crefo.actions.retryCrefo.button')}
                                         successMessage={t('application.crefo.actions.retryCrefo.successMessage')}
    />;
};

export const RetrySchufaButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    return <RetryGenericProcessingButton application={application}
                                         buttonLabel={t('application.schufa.actions.retrySchufa.button')}
                                         successMessage={t('application.schufa.actions.retrySchufa.successMessage')}
    />;
};

export const RetryCreateContractButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    return <RetryGenericProcessingButton application={application}
                                         buttonLabel={t('application.actions.retryCreateContract.button')}
                                         successMessage={t('application.actions.retryCreateContract.successMessage')}
    />;
};

interface RetryProcessingButtonProps extends GenericActionButtonProps {
    buttonLabel: string;
    successMessage: string;
}

const RetryGenericProcessingButton: FC<RetryProcessingButtonProps> = ({
                                                                          application,
                                                                          buttonLabel,
                                                                          successMessage,
                                                                      }) => {
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const [isActionLoading, setIsActionLoading] = useState(false);

    const enabledStates = [ApplicationStatus.NEEDS_MANUAL_INTERVENTION];
    const disabled = !enabledStates.includes(application.status);

    const onClick = () => {
        setIsLoading(true);
        setIsActionLoading(true);
        if (application.applicationNumber === undefined) {
            throw new Error('Application number must be defined');
        }
        processCreditCheck(application)
                .then(() => {
                    showSnackbar({
                        severity: 'success',
                        message: successMessage,
                    });
                })
                .catch((reason: ApiError | ProcessCreditCheckFailureResult) => {
                    if (isProcessCreditCheckFailureResult(reason)
                            && reason.reason !== ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION_SEARCH_FAILED
                            && reason.reason !== ApplicationProcessingErrorReasonDto.DUPLICATED_COMMUNICATION_FAILURE
                            && reason.reason !== ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE) {
                        showSnackbar({
                            severity: 'success',
                            message: successMessage,
                        });
                    }
                    throw reason;
                })
                .catch((reason) => processModificationResponseError(reason, application.applicationNumber))
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };

    return (<AppFormButton disabled={disabled}
                           inProgress={isActionLoading}
                           onClick={onClick}
                           icon={<ReplayIcon/>}>{buttonLabel}</AppFormButton>);
};

export function getUnresolvedDuplicate(application: BaseApplication) {
    if (!('ignoreDuplicates' in application)) {
        return false;
    }
    return !application.ignoreDuplicates && (application.processingErrorReason === ApplicationProcessingErrorReasonDto.DUPLICATED);
}

const LimitButton: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);
    const isUnresolvedDuplicate = getUnresolvedDuplicate(application);
    const validStatuses = [ApplicationStatus.NEEDS_MANUAL_INTERVENTION];
    const hasInvalidStatus = !validStatuses.includes(application.status);

    const disabled = hasInvalidStatus || isUnresolvedDuplicate;

    const onClick = () => {
        setIsLimitDialogOpen(true);
    };

    const handleLimitDialogClose = () => {
        setIsLimitDialogOpen(false);
    };

    if (application.applicationNumber === undefined) {
        throw new Error('Application number must be defined');
    }

    return (<>
                <AppFormButton disabled={disabled}
                               icon={<CreateOutlined/>}
                               onClick={onClick}>{t('application.overview.actions.limit.button')}</AppFormButton>

                {isLimitDialogOpen &&
                        <LimitDialog open={isLimitDialogOpen}
                                     onClose={handleLimitDialogClose}
                                     application={application}
                        />}
            </>
    );
};

const setLimitDataSchema = z.object({
    limit: z.object({
        amount: z.number().min(0.01),
        currency: z.nativeEnum(Currency)
    }).nullable().optional()
            .refine(input => input != null, 'common.form.field.required')
});

type SetLimitData = z.infer<typeof setLimitDataSchema>;

function moneyToFormData(money: Money | null | undefined): SetLimitData {
    return {
        limit: {
            amount: money?.amount ?? 0,
            currency: money?.currency ?? Currency.EUR,
        }
    };
}

function getInitialFormLimit(application: BaseApplication) {
    if (isProfiCardApplication(application)) {
        return application.desiredLimit;
    }
    const isCh = [ApplicationTypeDto.PROFI_CARD_CH_MAIN, ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL].includes(application.applicationType);
    return {
        currency: isCh ? Currency.CHF : Currency.EUR,
        amount: 2000,
    };
}

interface LimitDialogProps {
    open: boolean;
    application: BaseApplication;

    onClose(): void;
}

const LimitDialog: FC<LimitDialogProps> = (props) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const [isActionLoading, setIsActionLoading] = useState(false);

    const application = props.application;
    const formData = useAppForm<SetLimitData>(setLimitDataSchema, moneyToFormData(getInitialFormLimit(application)));
    const handleSubmit = formData.handleSubmit;

    const setManualLimit = (setLimitData: SetLimitData) => {
        formData.wrapPromise(setAccountLimit(application, setLimitData.limit?.currency!!, setLimitData.limit?.amount!!))
                .then((application) => {
                    showSnackbar({
                        severity: 'success',
                        message: t('application.overview.actions.limit.successMessage'),
                    });
                    props.onClose();
                    if (application.status === ApplicationStatus.NEEDS_MANUAL_INTERVENTION) {
                        if (application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE) {
                            showSnackbar({
                                message: t('application.actions.retryCreateContract.errorMessage'),
                                severity: 'error',
                            });
                        }
                    }
                    return application;
                })
                .catch((reason) => processModificationResponseError(reason, application.applicationNumber))
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };
    return (
            <Dialog open={props.open} onClose={() => props.onClose()}>
                <FormProvider {...formData}>
                    <form onSubmit={handleSubmit(setManualLimit)}>
                        <DialogTitle>{t('application.overview.actions.limit.dialog.title')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{t('application.overview.actions.limit.dialog.content')}</DialogContentText>
                            <FormRow>
                                <AppAmountField<SetLimitData>
                                        label={t('application.overview.actions.limit.dialog.amount.defaultMessage')}
                                        fieldPath={'limit'}
                                        helperText={t('application.overview.actions.limit.dialog.amount.helperText')}
                                />
                            </FormRow>
                        </DialogContent>
                        <DialogActions>
                            <AppFormButton onClick={() => props.onClose()}>{t('common.dialog.cancel')}</AppFormButton>
                            <AppFormButton inProgress={isActionLoading} type={'submit'}>
                                <Warning sx={{m: 0.7}}/>{t('application.overview.actions.limit.button')}
                            </AppFormButton>
                        </DialogActions>
                    </form>
                </FormProvider>
            </Dialog>
    );
};
