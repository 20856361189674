import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Link, Tab} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {PropsWithChildren, useEffect, useTransition} from 'react';
import {matchPath, useLocation, useNavigate} from 'react-router-dom';
import {Progress} from '../Loader';

const StyledTabList = styled(TabList)(props => ({
    borderBottom: '1px solid ' + props.theme.palette.divider,
}));

const StyledTabPanel = styled(TabPanel)({
    padding: 0,
    paddingTop: '24px',
});

interface TabDefinition {
    url: string;
    label: React.ReactNode;
    icon?: string | React.ReactElement;
    content: React.ReactNode;
    isVisible?: boolean;
}

interface RoutingTabsProps {
    tabDefinitions: TabDefinition[];
}

export function RoutingTabs(props: PropsWithChildren<RoutingTabsProps>) {
    const [isPending] = useTransition();

    const navigate = useNavigate();

    const tabDefinitions = props.tabDefinitions
            .filter(tabDefinition => tabDefinition.isVisible ?? true);

    const urls = tabDefinitions.map(item => item.url);
    const routeMatch = useRouteMatch(urls);
    const currentTabUrl = routeMatch?.pattern.path ?? tabDefinitions?.[0]?.url ?? '';

    const [currentTab, setCurrentTab] = React.useState(currentTabUrl);

    useEffect(() => {
        setCurrentTab(currentTabUrl);
    }, [currentTabUrl]);

    const onTabChange = (tab: string) => {
        navigate(tab, {replace: true});
    };

    if (tabDefinitions.length === 1) {
        return <>{tabDefinitions[0].content}</>;
    }

    return (
        <TabContext value={currentTab}>
            <StyledTabList onChange={(_, tab) => onTabChange(tab)}>
                {tabDefinitions.map(item => {
                    return <Tab key={item.url}
                                label={item.label}
                                icon={item.icon}
                                iconPosition="start"
                                value={item.url}
                                component={Link}/>;
                })}
            </StyledTabList>
            {isPending && <Progress/>}
            {!isPending && tabDefinitions.map(item => {
                return <StyledTabPanel key={item.url} value={item.url}>{item.content}</StyledTabPanel>;
            })}
        </TabContext>
    );
}

function useRouteMatch(patterns: readonly string[]) {
    const {pathname} = useLocation();

    for (const pattern of patterns) {
        const possibleMatch = matchPath(pattern, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }
    return null;
}
