import React, {FC} from 'react';
import {AppCard} from '../../../../components/SectionCard';
import {BaseApplication} from '../../model';
import {isMainProjektWeltApplication, MainProjektWeltApplication} from './model';
import {useTranslation} from 'react-i18next';
import {CardContent} from '@mui/material';

interface SchufaLatestInteractionSectionProps {
    application: BaseApplication;
}

export const SchufaLatestInteractionSection: FC<SchufaLatestInteractionSectionProps> = ({application}) => {
    if (!isMainProjektWeltApplication(application)) {
        throw new Error('Application is not of type Main ProjektWelt');
    }

    return <SchufaInteractionSection application={application}/>;
};

interface SchufaInteractionSectionProps {
    application: MainProjektWeltApplication;
}

const SchufaInteractionSection: FC<SchufaInteractionSectionProps> = ({
                                                                         application,
                                                                     }) => {
    const {t} = useTranslation();
    return (<AppCard>
        <CardContent
                sx={{fontWeight: 'bolder'}}>{t('application.history.processingErrorReason.CREDIT_INSTITUTION_SEARCH_FAILED')}</CardContent>
    </AppCard>);
};
