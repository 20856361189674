import {
    ApplicationTypeDto,
    CreateMainProfiCardDeApplicationRequest,
    FormAction,
    MainProfiCardDeApplicationDto,
    UpdateMainProfiCardDeApplicationRequest
} from '../../../../../api';
import {mapEnum} from '../../../../../utils/utils';
import {addIbanPrefix, removeIbanPrefix} from '../../../iban/model';
import {MainProfiCardApplicationWithCrefoDetailsId} from '../../model';
import {mapDtoToBaseMainProfiCardModel, mapModelToCreateBaseMainProfiCardApplicationRequest} from '../mapper';
import {isIbanRequired, MainProfiCardDeApplication} from './model';

export function mapModelToCreateMainProfiCardDeApplicationRequest(action: FormAction, data: MainProfiCardApplicationWithCrefoDetailsId<MainProfiCardDeApplication>): CreateMainProfiCardDeApplicationRequest {
    const baseMainProfiCardRequest = mapModelToCreateBaseMainProfiCardApplicationRequest(action, data);
    return {
        ...baseMainProfiCardRequest,
        applicationType: ApplicationTypeDto.PROFI_CARD_DE_MAIN,
        bankDetails: {
            paymentMethod: mapEnum(data.bankDetails?.paymentMethod),
            iban: isIbanRequired(data) ? addIbanPrefix(data.bankDetails?.iban) : undefined,
            bic: data.bankDetails?.bic,
            bankName: data.bankDetails?.bankName,
            bankCity: data.bankDetails?.bankCity,
        },
    };
}

export function mapModelToUpdateMainProfiCardDeApplicationRequest(action: FormAction, data: MainProfiCardDeApplication): UpdateMainProfiCardDeApplicationRequest {
    return {
        ...mapModelToCreateMainProfiCardDeApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version,
        applicationType: ApplicationTypeDto.PROFI_CARD_DE_MAIN,
    };
}

export async function mapDtoToMainProfiCardDeModel(data: MainProfiCardDeApplicationDto): Promise<MainProfiCardDeApplication> {
    const baseMainProfiCardApplication = await mapDtoToBaseMainProfiCardModel(data);
    const application: MainProfiCardDeApplication = {
        ...baseMainProfiCardApplication,
        bankDetails: {
            paymentMethod: mapEnum(data.bankDetails?.paymentMethod) ?? null,
            iban: removeIbanPrefix(data.bankDetails?.iban ?? ''),
            bic: data.bankDetails?.bic ?? undefined,
            bankName: data.bankDetails?.bankName ?? undefined,
            bankCity: data.bankDetails?.bankCity ?? undefined,
        },
    };
    return Promise.resolve(application);
}
