import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {UserRoleSelectField} from './UserRoleSelectField';
import {User, UserRole} from './model';
import {useFormContext} from 'react-hook-form';
import {FormRow} from '../../components/form/FormRow';
import {getMarketLabelAndId,} from '../reference/MasterMarketSelectedField';
import {useTranslation} from 'react-i18next';
import {AppSwitch} from '../../components/form/AppSwitch';
import {FieldValues} from 'react-hook-form/dist/types';
import {ReferenceSelectFieldProps} from '../reference/ReferenceSelectFieldProps';
import {useReferences} from '../reference/service';
import {Originator} from '../../utils/OriginatorProvider';
import {getOptionEntryId, OptionEntry} from '../reference/model';
import {Country, OriginatorDto} from '../../api';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {UserCountrySelectField} from './UserCountrySelectField';

interface UserRoleAndLocationFieldsProps {
    readOnly?: boolean,
    label: string,
}

export const UserRoleAndLocationFields: FC<UserRoleAndLocationFieldsProps> = (props) => {
    const {watch, setValue} = useFormContext<User>();
    const selectedRole = watch('role');
    const isKnistrRoleSelected = selectedRole === UserRole.KNISTR;
    const isCentralRoleSelected = selectedRole === UserRole.HORNBACH_CENTRAL;
    const isHornbachRoleSelected = selectedRole === UserRole.HORNBACH;
    const isHRBServiceToolRoleSelected = selectedRole === UserRole.HRB_SERVICE_TOOL;
    const hornbachUserCountry = watch('hornbachUserCountry');

    const showDeShops = watch('countryDe') === true;
    const showLuShops = watch('countryLu') === true;
    const showChShops = watch('countryCh') === true;

    // Modify the country, store and admin flag based on role selected
    useEffect(() => {
        if (isCentralRoleSelected || isKnistrRoleSelected) {
            setValue('countryDe', true);
            setValue('countryLu', true);
            setValue('countryCh', true);
            setValue('store', undefined);
        }
        if (!isKnistrRoleSelected && !isHRBServiceToolRoleSelected) {
            setValue('userAdminRights', false);
        }
        if (isHRBServiceToolRoleSelected) {
            setValue('userAdminRights', true);
            setValue('countryDe', false);
            setValue('countryLu', false);
            setValue('countryCh', false);
            setValue('store', undefined);
        }
    }, [isKnistrRoleSelected, isCentralRoleSelected, isHornbachRoleSelected, isHRBServiceToolRoleSelected, setValue]);

    // propagate changes to the boolean flags on changes of hornbachUserCountry
    useEffect(() => {
        if (isHornbachRoleSelected) {
            setValue('countryDe', hornbachUserCountry === Country.DE);
            setValue('countryLu', hornbachUserCountry === Country.LU);
            setValue('countryCh', hornbachUserCountry === Country.CH);
        }
    }, [setValue, hornbachUserCountry, isHornbachRoleSelected]);

    return (<>
        <FormRow>
            {/*Role*/}
            <UserRoleSelectField<User> breakpoint={3}
                                       fieldPath={'role'}
                                       required={true}
                                       readOnly={props.readOnly}/>

            {/*Admin Checkbox - only enabled when valid role selected*/}
            <AppSwitch<User> fieldPath={'userAdminRights'}
                             readOnly={props.readOnly || !isKnistrRoleSelected || isHRBServiceToolRoleSelected}
                             label={props.label}/>
        </FormRow>
        {isHornbachRoleSelected &&
                <FormRow>
                    <UserCountrySelectField<User> breakpoint={3}
                                                  fieldPath={'hornbachUserCountry'}
                                                  readOnly={!isHornbachRoleSelected}
                                                  required={true}
                                                  disabled={!isHornbachRoleSelected}/>

                    <UserMarketSelectField<User> breakpoint={3}
                                                 fieldPath={'store'}
                                                 required={true}
                                                 readOnly={!isHornbachRoleSelected}
                                                 showDeShops={showDeShops}
                                                 showLuShops={showLuShops}
                                                 showChShops={showChShops}
                    />
                </FormRow>
        }
    </>);
};

interface UserMarketSelectFieldProps<TFieldValues extends FieldValues = FieldValues> extends ReferenceSelectFieldProps<TFieldValues> {
    showDeShops: boolean;
    showLuShops: boolean;
    showChShops: boolean;
}

export function UserMarketSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<UserMarketSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const {getShops, isLoading} = useReferences((Originator.current!!));
    const {setValue, watch} = useFormContext<TFieldValues>();
    const [shops, setShops] = useState<OptionEntry[]>();
    const fieldPath = props.fieldPath;

    const showDeShops = props.showDeShops;
    const showLuShops = props.showLuShops;
    const showChShops = props.showChShops;

    const currentShopKey = watch(fieldPath)?.key;

    useEffect(() => {
        setShops(undefined);
    }, [showDeShops, showLuShops, showChShops]);

    useEffect(() => {
        if (shops !== undefined || getShops === undefined) {
            return;
        }
        let availableShops: OptionEntry[] = [];
        if (showDeShops) {
            availableShops = availableShops.concat(getShops?.(OriginatorDto.PROFI_CARD_GERMANY));
        }
        if (showLuShops) {
            availableShops = availableShops.concat(getShops?.(OriginatorDto.PROFI_CARD_LUXEMBURG));
        }
        if (showChShops) {
            availableShops = availableShops.concat(getShops?.(OriginatorDto.PROFI_CARD_SWITZERLAND));
        }
        setShops(availableShops);

        const isCurrentShopAvailable = availableShops.find(item => item.key == currentShopKey) !== undefined;
        if (!isCurrentShopAvailable) {
            setValue(fieldPath, null as any);
        }

    }, [shops, setValue, getShops, showDeShops, showLuShops, showChShops, currentShopKey, fieldPath]);

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 3}
                                                             options={shops}
                                                             isLoading={isLoading}
                                                             getLabel={getMarketLabelAndId}
                                                             getId={getOptionEntryId}
                                                             fieldPath={fieldPath}
                                                             disabled={props.disabled ?? shops?.length === 0}
                                                             required={props.required}
                                                             label={t('user.management.list.header.store')}
                                                             readOnly={props.readOnly}
                                                             size={props.size}
    />);
}
