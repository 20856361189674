import {ApplicationStatus, BaseApplication} from './model';
import {User, UserRole} from '../user/model';
import {hasAnyRole, hasRole} from '../user/utils';

export function canDeleteApplication(application: BaseApplication) {
    return application.status === ApplicationStatus.CREATED
            || application.status === ApplicationStatus.RECORDED
            || application.status === ApplicationStatus.LEGITIMIZED;
}

export function isApplicationReadOnly(application: BaseApplication, user: User) {
    if (hasAnyRole(user, [UserRole.HORNBACH, UserRole.HORNBACH_CENTRAL])) {
        return ![ApplicationStatus.CREATED, ApplicationStatus.RECORDED].includes(application.status);
    }
    if (hasRole(user, UserRole.KNISTR)) {
        return ![ApplicationStatus.CREATED, ApplicationStatus.RECORDED, ApplicationStatus.NEEDS_MANUAL_INTERVENTION].includes(application.status);
    }
    throw new Error(`Invalid user role: ${user.role}`);
}
