import {FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, Switch} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import React, {PropsWithChildren} from 'react';
import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId, markLabelAsMandatory} from './utils';

interface AppSwitchProps<TFieldValues extends FieldValues = FieldValues> {
    fieldPath: FieldPath<TFieldValues>;
    label: string;
    breakpoint?: boolean | GridSize;
    helperText?: React.ReactNode;
    sx?: SxProps<Theme>;
    required?: boolean;
    readOnly?: boolean;
}

export function AppSwitch<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<AppSwitchProps<TFieldValues>>) {
    const {control} = useFormContext();

    return (
            <Grid item xs={props.breakpoint}>
                <Controller control={control}
                            name={props.fieldPath}
                            render={({field, fieldState: {error}}) => {
                                const hasError = error !== undefined;
                                return (<FormControl
                                        error={hasError}
                                        component="fieldset"
                                        variant="standard"
                                        sx={props.sx ?? {marginTop: '24px'}}
                                >
                                    <FormGroup>
                                        <FormControlLabel label={markLabelAsMandatory(props.label, props.required)}
                                                          control={
                                                              <Switch {...field}
                                                                      color={'info'}
                                                                      disabled={props.readOnly}
                                                                      id={fieldPathToId(props.fieldPath)}
                                                                      ref={undefined}
                                                                      inputRef={field.ref}
                                                                      checked={field.value ?? false}
                                                                      inputProps={{'aria-invalid': hasError}}
                                                                      onChange={(event, checked) => {
                                                                          field.onChange(checked);
                                                                          field.onBlur();
                                                                      }}
                                                              />
                                                          }/>
                                        {(hasError || props.helperText !== undefined) &&
                                                <FormHelperText>{hasError ? getErrorLabel(error) : props.helperText}</FormHelperText>}
                                    </FormGroup>
                                </FormControl>);
                            }}/>
            </Grid>
    );
}
