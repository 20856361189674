import React, {FC} from 'react';
import {ActionButtonsSection,} from '../../domain/application/ActionButtons';
import {
    AdditionalProfiCardApplicationOverviewSection
} from '../../domain/application/profiCard/additional/AdditionalProfiCardApplicationOverviewSection';
import {AdditionalProfiCardApplication} from '../../domain/application/profiCard/additional/model';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {CreateContractFailureSection} from '../../domain/application/CreateContractFailureSection';

interface AdditionalProfiCardApplicationOverviewTabProps {
    application: AdditionalProfiCardApplication;
}

export const AdditionalProfiCardApplicationOverviewTab: FC<AdditionalProfiCardApplicationOverviewTabProps> = ({application}) => {
    return (<>
        <AdditionalProfiCardApplicationOverviewSection application={application}/>
        <ActionButtonsSection application={application}/>
        {application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE &&
                <CreateContractFailureSection application={application}/>}
    </>);
};
