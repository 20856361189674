import {ContactEmergencyOutlined, Delete, Print} from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import {
    Alert,
    AlertTitle,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from '@mui/material';
import {styled, Theme} from '@mui/material/styles';
import React, {FC, PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {scrollToTop} from '../../utils/utils';
import {ApplicationNumberAndVersion, ApplicationStatus, BaseApplication} from './model';
import {useSnackbar} from '../../components/SnackbarHelper';
import {usePageLoaderContext} from '../../components/PageLoader';
import {useAppForm} from '../../components/form/useAppForm';
import {z} from 'zod';
import {useUserContext} from '../user/UserProvider';
import {
    deleteApplication,
    legitimizeApplication,
    processCreditCheck,
    recordApplication,
    saveApplication
} from './service';
import {canDeleteApplication, isApplicationReadOnly} from './utils';
import {ROUTES} from '../../router';
import {ApplicationStatusLabel} from './ApplicationStatusLabel';
import {UserRole} from '../user/model';
import {AppFormButton} from '../../components/form/AppFormButton';
import {OpenAPI} from '../../api';
import {usePrintPdfHandler} from '../../components/usePrintPdfHandler';
import {SxProps} from '@mui/system';
import {hasAnyRole} from '../user/utils';
import {determineFormActionType} from '../../components/form/utils';
import {useProcessModificationResponseError} from './processResponseError';
import {isMainProfiCardApplication} from './profiCard/utils';
import {
    getSupportedCountryKeyForPlaceOfRegistration
} from './profiCard/main/commonMainProfiCardApplicationFormComponents';
import {useBlockNavigation} from '../../utils/useBlockNavigation';

export enum FormActionType {
    SAVE_DRAFT = 'SAVE_DRAFT',
    SUBMIT = 'SUBMIT',
    SAVE_AND_PROCEED = 'SAVE_AND_PROCEED',
}

export interface ReadOnlyProps {
    readOnly?: boolean;
}

interface ApplicationFormProps<T extends BaseApplication = BaseApplication> {
    application: T;
    applicationSchema: z.Schema<any, any>;
    showLegitimizeDialog?: boolean;

    legitimizationFormSchema(application: T): z.Schema<any, any>;

    renderFields(isReadOnly: boolean): React.ReactNode;

    afterDraftSaved?(application: T): Promise<T> | T;

    afterLegitimized?(application: T): Promise<T> | T;

    renderLegitimizationCheckList(application: T): ReactNode;
}

export function ApplicationForm<T extends BaseApplication = BaseApplication>(props: PropsWithChildren<ApplicationFormProps<T>>) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const showSnackbar = useSnackbar();
    const processModificationResponseError = useProcessModificationResponseError();
    const {setIsLoading} = usePageLoaderContext();
    const [isSaveDraftInProgress, setIsSaveDraftInProgress] = useState(false);
    const [isSubmittingInProgress, setIsSubmittingInProgress] = useState(false);
    const [isSaveAndProceedInProgress, setIsSaveAndProceedInProgress] = useState(false);

    const [isLegitimizeDialogOpen, setIsLegitimizeDialogOpen] = useState(props.showLegitimizeDialog ?? false);
    const [isLegitimizationSucceeded, setIsLegitimizationSucceeded] = useState(false);

    const application = props.application;
    const formData = useAppForm<T>(props.applicationSchema, application);

    const handleSubmit = formData.handleSubmit;
    const getValues = formData.getValues;
    const errors = formData.errors;
    const reset = formData.reset;
    const isSubmitSuccessful = formData.formState.isSubmitSuccessful;
    const isValid = formData.isValid;
    const wrapPromiseToProcessForm = formData.wrapPromise;

    const applicationNumber = application.applicationNumber;
    const isPersistent = applicationNumber !== undefined && applicationNumber !== null;

    const {user} = useUserContext();
    const isReadOnly = isApplicationReadOnly(application, user);

    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            console.error('Form Errors Detected:', errors);
        }
    }, [errors]);

    useBlockNavigation(formData.formState.isDirty);

    useEffect(() => {
        console.log('resetting form with new application', application);
        reset(application);
    }, [reset, application]);

    useEffect(() => {
        if (isSubmitSuccessful) {
            // This reset() function is used to reset the form state (isDirty) which is needed to move to another page after submission.
            // isSubmitSuccessful is set to true also when an error is returned from the API, so it is necessary to keep form values.
            const formValues = getValues();
            reset(formValues);
        }
    }, [isSubmitSuccessful, reset, getValues]);

    const onSaveDraft = () => {
        setIsLoading(true);
        setIsSaveDraftInProgress(true);

        const values = getValues();
        wrapPromiseToProcessForm(saveApplication(values), errorFieldPathMapper(values))
                .then(application => {
                    showSnackbar({
                        message: t('application.save.draft.success'),
                        severity: 'success',
                    });
                    return application;
                })
                .then(props.afterDraftSaved)
                .catch((reason) => processModificationResponseError(reason, applicationNumber))
                .finally(() => {
                    setIsSaveDraftInProgress(false);
                    setIsLoading(false);
                });
    };

    const onSubmit = () => {
        setIsLoading(true);
        setIsSubmittingInProgress(true);
        const values = getValues();
        wrapPromiseToProcessForm(recordApplication(values), errorFieldPathMapper(values))
                .then(application => {
                    showSnackbar({
                        message: t('application.save.record.success'),
                        severity: 'success',
                    });
                    if (isPersistent) {
                        setIsLegitimizeDialogOpen(true);
                    }
                    return application;
                })
                .then(props.afterLegitimized)
                .catch((reason) => processModificationResponseError(reason, applicationNumber))
                .finally(() => {
                    setIsSubmittingInProgress(false);
                    setIsLoading(false);
                });
    };

    const onSaveAndProceed = () => {
        if (!applicationNumber) {
            throw new Error('Application number cannot be null');
        }
        setIsLoading(true);
        setIsSaveAndProceedInProgress(true);
        const values = getValues();
        wrapPromiseToProcessForm(saveApplication(values)
                .then(application => {
                    showSnackbar({
                        message: t('application.save.record.success'),
                        severity: 'success',
                    });
                    return application;
                }), errorFieldPathMapper(values))
                .then((updatedApplication) => processCreditCheck(updatedApplication))
                .then(application => {
                    showSnackbar({
                        message: t('application.actions.retryCreateContract.successMessage'),
                        severity: 'success',
                    });
                    setIsLegitimizationSucceeded(true);
                    return application;
                })
                .catch((reason) => processModificationResponseError(reason, applicationNumber))
                .finally(() => {
                    setIsSaveAndProceedInProgress(false);
                    setIsLoading(false);
                    scrollToTop();
                    navigate(ROUTES.applications.overview(applicationNumber));
                });
    };

    const processSubmit = (data: T, event?: React.BaseSyntheticEvent) => {
        setIsLegitimizationSucceeded(false);
        const actionType = determineFormActionType(event);
        switch (actionType) {
            case FormActionType.SAVE_DRAFT:
                onSaveDraft();
                break;
            case FormActionType.SUBMIT:
                onSubmit();
                break;
            case FormActionType.SAVE_AND_PROCEED:
                onSaveAndProceed();
                break;
            default:
                throw new Error('Unsupported form submitter: ' + actionType);
        }
    };

    const handleLegitimizationDialogClose = (result: LegitimizationDialogResult) => {
        setIsLegitimizeDialogOpen(false);
        if (result === LegitimizationDialogResult.SUCCESS) {
            setIsLegitimizationSucceeded(true);
        }
    };

    return (<>
        <FormProvider {...formData}>
            <form onSubmit={handleSubmit(processSubmit)}>
                {!isValid && formData.isSubmitted &&
                        <Alert severity="error">{t('application.form.globalError')}</Alert>}
                {isLegitimizationSucceeded && <LegitimizationSummaryPanel application={application}/>}

                {props.renderFields(isReadOnly)}

                <FormActionButtons application={application}
                                   isSaveDraftInProgress={isSaveDraftInProgress}
                                   isSubmittingInProgress={isSubmittingInProgress}
                                   isSaveAndProceedInProgress={isSaveAndProceedInProgress}
                />
            </form>
        </FormProvider>
        {isLegitimizeDialogOpen && <LegitimizationDialog open={isLegitimizeDialogOpen}
                                                         onClose={handleLegitimizationDialogClose}
                                                         application={getValues()}
                                                         legitimizationSchema={props.legitimizationFormSchema}
                                                         renderLegitimizationCheckList={props.renderLegitimizationCheckList}
        />}
    </>);
}

const StickyButtonContainer = styled(Stack)(props => ({
    position: 'sticky',
    bottom: '0px',
    boxShadow: 'white 0px -15px 15px',
    background: 'white',
    marginLeft: '-10px',
    paddingLeft: '10px',
    paddingTop: props.theme.spacing(2),
    paddingBottom: props.theme.spacing(2),
}));

interface FormActionButtonsProps {
    application: BaseApplication;
    isSaveDraftInProgress: boolean;
    isSubmittingInProgress: boolean;
    isSaveAndProceedInProgress: boolean;
}

const FormActionButtons: FC<FormActionButtonsProps> = (props) => {
    const {t} = useTranslation();
    const {user} = useUserContext();

    const application = props.application;
    const status = application.status;

    const canAnyUserEditApplication = [undefined, ApplicationStatus.CREATED, ApplicationStatus.RECORDED].includes(status);
    const shouldSaveDraftAndLegitimizedVisible = hasAnyRole(user, [UserRole.HORNBACH, UserRole.HORNBACH_CENTRAL]) || canAnyUserEditApplication;
    const shouldSaveAndProceedVisible = hasAnyRole(user, [UserRole.KNISTR]) && status === ApplicationStatus.NEEDS_MANUAL_INTERVENTION;

    return (<StickyButtonContainer
                    spacing={1} direction="row">

                {shouldSaveDraftAndLegitimizedVisible &&
                        <AppFormButton name={FormActionType.SAVE_DRAFT}
                                       outlined={true}
                                       disabled={![ApplicationStatus.CREATED, ApplicationStatus.RECORDED, ApplicationStatus.LEGITIMIZED].includes(status)}
                                       inProgress={props.isSaveDraftInProgress}
                                       icon={
                                           <SaveIcon/>}>{t('application.form.save')}</AppFormButton>}

                {shouldSaveDraftAndLegitimizedVisible &&
                        <AppFormButton name={FormActionType.SUBMIT}
                                       outlined={false}
                                       color={'secondary'}
                                       disabled={![ApplicationStatus.CREATED, ApplicationStatus.RECORDED, ApplicationStatus.LEGITIMIZED].includes(status)}
                                       inProgress={props.isSubmittingInProgress}
                                       icon={
                                           <ContactEmergencyOutlined/>}>{t('application.form.submit')}</AppFormButton>}

                {shouldSaveAndProceedVisible &&
                        <AppFormButton name={FormActionType.SAVE_AND_PROCEED}
                                       outlined={false}
                                       color={'secondary'}
                                       disabled={![ApplicationStatus.NEEDS_MANUAL_INTERVENTION].includes(status)}
                                       inProgress={props.isSaveAndProceedInProgress}
                                       icon={<SaveIcon/>}>{t('application.form.save')}</AppFormButton>}

                <ApplicationDeleteButton application={props.application}/>
                <PrintApplicationButton application={props.application}/>
                <PrintWelcomeLetterButton application={props.application}/>
            </StickyButtonContainer>
    );
};

interface PrintWelcomeLetterButtonProps {
    application: BaseApplication;
    sx?: SxProps<Theme>;
}

function PrintWelcomeLetterButton({application, sx}: PropsWithChildren<PrintWelcomeLetterButtonProps>) {
    const {t} = useTranslation();
    const applicationNumber = application.applicationNumber;
    const {
        handlePrintWelcomeLetter,
        printingWelcomeLetterInProgress
    } = usePrintWelcomeLetterPdf(application);

    if (!applicationNumber) {
        return null;
    }

    return (<AppFormButton icon={<Print/>}
                           color={'success'}
                           sx={sx}
                           disabled={application.status !== ApplicationStatus.SUCCESSFUL_COMPLETED}
                           inProgress={printingWelcomeLetterInProgress}
                           onClick={handlePrintWelcomeLetter}>{t('application.welcomeLetter.printButton')}</AppFormButton>);
}

interface PrintApplicationButtonProps {
    application: BaseApplication;
    sx?: SxProps<Theme>;
}

function PrintApplicationButton({application, sx}: PropsWithChildren<PrintApplicationButtonProps>) {
    const {t} = useTranslation();
    const applicationNumber = application.applicationNumber;
    const {
        handlePrintApplication,
        printingApplicationInProgress
    } = usePrintApplicationPdf(application);

    if (!applicationNumber) {
        return null;
    }

    return (<AppFormButton icon={<Print/>}
                           sx={sx}
                           disabled={application.status === ApplicationStatus.CREATED}
                           inProgress={printingApplicationInProgress}
                           onClick={handlePrintApplication}>{t('application.dialog.legitimization.printApplicationButton')}</AppFormButton>);
}

interface ApplicationDeleteButtonProps {
    application: BaseApplication;
}

function ApplicationDeleteButton(props: PropsWithChildren<ApplicationDeleteButtonProps>) {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();

    const [isDeleteInProgress, setIsDeleteInProgress] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [isDeleteAllowed, setIsDeleteAllowed] = useState(false);

    const application = props.application;
    const applicationNumber = application.applicationNumber;
    const isPersistent = applicationNumber !== undefined && applicationNumber !== null;

    useEffect(() => {
        setIsDeleteAllowed(canDeleteApplication(application));
    }, [application]);

    if (!isPersistent) {
        return null;
    }

    const handleDeleteDialogClose = (result: DeleteDialogResult) => {
        setIsDeleteDialogOpen(false);
        if (result === DeleteDialogResult.DELETE) {
            onDeleteConfirmed();
        }
    };

    const onDeleteConfirmed = () => {
        setIsLoading(true);
        setIsDeleteInProgress(true);
        if (applicationNumber === undefined) {
            setIsDeleteInProgress(false);
            showSnackbar({
                message: t('application.form.delete.errorMessage'),
                severity: 'error',
            });
            throw new Error('Application number must be defined');
        }
        deleteApplication(applicationNumber)
                .then(() => {
                    showSnackbar({
                        severity: 'info',
                        message: t('application.form.delete.successMessage'),
                    });
                    navigate(ROUTES.applications.list);
                })
                .catch(() => {
                    showSnackbar({
                        severity: 'error',
                        message: t('application.form.delete.errorMessage'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsDeleteInProgress(false);
                });
    };

    return (<>
        <AppFormButton name={'Delete'}
                       disabled={!isDeleteAllowed}
                       onClick={() => setIsDeleteDialogOpen(true)}
                       inProgress={isDeleteInProgress}
                       icon={<Delete/>}>{t('application.form.delete.button')}</AppFormButton>

        <DeleteDialog open={isDeleteDialogOpen}
                      onClose={handleDeleteDialogClose}/>
    </>);
}

enum DeleteDialogResult {
    DELETE = 'DELETE',
    CANCEL = 'CANCEL',
}

interface DeleteDialogProps {
    open: boolean;

    onClose(result: DeleteDialogResult): void;
}

const DeleteDialog: FC<DeleteDialogProps> = (props) => {
    const {t} = useTranslation();

    return (
            <Dialog open={props.open} onClose={() => props.onClose(DeleteDialogResult.CANCEL)}>
                <DialogContent>
                    <DialogContentText>{t('application.form.delete.dialog.content')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.onClose(DeleteDialogResult.CANCEL)}
                            autoFocus>{t('common.dialog.cancel')}</Button>
                    <Button onClick={() => props.onClose(DeleteDialogResult.DELETE)}
                            color={'secondary'}
                            variant={'contained'}>{t('application.form.delete.button')}</Button>
                </DialogActions>
            </Dialog>
    );
};

enum LegitimizationDialogResult {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    NONE = 'NONE',
}

interface LegitimizationDialogProps<T extends BaseApplication = BaseApplication> {
    application: T;
    open: boolean;

    legitimizationSchema(application: T): z.Schema<any, any>,

    renderLegitimizationCheckList(application: T): ReactNode;

    onClose(result: LegitimizationDialogResult): void;
}

function LegitimizationDialog<T extends BaseApplication = BaseApplication>(props: PropsWithChildren<LegitimizationDialogProps<T>>) {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const showSnackbar = useSnackbar();
    const [isLegitimizedInProgress, setIsLegitimizedInProgress] = useState(false);
    const processModificationResponseError = useProcessModificationResponseError();

    const applicationNumber = props.application.applicationNumber;
    const version = props.application.version;
    const {handlePrintApplication, printingApplicationInProgress} = usePrintApplicationPdf(props.application);

    const formData = useAppForm(props.legitimizationSchema(props.application), {});
    const handleSubmit = formData.handleSubmit;

    if (applicationNumber === undefined) {
        throw new Error('Application number cannot be null or undefined');
    }

    const onLegitimize = () => {
        setIsLoading(true);
        setIsLegitimizedInProgress(true);
        formData.wrapPromise(legitimizeApplication({applicationNumber, version}))
                .then((application: BaseApplication) => {
                    props.onClose(LegitimizationDialogResult.SUCCESS);
                    scrollToTop();
                    showSnackbar({
                        severity: 'success',
                        message: t('application.dialog.legitimization.successText'),
                    });
                    return application;
                })
                .catch((reason) => {
                    processModificationResponseError(reason, applicationNumber);
                    showSnackbar({
                        severity: 'error',
                        message: t('application.dialog.legitimization.errorText'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsLegitimizedInProgress(false);
                });
    };

    return (<Dialog open={props.open} onClose={() => props.onClose(LegitimizationDialogResult.NONE)}>
        <FormProvider {...formData}>
            <form onSubmit={handleSubmit(onLegitimize)}>
                <DialogTitle>{t('application.dialog.legitimization.title')}</DialogTitle>
                <DialogContent sx={{minWidth: '600px'}}>
                    <Alert severity={'success'}>{t('application.dialog.legitimization.applicationValidText')}</Alert>
                    <ol>
                        <li>{t('application.dialog.legitimization.step1')} <Button
                                onClick={handlePrintApplication}>
                            {printingApplicationInProgress ? <CircularProgress size={16}/> : <Print/>}
                            {t('application.dialog.legitimization.printApplicationButton')}
                        </Button></li>
                        <li>{t('application.dialog.legitimization.step2')}</li>
                        <li>{t('application.dialog.legitimization.step3')}</li>
                        <li>{t('application.dialog.legitimization.step4', {buttonLabel: t('application.dialog.legitimization.legitimize')})}</li>
                    </ol>

                    {props.renderLegitimizationCheckList(props.application)}

                    {formData.isValid && <Alert
                            severity={'warning'}>{t('application.dialog.legitimization.legitimizationWarning')}</Alert>}
                </DialogContent>
                <DialogActions>
                    <Button
                            onClick={() => props.onClose(LegitimizationDialogResult.NONE)}>{t('common.dialog.cancel')}</Button>
                    <AppFormButton icon={<Print/>}
                                   inProgress={printingApplicationInProgress}
                                   onClick={handlePrintApplication}>{t('application.dialog.legitimization.printApplicationButton')}</AppFormButton>
                    <AppFormButton outlined={false}
                                   inProgress={isLegitimizedInProgress}
                                   icon={
                                       <ContactEmergencyOutlined/>}>{t('application.dialog.legitimization.legitimize')}</AppFormButton>
                </DialogActions>
            </form>
        </FormProvider>
    </Dialog>);
}

interface LegitimizationSummaryPanelProps {
    application: BaseApplication;
}

function LegitimizationSummaryPanel(props: PropsWithChildren<LegitimizationSummaryPanelProps>) {
    const {t} = useTranslation();
    const application = props.application;

    return (<Alert severity={'success'} sx={{position: 'relative'}}>
        <AlertTitle>{t('application.legitimization.summary.panel.title')}</AlertTitle>
        <ApplicationStatusLabel status={application.status}/>
        {application.status === ApplicationStatus.SUCCESSFUL_COMPLETED &&
                <PrintWelcomeLetterButton application={application}
                                          sx={{
                                              marginLeft: '8px',
                                          }}
                />}
    </Alert>);
}

function errorFieldPathMapper(application: BaseApplication) {
    return (path: string) => {
        if (path === 'legitimization.placeOfRegistration' && isMainProfiCardApplication(application)) {
            const renderAsTextField = !getSupportedCountryKeyForPlaceOfRegistration(application.company?.address?.country?.key);
            if (renderAsTextField) {
                return 'legitimization.placeOfRegistration.value';
            }
        }
    };
}

function usePrintApplicationPdf(applicationId: ApplicationNumberAndVersion) {
    const {
        handlePrint,
        printingInProgress
    } = usePrintPdfHandler(`${OpenAPI.BASE}/api/applications/${applicationId.applicationNumber}/${applicationId.version}/print`);
    return {
        handlePrintApplication: handlePrint,
        printingApplicationInProgress: printingInProgress
    };
}

function usePrintWelcomeLetterPdf(applicationId: ApplicationNumberAndVersion) {
    const {
        handlePrint,
        printingInProgress
    } = usePrintPdfHandler(`${OpenAPI.BASE}/api/applications/${applicationId.applicationNumber}/welcome-letter`);
    return {
        handlePrintWelcomeLetter: handlePrint,
        printingWelcomeLetterInProgress: printingInProgress
    };
}
