import React, {PropsWithChildren, ReactNode, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AppContent} from '../../components/AppContent';
import {ROUTES} from '../../router';
import {ApplicationPageLabel} from './ApplicationPageLabel';
import {User} from '../../domain/user/model';
import {useUserContext} from '../../domain/user/UserProvider';
import {useOriginatorContext} from '../../utils/OriginatorProvider';
import {BaseApplication} from '../../domain/application/model';

export interface ApplicationFormProps<T extends BaseApplication = BaseApplication> {
    application: T;
    showLegitimizeDialog?: boolean;

    afterDraftSaved?(application: T): Promise<T> | T;

    afterLegitimized?(application: T): Promise<T> | T;
}

interface NewApplicationPageProps<T extends BaseApplication = BaseApplication> {
    renderApplicationForm(props: ApplicationFormProps<T>): ReactNode;

    createEmptyApplication(user: User): Promise<T>;
}

export default function NewApplicationPage<T extends BaseApplication = BaseApplication>(props: PropsWithChildren<NewApplicationPageProps>) {
    const {setContextApplicationType} = useOriginatorContext();
    const {user} = useUserContext();
    const [application, setApplication] = useState<T>();
    const createEmptyApplication = props.createEmptyApplication;

    useEffect(() => {
        if (application === undefined) {
            createEmptyApplication(user)
                    .then(data => {
                        setApplication(data as T);
                        setContextApplicationType(data.applicationType);
                    });
        }
    }, [application, createEmptyApplication, user, setContextApplicationType]);

    if (application === undefined) {
        return null;
    }

    return <NewApplicationContent<T> application={application} renderApplicationForm={props.renderApplicationForm}/>;
}

interface NewApplicationContentProps<T extends BaseApplication = BaseApplication> {
    application: T;

    renderApplicationForm(props: ApplicationFormProps<T>): ReactNode;
}

function NewApplicationContent<T extends BaseApplication = BaseApplication>(props: PropsWithChildren<NewApplicationContentProps>) {
    const navigate = useNavigate();

    const afterDraftSaved = (application: T) => {
        if (application.applicationNumber) {
            navigate(ROUTES.applications.edit(application.applicationNumber), {replace: true});
        } else {
            throw new Error('Application number must be defined!');
        }
        return application;
    };

    const afterLegitimized = (application: T) => {
        if (application.applicationNumber) {
            const state: CreatedLegitimizedApplicationRouterState = {
                showLegitimizedDialog: true,
            };
            navigate(ROUTES.applications.edit(application.applicationNumber), {
                replace: true,
                state,  // have to pass a flag to the edit page (after redirection) to show the legitimization dialog
            });
        } else {
            throw new Error('Application number must be defined!');
        }
        return application;
    };

    const application = props.application;

    return (<AppContent label={<ApplicationPageLabel/>}>
        {props.renderApplicationForm({
            application,
            afterDraftSaved,
            afterLegitimized,
        })}
    </AppContent>);

}

export interface CreatedLegitimizedApplicationRouterState {
    showLegitimizedDialog: boolean;
}
