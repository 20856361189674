import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {
    MainProjektWeltApplication,
    mainProjektWeltApplicationSchema,
    MainProjektWeltLegitimizationForm,
    mainProjektWeltLegitimizationFormSchema,
    mainProjektWeltWithAdditionalCardLegitimizationFormSchema
} from './model';
import {ApplicationForm, ReadOnlyProps} from '../../ApplicationForm';
import {ApplicationFormProps} from '../../../../pages/application/NewApplicationPage';
import {ApplicationTypeDto} from '../../../../api';
import {AppCheckboxField} from '../../../../components/form/AppCheckboxField';
import {FormSection} from '../../../../components/form/FormSection';
import {IbanField} from '../../iban/IbanField';
import {FormRow} from '../../../../components/form/FormRow';
import {AppTextField} from '../../../../components/form/AppTextField';
import {AppDatePickerField} from '../../../../components/form/AppDatePickerField';
import {AdditionalProfiCardApplication} from '../../profiCard/additional/model';
import {trimAndCapitalizeFirstLetter} from '../../../../utils/utils';
import {GrossHouseholdIncomePerYearSelect} from './GrossHouseholdIncomePerYearSelect';
import {SalutationSelectField} from '../../../reference/SalutationSelectField';
import {PersonTitleSelectField} from '../../../reference/PersonTitleSelectField';
import {DateOfBirthPickerField} from '../../../../components/form/DateOfBirthPickerField';
import {NationalitySelectField} from '../../../reference/NationalitySelectField';
import {AddressForm} from '../../AddressForm';
import {ContactForm} from '../../ContactForm';
import {ConsentsFormSection} from '../../ConsentsFormSection';
import {MarketDataFormSection} from '../../marketData/MarketDataFormSection';
import {markLabelAsMandatory} from '../../../../components/form/utils';
import {AdditionalProjektWeltApplicationSection} from './AdditionalProjektWeltApplicationSection';
import {AdditionalProjektWeltLegitimizationForm} from '../additional/model';

interface MainProjektWeltApplicationFormProps extends ApplicationFormProps<MainProjektWeltApplication> {
}

export const MainProjektWeltApplicationForm: FC<MainProjektWeltApplicationFormProps> = (props) => (
        <ApplicationForm<MainProjektWeltApplication>
                application={props.application}
                applicationSchema={mainProjektWeltApplicationSchema}
                afterDraftSaved={props.afterDraftSaved}
                afterLegitimized={props.afterLegitimized}
                renderFields={isReadOnly =>
                        <MainProjektWeltApplicationFields isReadOnly={isReadOnly}/>}
                showLegitimizeDialog={props.showLegitimizeDialog}
                legitimizationFormSchema={getMainProjektWeltLegitimizationSchema}
                renderLegitimizationCheckList={() => <LegitimizeCheckList
                        hasAdditionalCardData={!!props.application.hasAdditionalCardData}/>}
        />);

function getMainProjektWeltLegitimizationSchema({hasAdditionalCardData}: MainProjektWeltApplication) {
    if (hasAdditionalCardData) {
        return mainProjektWeltWithAdditionalCardLegitimizationFormSchema;
    }
    return mainProjektWeltLegitimizationFormSchema;
}

interface MainProfiCardApplicationFieldsProps {
    isReadOnly: boolean;
}

const MainProjektWeltApplicationFields: FC<MainProfiCardApplicationFieldsProps> = ({isReadOnly}) => (<>
    <MainCardholderFormSection readOnly={isReadOnly}/>
    <GrossHouseholdIncomePerYear readOnly={isReadOnly}/>
    <BankDetailsFormSection readOnly={isReadOnly}/>
    <MarketDataFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROJEKT_WELT_MAIN}/>
    <AdditionalProjektWeltApplicationSection readOnly={isReadOnly}/>
    <LegitimationFormSection readOnly={isReadOnly}/>
    <ConsentsFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROJEKT_WELT_MAIN}/>
</>);

const MainCardholderFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.cardHolder.main.sectionTitle')}>
        <FormRow>
            <SalutationSelectField<MainProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.salutation'}
                    required={true}
                    readOnly={props.readOnly}/>
            <PersonTitleSelectField<MainProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.title'}
                    readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.firstName')}
                                                      fieldPath={'cardHolder.firstName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={props.readOnly}/>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.lastName')}
                                                      fieldPath={'cardHolder.lastName'}
                                                      transformValue={trimAndCapitalizeFirstLetter}
                                                      readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<MainProjektWeltApplication> breakpoint={3}
                                                                required={true}
                                                                readOnly={props.readOnly}
                                                                fieldPath={'cardHolder.dateOfBirth'}/>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      label={t('common.form.person.placeOfBirth')}
                                                      fieldPath={'cardHolder.placeOfBirth'}
                                                      readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <NationalitySelectField<MainProjektWeltApplication> breakpoint={3}
                                                                required={true}
                                                                fieldPath={'cardHolder.nationality'}
                                                                readOnly={props.readOnly}/>
        </FormRow>
        <AddressForm<MainProjektWeltApplication> basePath={'cardHolder.address'} showAdditionalLine={true}
                                                 required={true}
                                                 disableCountry={true}
                                                 readOnly={props.readOnly}/>
        <ContactForm<MainProjektWeltApplication> basePath={'cardHolder'} readOnly={props.readOnly}/>
    </FormSection>);
};


const LegitimizeCheckList: FC<{ hasAdditionalCardData: boolean }> = ({hasAdditionalCardData}) => {
    const {t} = useTranslation();
    return (<>
        <AppCheckboxField<MainProjektWeltLegitimizationForm> fieldPath={'principalCardholderSignature'}
                                                             label={t('application.dialog.legitimization.mainCardHolderSignature')}/>
        <AppCheckboxField<MainProjektWeltLegitimizationForm> fieldPath={'accountOwnerSignature'}
                                                             label={t('application.dialog.legitimization.accountOwnerSignature')}/>
        <AppCheckboxField<MainProjektWeltLegitimizationForm> fieldPath={'employeeSignature'}
                                                             label={t('application.dialog.legitimization.employeeSignature')}/>
        {hasAdditionalCardData &&
                <AppCheckboxField<AdditionalProjektWeltLegitimizationForm> fieldPath={'additionalCardHolderSignature'}
                                                                           label={t('application.dialog.legitimization.additionalCardHolderSignature')}/>}
    </>);
};

const GrossHouseholdIncomePerYear: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();

    return (<FormSection
            title={markLabelAsMandatory(t('application.form.projektWelt.grossHouseholdIncomePerYear.sectionTitle'), true) ?? ''}>
        <FormRow>
            <GrossHouseholdIncomePerYearSelect<MainProjektWeltApplication> breakpoint={3}
                                                                           fieldPath={'grossHouseholdIncomePerYear'}
                                                                           required={true}
                                                                           readOnly={props.readOnly}
            />
        </FormRow>
    </FormSection>);
};

const BankDetailsFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();

    return (<FormSection title={t('application.form.bankDetails.main.sectionTitle')}>
        <IbanField readOnly={props.readOnly}/>
    </FormSection>);
};

const LegitimationFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.legitimation.sectionTitle')}>
        <FormRow>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      readOnly={props.readOnly}
                                                      label={t('application.form.legitimation.idCardNumber')}
                                                      fieldPath={'legitimation.idCardNumber'}/>
            <AppTextField<MainProjektWeltApplication> breakpoint={3}
                                                      required={true}
                                                      readOnly={props.readOnly}
                                                      label={t('application.form.legitimation.issuingAuthority')}
                                                      fieldPath={'legitimation.issuingAuthority'}/>
            <AppDatePickerField<MainProjektWeltApplication> breakpoint={3}
                                                            required={true}
                                                            readOnly={props.readOnly}
                                                            label={t('application.form.legitimation.dateOfIssue')}
                                                            fieldPath={'legitimation.dateOfIssue'}
                                                            disableFuture={true}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          label={t('common.form.person.employeeName')}
                                                          fieldPath={'legitimation.employeeName'}
                                                          required={true}
                                                          transformValue={trimAndCapitalizeFirstLetter}
                                                          readOnly={props.readOnly}/>
        </FormRow>
    </FormSection>);
};


