import {z} from 'zod';
import {validateIban} from '../../auxiliary/service';

export enum PaymentMethod {
    DEBIT_CHARGE = 'DEBIT_CHARGE',
    TRANSFER = 'TRANSFER',
}

export const ibanSchema = z.string().optional()
    .refine(async (ibanValue) => validateIban(ibanValue), 'application.form.bankDetails.ibanInvalidMessage');

export const IBAN_PREFIX = 'DE';

export function addIbanPrefix(iban: string | undefined) {
    if (!iban) {
        return undefined;
    }
    if (iban?.startsWith(IBAN_PREFIX)) {
        return iban;
    }
    return IBAN_PREFIX + iban;
}

export function removeIbanPrefix(iban: string | undefined) {
    return iban?.startsWith(IBAN_PREFIX) ? iban.slice(2) : iban;
}

export const bankDetailsSchema = z.object({
    paymentMethod: z.nativeEnum(PaymentMethod).nullable(),
    iban: z.string().optional().transform(iban => addIbanPrefix(iban)),
    bic: z.string().optional(),
    bankName: z.string().optional(),
    bankCity: z.string().optional(),
});
export type BankDetails = z.infer<typeof bankDetailsSchema>;
