import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {BaseMainProfiCardApplicationUnion} from '../model';
import {AddressDefinition, ApplicationAddressProblemSection} from '../../ApplicationAddressProblemSection';
import {isCompanyOwnerRequired} from './model';

interface MainProfiCardApplicationAddressProblemSectionProps {
    application: BaseMainProfiCardApplicationUnion;
}

export const MainProfiCardApplicationAddressProblemSection: FC<MainProfiCardApplicationAddressProblemSectionProps> = ({application}) => {
    const {t} = useTranslation();

    const addressDefinitions = useMemo(() => {
        const addressDefinitions: AddressDefinition<BaseMainProfiCardApplicationUnion>[] = [
            {
                path: 'company.address',
                label: t('application.company.address'),
                showAdditionalLine: true,
            },
            {
                path: 'billingAddress.address',
                label: t('application.form.billingAddress.sectionTitle'),
            },
        ];
        if (isCompanyOwnerRequired(application)) {
            addressDefinitions.push({
                path: 'companyOwner.address',
                label: t('application.companyOwner.address'),
                showAdditionalLine: true,
            });
        }
        return addressDefinitions;
    }, [application, t]);

    return (<ApplicationAddressProblemSection<BaseMainProfiCardApplicationUnion> application={application}
                                                                                 addressDefinitions={addressDefinitions}/>);
};
