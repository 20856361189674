import {Alert, AlertTitle, Link} from '@mui/material';
import React, {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

interface ErrorPanelProps {
    title?: React.ReactNode;
    message?: React.ReactNode;
}

export function ErrorPanel({title, message}: PropsWithChildren<ErrorPanelProps>) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const refreshPage = () => navigate(0);
    return (
        <Alert severity="error">
            {title && <AlertTitle>{title}</AlertTitle>}
            {message ?? <Link href="" onClick={refreshPage}>{t('common.error.try-again')}</Link>}
        </Alert>
    );
}
