import ErrorIcon from '@mui/icons-material/Error';
import SaveIcon from '@mui/icons-material/Save';
import {Alert, Card, CardActions, CardContent, CardHeader, Stack, Typography} from '@mui/material';
import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {FieldPath, FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AppFormButton} from '../../components/form/AppFormButton';
import {FormRow} from '../../components/form/FormRow';
import {useAppForm} from '../../components/form/useAppForm';
import {usePageLoaderContext} from '../../components/PageLoader';
import {AppCard} from '../../components/SectionCard';
import {useSnackbar} from '../../components/SnackbarHelper';
import {scrollToTop} from '../../utils/utils';
import {AddressPicker} from '../auxiliary/AddressPicker';
import {Address, AddressWithAdditionalLine, OptionalAddress} from '../auxiliary/model';
import {processCreditCheck, recordApplication} from './service';
import {mainProfiCardDeApplicationSchema} from './profiCard/main/de/model';
import {ApplicationTypeDto} from '../../api';
import {mainProfiCardLuApplicationSchema} from './profiCard/main/lu/model';
import {mainProfiCardChApplicationSchema} from './profiCard/main/ch/model';
import {mainProjektWeltApplicationSchema} from './projektWelt/main/model';
import {BaseApplication} from './model';
import {useProcessModificationResponseError} from './processResponseError';

interface ApplicationAddressProblemSectionProps<T extends BaseApplication> {
    application: T;
    addressDefinitions: AddressDefinition<T>[];
}

export function ApplicationAddressProblemSection<T extends BaseApplication>({
                                                                                application,
                                                                                addressDefinitions
                                                                            }: PropsWithChildren<ApplicationAddressProblemSectionProps<T>>) {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const showSnackbar = useSnackbar();
    const processModificationResponseError = useProcessModificationResponseError();
    const formData = useAppForm<T>(getFormSchema(application.applicationType), application);
    const handleSubmit = formData.handleSubmit;
    const setValue = formData.setValue;
    const watch = formData.watch;

    const onAddressSelect = useCallback((path: FieldPath<T>, selectedAddress: Address | AddressWithAdditionalLine | undefined | null) => {
        setValue(path, selectedAddress as any);
    }, [setValue]);

    // we need to keep the initial value to present the invalid address block (even when user selects the checkbox to ignore the address problem) until a user clicks on save button - that's why useMemo is there
    const invalidAddresses: AddressDefinitionWithAddressObject<T>[] = useMemo(() => {
        return addressDefinitions
                .filter(definition => !!definition)
                .map(definition => ({
                    ...definition,
                    address: watch(definition.path) as Address
                }) as AddressDefinitionWithAddressObject<T>)
                .filter(definition => !definition.address?.verified)
                .filter(definition => !definition.address?.unverifiedAddressAccepted)
                .filter(definition => !!definition.address?.street);
    }, [addressDefinitions, watch]);

    if (invalidAddresses.length === 0) {
        return null;
    }

    const processSubmit = (data: T) => {
        const applicationNumber = application.applicationNumber;
        if (applicationNumber === undefined) {
            throw new Error('Application number cannot be empty');
        }
        setIsLoading(true);
        formData.wrapPromise(recordApplication(data)
                .then(application => {
                    showSnackbar({
                        message: t('application.save.address.success'),
                        severity: 'success',
                    });
                    scrollToTop();
                    return application;
                }))
                .then((updatedApplication) => processCreditCheck(updatedApplication))
                .catch((reason) => processModificationResponseError(reason, applicationNumber))
                .finally(() => {
                    setIsLoading(false);
                });
    };

    return (<AppCard>
        <FormProvider {...formData}>
            <form onSubmit={handleSubmit(processSubmit)}>
                <CardHeader title={<Typography variant={'h5'}
                                               sx={theme => ({color: theme.palette.error.main})}><ErrorIcon/><span>{t('application.overview.address.title')}</span></Typography>}/>
                <CardContent>
                    <Alert severity={'error'}>{t('application.overview.address.alert')}</Alert>

                    <Stack direction="row" spacing={2} sx={theme => ({marginTop: theme.spacing(2)})}>
                        {invalidAddresses
                                .map(item => {
                                            const address = watch(item.path) as Address;
                                            return <Card key={item.path} variant={'outlined'} sx={{width: '50%'}}>
                                                <CardHeader title={<Typography variant={'h6'}>{item.label}</Typography>}/>
                                                <CardContent>
                                                    <FormRow>
                                                        <AddressPicker<T> id={item.path}
                                                                          breakpoint={12}
                                                                          fieldPath={item.path}
                                                                          address={address}
                                                                          clearable={false}
                                                                          showAdditionalLine={item.showAdditionalLine}
                                                                          showAcceptUnverifiedAddress={true}
                                                                          onAddressSelect={(selectedAddress: Address | AddressWithAdditionalLine | undefined | null) => onAddressSelect(item.path, selectedAddress)}/>
                                                    </FormRow>
                                                </CardContent>
                                            </Card>;
                                        }
                                )}
                    </Stack>
                </CardContent>
                <CardActions>
                    <AppFormButton outlined={false}
                                   color="secondary"
                                   icon={
                                       <SaveIcon/>}>{t('application.overview.address.action.saveAndProceed')}</AppFormButton>
                </CardActions>
            </form>
        </FormProvider>
    </AppCard>);
}

export interface AddressDefinition<T extends BaseApplication> {
    path: FieldPath<T>;
    label: string;
    showAdditionalLine?: true;
}

interface AddressDefinitionWithAddressObject<T extends BaseApplication> extends AddressDefinition<T> {
    address: OptionalAddress | AddressWithAdditionalLine | undefined | null;
}

function getFormSchema(applicationType: ApplicationTypeDto) {
    switch (applicationType) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
            return mainProfiCardDeApplicationSchema;
        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
            return mainProfiCardLuApplicationSchema;
        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
            return mainProfiCardChApplicationSchema;
        case ApplicationTypeDto.PROJEKT_WELT_MAIN:
            return mainProjektWeltApplicationSchema;
    }
    throw new Error('Unsupported application type: ' + applicationType);
}
