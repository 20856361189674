import {Container, Grid} from '@mui/material';
import React, {PropsWithChildren} from 'react';
import {MandatoryChildren} from '../utils/utils';

interface AppContentProps extends MandatoryChildren {
    label: React.ReactNode;
}

export function AppContent({label, children}: PropsWithChildren<AppContentProps>) {
    return (<Container>
        <Grid container>
            <Grid item xs={12}>{label}</Grid>
        </Grid>
        <Grid container>
            <Grid item xs={12}>{children}</Grid>
        </Grid>
    </Container>);
}
