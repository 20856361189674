import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AppContent} from '../../components/AppContent';
import {Alert, Box, Button, Grid, Pagination, Stack, TableCell, TableRow, Typography} from '@mui/material';
import {StickyHeaderTable} from '../../components/StickyHeaderTable';
import {AppFormButton} from '../../components/form/AppFormButton';
import {BackspaceOutlined, Search, Visibility} from '@mui/icons-material';
import {getUserSearchCriteria, invalidateGetUsersCache, useGetUsers} from './service';
import {Progress} from '../../components/Loader';
import {ErrorPanel} from '../../components/ErrorPanel';
import {ROUTES} from '../../router';
import {useNavigate, useSearchParams} from 'react-router-dom';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import {UserStatusLabel} from './UserStatusLabel';
import {z} from 'zod';
import {ApplicationTypeDto, Country, OriginatorDto} from '../../api';
import {UserRole, UserSearchStatus} from './model';
import {useAppForm} from '../../components/form/useAppForm';
import {FormProvider} from 'react-hook-form';
import {FormRow} from '../../components/form/FormRow';
import {AppTextField} from '../../components/form/AppTextField';
import {UserCountrySelectField} from './UserCountrySelectField';
import {AppCheckboxField} from '../../components/form/AppCheckboxField';
import {UserRoleSelectField} from './UserRoleSelectField';
import {UserMarketSelectField} from './UserRoleAndLocationFields';
import {useOriginatorContext} from '../../utils/OriginatorProvider';
import {optionalOptionEntry} from '../reference/model';
import {findOptionEntryByKey, useReferences} from '../reference/service';
import {UserStatusSelectField} from './UserStatusSelectField';
import AppConfig from '../../config';
import {styled} from '@mui/material/styles';

export function UsersListPage() {
    const {t} = useTranslation();

    return (<AppContent label={<Typography variant="h4">{t('user.management.list.title')}</Typography>}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            marginTop: '-40px',
                            marginBottom: '30px'
                        }}>
                            <AddNewUserButton/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <UserSearchFields/>
                        <UserTable/>
                    </Grid>
                </Grid>
            </AppContent>
    );
}

function AddNewUserButton() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (<AppFormButton disabled={false}
                           icon={<PersonAddAltOutlinedIcon/>}
                           color={'secondary'}
                           onClick={() => navigate(ROUTES.users.new)}>
        {t('user.management.button.add.new.user')}
    </AppFormButton>);
}

function UserTable() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchCriteria = getUserSearchCriteria(searchParams);
    const {userListResponse, isFetching, isError} = useGetUsers(searchCriteria);

    if (isFetching) {
        return <Progress/>;
    }
    if (isError || userListResponse === undefined) {
        return <ErrorPanel title={t('common.error.request-failure')}/>;
    }

    const currentPage = searchCriteria.pageIndex ?? 1;
    const pageSize = AppConfig.userListPageSize;
    const maxPage = Math.ceil(userListResponse.totalCount / pageSize);

    const onPageChange = (page: number) => {
        navigate(ROUTES.users.search({
            ...searchCriteria,
            pageSize: pageSize,
            pageIndex: page,
        }));
    };

    return (<>
        <StickyHeaderTable headerRow={
            <TableRow>
                <TableCell align={'center'}>{t('user.management.list.header.userName')}</TableCell>
                <TableCell align={'center'}>{t('user.management.list.header.firstName')}</TableCell>
                <TableCell align={'center'}>{t('user.management.list.header.lastName')}</TableCell>
                <TableCell align={'center'} size="small">{t('user.management.list.header.roles')}</TableCell>
                <TableCell align={'center'} size="small">{t('user.management.form.admin.checkbox')}</TableCell>
                <TableCell align={'center'} size="small">{t('user.management.list.header.country')}</TableCell>
                <TableCell align={'center'}>{t('user.management.list.header.store')}</TableCell>
                <TableCell align={'center'}>{t('user.management.list.header.status')}</TableCell>
                <TableCell></TableCell>
            </TableRow>}>
            {userListResponse.totalCount === 0 ? (
                    <TableRow><TableCell colSpan={6}
                                         sx={{color: 'secondary'}}><Alert
                            severity="info">{t('search.noResults')}</Alert></TableCell></TableRow>
            ) : userListResponse?.users.map((row) => {
                return (
                        <TableRow key={row.uid}>
                            <StyledTableCell align={'center'}>{row.userName}</StyledTableCell>
                            <StyledTableCell align={'center'}>{row.firstName}</StyledTableCell>
                            <StyledTableCell align={'center'}>{row.lastName}</StyledTableCell>
                            <StyledTableCell align={'center'} size="small">{row.role}</StyledTableCell>
                            <StyledTableCell
                                    align={'center'}
                                    size="small">{row.userAdminRights === true ? t('common.yes') : t('common.no')}</StyledTableCell>
                            <StyledTableCell
                                    align={'center'}
                                    size="small">{formatCountriesForOutput(row.countryDe, row.countryLu, row.countryCh)}</StyledTableCell>
                            <StyledTableCell
                                    align={'center'}>{row.store?.key ? `${row.store?.value}` : ''}</StyledTableCell>
                            <StyledTableCell align={'center'}>
                                <UserStatusLabel
                                        size="small"
                                        isActive={row.isActive}/>
                            </StyledTableCell>
                            <StyledTableCell align={'center'}>
                                <AppFormButton
                                        sx={{padding: '0.125rem 0.5rem', fontSize: '0.875rem'}}
                                        onClick={() => navigate(ROUTES.users.edit(row.userName))}
                                        icon={<Visibility sx={{
                                            width: '1rem',
                                            height: '1rem'
                                        }}/>}
                                >
                                    {t('common.row.details')}
                                </AppFormButton>
                            </StyledTableCell>
                        </TableRow>
                );
            })}
        </StickyHeaderTable>
        <Pagination sx={theme => ({float: 'right', marginTop: theme.spacing(1)})}
                    shape="rounded"
                    count={maxPage}
                    page={currentPage}
                    onChange={(event, page) => onPageChange(page)}
        />
    </>);
}

const StyledTableCell = styled(TableCell)({
    width: 80,
    maxWidth: 160,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderStyle: 'border-box',
    wordWrap: 'break-word',
});

function formatCountriesForOutput(isDE?: boolean, isLU?: boolean, isCH?: boolean): string {
    if (isDE && isLU && isCH) {
        return 'ALL';
    } else {
        const countries: string[] = [];
        if (isDE) {
            countries.push('DE');
        }
        if (isLU) {
            countries.push('LU');
        }
        if (isCH) {
            countries.push('CH');
        }
        return countries.join(' | ');
    }
}

const userSearchDataSchema = z.object({
    country: z.nativeEnum(Country).optional().nullable(),
    status: z.nativeEnum(UserSearchStatus).optional().nullable(),
    firstName: z.string().optional(),
    isAdmin: z.boolean().optional(),
    lastName: z.string().optional(),
    role: z.nativeEnum(UserRole).optional().nullable(),
    store: optionalOptionEntry,
    username: z.string().optional(),
});
type UserSearchData = z.infer<typeof userSearchDataSchema>;

function UserSearchFields() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {setContextApplicationType} = useOriginatorContext();
    setContextApplicationType(ApplicationTypeDto.PROFI_CARD_DE_MAIN);
    const {allShops} = useReferences(OriginatorDto.PROFI_CARD_GERMANY);

    const formData = useAppForm<UserSearchData>(userSearchDataSchema, {});

    const setValue = formData.setValue;
    const watch = formData.watch;

    useEffect(() => {
        if (!allShops) {
            return;
        }
        const userSearchCriteria = getUserSearchCriteria(searchParams);

        const shop = findOptionEntryByKey(allShops, userSearchCriteria.storeKey);
        setValue('store', shop);

        const {storeKey, pageIndex, pageSize, ...searchData} = userSearchCriteria;
        let key: keyof typeof searchData;
        for (key in searchData) {
            setValue(key, searchData[key]);
        }
    }, [setValue, searchParams, allShops]);

    const handleSubmit = formData.handleSubmit;

    const processSearch = (searchData: UserSearchData) => {
        invalidateGetUsersCache();
        const {store, ...searchDataWithoutStore} = searchData;
        navigate(ROUTES.users.search({storeKey: searchData.store?.key, ...searchDataWithoutStore}));
    };

    const country = watch('country');
    const showDeShops = !country || country === Country.DE;
    const showLuShops = !country || country === Country.LU;
    const showChShops = !country || country === Country.CH;

    const role = watch('role');

    const [isStoreSelectBoxActive, setIsStoreSelectBoxActive] = useState(true);
    useEffect(() => {
        const isStoreBoxActive = !role || role === UserRole.HORNBACH;
        setIsStoreSelectBoxActive(isStoreBoxActive);
        if (!isStoreBoxActive) {
            setValue('store', null);
        }

    }, [setValue, role]);

    return (<FormProvider {...formData}>
        <form onSubmit={handleSubmit(processSearch)}>
            <FormRow>
                <AppTextField<UserSearchData> breakpoint={2}
                                              label={t('user.management.form.username')}
                                              fieldPath={'username'}
                                              size="small"/>
                <AppTextField<UserSearchData> breakpoint={2}
                                              label={t('common.form.person.firstName')}
                                              fieldPath={'firstName'}
                                              size="small"/>
                <AppTextField<UserSearchData> breakpoint={2}
                                              label={t('common.form.person.lastName')}
                                              fieldPath={'lastName'}
                                              size="small"/>
                <UserRoleSelectField<UserSearchData> breakpoint={3}
                                                     fieldPath={'role'}
                                                     size={'small'}/>
                <AppCheckboxField<UserSearchData> breakpoint={3}
                                                  sx={theme => ({marginTop: theme.spacing(2.5)})}
                                                  fieldPath={'isAdmin'}
                                                  label={t('user.management.search.adminsOnly')}/>
            </FormRow>
            <FormRow>
                <UserCountrySelectField<UserSearchData> breakpoint={2}
                                                        fieldPath={'country'}
                                                        required={false}
                                                        size="small"/>
                <UserMarketSelectField<UserSearchData> breakpoint={3}
                                                       fieldPath={'store'}
                                                       disabled={!isStoreSelectBoxActive}
                                                       showDeShops={showDeShops}
                                                       showLuShops={showLuShops}
                                                       showChShops={showChShops}
                                                       size={'small'}/>
                <UserStatusSelectField<UserSearchData> breakpoint={2}
                                                       fieldPath={'status'}
                                                       size={'small'}/>
            </FormRow>
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center"
                   mt={'-40px'} pb="20px">
                <AppFormButton icon={<Search/>}>
                    {t('application.button.search')}
                </AppFormButton>
                <Button startIcon={<BackspaceOutlined/>} type={'reset'}
                        onClick={() => {
                            formData.reset();
                            processSearch(formData.getValues());
                        }}>
                    {t('application.button.searchReset')}
                </Button>
            </Stack>
        </form>
    </FormProvider>);
}
