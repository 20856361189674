import moment from 'moment/moment';
import React from 'react';
import {z, ZodDate} from 'zod';
import AppConfig from '../config';
import {Money} from '../domain/money/model';
import {InvalidOption, invalidOptionSchema, isInvalidOption} from './invalidOptionSchema';

export interface MandatoryChildren {
    children: React.ReactNode;
}

type OptionalString = string | undefined | null;

export function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}

export function mapEnum<T>(value: any): T | undefined {
    if (value === undefined || value === '') {
        return undefined;
    }
    return value as unknown as T;
}

export function trimAndCapitalizeFirstLetter(str: OptionalString) {
    return capitalizeFirstLetter(str?.trim());
}

export function capitalizeFirstLetter(str: OptionalString) {
    if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
}

export function formatAmountWithCurrency(money: Money | undefined | null) {
    if (money?.currency === undefined || money?.amount === undefined || money?.amount === null) {
        return '-';
    }
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: money.currency}).format(money.amount);
}

export function formatLocalDate(date: Date | InvalidOption | null | undefined) {
    if (date === null || date === undefined || isInvalidOption(date)) {
        return undefined;
    }
    return moment(date as Date).format('YYYY-MM-DD');
}

export function parseLocalDate(localDateString: string | null | undefined) {
    if (localDateString === null || localDateString === undefined) {
        return null;
    }
    return moment(localDateString).toDate();
}

export function formatDateTime(date: Date | string | null | undefined) {
    if (date === null || date === undefined || date === '') {
        return null;
    }
    return moment(date).format('YYYY-MM-DD HH:mm');
}

export function formatDateTimeWithSeconds(date: Date | string | null | undefined) {
    if (date === null || date === undefined || date === '') {
        return null;
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
}

export function getDateSchema(required: boolean, zodDateCustomizer?: (zodDate: ZodDate) => ZodDate) {
    const zodDate = zodDateCustomizer?.(z.date()) ?? z.date();
    const schema = zodDate
            .optional()
            .nullable()
            .or(invalidOptionSchema)
            .refine(
                    value => !isInvalidOption(value),
                    'zod:errors.invalid_date');
    if (required) {
        return schema.refine(
                value => value !== undefined && value !== null,
                'common.form.field.required'
        );
    }
    return schema;
}

export function getSchemaForDateOfBirthAndAdultCheck() {
    return getDateSchema(false,
            zodDate => zodDate.max(moment().endOf('day').subtract(AppConfig.ageOfAdulthood, 'year').toDate(),
                    'common.form.person.validation.notAdult'));
}

export function maxToday() {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
}

export function toIntSafe(text: string | undefined | null): number | undefined {
    if (text === undefined || text === null || text === '') {
        return undefined;
    }
    try {
        return parseInt(text);
    } catch (e) {
        console.error('Parsing to number failed:', text);
        return undefined;
    }
}

export const isAnyPropertyNotEmpty = (someObject: object) => {
    return Object.values(someObject).some(v => !!v);
};
