import React, {FC, Fragment} from 'react';
import XMLViewer from 'react-xml-viewer';
import {styled} from '@mui/material/styles';
import {AppCard} from '../../components/SectionCard';
import {CardContent, CardHeader, TableCell, TableRow} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {XMLValidator} from 'fast-xml-parser';
import {StickyHeaderTable} from '../../components/StickyHeaderTable';
import {
    isMainProjektWeltApplication,
    MainProjektWeltApplication
} from '../../domain/application/projektWelt/main/model';
import {
    MainProjektWeltApplicationOverviewSection
} from '../../domain/application/projektWelt/main/MainProjektWeltApplicationOverviewSection';
import {useGetSchufaInteractions} from '../../domain/application/service';
import {Progress} from '../../components/Loader';
import {SchufaMessageExchangeDto} from '../../api';

const customTheme = {
    'attributeKeyColor': '#ac81fe',
    'attributeValueColor': '#fd971f',
    'cdataColor': '#a6e22e',
    'separatorColor': '#66d9ef',
    'tagColor': '#f92672',
    'textColor': '#fd971f',
    'commentColor': '#9fa19f',
    'font-family': 'monospace;'
};

const StyledCode = styled('div')({
    fontSize: '0.875rem',
    width: '100%',
    padding: '0.5rem',
    backgroundColor: '#272822',
    overflow: 'auto',
    color: '#9fa19f'
});

const StyledTableCell = styled(TableCell)({
    width: '10%',
    fontWeight: 'bolder',
    verticalAlign: 'top',
    padding: '1.5rem 1rem',
});

interface ApplicationSchufaTabProps {
    applicationNumber: string;
    application: MainProjektWeltApplication;
}

export const ApplicationSchufaTab: FC<ApplicationSchufaTabProps> = ({application}) => {
    const {schufaInteractions, isFetched} = useGetSchufaInteractions(application.applicationNumber!!);
    const lastSchufaInteraction = schufaInteractions?.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))[0];

    if (!isMainProjektWeltApplication(application)) {
        throw new Error('Application is not of type Main Projekt Welt');
    }
    if (schufaInteractions === undefined || schufaInteractions === null) {
        return null;
    }
    if (!isFetched) {
        return <Progress size={28}/>;
    }

    return (<>
        <MainProjektWeltApplicationOverviewSection application={application}/>
        <SchufaInteractionSection schufaInteractions={lastSchufaInteraction?.messages}/>
    </>);
};

interface SchufaInteractionSectionProps {
    schufaInteractions?: SchufaMessageExchangeDto[];
}

const SchufaInteractionSection: FC<SchufaInteractionSectionProps> = ({schufaInteractions}) => {
    const {t} = useTranslation();

    if (!schufaInteractions || !schufaInteractions.length) {
        return (
                <AppCard>
                    <CardHeader sx={{
                        backgroundColor: '#e5e5e5',
                        border: '1px solid #cccccc'
                    }} title={t('application.schufa.interaction.title')}/>
                    <CardContent>{t('application.schufa.interaction.noResults')}</CardContent>
                </AppCard>
        );
    }

    return (
            <AppCard>
                <StickyHeaderTable headerRow={
                    <TableRow>
                        <TableCell colSpan={2} padding="none">
                            <CardHeader title={t('application.schufa.interaction.title')}/>
                        </TableCell>
                    </TableRow>
                }>
                    {schufaInteractions?.map((item) =>
                            <Fragment key={item.id}>
                                {item.requestContent && <SchufaInteractionItem
                                        type={t('application.schufa.request')}
                                        message={item.requestContent}
                                />}
                                {item.responseContent && <SchufaInteractionItem
                                        type={t('application.schufa.response')}
                                        message={item.responseContent}
                                />}
                            </Fragment>
                    )}
                </StickyHeaderTable>
            </AppCard>
    );
};

const SchufaInteractionItem = ({type, message}: { type: string, message: string }) => {
    const isValid = XMLValidator.validate(message);

    return (
            <TableRow>
                <StyledTableCell>{type}</StyledTableCell>
                <TableCell>
                    <StyledCode>
                        {isValid === true ? <XMLViewer xml={message} theme={customTheme}/> : message}
                    </StyledCode>
                </TableCell>
            </TableRow>
    );
};
