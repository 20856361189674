import {useQuery} from '@tanstack/react-query';
import {
    ApplicationTypeDto,
    Language,
    MainProfiCardChApplicationDto,
    MainProfiCardDeApplicationDto,
    MainProfiCardLuApplicationDto,
    OriginatorDto,
    ReferencesResourceService
} from '../../api';

import {getCurrentLanguage} from '../../i18n';
import {queryClient} from '../../QueryHookClientProvider';
import {withAbortSignal} from '../../utils/  withAbortSignal';
import {AllSupportedCountriesIsoCode, AllSupportedCountriesKey, CountryUtils} from '../country';
import {ANY_VALUE_KEY, OptionEntry} from './model';

function mapToSortedEntriesList(data: any) {
    return Object.entries(data)
        .map(entry => ({key: entry[0], value: entry[1]} as OptionEntry))
        .sort((a, b) => a.value.toLowerCase().localeCompare(b.value.toLowerCase()));
}

function fetchReferences(originator: OriginatorDto, language: Language, signal?: AbortSignal) {
    return withAbortSignal(() => ReferencesResourceService.getAll(language, originator), signal)
        .then(response => {
            const branchesOfIndustry = Object.entries(response.branchesOfIndustry as any)
                .map(entry => ({key: entry[0], value: mapToSortedEntriesList(entry[1])}));
            return {
                getLegalForms: (applicationType: ApplicationTypeDto | AllSupportedCountriesKey | AllSupportedCountriesIsoCode | string) => {
                    switch (applicationType) {
                        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
                        case CountryUtils.DE.code:
                        case CountryUtils.DE.key:
                            return mapToSortedEntriesList(response.legalFormsDE);

                        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
                        case CountryUtils.LU.code:
                        case CountryUtils.LU.key:
                            return mapToSortedEntriesList(response.legalFormsLU);

                        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
                        case CountryUtils.CH.code:
                        case CountryUtils.CH.key:
                            return mapToSortedEntriesList(response.legalFormsCH);

                        case CountryUtils.FR.code:
                        case CountryUtils.FR.key:
                            return mapToSortedEntriesList(response.legalFormsFR);

                        case CountryUtils.BE.code:
                        case CountryUtils.BE.key:
                            return mapToSortedEntriesList(response.legalFormsBE);

                        case CountryUtils.AT.code:
                        case CountryUtils.AT.key:
                            return mapToSortedEntriesList(response.legalFormsAT);
                    }
                    throw new Error('Unsupported application type to get legal forms: ' + applicationType);
                },
                titles: mapToSortedEntriesList(response.titles),
                salutations: mapToSortedEntriesList(response.salutations),
                citizenship: mapToSortedEntriesList(response.citizenship),
                countryCodes: mapToSortedEntriesList(response.countryCodes),
                branchCategories: mapToSortedEntriesList(response.branchCategories),
                branchesOfIndustry: branchesOfIndustry,
                findBranchOfIndustryByBranchCategoryKey: (branchCategoryKey: string | undefined | null, branchOfIndustryKey: string | undefined | null, defaultIfNotFound: (null | undefined) = null) => {
                    if (branchCategoryKey === undefined || branchCategoryKey === null) {
                        return defaultIfNotFound;
                    }
                    return branchesOfIndustry.find(item => item.key === branchCategoryKey)?.value
                        .find(item => item?.key === branchOfIndustryKey) ?? defaultIfNotFound;
                },
                getShops: (applicationType: ApplicationTypeDto | OriginatorDto) => {
                    switch (applicationType) {
                        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
                        case OriginatorDto.PROFI_CARD_GERMANY:
                        case ApplicationTypeDto.PROJEKT_WELT_MAIN:
                        case ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL:
                        case OriginatorDto.PROJEKT_WELT_GERMANY:
                            return mapToSortedEntriesList(response.shopsDE);

                        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
                        case OriginatorDto.PROFI_CARD_LUXEMBURG:
                            return mapToSortedEntriesList(response.shopsLU);

                        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
                        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
                        case OriginatorDto.PROFI_CARD_SWITZERLAND:
                            return mapToSortedEntriesList(response.shopsCH);
                    }
                    throw new Error('Unsupported application type to get shops: ' + applicationType);
                },
                allShops: mapToSortedEntriesList(response.shopsDE).concat(mapToSortedEntriesList(response.shopsLU)).concat(mapToSortedEntriesList(response.shopsCH)),
                placeOfRegistration: mapToSortedEntriesList(response.placeOfRegistration),
                getPlaceOfRegistration: (country: AllSupportedCountriesKey | AllSupportedCountriesIsoCode | string | undefined) => {
                    switch (country) {
                        case 'DE':
                        case '276':
                            return mapToSortedEntriesList(response.placeOfRegistrationDe);
                        case 'CH':
                        case '756':
                            return mapToSortedEntriesList(response.placeOfRegistrationCh);
                        default:
                            return undefined;
                    }
                },
            };
        });
}

export function useReferences(originator: OriginatorDto) {
    const language = getCurrentLanguage();
    const {data, isLoading, isLoadingError} = useQuery({
        queryKey: ['references', originator, language],
        queryFn: ({signal}) => fetchReferences(originator, language, signal)
    });
    return {
        data,
        isLoading,
        isLoadingError,
        getLegalForms: data?.getLegalForms,
        titles: data?.titles,
        salutations: data?.salutations,
        citizenship: data?.citizenship,
        countryCodes: data?.countryCodes,
        branchCategories: data?.branchCategories,
        branchesOfIndustry: data?.branchesOfIndustry,
        findBranchOfIndustryByBranchCategoryKey: data?.findBranchOfIndustryByBranchCategoryKey,
        getShops: data?.getShops,
        allShops: data?.allShops,
        placeOfRegistration: data?.placeOfRegistration,
        getPlaceOfRegistration: data?.getPlaceOfRegistration,
    };
}

export function getReferences(originator: OriginatorDto) {
    const language = getCurrentLanguage();
    return queryClient.fetchQuery({
        queryKey: ['references', originator, language],
        queryFn: ({signal}) => fetchReferences(originator, language, signal)
    });
}

export function findOptionEntryByKey(entries: OptionEntry[] | undefined, key: string | undefined, defaultIfNotFound: (null | undefined | any) = null) {
    return entries?.find(item => item.key === key) ?? defaultIfNotFound;
}

export function findPlaceOfRegistrationOptionEntryByKey(getPlaceOfRegistration: (country: AllSupportedCountriesKey | AllSupportedCountriesIsoCode | string | undefined) => OptionEntry[] | undefined,
                                                        data: MainProfiCardDeApplicationDto | MainProfiCardChApplicationDto | MainProfiCardLuApplicationDto) {
    const placeOfRegistrations = getPlaceOfRegistration(data.company?.address?.country?.key);
    const defaultIfNotFound = placeOfRegistrations === undefined ? {
        key: ANY_VALUE_KEY,
        value: data.legitimization?.placeOfRegistration?.value ?? '',
    } : null;
    return findOptionEntryByKey(placeOfRegistrations, data.legitimization?.placeOfRegistration?.key, defaultIfNotFound);
}
