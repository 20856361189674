import Config from '../../config';
import {fetchBlob} from '../../utils/downloadFile';
import {formatLocalDate} from '../../utils/utils';

export function getDetailedReport(fromDate: Date, toDate: Date) {
    const {from, to} = formatDateRange(fromDate, toDate);

    const url = `${Config.serviceUrl}/api/reports/detailed?from=${from}&to=${to}`;
    return fetchBlob(url);
}

export function getSummaryReport(fromDate: Date, toDate: Date) {
    const {from, to} = formatDateRange(fromDate, toDate);

    const url = `${Config.serviceUrl}/api/reports/summary?from=${from}&to=${to}`;
    return fetchBlob(url);
}

function formatDateRange(from: Date, to: Date) {
    return {
        from: formatLocalDate(from),
        to: formatLocalDate(to),
    };
}
