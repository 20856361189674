import {BaseApplication} from '../model';
import {isAdditionalProfiCardApplication} from './additional/model';
import {isMainProfiCardChApplication} from './main/ch/model';
import {isMainProfiCardDeApplication} from './main/de/model';
import {isMainProfiCardLuApplication} from './main/lu/model';
import {BaseMainProfiCardApplicationUnion, BaseProfiCardApplication} from './model';

export function isMainProfiCardApplication(application: BaseApplication | undefined): application is BaseMainProfiCardApplicationUnion {
    if (application === undefined) {
        console.log('Application is undefined');
        return false;
    }
    return isMainProfiCardDeApplication(application)
        || isMainProfiCardLuApplication(application)
        || isMainProfiCardChApplication(application);
}

export function isProfiCardApplication(application: BaseApplication): application is BaseProfiCardApplication {
    return isMainProfiCardApplication(application) || isAdditionalProfiCardApplication(application);
}
