import React from 'react';
import {Outlet} from 'react-router-dom';
import {AppBreadcrumbs} from '../../components/AppBreadcrumbs';
import {TopBar} from '../../components/TopBar';

export default function SharedLayout() {
    return (<>
        <TopBar/>
        <AppBreadcrumbs/>
        <Outlet/>
    </>);
}
