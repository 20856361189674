import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {MandatoryChildren} from './utils/utils';
import React, {FC} from 'react';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 30 * 60 * 1000,   // how long cache has to be valid in ms
        }
    }
});

export const QueryHookClientProvider: FC<MandatoryChildren> = ({children}) => {
    return (<QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>);
};
