import {RefinementCtx, z} from 'zod';
import {ApplicationTypeDto, CardDispatchDto, Currency, RiskByDto} from '../../../../api';
import AppConfig from '../../../../config';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {toSchemaWithoutValidationRules} from '../../../../utils/toSchemaWithoutValidationRules';
import {getDateSchema, getSchemaForDateOfBirthAndAdultCheck, maxToday} from '../../../../utils/utils';
import {addressSchema, addressWithAdditionalLineSchema} from '../../../auxiliary/model';
import {createEmptyAddress, createEmptyAddressWithAdditionalLine} from '../../../auxiliary/service';
import {optionalEmailSchema} from '../../../common/model';
import {CountryUtils} from '../../../country';
import {moneySchema} from '../../../money/model';
import {optionalOptionEntry, OptionEntry} from '../../../reference/model';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {User} from '../../../user/model';
import {addValidationErrorToPath} from '../../../util';
import {marketDataSchema} from '../../marketData/model';
import {ApplicationStatus} from '../../model';
import {baseProfiCardApplicationSchema} from '../model';

const companyDataSchema = z.object({
    companyName: z.string().max(120),
    legalForm: optionalOptionEntry,
    branchCategory: optionalOptionEntry,
    branchOfIndustry: optionalOptionEntry,
    address: z.optional(addressWithAdditionalLineSchema),
});
export type CompanyData = z.infer<typeof companyDataSchema>;

const billingAddressSchema = z.object({
    contactPersonName: z.string().max(40),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
    phoneNumber: z.string().max(38).nullable().optional(),
    address: z.optional(addressSchema),
});
export type BillingAddress = z.infer<typeof billingAddressSchema>;

const billingAddressSchemaOptional = toSchemaWithoutValidationRules(billingAddressSchema);
export type BillingAddressOptional = z.infer<typeof billingAddressSchemaOptional>;

const mainCardholderSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getSchemaForDateOfBirthAndAdultCheck(),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
    phoneNumber: z.string().max(38).nullable().optional(),
});
export type MainCardholder = z.infer<typeof mainCardholderSchema>;

const companyOwnerSchema = z.object({
    differentFromCardholder: z.boolean(),
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getSchemaForDateOfBirthAndAdultCheck(),
    placeOfBirth: z.string().max(40),
    nationality: optionalOptionEntry,
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38).nullable().optional(),
    phoneNumber: z.string().max(38).nullable().optional(),
    address: z.optional(addressWithAdditionalLineSchema),
});
export type CompanyOwner = z.infer<typeof companyOwnerSchema>;

const optionalCompanyOwnerSchema = toSchemaWithoutValidationRules(companyOwnerSchema);

const legitimizationSchema = z.object({
    idCardNumber: z.string().max(30),
    issuingAuthority: z.string().max(40),
    dateOfIssue: getDateSchema(false, zodDate => zodDate.max(maxToday())),
    commercialRegisterNumber: z.string().max(21).optional(),
    placeOfRegistration: optionalOptionEntry,
    name1Signature: z.string().max(60),
    name2Signature: z.string().max(60).optional(),
});
export type Legitimization = z.infer<typeof legitimizationSchema>;

export const baseMainProfiCardApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_DE_MAIN).or(z.literal(ApplicationTypeDto.PROFI_CARD_LU_MAIN).or(z.literal(ApplicationTypeDto.PROFI_CARD_CH_MAIN))),
    version: z.number(),
    company: z.optional(companyDataSchema),
    companyOwner: optionalCompanyOwnerSchema,
    isAdditionalBillingAddress: z.boolean().optional(),
    billingAddress: z.optional(billingAddressSchemaOptional),
    cardHolder: mainCardholderSchema.optional(),
    marketData: marketDataSchema.optional(),
    cardDispatch: z.nativeEnum(CardDispatchDto).optional().nullable(),
    legitimization: legitimizationSchema.optional(),
    consents: z.boolean().optional().transform(value => !!value),
    advertisementConsent: z.boolean().optional().transform(value => !!value),
    creditValidatorLimit: moneySchema,
    riskBy: z.nativeEnum(RiskByDto).optional().nullable(),
    solvencyIndex: z.number().optional().nullable(),
    ignoreDuplicates: z.boolean().optional().transform(value => !!value),
    isCreatedBySmartSignUp: z.boolean().optional().nullable(),
})
    .merge(baseProfiCardApplicationSchema)
    .superRefine(async (data, ctx) => {
        validateBillingAddress(data, ctx);
        validateCompanyOwner(data, ctx);
    });

export type BaseMainProfiCardApplication = z.infer<typeof baseMainProfiCardApplicationSchema>;

function validateBillingAddress(data: BaseMainProfiCardApplication, ctx: RefinementCtx) {
    if (data.isAdditionalBillingAddress) {
        const billingAddressKey = 'billingAddress';
        const obj = data[billingAddressKey];
        const validationResult = billingAddressSchema.safeParse(obj);
        addValidationErrorToPath(billingAddressKey, validationResult, ctx);
    }
}

export function isCompanyOwnerRequired(data: BaseMainProfiCardApplication) {
    const legalFormKey = data.company?.legalForm?.key;
    const countryKey = data.company?.address?.country?.key;
    if (legalFormKey === undefined || countryKey === undefined) {
        return false;
    }
    switch (countryKey) {
        case CountryUtils.DE.code:
            return AppConfig.applicationCompanyOwnerRequiredWhenLegalFormKeyDe.includes(legalFormKey);
        case CountryUtils.CH.code:
            return AppConfig.applicationCompanyOwnerRequiredWhenLegalFormKeyCh.includes(legalFormKey);
        case CountryUtils.BE.code:
            return AppConfig.applicationCompanyOwnerRequiredWhenLegalFormKeyBe.includes(legalFormKey);
        case CountryUtils.FR.code:
            return AppConfig.applicationCompanyOwnerRequiredWhenLegalFormKeyFr.includes(legalFormKey);
        case CountryUtils.AT.code:
            return AppConfig.applicationCompanyOwnerRequiredWhenLegalFormKeyAt.includes(legalFormKey);
    }
    return false;
}

function validateCompanyOwner(data: BaseMainProfiCardApplication, ctx: RefinementCtx) {
    if (isCompanyOwnerRequired(data)) {
        const companyOwnerKey = 'companyOwner';
        const obj = data[companyOwnerKey];
        const validationResult = companyOwnerSchema.safeParse(obj);
        addValidationErrorToPath(companyOwnerKey, validationResult, ctx);
    }
}

export type MainProfiCardApplicationType =
    ApplicationTypeDto.PROFI_CARD_DE_MAIN
    | ApplicationTypeDto.PROFI_CARD_LU_MAIN
    | ApplicationTypeDto.PROFI_CARD_CH_MAIN;

export async function createEmptyBaseMainProfiCardApplication(user: User, applicationType: MainProfiCardApplicationType, country?: OptionEntry): Promise<BaseMainProfiCardApplication> {
    const originator = mapApplicationTypeToOriginator(applicationType);
    const references = await getReferences(originator);
    const application: BaseMainProfiCardApplication = {
        applicationType: applicationType,
        version: 0,
        status: ApplicationStatus.CREATED,
        desiredLimit: {
            amount: 2000,
            currency: Currency.EUR,
        },
        company: {
            companyName: '',
            legalForm: null,
            branchCategory: null,
            branchOfIndustry: null,
            address: {
                ...createEmptyAddressWithAdditionalLine(),
                country: country,
            },
        },
        companyOwner: {
            differentFromCardholder: false,
            salutation: null,
            title: null,
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            placeOfBirth: '',
            email: '',
            cellPhoneNumber: '',
            phoneNumber: '',
            nationality: null,
            ...createEmptyAddressWithAdditionalLine(),
        },
        isAdditionalBillingAddress: false,
        billingAddress: {
            contactPersonName: '',
            address: createEmptyAddress(),
            email: '',
            cellPhoneNumber: '',
            phoneNumber: '',
        },
        cardHolder: {
            salutation: null,
            title: null,
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            email: '',
            cellPhoneNumber: '',
            phoneNumber: '',
        },
        marketData: {
            masterStore: findOptionEntryByKey(references.getShops(applicationType), user.store?.key),
            pinForWebShop: true,
        },
        cardDispatch: null,
        legitimization: {
            idCardNumber: '',
            dateOfIssue: null,
            issuingAuthority: '',
            commercialRegisterNumber: '',
            placeOfRegistration: null,
            name1Signature: '',
            name2Signature: '',
        },
        consents: false,
        advertisementConsent: true,
        ignoreDuplicates: false,
    };
    return application;
}
