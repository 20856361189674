import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppCheckboxField} from '../../../../../components/form/AppCheckboxField';
import {ApplicationFormProps} from '../../../../../pages/application/NewApplicationPage';
import {
    createEmptyMainProfiCardLuApplication,
    MainProfiCardLuApplication,
    mainProfiCardLuApplicationSchema,
    MainProfiCardLuLegitimizationForm,
    mainProfiCardLuLegitimizationFormSchema
} from './model';
import {
    BillingAddressFormSection,
    CardDispatchFormSection,
    CompanyDataFormSection, CompanyOwnerFormSection,
    DesiredLimitFormSection,
    LegitimationFormSection,
    MainCardholderFormSection
} from '../commonMainProfiCardApplicationFormComponents';
import {SmartSignUpWithApplication} from '../SmartSignUpWithApplication';
import {ApplicationTypeDto} from '../../../../../api';
import {ConsentsFormSection} from '../../../ConsentsFormSection';
import {MarketDataFormSection} from '../../../marketData/MarketDataFormSection';

interface MainProfiCardLuApplicationFormProps extends ApplicationFormProps<MainProfiCardLuApplication> {
}

export const MainProfiCardLuApplicationForm: FC<MainProfiCardLuApplicationFormProps> = (props) => (
        <SmartSignUpWithApplication<MainProfiCardLuApplication>
                createEmptyMainProfiCardApplication={createEmptyMainProfiCardLuApplication}
                application={props.application}
                applicationSchema={mainProfiCardLuApplicationSchema}
                afterDraftSaved={props.afterDraftSaved}
                afterLegitimized={props.afterLegitimized}
                renderFields={isReadOnly =>
                        <MainProfiCardLuApplicationFields isReadOnly={isReadOnly} application={props.application}/>}
                showLegitimizeDialog={props.showLegitimizeDialog}
                legitimizationFormSchema={getMainProfiCardLegitimizationSchema}
                renderLegitimizationCheckList={() => <LegitimizeCheckList/>}
        />);

function getMainProfiCardLegitimizationSchema() {
    return mainProfiCardLuLegitimizationFormSchema;
}

interface MainProfiCardApplicationFieldsProps {
    isReadOnly: boolean;
    application: MainProfiCardLuApplication;
}

const MainProfiCardLuApplicationFields: FC<MainProfiCardApplicationFieldsProps> = ({isReadOnly, application}) => (<>
    <CompanyDataFormSection readOnly={isReadOnly} application={application}/>
    <BillingAddressFormSection readOnly={isReadOnly}/>
    <MainCardholderFormSection readOnly={isReadOnly}/>
    <CompanyOwnerFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROFI_CARD_DE_MAIN}/>
    <DesiredLimitFormSection readOnly={isReadOnly}/>
    <MarketDataFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROFI_CARD_LU_MAIN}/>
    <CardDispatchFormSection readOnly={isReadOnly}/>
    <LegitimationFormSection readOnly={isReadOnly}/>
    <ConsentsFormSection readOnly={isReadOnly} applicationType={application.applicationType}/>
</>);

function LegitimizeCheckList() {
    const {t} = useTranslation();

    return (<>
        <AppCheckboxField<MainProfiCardLuLegitimizationForm> fieldPath={'companySignature'}
                                                             label={t('application.dialog.legitimization.companySignature')}/>
        <AppCheckboxField<MainProfiCardLuLegitimizationForm> fieldPath={'principalCardholderSignature'}
                                                             label={t('application.dialog.legitimization.mainCardHolderSignature')}/>
        <AppCheckboxField<MainProfiCardLuLegitimizationForm> fieldPath={'employeeSignature'}
                                                             label={t('application.dialog.legitimization.employeeSignature')}/>
    </>);
}
