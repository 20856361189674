import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {ReferenceSelectFieldProps} from '../reference/ReferenceSelectFieldProps';
import {UserCountry} from './model';

export function UserCountrySelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const countries: string[] = Object.values(UserCountry);

    return (<AppAutocompleteField<string, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                        options={countries}
                                                        getLabel={option => option}
                                                        getId={option => option}
                                                        fieldPath={props.fieldPath}
                                                        disabled={props.disabled}
                                                        label={t('common.form.address.country')}
                                                        required={props.required}
                                                        size={props.size}
                                                        readOnly={props.readOnly}
    />);

}
