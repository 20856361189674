import React, {FC} from 'react';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {ActionButtonsSection,} from '../../domain/application/ActionButtons';
import {
    MainProfiCardApplicationOverviewSection
} from '../../domain/application/profiCard/main/MainProfiCardApplicationOverviewSection';
import {CrefoLatestInteractionSection} from '../../domain/application/profiCard/main/CrefoLatestInteractionSection';
import {
    DuplicateApplicationProblemSection
} from '../../domain/application/profiCard/main/DuplicateApplicationProblemSection';
import {CreateContractFailureSection} from '../../domain/application/CreateContractFailureSection';

import {BaseMainProfiCardApplicationUnion} from '../../domain/application/profiCard/model';
import {
    MainProfiCardApplicationAddressProblemSection
} from '../../domain/application/profiCard/main/MainProfiCardApplicationAddressProblemSection';
import {
    DuplicateServiceOutageProblemSection
} from '../../domain/application/profiCard/main/DuplicateServiceOutageProblemSection';
import AutoAssignLimitSection from '../../domain/application/AutoAssignLimitSection';
import {ApplicationStatus} from '../../domain/application/model';

interface MainProfiCardApplicationOverviewTabProps {
    application: BaseMainProfiCardApplicationUnion;
}

export const MainProfiCardApplicationOverviewTab: FC<MainProfiCardApplicationOverviewTabProps> = ({application}) => {
    const isReadOnlyMode = [ApplicationStatus.READY, ApplicationStatus.SUCCESSFUL_COMPLETED, ApplicationStatus.WITHDRAWN, ApplicationStatus.REJECTED].includes(application.status);

    return (<>
        <MainProfiCardApplicationOverviewSection application={application}/>
        <ActionButtonsSection application={application}/>
        {application.processingErrorReason === ApplicationProcessingErrorReasonDto.INVALID_ADDRESS &&
                <MainProfiCardApplicationAddressProblemSection application={application}/>}
        {application.processingErrorReason === ApplicationProcessingErrorReasonDto.DUPLICATED &&
                <DuplicateApplicationProblemSection application={application}/>}
        {application.processingErrorReason === ApplicationProcessingErrorReasonDto.DUPLICATED_COMMUNICATION_FAILURE &&
                <DuplicateServiceOutageProblemSection application={application}/>}
        {(application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION
                        || application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION_SEARCH_FAILED) &&
                <CrefoLatestInteractionSection application={application}
                                               additionalRow={!isReadOnlyMode &&
                                                       <AutoAssignLimitSection application={application}/>}/>}
        {application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE &&
                <CreateContractFailureSection application={application}/>}
    </>);
};
