import {useQuery} from '@tanstack/react-query';
import {ApiError, AuthResourceService, Country, UserOperationStatus, UserResourceService} from '../../api';
import AppConfig from '../../config';
import {queryClient} from '../../QueryHookClientProvider';
import {withAbortSignal} from '../../utils/  withAbortSignal';
import {mapEnum, toIntSafe} from '../../utils/utils';
import {mapUserDtoToModel, mapUserToUserOperationRequest} from './mapper';
import {User, UserRole, UserSearchStatus} from './model';

export function getUserInfo(signal?: AbortSignal) {
    return withAbortSignal(() => AuthResourceService.userInfo(), signal)
        .then(mapUserDtoToModel);
}

export interface UsersResponse {
    users: User[];
    totalCount: number;
}

export interface UserSearchQueryParameters {
    pageIndex?: number;
    pageSize?: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    role?: UserRole | null;
    country?: Country | null;
    storeKey?: string;
    status?: UserSearchStatus | null;
    isAdmin?: boolean;
}

export function getUserSearchCriteria(searchParams: URLSearchParams): UserSearchQueryParameters {
    return {
        pageIndex: toIntSafe(searchParams.get('pageIndex')),
        pageSize: toIntSafe(searchParams.get('pageSize')) ?? AppConfig.applicationListPageSize,
        username: searchParams.get('username') ?? undefined,
        firstName: searchParams.get('firstName') ?? undefined,
        lastName: searchParams.get('lastName') ?? undefined,
        role: mapEnum(searchParams.get('role')) ?? undefined,
        country: mapEnum(searchParams.get('country')) ?? undefined,
        storeKey: searchParams.get('storeKey') ?? undefined,
        status: mapEnum(searchParams.get('status')) ?? UserSearchStatus.ALL,
        isAdmin: searchParams.get('isAdmin')?.toLowerCase() === 'true',
    };
}

function getUsers(query: UserSearchQueryParameters | undefined, signal?: AbortSignal): Promise<UsersResponse> {
    return withAbortSignal(() => UserResourceService.getUsers(
        query?.country ?? undefined,
        query?.status === UserSearchStatus.ACTIVE ? true : query?.status === UserSearchStatus.BLOCKED ? false : undefined,
        query?.firstName ?? undefined,
        query?.isAdmin || undefined,    // if false -> just ignore the parameter
        query?.lastName ?? undefined,
        (query?.pageIndex ?? 1) - 1,
        query?.pageSize ?? AppConfig.userListPageSize,
        query?.role ?? undefined,
        query?.storeKey ?? undefined,
        query?.username ?? undefined,
    ), signal)
        .then((userListResponse) => {
            const mappedUserPromises = userListResponse.users?.map(mapUserDtoToModel);
            if (mappedUserPromises === undefined) {
                return {
                    users: [],
                    totalCount: 0,
                };
            }
            return Promise.all(mappedUserPromises).then(users => {
                const sortedUsers = [...users].sort((a, b) =>
                    (b.userName?.toLowerCase() ?? '').localeCompare(a.userName?.toLowerCase() ?? ''));
                const usersResponse: UsersResponse = {
                    users: sortedUsers,
                    totalCount: userListResponse.totalCount ?? 0,
                };
                return usersResponse;
            });
        });
}

export function useGetUsers(query: UserSearchQueryParameters | undefined) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['user-list', query],
        queryFn: ({signal, queryKey}) => getUsers(queryKey[1] as UserSearchQueryParameters, signal),
        retry: false,
    });

    return {
        userListResponse: data,
        isFetching,
        isFetched,
        isError,
        error
    };
}

function getUserByUserName(username: string, signal?: AbortSignal) {
    return withAbortSignal(() => UserResourceService.searchByUsername(true, username), signal)
        .then(users => users[0])
        .then(mapUserDtoToModel);
}

export function useGetUserByUserName(username: string) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['username', username],
        queryFn: ({signal, queryKey}) => getUserByUserName(queryKey[1], signal),
        retry: false,
    });

    return {
        user: data,
        isFetching,
        isFetched,
        isError,
        error,
        isNotFoundError: (error as ApiError)?.status === 404,
    };
}

function throwNoSuchUserError(): never {
    throw new Error('User is undefined');
}

export function createOrUpdateUser(user: User, signal?: AbortSignal) {
    const createRequest = mapUserToUserOperationRequest(user);
    return withAbortSignal(() => UserResourceService.createOrUpdateUser(createRequest), signal)
        .then(async (status: UserOperationStatus) => {
            if (status.user) {
                updateUserInCache(await mapUserDtoToModel(status.user));
            }
            return status;
        });
}

export function blockUser(uid: string, signal?: AbortSignal) {
    return withAbortSignal(() => UserResourceService.block(uid), signal)
        .then(mapUserOperationStatusToUser)
        .then(updateUserInCache);
}

export function activateUser(uid: string, signal?: AbortSignal) {
    return withAbortSignal(() => UserResourceService.activate(uid), signal)
        .then(mapUserOperationStatusToUser)
        .then(updateUserInCache);
}

export function sendPasswordUpdate(uid: string, signal?: AbortSignal) {
    return withAbortSignal(() => UserResourceService.sendPasswordUpdate(uid), signal)
        .then(response =>
            mapUserOperationStatusToUser(response)
                .then(updateUserInCache)
                .then(() => response));
}

function mapUserOperationStatusToUser(status: UserOperationStatus) {
    const user = status.user ?? throwNoSuchUserError();
    return mapUserDtoToModel(user);
}

function updateUserInCache(user: User) {
    queryClient.setQueryData(['username', user.userName], user);
    invalidateGetUsersCache();
    return user;
}

export function invalidateGetUsersCache<T>() {
    queryClient.invalidateQueries({queryKey: ['user-list']});
}

