import {CardContent, Grid} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {AppCard} from '../../../../components/SectionCard';
import {formatAmountWithCurrency} from '../../../../utils/utils';
import {getProductName} from '../../../../pages/application/ApplicationsListPage';
import {AdditionalProfiCardApplication, isAdditionalProfiCardApplication} from './model';

interface AdditionalProfiCardApplicationOverviewSectionProps {
    application: AdditionalProfiCardApplication;
}

export const AdditionalProfiCardApplicationOverviewSection: FC<AdditionalProfiCardApplicationOverviewSectionProps> = ({application}) => {
    const {t} = useTranslation();

    if (!isAdditionalProfiCardApplication(application)) {
        throw new Error('Application is not of type Additional Card');
    }

    return (<AppCard>
        <CardContent>
            <Grid container item xs={6}>
                <LabelAndValue label={<strong>{t('application.crefo.details.row.productType')}</strong>}
                               value={getProductName(application.applicationType)}/>
                <LabelAndValue label={<strong>{t('application.form.companyData.companyName')}</strong>}
                               value={application.mainCard?.companyName}/>
                <LabelAndValue
                        label={<strong>{t('application.form.additionalCard.mainCard.search.cardNumber')}</strong>}
                        value={application.mainCard?.mainCardNumber}/>
                <LabelAndValue
                        label={<strong>{t('application.form.additionalCard.mainCard.accountLimit.label')}</strong>}
                        value={formatAmountWithCurrency(application.mainCard?.cardLimit)}/>
                <LabelAndValue label={<strong>{t('application.overview.additionalCard.limit')}</strong>}
                               value={formatAmountWithCurrency(application.accountLimit)}/>
                <LabelAndValue label={<strong>{t('application.accountNumber')}</strong>}
                               value={<strong>{application.accountNumber ?? '-'}</strong>}/>
                <LabelAndValue label={<strong>{t('application.cardNumber')}</strong>}
                               value={<strong>{application.cardNumber ?? '-'}</strong>}/>
            </Grid>
        </CardContent>
    </AppCard>);
};
