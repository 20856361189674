import React, {FC, ReactNode} from 'react';
import {Navigate, RouteObject, useLocation} from 'react-router-dom';
import {ApplicationSearchQueryParameters} from './domain/application/service';
import ApplicationDetailsPage from './pages/application/ApplicationDetailsPage';
import ApplicationsListPage from './pages/application/ApplicationsListPage';
import SharedLayout from './pages/application/SharedLayout';
import ErrorPage, {NoPermissionErrorPage} from './pages/error/ErrorPage';
import {formatLocalDate} from './utils/utils';
import AppConfig from './config';
import NewMainProfiCardDeApplicationPage from './pages/application/NewMainProfiCardDeApplicationPage';
import NewMainProfiCardLuApplicationPage from './pages/application/NewMainProfiCardLuApplicationPage';
import NewMainProfiCardChApplicationPage from './pages/application/NewMainProfiCardChApplicationPage';
import NewAdditionalProfiCardDeApplicationPage from './pages/application/NewAdditionalProfiCardDeApplicationPage';
import NewAdditionalProfiCardLuApplicationPage from './pages/application/NewAdditionalProfiCardLuApplicationPage';
import NewAdditionalProfiCardChApplicationPage from './pages/application/NewAdditionalProfiCardChApplicationPage';
import {UsersListPage} from './domain/user/UsersListPage';
import UserDetailsPage from './domain/user/UserDetailsPage';
import NewUserPage from './domain/user/NewUserPage';
import {useUserContext} from './domain/user/UserProvider';
import {hasAnyRole, hasOnlyAdminRole, isAdmin} from './domain/user/utils';
import {UserRole} from './domain/user/model';
import {ReportsPage} from './domain/reports/ReportsPage';
import {UserSearchQueryParameters} from './domain/user/service';
import NewMainProjektWeltApplicationPage from './pages/application/NewMainProjektWeltApplicationPage';
import NewAdditionalProjektWeltApplicationPage from './pages/application/NewAdditionalProjektWeltApplicationPage';
import SharedApplicationLayout from './pages/application/SharedApplicationLayout';

function buildQueryParamString<T extends Record<string, any>>(searchData: T) {
    return Object.entries(searchData)
            .filter(([key, value]) => !!value)
            .filter(([key, value]) => {
                // hide key-value pairs in url with default values of pagination parameters
                if (key === 'pageSize' && value === AppConfig.applicationListPageSize) {
                    return false;
                }
                if (key === 'pageIndex' && value === 1) {
                    return false;
                }
                return true;
            })
            .map(([key, value]) => key + '=' + encodeURIComponent(value))
            .join('&');
}

export const ROUTES = {
    applications: {
        list: '/applications',
        profiCard: {
            main: {
                create: {
                    de: '/applications/new/profi-card/main/de',
                    lu: '/applications/new/profi-card/main/lu',
                    ch: '/applications/new/profi-card/main/ch',
                },
            },
            additional: {
                create: {
                    de: '/applications/new/profi-card/additional/de',
                    lu: '/applications/new/profi-card/additional/lu',
                    ch: '/applications/new/profi-card/additional/ch',
                }
            },
        },
        projektWelt: {
            main: {
                create: '/applications/new/projekt-welt/main',
            },
            additional: {
                create: '/applications/new/projekt-welt/additional',
            },
        },
        search: (searchParams: ApplicationSearchQueryParameters) => {
            const searchData = {
                ...searchParams,
                creationDate: formatLocalDate(searchParams.creationDate),
                cardHolderDateOfBirth: formatLocalDate(searchParams.cardHolderDateOfBirth)
            };
            const query = buildQueryParamString(searchData);
            return `/applications?${query}`;
        },
        edit: (applicationNumber: string) => `/applications/${applicationNumber}`,
        delete: (applicationNumber: string) => `/applications/${applicationNumber}`,
        crefo: (applicationNumber: string) => `/applications/${applicationNumber}/crefo`,
        schufa: (applicationNumber: string) => `/applications/${applicationNumber}/schufa`,
        overview: (applicationNumber: string) => `/applications/${applicationNumber}/overview`,
        history: (applicationNumber: string) => `/applications/${applicationNumber}/history`,
    },
    users: {
        list: '/users',
        search: (searchParams: UserSearchQueryParameters) => {
            const query = buildQueryParamString(searchParams);
            return `/users?${query}`;
        },
        new: '/users/new',
        edit: (username: string | undefined) => `/users/${username}`,
    },
    reports: '/reports',
};

export function useStartPageUrl() {
    const location = useLocation();
    const {user} = useUserContext();
    const startPageUrl = hasOnlyAdminRole(user) ? ROUTES.users.list : ROUTES.applications.list;

    const isDefaultRoute = location.pathname === startPageUrl && !location.search;
    return {
        startPageUrl,
        isDefaultRoute,
    };
}

export function StartPage() {
    const {startPageUrl} = useStartPageUrl();
    return <Navigate to={startPageUrl}/>;
}

interface HasPermissionProps {
    element: ReactNode;
    url: string;
}

const HasPermission: FC<HasPermissionProps> = ({element, url}) => {
    const {user} = useUserContext();
    if (url.startsWith(ROUTES.applications.list) && hasAnyRole(user, [UserRole.HORNBACH, UserRole.HORNBACH_CENTRAL, UserRole.KNISTR])) {
        return <>{element}</>;
    }
    if (url.startsWith(ROUTES.users.list) && isAdmin(user)) {
        return <>{element}</>;
    }
    if (url.startsWith(ROUTES.reports) && (hasAnyRole(user, [UserRole.HORNBACH_CENTRAL, UserRole.KNISTR]) || isAdmin(user))) {
        return <>{element}</>;
    }
    return <NoPermissionErrorPage/>;
};

export const router: RouteObject[] = ([
    {
        path: '/',
        element: <StartPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: 'applications',
        element: <HasPermission element={<SharedApplicationLayout/>} url={ROUTES.applications.list}/>,
        children: [
            {
                index: true,
                element: <ApplicationsListPage/>,
            },
            {
                path: 'new/profi-card/main/de',
                element: <NewMainProfiCardDeApplicationPage/>,
            },
            {
                path: 'new/profi-card/main/lu',
                element: <NewMainProfiCardLuApplicationPage/>,
            },
            {
                path: 'new/profi-card/main/ch',
                element: <NewMainProfiCardChApplicationPage/>,
            },
            {
                path: 'new/profi-card/additional/de',
                element: <NewAdditionalProfiCardDeApplicationPage/>,
            },
            {
                path: 'new/profi-card/additional/lu',
                element: <NewAdditionalProfiCardLuApplicationPage/>,
            },
            {
                path: 'new/profi-card/additional/ch',
                element: <NewAdditionalProfiCardChApplicationPage/>,
            },
            {
                path: 'new/projekt-welt/main',
                element: <NewMainProjektWeltApplicationPage/>,
            },
            {
                path: 'new/projekt-welt/additional',
                element: <NewAdditionalProjektWeltApplicationPage/>,
            },
            {
                path: ':applicationNumber/*',
                element: <ApplicationDetailsPage/>,
            },
        ]
    },
    {
        path: 'users',
        element: <HasPermission element={<SharedLayout/>} url={ROUTES.users.list}/>,
        children: [
            {
                index: true,
                element: <UsersListPage/>,
            },
            {
                path: 'new',
                element: <NewUserPage/>,
            },
            {
                path: ':username',
                element: <UserDetailsPage/>,
            }
        ],
    },
    {
        path: 'reports',
        element: <HasPermission element={<SharedLayout/>} url={ROUTES.reports}/>,
        children: [
            {
                index: true,
                element: <ReportsPage/>,
            },
        ],
    },
]);


