import LoadingButton from '@mui/lab/LoadingButton';
import {ButtonPropsColorOverrides} from '@mui/material/Button/Button';
import {styled, Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import {OverridableStringUnion} from '@mui/types';
import React, {PropsWithChildren} from 'react';
import {MandatoryChildren} from '../../utils/utils';

interface FormButtonProps extends MandatoryChildren {
    id?: string;
    name?: string;
    type?: 'submit' | 'reset' | 'button';
    outlined?: boolean;
    disabled?: boolean;
    inProgress?: boolean;
    icon?: React.ReactElement;
    sx?: SxProps<Theme>;
    hasError?: boolean;
    color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
            ButtonPropsColorOverrides>;

    onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;

    onFocus?(event: React.FocusEvent<HTMLButtonElement, Element>): void;

    onBlur?(event: React.FocusEvent<HTMLButtonElement, Element>): void;
}

export const AppFormButton = React.memo(AppFormButtonInternal);

const StyledLoadingButton = styled(LoadingButton)({
    boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
});

function AppFormButtonInternal(props: PropsWithChildren<FormButtonProps>) {
    const disabled = props.disabled ?? false;
    const inProgress = props.inProgress ?? false;

    return (<StyledLoadingButton
            id={props.id}
            type={props.type ?? 'submit'}
            name={props.name}
            variant={props.outlined === true ? 'outlined' : 'contained'}
            color={props.color}
            disabled={disabled || inProgress}
            onClick={(event) => props.onClick?.(event)}
            loading={inProgress}
            {...(props.icon && {loadingPosition: 'start'})}
            startIcon={props.icon}
            sx={{
                ...props.sx,
                boxShadow: disabled ? 'none' : undefined,
            }}
            aria-invalid={props.hasError}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
    >
        <span style={{display: 'flex', alignItems: 'center'}}>{props.children}</span>
    </StyledLoadingButton>);
}
