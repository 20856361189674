import {Grid, InputLabel} from '@mui/material';
import React, {PropsWithChildren} from 'react';

interface LabelAndValueProps {
    label: React.ReactNode;
    title?: string;
    value: React.ReactNode;
    xsLabel?: number;
}

export function LabelAndValue({label, value, xsLabel, title}: PropsWithChildren<LabelAndValueProps>) {
    const defaultLabelWidth = 8;

    return (<Grid container>
        <Grid item xs={xsLabel ?? defaultLabelWidth} alignContent={'end'}>
            <InputLabel sx={{textAlign: 'right', paddingRight: '8px',}} title={title}>{label}:</InputLabel>
        </Grid>
        <Grid item xs={12 - (xsLabel ?? defaultLabelWidth)} sx={{fontSize: '1rem'}}>{value}</Grid>
    </Grid>);
}
