import React, {useEffect} from 'react';
import {UserForm} from './UserForm';
import {useGetUserByUserName} from './service';
import {UserPageLabel} from './UserPageLabel';
import {Link, useParams} from 'react-router-dom';
import {useOriginatorContext} from '../../utils/OriginatorProvider';
import {ApplicationTypeDto} from '../../api';
import {usePageLoaderContext} from '../../components/PageLoader';
import {ErrorPanel} from '../../components/ErrorPanel';
import {ROUTES} from '../../router';
import {useTranslation} from 'react-i18next';
import {AppContent} from '../../components/AppContent';

export default function UserDetailsPage() {
    const {t} = useTranslation();
    const {setContextApplicationType} = useOriginatorContext();
    setContextApplicationType(ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL);
    const {username: usernameParam} = useParams();
    const {user, isError, isNotFoundError, isFetching} = useGetUserByUserName(validateUsername(usernameParam));
    const {setIsLoading} = usePageLoaderContext();

    useEffect(() => {
        if (isError) {
            setIsLoading(false);
        } else {
            setIsLoading(isFetching);
        }
    }, [setIsLoading, isFetching, isError]);

    const isOtherError = isError && !isNotFoundError;

    return (<AppContent label={user === undefined ? '' : <UserPageLabel user={user}/>}>
        {isNotFoundError &&
                <ErrorPanel title={t('user.management.error.notFound', {username: usernameParam})}
                            message={<Link to={ROUTES.users.list}>{t('user.management.error.goToUserList')}</Link>}/>}
        {isOtherError &&
                <ErrorPanel title={t('application.error.otherError')}
                            message={<Link to={ROUTES.users.list}>{t('user.management.error.goToUserList')}</Link>}/>}
        {user && !isError &&
                <UserForm user={user}/>
        }
    </AppContent>);
}

export function validateUsername(username: string | undefined) {
    if (username === undefined) {
        throw new Error('Username number must be defined!');
    }
    return username;
}
