import {ErrorResponse} from '@remix-run/router/utils';
import React, {FC} from 'react';
import {useRouteError} from 'react-router-dom';
import {AppContent} from '../../components/AppContent';
import {Link, Box, Typography} from '@mui/material';
import {ErrorPanel} from '../../components/ErrorPanel';
import {useTranslation} from 'react-i18next';
import {FallbackProps} from 'react-error-boundary';
import WarningIcon from '@mui/icons-material/Warning';

export default function ErrorPage() {
    const error = useRouteError() as ErrorResponse;
    console.error(error);

    return (
            <div id="error-page">
                <h1>Oops!</h1>
                <p>Sorry, an unexpected error has occurred.</p>
                <p>
                    <i>{error.statusText}</i>
                </p>
            </div>
    );
}

export function NoPermissionErrorPage() {
    const {t} = useTranslation();
    return (
            <Box sx={{paddingTop: '4rem'}}>
                <AppContent label={''}>
                    <ErrorPanel title={t('common.error.noPermission.title')}
                                message={<Link href="/">{t('common.error.noPermission.goToStartPage')}</Link>}/>
                </AppContent>
            </Box>
    );
}


export const ErrorBoundaryPage: FC<FallbackProps> = ({error}) => {
    console.error(error);

    return (
            <Box
                    id="error-page"
                    sx={theme => ({
                        minHeight: '100vh',
                        backgroundColor: 'rgba(248, 248, 248, 0.5)',
                        color: '#565656'
                    })}
                    width="full"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
            >
                <WarningIcon
                        sx={theme => ({
                            width: '8rem',
                            height: '8rem',
                            color: 'rgba(237, 108, 2, 0.6)',
                        })}/>
                <Box sx={{marginLeft: '0.5rem'}}>
                    <Typography variant="h1" sx={{fontSize: '2rem', fontWeight: 'bolder'}}>Oops!</Typography>
                    <Typography variant="h2" sx={{fontSize: '1rem'}}>
                        Sorry, an unexpected error has occurred.
                    </Typography>
                </Box>
            </Box>
    );
};
