import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {MainProjektWeltApplication} from './model';
import {AddressDefinition, ApplicationAddressProblemSection} from '../../ApplicationAddressProblemSection';

interface MainProjektWeltApplicationAddressProblemSectionProps {
    application: MainProjektWeltApplication;
}

export const MainProjektWeltApplicationAddressProblemSection: FC<MainProjektWeltApplicationAddressProblemSectionProps> = ({application}) => {
    const {t} = useTranslation();

    const addressDefinitions: AddressDefinition<MainProjektWeltApplication>[] = useMemo(() => {
        return [
            {
                path: 'cardHolder.address',
                label: t('application.cardHolder.address'),
                showAdditionalLine: true,
            },
        ];
    }, [t]);

    return (<ApplicationAddressProblemSection<MainProjektWeltApplication> application={application}
                                                                          addressDefinitions={addressDefinitions}/>);
};
