import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    AdditionalProfiCardApplication,
    createEmptyAdditionalProfiCardChApplication
} from '../../domain/application/profiCard/additional/model';
import {
    AdditionalProfiCardApplicationForm
} from '../../domain/application/profiCard/additional/AdditionalProfiCardApplicationForm';

export default function NewAdditionalProfiCardChApplicationPage() {
    return (<NewApplicationPage<AdditionalProfiCardApplication>
            renderApplicationForm={AdditionalProfiCardApplicationForm}
            createEmptyApplication={createEmptyAdditionalProfiCardChApplication}/>);
}

