import {
    ApplicationTypeDto,
    CreateMainProfiCardLuApplicationRequest,
    FormAction,
    MainProfiCardLuApplicationDto,
    UpdateMainProfiCardLuApplicationRequest
} from '../../../../../api';
import {MainProfiCardApplicationWithCrefoDetailsId} from '../../model';
import {mapDtoToBaseMainProfiCardModel, mapModelToCreateBaseMainProfiCardApplicationRequest} from '../mapper';
import {MainProfiCardLuApplication} from './model';

export function mapModelToCreateMainProfiCardLuApplicationRequest(action: FormAction, data: MainProfiCardApplicationWithCrefoDetailsId<MainProfiCardLuApplication>): CreateMainProfiCardLuApplicationRequest {
    const baseMainProfiCardRequest = mapModelToCreateBaseMainProfiCardApplicationRequest(action, data);
    return {
        ...baseMainProfiCardRequest,
        applicationType: ApplicationTypeDto.PROFI_CARD_LU_MAIN,
    };
}

export function mapModelToUpdateMainProfiCardLuApplicationRequest(action: FormAction, data: MainProfiCardLuApplication): UpdateMainProfiCardLuApplicationRequest {
    return {
        ...mapModelToCreateMainProfiCardLuApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version,
        applicationType: ApplicationTypeDto.PROFI_CARD_LU_MAIN,
    };
}

export async function mapDtoToMainProfiCardLuModel(data: MainProfiCardLuApplicationDto): Promise<MainProfiCardLuApplication> {
    const baseMainProfiCardApplication = await mapDtoToBaseMainProfiCardModel(data);
    const application: MainProfiCardLuApplication = {
        ...baseMainProfiCardApplication,
    };
    return Promise.resolve(application);
}
