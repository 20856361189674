export type CountryKeyDE = 'DE';
export type CountryKeyCH = 'CH';
export type CountryKeyLU = 'LU';
export type CountryKeyFR = 'FR';
export type CountryKeyBE = 'BE';
export type CountryKeyAT = 'AT';

export type AllSupportedCountriesKey =
    CountryKeyDE
    | CountryKeyCH
    | CountryKeyLU
    | CountryKeyFR
    | CountryKeyBE
    | CountryKeyAT;

export type CountryIsoCodeDE = '276';
export type CountryIsoCodeCH = '756';
export type CountryIsoCodeLU = '442';
export type CountryIsoCodeFR = '250';
export type CountryIsoCodeBE = '56';
export type CountryIsoCodeAT = '40';

export type AllSupportedCountriesIsoCode =
    CountryIsoCodeDE
    | CountryIsoCodeCH
    | CountryIsoCodeLU
    | CountryIsoCodeFR
    | CountryIsoCodeBE
    | CountryIsoCodeAT;

export const CountryUtils = {
    DE: {
        code: '276' as CountryIsoCodeDE,
        key: 'DE' as CountryKeyDE,
    },
    CH: {
        code: '756' as CountryIsoCodeCH,
        key: 'CH' as CountryKeyCH,
    },
    LU: {
        code: '442' as CountryIsoCodeLU,
        key: 'LU' as CountryKeyLU,
    },
    FR: {
        code: '250' as CountryIsoCodeFR,
        key: 'FR' as CountryKeyFR,
    },
    BE: {
        code: '56' as CountryIsoCodeBE,
        key: 'BE' as CountryKeyBE,
    },
    AT: {
        code: '40' as CountryIsoCodeAT,
        key: 'AT' as CountryKeyAT,
    },
};
