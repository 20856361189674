import {MoneyDto} from '../../api';
import {mapEnum} from '../../utils/utils';
import {Money} from './model';

export function mapToMoneyModel(money: MoneyDto | undefined | null): Money {
    return {
        amount: money?.amount ?? null,
        currency: mapEnum(money?.currency),
    };
}

export function mapToMoneyDto(money: Money | undefined | null): MoneyDto {
    return {
        amount: money?.amount ?? undefined,
        currency: mapEnum(money?.currency),
    };
}
