import {Divider, Grid, Typography} from '@mui/material';
import React, {PropsWithChildren} from 'react';
import {FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {getObjectProperty} from '../../utils/  getObjectProperty';
import {MandatoryChildren} from '../../utils/utils';
import {AppCheckboxField} from './AppCheckboxField';

interface FormSectionProps<TFieldValues extends FieldValues = FieldValues> extends MandatoryChildren {
    title: string;
    optional?: {
        optionalCheckboxLabel: string;
        optionalFieldPath: FieldPath<TFieldValues>;
        readOnly?: boolean;
        keepVisible?: boolean;
    }
}

export function FormSection<TFieldValues extends FieldValues = FieldValues>({
                                                                                title,
                                                                                optional,
                                                                                children
                                                                            }: PropsWithChildren<FormSectionProps<TFieldValues>>) {
    const {getValues} = useFormContext();
    const values = getValues();

    const checkboxFieldState = optional?.keepVisible ?? (optional?.optionalFieldPath ? getObjectProperty(values, optional.optionalFieldPath) : true);

    return <>
        <Grid container spacing={2}>
            <Grid item>
                <Typography variant="h5">{title}</Typography>
                {optional &&
                    <AppCheckboxField fieldPath={optional.optionalFieldPath}
                                      label={optional.optionalCheckboxLabel}
                                      readOnly={optional.readOnly}
                                      sx={{}}/>}
            </Grid>
        </Grid>
        <Divider sx={theme => !checkboxFieldState ? {marginBottom: theme.spacing(5)} : {}}/>
        {checkboxFieldState && <Grid container spacing={2} sx={theme => ({marginBottom: theme.spacing(5)})}>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
        }
    </>;
}
