import {RefinementCtx, SafeParseError, SafeParseSuccess} from 'zod';

export type NoUndefined<T> = T extends undefined ? never : T;

export function addValidationErrorToPath<T>(path: string | string[], validationResult: SafeParseSuccess<T> | SafeParseError<T>, ctx: RefinementCtx) {
    if (!validationResult.success) {
        validationResult.error.issues.forEach((error => {
            ctx.addIssue({
                ...error,
                path: typeof path === 'string' ? [path, ...error.path] : [...path, ...error.path],
            });
        }));
    }
}
