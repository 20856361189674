import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppAmountField, AppAmountFieldProps} from '../../../../components/form/AppAmountField';
import {AppCheckboxField} from '../../../../components/form/AppCheckboxField';
import {AppTextField} from '../../../../components/form/AppTextField';
import {DateOfBirthPickerField} from '../../../../components/form/DateOfBirthPickerField';
import {FormRow} from '../../../../components/form/FormRow';
import {FormSection} from '../../../../components/form/FormSection';
import {trimAndCapitalizeFirstLetter} from '../../../../utils/utils';
import {MasterMarketSelectField} from '../../../reference/MasterMarketSelectedField';
import {PersonTitleSelectField} from '../../../reference/PersonTitleSelectField';
import {SalutationSelectField} from '../../../reference/SalutationSelectField';
import {
    AdditionalProfiCardApplication,
    additionalProfiCardApplicationSchema,
    AdditionalProfiCardLegitimizationForm,
    additionalProfiCardLegitimizationFormSchema,
} from './model';
import {ApplicationForm, ReadOnlyProps} from '../../ApplicationForm';
import {ApplicationFormProps} from '../../../../pages/application/NewApplicationPage';
import {useSnackbar} from '../../../../components/SnackbarHelper';
import {saveApplication} from '../../service';
import {ApplicationTypeDto, Currency} from '../../../../api';
import AppConfig from '../../../../config';
import {BaseAdditionalProfiCardApplicationUnion} from '../model';
import {MainCardSearchSection} from '../../mainCard/MainCardSearchSection';
import {CardContent, Grid} from '@mui/material';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {AppCard} from '../../../../components/SectionCard';
import {useFormContext} from 'react-hook-form';
import PrintMoney from '../../../common/components/PrintMoney';

interface AdditionalProfiCardApplicationFormProps extends ApplicationFormProps<BaseAdditionalProfiCardApplicationUnion> {
}

export function AdditionalProfiCardApplicationForm(props: AdditionalProfiCardApplicationFormProps) {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const application = props.application;

    if (!application.mainCard?.mainCardNumber) {
        return (<MainCardSearchSection<AdditionalProfiCardApplication> onMainCardDataRetrieved={mainCard => {
            return saveApplication({...application, mainCard})
                    .then(application => {
                        showSnackbar({
                            message: t('application.save.draft.success'),
                            severity: 'success',
                        });
                        return application;
                    })
                    .then(props.afterDraftSaved);
        }}/>);
    }

    return (<ApplicationForm<AdditionalProfiCardApplication>
            application={application}
            applicationSchema={additionalProfiCardApplicationSchema}
            afterDraftSaved={props.afterDraftSaved}
            afterLegitimized={props.afterLegitimized}
            renderFields={isReadOnly => <AdditionalProfiCardApplicationFields isReadOnly={isReadOnly}
                                                                              applicationType={application.applicationType}/>}
            showLegitimizeDialog={props.showLegitimizeDialog}
            legitimizationFormSchema={getAdditionalProfiCardLegitimizationSchema}
            renderLegitimizationCheckList={LegitimizeCheckList}
    />);
}

function getAdditionalProfiCardLegitimizationSchema() {
    return additionalProfiCardLegitimizationFormSchema;
}

interface AdditionalProfiCardApplicationFieldsProps {
    isReadOnly: boolean;
    applicationType: ApplicationTypeDto;
}

const AdditionalProfiCardApplicationFields: FC<AdditionalProfiCardApplicationFieldsProps> = ({
                                                                                                 isReadOnly,
                                                                                                 applicationType
                                                                                             }) => {
    const defaultCurrency = applicationType === ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL ? Currency.CHF : undefined;
    return (<>
        <MainCardFormSection/>
        <AdditionalCardholderFormSection readOnly={isReadOnly}/>
        <PinForWebshopFormSection readOnly={isReadOnly} applicationType={applicationType}/>
        <DesiredLimitFormSection readOnly={isReadOnly} defaultCurrency={defaultCurrency}/>
        <LegimitationsFormSection readOnly={isReadOnly}/>
    </>);
};

function LegitimizeCheckList() {
    const {t} = useTranslation();

    return (
            <AppCheckboxField<AdditionalProfiCardLegitimizationForm> fieldPath={'nameSignature'}
                                                                     label={t('application.dialog.legitimization.mainCardHolderSignature')}/>);
}

type DesiredLimitFormSectionProps = ReadOnlyProps & Pick<AppAmountFieldProps, 'defaultCurrency'>;

const DesiredLimitFormSection: FC<DesiredLimitFormSectionProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.desiredLimit.sectionTitle')}>
        <FormRow>
            <AppAmountField<AdditionalProfiCardApplication> breakpoint={3}
                                                            required={true}
                                                            label={t('application.form.desiredLimit.label')}
                                                            fieldPath={'desiredLimit'}
                                                            helperText={t('application.form.desiredLimit.helperText')}
                                                            readOnly={props.readOnly}
                                                            defaultCurrency={props.defaultCurrency}
            />
        </FormRow>
    </FormSection>);
};

type PinForWebshopFormSectionProps = ReadOnlyProps & { applicationType: ApplicationTypeDto };
const PinForWebshopFormSection: FC<PinForWebshopFormSectionProps> = (props) => {
    const {t} = useTranslation();
    let isPinForWebShopVisible = true;
    if (props.applicationType === ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL) {
        isPinForWebShopVisible = AppConfig.pinForWebShopEnabledForLU;
    }
    if (props.applicationType === ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL) {
        isPinForWebShopVisible = AppConfig.pinForWebShopEnabledForCH;
    }
    if (!isPinForWebShopVisible) {
        return null;
    }
    return (<FormSection title={t('application.form.marketData.pinForWebshopTitle')}>
        <FormRow>
            <AppCheckboxField<AdditionalProfiCardApplication> breakpoint={3}
                                                              readOnly={props.readOnly}
                                                              fieldPath={'pinForWebshop'}
                                                              label={t('application.form.marketData.pinForWebshop')}/>
        </FormRow>
    </FormSection>);
};

const LegimitationsFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.legimitations.sectionTitle')}>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          label={t('common.form.person.employeeName')}
                                                          fieldPath={'legitimation.employeeName'}
                                                          transformValue={trimAndCapitalizeFirstLetter}
                                                          readOnly={props.readOnly}/>

            <MasterMarketSelectField<AdditionalProfiCardApplication> fieldPath={'legitimation.marketStore'}
                                                                     required={true}
                                                                     readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          required={true}
                                                          label={t('application.form.legitimation.name1Signature')}
                                                          fieldPath={'legitimation.name1Signature'}
                                                          transformValue={trimAndCapitalizeFirstLetter}
                                                          readOnly={props.readOnly}/>
        </FormRow>
    </FormSection>);
};

function MainCardFormSection() {
    const {watch} = useFormContext<AdditionalProfiCardApplication>();
    const mainCard = watch('mainCard');
    const {mainCardNumber, expiryDate, companyName, cardLimit} = mainCard ?? {};

    const {t} = useTranslation();

    return (<AppCard>
        <CardContent>
            <Grid container>
                <Grid item xs={12} md={5}>
                    <LabelAndValue label={t('application.form.additionalCard.mainCard.search.cardNumber')}
                                   title={t('application.form.additionalCard.mainCard.search.cardNumber')}
                                   value={<strong>{mainCardNumber}</strong>}/>
                    <LabelAndValue label={t('application.form.additionalCard.mainCard.search.expirationDate')}
                                   title={t('application.form.additionalCard.mainCard.search.expirationDate')}
                                   value={`${expiryDate?.month}/${expiryDate?.year}`}/>
                    <LabelAndValue label={<strong>{t('application.form.companyData.companyName')}</strong>}
                                   title={t('application.form.companyData.companyName')}
                                   value={companyName}/>
                    <LabelAndValue label={t('application.form.additionalCard.mainCard.accountLimit.label')}
                                   title={t('application.form.additionalCard.mainCard.accountLimit.label')}
                                   value={cardLimit?.amount && cardLimit?.currency && <PrintMoney
                                           money={{amount: cardLimit.amount, currency: cardLimit.currency}}/>}/>
                </Grid>
            </Grid>
        </CardContent>
    </AppCard>);
}

const AdditionalCardholderFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.cardHolder.additional.sectionTitle')}>
        <FormRow>
            <SalutationSelectField<AdditionalProfiCardApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.salutation'}
                    required={true}
                    readOnly={props.readOnly}/>
            <PersonTitleSelectField<AdditionalProfiCardApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.title'}
                    readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          required={true}
                                                          label={t('common.form.person.firstName')}
                                                          fieldPath={'cardHolder.firstName'}
                                                          transformValue={trimAndCapitalizeFirstLetter}
                                                          readOnly={props.readOnly}/>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          required={true}
                                                          label={t('common.form.person.lastName')}
                                                          fieldPath={'cardHolder.lastName'}
                                                          transformValue={trimAndCapitalizeFirstLetter}
                                                          readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<AdditionalProfiCardApplication> breakpoint={3}
                                                                    readOnly={props.readOnly}
                                                                    fieldPath={'cardHolder.dateOfBirth'}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={6}
                                                          label={t('common.form.contact.email')}
                                                          fieldPath={'cardHolder.email'}
                                                          readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProfiCardApplication> breakpoint={3}
                                                          label={t('common.form.contact.cellPhoneNumber')}
                                                          fieldPath={'cardHolder.cellPhoneNumber'}
                                                          readOnly={props.readOnly}/>
        </FormRow>
    </FormSection>);
};
