import {Box, Grid, Typography} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {ApplicationStatusLabel} from '../../domain/application/ApplicationStatusLabel';
import {BaseApplication} from '../../domain/application/model';
import {getProductName} from './ApplicationsListPage';

interface ApplicationPageLabelProps {
    application?: BaseApplication;
}

export const ApplicationPageLabel: FC<ApplicationPageLabelProps> = ({application}) => {
    const {t} = useTranslation();
    const applicationNumber = application?.applicationNumber;
    const isPersistent = applicationNumber !== undefined;
    const productType = application?.applicationType && getProductName(application.applicationType);

    return (<Grid container sx={theme => ({marginBottom: theme.spacing(1)})}>
        <Grid item xs={6}>
            <Grid item xs={12}>
                <Box
                        display="flex"
                        sx={(theme) => ({
                            alignItems: 'baseline',
                            columnGap: theme.spacing(3),
                            rowGap: theme.spacing(1),
                            fontFamily: 'monospace',
                            flexWrap: 'wrap',
                        })}
                >
                    <Typography variant="h4">
                        {isPersistent ? t('application.form.header.edit', {applicationNumber}) : t('application.form.header.new')}
                    </Typography>
                    {productType && (
                            <Typography variant="h6" fontSize="1,25rem">
                                {t('application.list.header.product')}: {productType}
                            </Typography>
                    )}
                </Box>
            </Grid>
        </Grid>
        <Grid item>
            <ApplicationStatusLabel status={application?.status}/>
        </Grid>
    </Grid>);
};
