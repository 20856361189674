import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/fr-ch';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {availableLanguages, getI18nLanguage} from '../i18n';

moment.locale('en-gb');

export function LanguageChooser() {
    const { i18n} = useTranslation();

    const onLanguageChanged = (e: SelectChangeEvent) => {
        moment.locale(e.target.value === 'de' ? 'de' : 'en-gb');
        return i18n.changeLanguage(e.target.value);
    };
    return (
        <Select id="language-chooser"
                sx={{background: 'silver'}}
                label=""
                value={getI18nLanguage()}
                onChange={onLanguageChanged}>
            {availableLanguages.map((language) => (<MenuItem key={language} value={language}>{language}</MenuItem>))}
        </Select>
    );

}
