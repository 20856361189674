import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {getOptionEntryId, getOptionEntryLabel, OptionEntry} from './model';
import {ReferenceSelectFieldProps} from './ReferenceSelectFieldProps';
import {useReferences} from './service';
import {Originator} from '../../utils/OriginatorProvider';

export function NationalitySelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const {citizenship, isLoading} = useReferences((Originator.current!!));

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                             options={citizenship}
                                                             isLoading={isLoading}
                                                             getLabel={getOptionEntryLabel}
                                                             getId={getOptionEntryId}
                                                             fieldPath={props.fieldPath}
                                                             disabled={props.disabled}
                                                             label={t('common.form.person.nationality')}
                                                             required={props.required}
                                                             readOnly={props.readOnly}
                                                             size={props.size}
    />);

}
