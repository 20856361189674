import {z} from 'zod';
import {ApplicationTypeDto, Currency} from '../../../../api';
import AppConfig from '../../../../config';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {moneySchema} from '../../../money/model';
import {optionalOptionEntry} from '../../../reference/model';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {User} from '../../../user/model';
import {mainCardSchema} from '../../mainCard/model';
import {ApplicationStatus, BaseApplication} from '../../model';
import {baseProfiCardApplicationSchema} from '../model';
import {optionalEmailSchema} from '../../../common/model';
import {getDateSchema, maxToday} from '../../../../utils/utils';

const legitimationSchema = z.object({
    employeeName: z.string().optional(),
    marketStore: optionalOptionEntry,
    name1Signature: z.string().optional(),
});
type Legitimation = z.infer<typeof legitimationSchema>;

const ownerOfAdditionalCardSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getDateSchema(false, zodDate => zodDate.max(maxToday())),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
});

export type OwnerOfAdditionalCard = z.infer<typeof ownerOfAdditionalCardSchema>;
export const additionalProfiCardApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL)
        .or(z.literal(ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL))
        .or(z.literal(ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL)),
    version: z.number(),
    accountLimit: z.optional(moneySchema),
    mainCard: z.optional(mainCardSchema),
    cardHolder: z.optional(ownerOfAdditionalCardSchema),
    pinForWebshop: z.boolean(),
    legitimation: z.optional(legitimationSchema),
})
    .merge(baseProfiCardApplicationSchema);

export type AdditionalProfiCardApplication = z.infer<typeof additionalProfiCardApplicationSchema>;

export function isAdditionalProfiCardApplication(application: BaseApplication | undefined): application is AdditionalProfiCardApplication {
    if (application === undefined) {
        console.log('Application is undefined');
        return false;
    }
    const additionalCardTypes = [ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL, ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL,
        ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL];
    return additionalCardTypes.includes(application.applicationType);
}

export const additionalProfiCardLegitimizationFormSchema = z.object({
    nameSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type AdditionalProfiCardLegitimizationForm = z.infer<typeof additionalProfiCardLegitimizationFormSchema>;

export type AdditionalProfiCardApplicationTypeUnion =
    ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL
    | ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL
    | ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL;

export async function createEmptyAdditionalProfiCardDeApplication(user: User): Promise<AdditionalProfiCardApplication> {
    return createEmptyAdditionalProfiCardApplication(user, ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL);
}

export async function createEmptyAdditionalProfiCardLuApplication(user: User): Promise<AdditionalProfiCardApplication> {
    return createEmptyAdditionalProfiCardApplication(user, ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL);
}

export async function createEmptyAdditionalProfiCardChApplication(user: User): Promise<AdditionalProfiCardApplication> {
    return {
        ...(await createEmptyAdditionalProfiCardApplication(user, ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL)),
        desiredLimit: {
            amount: 2000,
            currency: Currency.CHF,
        },
    };
}

async function createEmptyAdditionalProfiCardApplication(user: User, applicationType: AdditionalProfiCardApplicationTypeUnion): Promise<AdditionalProfiCardApplication> {
    const originator = mapApplicationTypeToOriginator(applicationType);
    const references = await getReferences(originator);
    let pinForWebShop = true;
    if (applicationType === ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL) {
        pinForWebShop = AppConfig.pinForWebShopEnabledForLU;
    }
    if (applicationType === ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL) {
        pinForWebShop = AppConfig.pinForWebShopEnabledForCH;
    }
    return {
        applicationType: applicationType,
        version: 0,
        status: ApplicationStatus.CREATED,
        cardHolder: createEmptyAdditionalCardOwnerModel(),
        pinForWebshop: pinForWebShop,
        desiredLimit: {
            amount: 2000,
            currency: Currency.EUR,
        },
        legitimation: {
            employeeName: '',
            marketStore: findOptionEntryByKey(references.getShops(applicationType), user.store?.key),
            name1Signature: '',
        }
    };
}

function createEmptyAdditionalCardOwnerModel(): OwnerOfAdditionalCard {
    return {
        salutation: null,
        title: null,
        firstName: '',
        lastName: '',
        dateOfBirth: undefined,
        email: '',
        cellPhoneNumber: '',
    };
}
