import {useKeycloak} from '@react-keycloak/web';
import React, {FC, useEffect, useMemo, useState} from 'react';
import {OpenAPI} from '../../api';
import {usePageLoaderContext} from '../../components/PageLoader';
import {MandatoryChildren} from '../../utils/utils';
import {User} from './model';
import {getUserInfo} from './service';
import i18n, {availableLanguages} from '../../i18n';

export type UserContextType = {
    user: User;
};

const defaultUser: User = {
    uid: undefined,
    userName: '',
    emailAddress: '',
    firstName: '',
    lastName: '',
    role: undefined,
    store: undefined,
    hornbachUserCountry: undefined,
    countryDe: undefined,
    countryLu: undefined,
    countryCh: undefined,
    preferredLanguage: undefined,
    userAdminRights: undefined,
    isActive: undefined,
};

export const UserContext = React.createContext<UserContextType>({} as UserContextType);

export const UserContextProvider: FC<MandatoryChildren> = ({children}) => {
    const {setIsLoading} = usePageLoaderContext();
    const {keycloak, initialized} = useKeycloak();
    const [user, setUser] = useState<User>(defaultUser);
    const [isUserLoaded, setIsUserLoaded] = useState(false);

    const token = keycloak.token;
    const userContextValue = useMemo(() => ({user}), [user]);

    useEffect(() => {
        if (initialized && token) {
            const abortController = new AbortController();
            OpenAPI.TOKEN = () => Promise.resolve(token);
            getUserInfo(abortController.signal)
                    .then(user => {
                        setUser(user);
                        // set application language to user's preference or local browser if supported otherwise English
                        const desiredLang = user.preferredLanguage?.toLowerCase() ?? localStorage.getItem('i18nextLng') ?? 'en-GB';
                        const parsedLang = desiredLang.slice(0, 2);
                        const selectedLang = availableLanguages.includes(parsedLang) ? parsedLang : 'en';
                        i18n.changeLanguage(selectedLang);
                        setIsUserLoaded(true);
                    }).catch(e => {
                        if (e.name !== 'CancelError') {
                            setIsUserLoaded(true);
                        }
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            return () => abortController.abort();
        } else {
            setIsLoading(true);
        }
    }, [initialized, token, setIsLoading, setIsUserLoaded]);

    if (!isUserLoaded) {
        return null;
    }

    return (
            <UserContext.Provider value={userContextValue}>
                {children}
            </UserContext.Provider>
    );
};

export function useUserContext(): UserContextType {
    return React.useContext(UserContext);
}
