import {unstable_usePrompt, useBeforeUnload} from 'react-router-dom';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

export function useBlockNavigation(shouldBlock: boolean) {
    const {t} = useTranslation();

    useBeforeUnload(
        useCallback((e: BeforeUnloadEvent) => {
            if (shouldBlock) {
                e.preventDefault();
                e.returnValue = '';
                return e;
            }
            return true;
        }, [shouldBlock])
    );

    unstable_usePrompt({
        when: shouldBlock,
        message: t('application.form.blockNavigation'),
    });
}
