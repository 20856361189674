import {z} from 'zod';
import {optionalOptionEntry} from '../../reference/model';

export const marketDataSchema = z.object({
    pinForWebShop: z.boolean(),
    masterStore: optionalOptionEntry,
});
export type MarketData = z.infer<typeof marketDataSchema>;
const consentsSchema = z.object({
    consent: z.boolean(),
});
export type Consents = z.infer<typeof consentsSchema>;
