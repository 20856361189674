import React, {FC} from 'react';
import {
    MainProfiCardApplicationOverviewSection
} from '../../domain/application/profiCard/main/MainProfiCardApplicationOverviewSection';
import {CrefoLatestInteractionSection} from '../../domain/application/profiCard/main/CrefoLatestInteractionSection';

import {BaseMainProfiCardApplicationUnion} from '../../domain/application/profiCard/model';
import AutoAssignLimitSection from '../../domain/application/AutoAssignLimitSection';
import {ApplicationStatus} from '../../domain/application/model';
import {ApplicationProcessingErrorReasonDto} from '../../api';

interface ApplicationCrefoTabProps {
    application: BaseMainProfiCardApplicationUnion;
}

export const ApplicationCrefoTab: FC<ApplicationCrefoTabProps> = ({application}) => {
    const isReadOnlyMode = [ApplicationStatus.READY, ApplicationStatus.SUCCESSFUL_COMPLETED, ApplicationStatus.WITHDRAWN, ApplicationStatus.REJECTED].includes(application.status);

    return (<>
        <MainProfiCardApplicationOverviewSection application={application}/>
        <CrefoLatestInteractionSection application={application}
                                       additionalRow={(application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION
                                                       || application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION_SEARCH_FAILED) && !isReadOnlyMode &&
                                               <AutoAssignLimitSection application={application}/>}/>
    </>);
};
