import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {usePageLoaderContext} from './PageLoader';
import {useSnackbar} from './SnackbarHelper';
import {fetchBlob} from '../utils/downloadFile';
import {useProcessModificationResponseError} from '../domain/application/processResponseError';

export function usePrintPdfHandler(url: string) {
    const {t} = useTranslation();
    const {setIsLoading} = usePageLoaderContext();
    const [printingInProgress, setPrintingInProgress] = useState(false);
    const showSnackbar = useSnackbar();
    const processModificationResponseError = useProcessModificationResponseError();

    const handlePrint = (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event?.preventDefault();
        setIsLoading(true);
        setPrintingInProgress(true);

        fetchBlob(url)
                .then((blobInput: Blob) => {
                    const blob = new Blob([blobInput], {type: 'application/pdf'});
                    const objectUrl = URL.createObjectURL(blob);
                    if (objectUrl !== undefined) {
                        window.open(objectUrl, '_blank');
                        setTimeout(() => {
                            URL.revokeObjectURL(objectUrl);
                        }, 60000);
                    } else {
                        throw new Error('Empty objectUrl');
                    }
                })
                .catch((reason) => {
                    processModificationResponseError(reason, '');
                    showSnackbar({
                        severity: 'error',
                        message: t('common.printing.errorText'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setPrintingInProgress(false);
                });
    };

    return {
        handlePrint,
        printingInProgress,
    };
}
