import {z} from 'zod';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from '../../../../components/SnackbarHelper';
import React, {FC, useEffect, useState} from 'react';
import {CrefoDetailsDto, CrefoInteractionDto, CrefoInteractionStateDto} from '../../../../api';
import {useAppForm} from '../../../../components/form/useAppForm';
import {saveApplication, smartSignUp} from '../../service';
import {FormProvider, useFormContext} from 'react-hook-form';
import {FormSection} from '../../../../components/form/FormSection';
import {Alert, Box, Button, Stack, TableCell, TableRow} from '@mui/material';
import {AppTextField} from '../../../../components/form/AppTextField';
import {AppFormButton} from '../../../../components/form/AppFormButton';
import {StickyHeaderTable} from '../../../../components/StickyHeaderTable';
import {useUserContext} from '../../../user/UserProvider';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../../../router';
import {usePageLoaderContext} from '../../../../components/PageLoader';
import {scrollToTop} from '../../../../utils/utils';
import {User} from '../../../user/model';
import {BaseMainProfiCardApplicationUnion, MainProfiCardApplicationWithCrefoDetailsId} from '../model';

const smartSignUpFormSchema = z.object({
    searchTerm: z.string().min(3),
});
type SmartSignUpForm = z.infer<typeof smartSignUpFormSchema>;

interface SmartSignUpProps {
    onReturnToRegularForm(searchTerm: string): void;

    createEmptyMainProfiCardApplication(user: User): Promise<BaseMainProfiCardApplicationUnion>;
}

export const SmartSignUp: FC<SmartSignUpProps> = (props) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const {setIsLoading} = usePageLoaderContext();
    const [crefoInteraction, setCrefoInteraction] = useState<CrefoInteractionDto>();

    const formData = useAppForm<SmartSignUpForm>(smartSignUpFormSchema, {searchTerm: ''});
    const handleSubmit = formData.handleSubmit;
    const setFocus = formData.setFocus;

    useEffect(() => {
        setFocus('searchTerm');
    }, [setFocus]);

    const processSubmit = ({searchTerm}: SmartSignUpForm) => {
        setIsLoading(true);
        setIsButtonLoading(true);
        formData.wrapPromise(smartSignUp(searchTerm))
                .then((crefoInteraction: CrefoInteractionDto) => {
                    setCrefoInteraction(crefoInteraction);
                    const state = crefoInteraction.state ?? CrefoInteractionStateDto.SEARCH_FAILED;
                    showSnackbar({
                        severity: [CrefoInteractionStateDto.MANY_RESULTS, CrefoInteractionStateDto.ZERO_RESULTS, CrefoInteractionStateDto.ONE_RESULT].includes(state) ? 'success' : 'error',
                        message: t('application.form.smartSignUp.search.result.toast.status.' + state as any),
                    });
                    return crefoInteraction;
                })
                .catch(() => {
                    showSnackbar({
                        severity: 'error',
                        message: t('application.form.smartSignUp.search.result.toast.error'),
                    });
                })
                .finally(() => {
                    setIsLoading(false);
                    setIsButtonLoading(false);
                });
    };

    return (<FormProvider {...formData}>
        <form onSubmit={handleSubmit(processSubmit)}>
            <FormSection title={t('application.form.smartSignUp.sectionTitle')}>
                <Stack spacing={1} direction="row" sx={theme => ({marginTop: theme.spacing(1)})}>
                    <AppTextField<SmartSignUpForm> breakpoint={8}
                                                   required={true}
                                                   size={'small'}
                                                   label={t('application.form.smartSignUp.form.searchTerm')}
                                                   fieldPath={'searchTerm'}
                                                   helperText={t('application.form.smartSignUp.form.nameHelperText')}
                    />
                    <Box sx={{paddingTop: '24px'}}>
                        <AppFormButton color="secondary"
                                       inProgress={isButtonLoading}
                        >{t('application.form.smartSignUp.form.search')}</AppFormButton>
                    </Box>
                </Stack>
            </FormSection>
            <CrefoInteractionPanel crefoInteraction={crefoInteraction}
                                   onReturnToRegularForm={props.onReturnToRegularForm}
                                   createEmptyMainProfiCardApplication={props.createEmptyMainProfiCardApplication}/>
        </form>
    </FormProvider>);
};

interface CrefoInteractionPanelProps {
    crefoInteraction: CrefoInteractionDto | undefined;

    onReturnToRegularForm(searchTerm: string): void;

    createEmptyMainProfiCardApplication(user: User): Promise<BaseMainProfiCardApplicationUnion>;
}

const CrefoInteractionPanel: FC<CrefoInteractionPanelProps> = ({
                                                                   crefoInteraction,
                                                                   onReturnToRegularForm,
                                                                   createEmptyMainProfiCardApplication
                                                               }) => {
    const {t} = useTranslation();
    const {watch} = useFormContext<SmartSignUpForm>();

    if (crefoInteraction === undefined) {
        return null;
    }

    const handleClickOnReturnToRegularForm = () => {
        onReturnToRegularForm(watch('searchTerm'));
    };

    const state = crefoInteraction.state;
    if (state === CrefoInteractionStateDto.ZERO_RESULTS) {
        return <Alert severity={'warning'}>
            {t('application.form.smartSignUp.result.alert.ZERO_RESULTS')}
            <Button color={'warning'}
                    onClick={handleClickOnReturnToRegularForm}>{t('application.form.smartSignUp.form.backToRegularForm')}</Button>
        </Alert>;
    }

    if (state === CrefoInteractionStateDto.ONE_RESULT || state === CrefoInteractionStateDto.MANY_RESULTS) {
        return (<>
            <StickyHeaderTable headerRow={<TableRow>
                <TableCell>{t('application.form.smartSignUp.result.table.companyName')}</TableCell>
                <TableCell>{t('application.form.smartSignUp.result.table.address')}</TableCell>
                <TableCell>{t('application.form.smartSignUp.result.table.city')}</TableCell>
                <TableCell>{t('application.form.smartSignUp.result.table.country')}</TableCell>
                <TableCell></TableCell>
            </TableRow>}>
                {crefoInteraction.details?.map(crefoDetail => {
                    return <TableRow key={crefoDetail.id}>
                        <TableCell>{crefoDetail.crefoName}</TableCell>
                        <TableCell>{crefoDetail.crefoAddress?.street} {crefoDetail.crefoAddress?.houseNumber}</TableCell>
                        <TableCell>{crefoDetail.crefoAddress?.zipCode} {crefoDetail.crefoAddress?.city}</TableCell>
                        <TableCell>{crefoDetail.crefoAddress?.country?.value}</TableCell>
                        <TableCell><SelectCrefoDetailsButton crefoDetail={crefoDetail}
                                                             createEmptyMainProfiCardApplication={createEmptyMainProfiCardApplication}/></TableCell>
                    </TableRow>;
                })}
            </StickyHeaderTable>
            <Alert severity={'warning'} sx={theme => ({
                marginBottom: theme.spacing(3),
                marginTop: theme.spacing(2),
            })}>
                {t('application.form.smartSignUp.result.alert.desiredResultNotFound')}
                <Button color={'warning'}
                        onClick={handleClickOnReturnToRegularForm}>{t('application.form.smartSignUp.form.backToRegularForm')}</Button>
            </Alert>
        </>);
    }
    return null;
};

interface SelectCrefoDetailsButtonProps {
    crefoDetail: CrefoDetailsDto;

    createEmptyMainProfiCardApplication(user: User): Promise<BaseMainProfiCardApplicationUnion>;
}

const SelectCrefoDetailsButton: FC<SelectCrefoDetailsButtonProps> = ({
                                                                         crefoDetail,
                                                                         createEmptyMainProfiCardApplication
                                                                     }) => {
    const {t} = useTranslation();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const {setIsLoading} = usePageLoaderContext();
    const selectCrefoDetails = useSelectCrefoDetails();

    const handleSelectCrefoDetails = () => {
        setIsButtonLoading(true);
        setIsLoading(true);
        selectCrefoDetails(crefoDetail, createEmptyMainProfiCardApplication)
                .finally(() => {
                    setIsButtonLoading(false);
                    setIsLoading(false);
                });
    };

    return (<AppFormButton onClick={handleSelectCrefoDetails}
                           inProgress={isButtonLoading}>
        {t('application.form.smartSignUp.result.table.select')}
    </AppFormButton>);
};

/**
 * The hook return a function that:
 * 1. creates empty MainProfiCardApplication
 * 2. saves the application as draft
 * 3. redirects to edit page of the newly created application
 */
function useSelectCrefoDetails() {
    const {t} = useTranslation();
    const {user} = useUserContext();
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();

    return (selectedCrefoDetail: CrefoDetailsDto, createEmptyMainProfiCardApplication: (user: User) => Promise<BaseMainProfiCardApplicationUnion>) => {
        return createEmptyMainProfiCardApplication(user)
                .then(emptyApplication => {
                    const applicationToCreate: MainProfiCardApplicationWithCrefoDetailsId<BaseMainProfiCardApplicationUnion> = {
                        ...emptyApplication,
                        crefoDetailsId: selectedCrefoDetail.id,
                    };
                    return saveApplication(applicationToCreate)
                            .then(savedApplication => {
                                showSnackbar({
                                    message: t('application.save.draft.success'),
                                    severity: 'success',
                                });
                                if (savedApplication.applicationNumber) {
                                    navigate(ROUTES.applications.edit(savedApplication.applicationNumber), {replace: true});
                                    scrollToTop();
                                } else {
                                    throw new Error('Application number must be defined!');
                                }
                                return savedApplication;
                            });
                });
    };
}
