import {
    AdditionalProfiCardChApplicationDto,
    AdditionalProfiCardDeApplicationDto,
    AdditionalProfiCardLuApplicationDto,
    CreateAdditionalProfiCardChApplicationRequest,
    CreateAdditionalProfiCardDeApplicationRequest,
    CreateAdditionalProfiCardLuApplicationRequest,
    FormAction,
    UpdateAdditionalProfiCardChApplicationRequest,
    UpdateAdditionalProfiCardDeApplicationRequest,
    UpdateAdditionalProfiCardLuApplicationRequest
} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {mapEnum, parseLocalDate} from '../../../../utils/utils';
import {mapToMoneyDto, mapToMoneyModel} from '../../../money/mapper';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {
    mapDtoToAdditionalCardHolderModel,
    mapDtoToAdditionalMainCardModel,
    mapModelToCardHolderDto,
    mapModelToMainCardDto
} from '../../mainCard/mapper';
import {ApplicationStatus} from '../../model';
import {AdditionalProfiCardApplicationTypeUnion, AdditionalProfiCardApplication} from './model';

type CreateAdditionalCardRequest =
    CreateAdditionalProfiCardDeApplicationRequest
    | CreateAdditionalProfiCardLuApplicationRequest
    | CreateAdditionalProfiCardChApplicationRequest;

export function mapModelToCreateAdditionalProfiCardApplicationRequest(action: FormAction, data: AdditionalProfiCardApplication): CreateAdditionalCardRequest {
    return {
        applicationType: data.applicationType as AdditionalProfiCardApplicationTypeUnion,
        action: action,
        mainCard: mapModelToMainCardDto(data.mainCard),
        cardHolder: mapModelToCardHolderDto(data.cardHolder),
        pinForWebshop: data.pinForWebshop,
        desiredLimit: mapToMoneyDto(data.desiredLimit),
        legitimation: {
            employeeName: data.legitimation?.employeeName,
            marketStore: data.legitimation?.marketStore ?? undefined,
            name1Signature: data.legitimation?.name1Signature,
        }
    };
}

type UpdateAdditionalCardRequest =
    UpdateAdditionalProfiCardDeApplicationRequest
    | UpdateAdditionalProfiCardLuApplicationRequest
    | UpdateAdditionalProfiCardChApplicationRequest;

export function mapModelToUpdateAdditionalProfiCardApplicationRequest(action: FormAction, data: AdditionalProfiCardApplication): UpdateAdditionalCardRequest {
    return {
        ...mapModelToCreateAdditionalProfiCardApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version
    };
}

type AdditionalProfiCardApplicationUnion =
    AdditionalProfiCardDeApplicationDto
    | AdditionalProfiCardLuApplicationDto
    | AdditionalProfiCardChApplicationDto;

export async function mapDtoToAdditionalProfiCardModel(data: AdditionalProfiCardApplicationUnion): Promise<AdditionalProfiCardApplication> {
    const applicationType = data.applicationType as AdditionalProfiCardApplicationTypeUnion;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const {getShops} = await getReferences(originator);
    const cardHolder = await mapDtoToAdditionalCardHolderModel(data.cardHolder, originator);
    const mainCard = await mapDtoToAdditionalMainCardModel(data.mainCard, originator);
    const application: AdditionalProfiCardApplication = {
        applicationNumber: data.applicationNumber,
        version: data.version,
        applicationType: applicationType,
        status: mapEnum(data.status) ?? ApplicationStatus.CREATED,
        processingErrorReason: mapEnum(data.processingErrorReason),
        creationDate: data.creationDate,
        accountNumber: data.accountNumber,
        cardNumber: data.cardNumber,

        mainCard: mainCard,
        cardHolder: {...cardHolder, ...{dateOfBirth: parseLocalDate(data.cardHolder?.dateOfBirth) ?? undefined,}},
        pinForWebshop: data.pinForWebshop ?? false,
        desiredLimit: mapToMoneyModel(data.desiredLimit),
        accountLimit: mapToMoneyModel(data.accountLimit),
        legitimation: {
            employeeName: data.legitimation?.employeeName ?? '',
            marketStore: findOptionEntryByKey(getShops(applicationType), data.legitimation?.marketStore?.key),
            name1Signature: data.legitimation?.name1Signature ?? '',
        },
    };
    return Promise.resolve(application);
}
