import React, {PropsWithChildren, useEffect} from 'react';
import {FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {FormRow} from '../../components/form/FormRow';
import {getOptionEntryId, getOptionEntryLabel, OptionEntry} from './model';
import {useReferences} from './service';
import {Originator} from '../../utils/OriginatorProvider';

interface BranchOfIndustrySelectFieldProps<TFieldValues extends FieldValues = FieldValues> {
    branchCategoryFieldPath: FieldPath<TFieldValues>;
    branchOfIndustryFieldPath: FieldPath<TFieldValues>;
    required?: boolean;
    readOnly?: boolean;
}

export function BranchOfIndustrySelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<BranchOfIndustrySelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const {branchCategories, branchesOfIndustry, isLoading} = useReferences(Originator.current!!);
    const {watch, setValue} = useFormContext<TFieldValues>();

    const branchCategory = watch(props.branchCategoryFieldPath) as OptionEntry | undefined;
    const branchOfIndustry = watch(props.branchOfIndustryFieldPath) as OptionEntry | undefined;

    let branchesOfIndustryOptions: OptionEntry[] | undefined = undefined;
    if (branchCategory !== undefined && branchCategory !== null) {
        branchesOfIndustryOptions = branchesOfIndustry?.find(item => item.key === branchCategory.key)?.value;
    }

    const selectedBranchCategory = branchCategory?.key;
    const branchOfIndustryFieldPath = props.branchOfIndustryFieldPath;

    useEffect(() => {
        if (isLoading) {
            return;
        }
        const isValid = branchesOfIndustryOptions?.find(item => item.key === branchOfIndustry?.key);
        if (!isValid) {
            setValue(branchOfIndustryFieldPath, null as any, {shouldValidate: false});
        }
    }, [isLoading, branchesOfIndustryOptions, branchOfIndustry, selectedBranchCategory, setValue, branchOfIndustryFieldPath]);

    return (<FormRow>
        <AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={3}
                                                         options={branchCategories}
                                                         isLoading={isLoading}
                                                         getLabel={getOptionEntryLabel}
                                                         getId={getOptionEntryId}
                                                         fieldPath={props.branchCategoryFieldPath}
                                                         label={t('application.form.companyData.branchCategory')}
                                                         required={props.required}
                                                         readOnly={props.readOnly}
        />
        <AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={3}
                                                         disabled={branchesOfIndustryOptions === undefined}
                                                         options={branchesOfIndustryOptions}
                                                         isLoading={isLoading}
                                                         getLabel={getOptionEntryLabel}
                                                         getId={getOptionEntryId}
                                                         fieldPath={branchOfIndustryFieldPath}
                                                         label={t('application.form.companyData.branchOfIndustry')}
                                                         required={props.required}
                                                         readOnly={props.readOnly}
        />
    </FormRow>);

}
