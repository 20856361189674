import {CardContent, Grid, Box} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {AppCard} from '../../../../components/SectionCard';
import {formatAmountWithCurrency} from '../../../../utils/utils';
import {isMainProjektWeltApplication, MainProjektWeltApplication} from './model';
import {useGetSchufaInteractions} from '../../service';
import {tryTranslate} from '../../../../utils/tryTranslate';

interface MainProjektWeltApplicationOverviewSectionProps {
    application: MainProjektWeltApplication;
}

export const MainProjektWeltApplicationOverviewSection: FC<MainProjektWeltApplicationOverviewSectionProps> = ({application}) => {
    const {t} = useTranslation();
    const {schufaInteractions} = useGetSchufaInteractions(application.applicationNumber!!);

    if (!isMainProjektWeltApplication(application)) {
        throw new Error('Application is not of type Main ProjektWelt');
    }

    const lastSchufaInteraction = schufaInteractions?.sort((a, b) => (b.id ?? 0) - (a.id ?? 0))[0];

    return (<AppCard>
        <CardContent>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item xs={5}>
                        <LabelAndValue label={<strong>{t('application.crefo.limit.account')}</strong>}
                                       value={
                                           <strong>{formatAmountWithCurrency(application.accountLimit)}</strong>}/>
                        <LabelAndValue label={<strong>{t('application.accountNumber')}</strong>}
                                       value={<strong>{application.accountNumber ?? '-'}</strong>}/>
                        <LabelAndValue label={<strong>{t('application.cardNumber')}</strong>}
                                       value={<strong>{application.cardNumber ?? '-'}</strong>}/>
                        <Box sx={{marginTop: '1rem'}}>
                            <LabelAndValue label={<strong>{t('application.schufaScore')}</strong>}
                                           value={<strong>{lastSchufaInteraction?.score ?? '-'}</strong>}/>
                            <LabelAndValue label={<strong>{t('application.schufaStatus')}</strong>}
                                           value={
                                               <strong>{lastSchufaInteraction?.state ? tryTranslate(t, 'application.schufa.interaction.state.', lastSchufaInteraction?.state) ?? lastSchufaInteraction?.state : '-'}</strong>}/>
                        </Box>
                    </Grid>
                    <Grid item xs={7}>
                        {[application.cardHolder?.salutation?.value, application.cardHolder?.title?.value,
                            application.cardHolder?.firstName, application.cardHolder?.lastName].filter(text => !!text).join(' ')}
                        <br/>
                        {application.cardHolder?.email}
                        <br/>
                        {application.cardHolder?.cellPhoneNumber}
                        <br/>
                        {application.cardHolder?.address?.additionalLine && <br/>}
                        {application.cardHolder?.address?.street} {application.cardHolder?.address?.houseNumber}
                        <br/>
                        {application.cardHolder?.address?.zipCode} {application.cardHolder?.address?.city}
                        <br/>
                        {application.cardHolder?.address?.country?.value}
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    </AppCard>);
};
