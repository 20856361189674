import {
    AdditionalProjektWeltApplicationDto,
    ApplicationTypeDto,
    CreateAdditionalProjektWeltApplicationRequest,
    FormAction,
    UpdateAdditionalProjektWeltApplicationRequest
} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {mapEnum} from '../../../../utils/utils';
import {mapToMoneyModel} from '../../../money/mapper';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {
    mapDtoToAdditionalCardHolderModel,
    mapDtoToAdditionalMainCardModel,
    mapModelToCardHolderDto,
    mapModelToMainCardDto
} from '../../mainCard/mapper';
import {ApplicationStatus} from '../../model';
import {AdditionalProjektWeltApplication} from './model';

export function mapModelToCreateAdditionalProjektWeltApplicationRequest(action: FormAction, data: AdditionalProjektWeltApplication): CreateAdditionalProjektWeltApplicationRequest {
    return {
        applicationType: ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL,
        action: action,
        mainCard: mapModelToMainCardDto(data.mainCard),
        cardHolder: mapModelToCardHolderDto(data.cardHolder),
        marketData: {
            pinForWebShop: data.marketData?.pinForWebShop,
            masterStore: data.marketData?.masterStore ?? undefined,
        },
    };
}

export function mapModelToUpdateAdditionalProjektWeltApplicationRequest(action: FormAction, data: AdditionalProjektWeltApplication): UpdateAdditionalProjektWeltApplicationRequest {
    return {
        ...mapModelToCreateAdditionalProjektWeltApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version
    };
}

export async function mapDtoToAdditionalProjektWeltModel(data: AdditionalProjektWeltApplicationDto): Promise<AdditionalProjektWeltApplication> {
    const applicationType = ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const {getShops} = await getReferences(originator);
    const cardHolder = await mapDtoToAdditionalCardHolderModel(data.cardHolder, originator);
    const mainCard = await mapDtoToAdditionalMainCardModel(data.mainCard, originator);
    const application: AdditionalProjektWeltApplication = {
        applicationNumber: data.applicationNumber,
        version: data.version,
        applicationType: applicationType,
        status: mapEnum(data.status) ?? ApplicationStatus.CREATED,
        processingErrorReason: mapEnum(data.processingErrorReason),
        creationDate: data.creationDate,
        accountNumber: data.accountNumber,
        cardNumber: data.cardNumber,
        accountLimit: mapToMoneyModel(data.accountLimit),

        mainCard: mainCard,
        cardHolder: cardHolder,
        marketData: {
            pinForWebShop: data.marketData?.pinForWebShop ?? false,
            masterStore: findOptionEntryByKey(getShops(applicationType), data.marketData?.masterStore?.key),
        },
    };
    return Promise.resolve(application);
}

