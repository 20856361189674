import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {getOptionEntryId, getOptionEntryLabel, OptionEntry} from './model';
import {ReferenceSelectFieldProps} from './ReferenceSelectFieldProps';
import {useReferences} from './service';
import {Originator} from '../../utils/OriginatorProvider';
import {CountryKeyCH, CountryKeyDE} from '../country';

export type PlaceOfRegistrationSupportedCountryType = CountryKeyDE | CountryKeyCH;

interface PlaceOfRegistrationSelectFieldProps<TFieldValues extends FieldValues = FieldValues> extends ReferenceSelectFieldProps<TFieldValues> {
    optionsFromCountry: PlaceOfRegistrationSupportedCountryType;
}

export function PlaceOfRegistrationSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<PlaceOfRegistrationSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();
    const {getPlaceOfRegistration, isLoading} = useReferences(Originator.current!!);

    const placeOfRegistration = getPlaceOfRegistration?.(props.optionsFromCountry);

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                             options={placeOfRegistration}
                                                             isLoading={isLoading}
                                                             getLabel={getOptionEntryLabel}
                                                             getId={getOptionEntryId}
                                                             fieldPath={props.fieldPath}
                                                             disabled={props.disabled}
                                                             label={t('application.form.legitimation.placeOfRegistration')}
                                                             required={props.required}
                                                             readOnly={props.readOnly}
                                                             size={props.size}
    />);

}
