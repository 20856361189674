import {
    ApplicationTypeDto,
    BaseApplicationDto,
    CreateApplicationRequest,
    FormAction,
    UpdateApplicationRequest
} from '../../api';
import {BaseApplication} from './model';
import {
    mapDtoToAdditionalProfiCardModel,
    mapModelToCreateAdditionalProfiCardApplicationRequest,
    mapModelToUpdateAdditionalProfiCardApplicationRequest
} from './profiCard/additional/mapper';
import {isAdditionalProfiCardApplication} from './profiCard/additional/model';
import {
    mapDtoToMainProfiCardChModel,
    mapModelToCreateMainProfiCardChApplicationRequest,
    mapModelToUpdateMainProfiCardChApplicationRequest
} from './profiCard/main/ch/mapper';
import {isMainProfiCardChApplication} from './profiCard/main/ch/model';
import {
    mapDtoToMainProfiCardDeModel,
    mapModelToCreateMainProfiCardDeApplicationRequest,
    mapModelToUpdateMainProfiCardDeApplicationRequest
} from './profiCard/main/de/mapper';
import {isMainProfiCardDeApplication} from './profiCard/main/de/model';
import {
    mapDtoToMainProfiCardLuModel,
    mapModelToCreateMainProfiCardLuApplicationRequest,
    mapModelToUpdateMainProfiCardLuApplicationRequest
} from './profiCard/main/lu/mapper';
import {isMainProfiCardLuApplication} from './profiCard/main/lu/model';
import {
    mapDtoToAdditionalProjektWeltModel,
    mapModelToCreateAdditionalProjektWeltApplicationRequest, mapModelToUpdateAdditionalProjektWeltApplicationRequest
} from './projektWelt/additional/mapper';
import {isAdditionalProjektWeltApplication} from './projektWelt/additional/model';
import {
    mapDtoToMainProjektWeltModel,
    mapModelToCreateMainProjektWeltApplicationRequest,
    mapModelToUpdateMainProjektWeltApplicationRequest
} from './projektWelt/main/mapper';
import {isMainProjektWeltApplication} from './projektWelt/main/model';

export async function mapDtoToApplicationModel(applicationDto: BaseApplicationDto) {
    switch (applicationDto.applicationType) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
            return await mapDtoToMainProfiCardDeModel(applicationDto);

        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
            return await mapDtoToMainProfiCardLuModel(applicationDto);

        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
            return await mapDtoToMainProfiCardChModel(applicationDto);

        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
            return await mapDtoToAdditionalProfiCardModel(applicationDto);

        case ApplicationTypeDto.PROJEKT_WELT_MAIN:
            return await mapDtoToMainProjektWeltModel(applicationDto);

        case ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL:
            return await mapDtoToAdditionalProjektWeltModel(applicationDto);

        default: {
            console.error('Type not supported', applicationDto);
            throw new Error('Type not supported: ' + applicationDto.applicationType);
        }
    }
}

export function mapModelToCreateApplicationRequest(action: FormAction, application: BaseApplication): CreateApplicationRequest {
    if (isMainProfiCardDeApplication(application)) {
        return mapModelToCreateMainProfiCardDeApplicationRequest(action, application);
    }
    if (isMainProfiCardLuApplication(application)) {
        return mapModelToCreateMainProfiCardLuApplicationRequest(action, application);
    }
    if (isMainProfiCardChApplication(application)) {
        return mapModelToCreateMainProfiCardChApplicationRequest(action, application);
    }
    if (isAdditionalProfiCardApplication(application)) {
        return mapModelToCreateAdditionalProfiCardApplicationRequest(action, application);
    }
    if (isMainProjektWeltApplication(application)) {
        return mapModelToCreateMainProjektWeltApplicationRequest(action, application);
    }
    if (isAdditionalProjektWeltApplication(application)) {
        return mapModelToCreateAdditionalProjektWeltApplicationRequest(action, application);
    }
    console.error('Type not supported', application);
    // @ts-ignore
    throw new Error('Type not supported: ' + application.applicationType);
}

export function mapModelToUpdateApplicationRequest(action: FormAction, application: BaseApplication): UpdateApplicationRequest {
    if (isMainProfiCardDeApplication(application)) {
        return mapModelToUpdateMainProfiCardDeApplicationRequest(action, application);
    }
    if (isMainProfiCardLuApplication(application)) {
        return mapModelToUpdateMainProfiCardLuApplicationRequest(action, application);
    }
    if (isMainProfiCardChApplication(application)) {
        return mapModelToUpdateMainProfiCardChApplicationRequest(action, application);
    }
    if (isAdditionalProfiCardApplication(application)) {
        return mapModelToUpdateAdditionalProfiCardApplicationRequest(action, application);
    }
    if (isMainProjektWeltApplication(application)) {
        return mapModelToUpdateMainProjektWeltApplicationRequest(action, application);
    }
    if (isAdditionalProjektWeltApplication(application)) {
        return mapModelToUpdateAdditionalProjektWeltApplicationRequest(action, application);
    }
    console.error('Type not supported', application);
    // @ts-ignore
    throw new Error('Type not supported: ' + application.applicationType);
}
