import {CardContent, Grid, InputLabel} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {AppCard} from '../../../../components/SectionCard';
import {formatAmountWithCurrency} from '../../../../utils/utils';
import {BaseMainProfiCardApplicationUnion} from '../model';
import {isMainProfiCardApplication} from '../utils';
import {isCompanyOwnerRequired} from './model';

interface MainProfiCardApplicationOverviewSectionProps {
    application: BaseMainProfiCardApplicationUnion;
}

export const MainProfiCardApplicationOverviewSection: FC<MainProfiCardApplicationOverviewSectionProps> = ({application}) => {
    const {t} = useTranslation();

    if (!isMainProfiCardApplication(application)) {
        throw new Error('Application is not of type Main Card');
    }

    const companyOwnerRequired = isCompanyOwnerRequired(application);

    return (<AppCard>
        <CardContent>
            <Grid container>
                <Grid item container xs={12}>
                    <Grid item xs={companyOwnerRequired ? 4 : 5}>
                        <LabelAndValue label={t('application.crefo.limit.desired')}
                                       value={formatAmountWithCurrency(application.desiredLimit)}/>
                        <LabelAndValue label={t('application.crefo.limit.crefo')}
                                       value={formatAmountWithCurrency(application.creditValidatorLimit)}/>
                        <LabelAndValue label={<strong>{t('application.crefo.limit.account')}</strong>}
                                       value={
                                           <strong>{formatAmountWithCurrency(application.accountLimit)}</strong>}/>
                        <LabelAndValue label={t('application.crefo.riskByFlag')} value={application.riskBy ?? '-'}/>
                        <LabelAndValue label={t('application.crefo.score')}
                                       value={application.solvencyIndex ?? '-'}/>
                        <LabelAndValue label={<strong>{t('application.accountNumber')}</strong>}
                                       value={<strong>{application.accountNumber ?? '-'}</strong>}/>
                        <LabelAndValue label={<strong>{t('application.cardNumber')}</strong>}
                                       value={<strong>{application.cardNumber ?? '-'}</strong>}/>
                    </Grid>
                    <Grid item xs={companyOwnerRequired ? 4 : 7}>
                        <InputLabel>{t('application.company.address')}:</InputLabel>
                        {application.company?.companyName}
                        <br/>
                        {application.company?.address?.additionalLine}
                        {application.company?.address?.additionalLine && <br/>}
                        {application.company?.address?.street} {application.company?.address?.houseNumber}
                        <br/>
                        {application.company?.address?.zipCode} {application.company?.address?.city}
                        <br/>
                        {application.company?.address?.country?.value}
                    </Grid>
                    {companyOwnerRequired &&
                            <Grid item xs={4}>
                                <InputLabel>{t('application.form.companyOwner.sectionTitle')}:</InputLabel>
                                {application.companyOwner?.title?.value} {application.companyOwner?.firstName} {application.companyOwner?.lastName}
                                <br/>
                                {application.companyOwner?.address?.additionalLine}
                                {application.companyOwner?.address?.additionalLine && <br/>}
                                {application.companyOwner?.address?.street} {application.companyOwner?.address?.houseNumber}
                                <br/>
                                {application.companyOwner?.address?.zipCode} {application.companyOwner?.address?.city}
                                <br/>
                                {application.companyOwner?.address?.country?.value}
                            </Grid>}
                </Grid>
            </Grid>
        </CardContent>
    </AppCard>);
};
