import React, {useCallback, useEffect, useState} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {getOptionEntryId, getOptionEntryLabel, OptionEntry} from './model';
import {ReferenceSelectFieldProps} from './ReferenceSelectFieldProps';
import {useReferences} from './service';
import {useFormContext} from 'react-hook-form';
import {MainProfiCardDeApplication} from '../application/profiCard/main/de/model';
import {Originator} from '../../utils/OriginatorProvider';
import {tryTranslate} from '../../utils/tryTranslate';
import {ApplicationTypeDto, OriginatorDto} from '../../api';
import {CountryUtils} from '../country';

export function LegalFormSelectField<TFieldValues extends FieldValues = FieldValues>(props: ReferenceSelectFieldProps<TFieldValues> & {countryKey?: string}) {
    const {t} = useTranslation();
    const countryKey = props.countryKey;
    const originator = Originator.current!!;
    const [previousCountry, setPreviousCountry] = useState<string | undefined>();
    const {getLegalForms, isLoading} = useReferences(originator);
    const {getValues} = useFormContext<MainProfiCardDeApplication>();
    const [legalForms, setLegalForms] = useState<OptionEntry[]>();

    useEffect(() => {
        if (countryKey === previousCountry) {
            return;
        }
        const applicationType = getValues('applicationType');
        setLegalForms(getLegalForms?.(countryKey ?? applicationType));
        setPreviousCountry(countryKey);
    }, [countryKey, getValues, getLegalForms, previousCountry]);

    const getLegalFormLabelInternal = useCallback((option: OptionEntry) => {
        return getLegalFormLabel(t, countryKey ?? originator, option);
    }, [t, originator, countryKey]);

    return (<AppAutocompleteField<OptionEntry, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                             options={legalForms}
                                                             isLoading={isLoading}
                                                             getLabel={getLegalFormLabelInternal}
                                                             getId={getOptionEntryId}
                                                             fieldPath={props.fieldPath}
                                                             disabled={props.disabled}
                                                             label={t('application.form.companyData.legalForm')}
                                                             required={true}
                                                             readOnly={props.readOnly}
                                                             size={props.size}
    />);
}

export function getLegalFormLabel(t: Function, originator: OriginatorDto | ApplicationTypeDto | string, option: OptionEntry) {
    const optionId = getOptionEntryId(option);
    const optionValue = getOptionEntryLabel(option);
    const countryCode = getLegalFormCountryPrefix(originator);
    return tryTranslate(t, `legalForms.${countryCode}.`, optionId) ?? optionValue;
}

function getLegalFormCountryPrefix(originator: OriginatorDto | ApplicationTypeDto | string) {
    switch (originator) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
        case OriginatorDto.PROFI_CARD_GERMANY:
        case OriginatorDto.PROJEKT_WELT_GERMANY:
        case CountryUtils.DE.code:
            return 'de';

        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
        case OriginatorDto.PROFI_CARD_LUXEMBURG:
        case CountryUtils.LU.code:
            return 'lu';

        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
        case OriginatorDto.PROFI_CARD_SWITZERLAND:
        case CountryUtils.CH.code:
            return 'ch';

        case CountryUtils.FR.code:
            return 'fr';

        case CountryUtils.BE.code:
            return 'be';

        case CountryUtils.AT.code:
            return 'at';
    }
}
