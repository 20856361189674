import {BackspaceOutlined, PostAdd, Search, Visibility} from '@mui/icons-material';
import {Alert, Box, Button, Grid, Pagination, TableCell, TableRow, Typography} from '@mui/material';
import React, {useEffect, useMemo} from 'react';
import {FormProvider} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {z} from 'zod';
import {AppContent} from '../../components/AppContent';
import {AppDatePickerField} from '../../components/form/AppDatePickerField';
import {AppFormButton} from '../../components/form/AppFormButton';
import {AppTextField} from '../../components/form/AppTextField';
import {FormRow} from '../../components/form/FormRow';
import {useAppForm} from '../../components/form/useAppForm';
import {StickyHeaderTable} from '../../components/StickyHeaderTable';
import {ApplicationStatusLabel} from '../../domain/application/ApplicationStatusLabel';
import {User, UserRole} from '../../domain/user/model';
import {useUserContext} from '../../domain/user/UserProvider';
import {ROUTES} from '../../router';
import {formatDateTime, getDateSchema} from '../../utils/utils';
import {ApplicationStatusSelectField} from './ApplicationStatusSelectField';
import {DropdownButton} from '../../components/DropdownButton';
import {ApplicationTypeDto, Language} from '../../api';
import {ApplicationTypeSelectField} from './ApplicationTypeSelectField';
import {
    getApplicationSearchCriteria,
    invalidateGetApplicationCache,
    useGetApplications
} from '../../domain/application/service';
import AppConfig from '../../config';
import {Progress} from '../../components/Loader';
import {ErrorPanel} from '../../components/ErrorPanel';
import {AppCheckboxField} from '../../components/form/AppCheckboxField';
import {styled} from '@mui/material/styles';
import {BaseApplication} from '../../domain/application/model';
import {hasAnyRole, hasRole} from '../../domain/user/utils';
import {isAdditionalProfiCardApplication} from '../../domain/application/profiCard/additional/model';
import {isMainProfiCardApplication} from '../../domain/application/profiCard/utils';
import {isMainProjektWeltApplication} from '../../domain/application/projektWelt/main/model';
import {isAdditionalProjektWeltApplication} from '../../domain/application/projektWelt/additional/model';
import {getCurrentLanguage} from '../../i18n';

const StyledProductName = styled('span')({
    fontSize: '78%',
    whiteSpace: 'nowrap',
});

const StyledTableCell = styled(TableCell)({
    width: 80,
    maxWidth: 180,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderStyle: 'border-box',
    wordWrap: 'break-word',
});

const StyledTableCellLarge = styled(StyledTableCell)({
    width: 90,
    maxWidth: 220,
});

function ApplicationTable() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useUserContext();
    const [searchParams] = useSearchParams();
    const searchCriteria = getApplicationSearchCriteria(searchParams);

    const {applicationsResponse, isFetching, isError} = useGetApplications({
        ...searchCriteria,
        storeKey: hasRole(user, UserRole.HORNBACH) && user.store?.key ? user.store.key : undefined,
    });

    if (isFetching) {
        return <Progress/>;
    }

    if (isError || applicationsResponse === undefined) {
        return <ErrorPanel title={t('common.error.request-failure')}/>;
    }

    const currentPage = searchCriteria.pageIndex ?? 1;
    const pageSize = AppConfig.applicationListPageSize;
    const maxPage = Math.ceil(applicationsResponse.totalCount / pageSize);

    const onPageChange = (page: number) => {
        navigate(ROUTES.applications.search({
            ...searchCriteria,
            pageSize: pageSize,
            pageIndex: page,
        }));
    };

    return (<>
        <StickyHeaderTable headerRow={
            <TableRow>
                <StyledTableCell size="small">{t('application.list.header.applicationNumber')}</StyledTableCell>
                <StyledTableCell size="small">{t('application.list.header.product')}</StyledTableCell>
                <StyledTableCell>{t('application.list.header.cardHolderName')}</StyledTableCell>
                <StyledTableCell>{t('application.list.header.companyName')}</StyledTableCell>
                <StyledTableCell>{t('application.list.header.status')}</StyledTableCell>
                <StyledTableCell>{t('application.list.header.creationDate')}</StyledTableCell>
                <StyledTableCell></StyledTableCell>
            </TableRow>}>
            {applicationsResponse.applications.length <= 0 ? (
                    <TableRow><TableCell colSpan={6}
                                         sx={{color: 'secondary'}}><Alert
                            severity="info">{t('search.noResults')}</Alert></TableCell></TableRow>
            ) : applicationsResponse.applications.map((row) => {
                const formattedName = [row.cardHolder?.lastName, row.cardHolder?.firstName].filter(text => !!text).join(', ');
                const applicationNumber = row.applicationNumber;
                const applicationType = row.applicationType;
                return (
                        <TableRow key={applicationNumber}>
                            <StyledTableCell size="small">{applicationNumber}</StyledTableCell>
                            <StyledTableCell
                                    size="small"><StyledProductName>{getProductName(applicationType)}</StyledProductName></StyledTableCell>
                            <StyledTableCell>{formattedName}</StyledTableCell>
                            <StyledTableCellLarge>{getCompanyName(row)}</StyledTableCellLarge>
                            <StyledTableCellLarge><ApplicationStatusLabel status={row.status}
                                                                          size="small"/></StyledTableCellLarge>
                            <StyledTableCell>{formatDateTime(row.creationDate)}</StyledTableCell>
                            <StyledTableCell>
                                {applicationNumber &&
                                        <AppFormButton
                                                onClick={() => navigate(ROUTES.applications.edit(applicationNumber))}
                                                sx={{padding: '0.125rem 0.5rem', fontsize: '0.75rem'}}
                                                icon={<Visibility sx={{
                                                    width: '1rem',
                                                    height: '1rem'
                                                }}/>}>{t('common.row.details')}
                                        </AppFormButton>}
                            </StyledTableCell>
                        </TableRow>
                );
            })}
        </StickyHeaderTable>
        <Pagination sx={theme => ({float: 'right', marginTop: theme.spacing(1)})}
                    shape="rounded"
                    count={maxPage}
                    page={currentPage}
                    onChange={(event, page) => onPageChange(page)}
        />
    </>);
}

export function getProductName(applicationType: ApplicationTypeDto) {
    switch (applicationType) {
        case ApplicationTypeDto.PROFI_CARD_DE_MAIN:
            return 'PC-DE-HK';
        case ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL:
            return 'PC-DE-ZK';
        case ApplicationTypeDto.PROFI_CARD_CH_MAIN:
            return 'PC-CH-HK';
        case ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL:
            return 'PC-CH-ZK';
        case ApplicationTypeDto.PROFI_CARD_LU_MAIN:
            return 'PC-LUX-HK';
        case ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL:
            return 'PC-LUX-ZK';
        case ApplicationTypeDto.PROJEKT_WELT_MAIN:
            return 'PW-HK';
        case ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL:
            return 'PW-ZK';
    }
    throw new Error('Unsupported type: ' + applicationType);
}

function getCompanyName(row: BaseApplication) {
    if (isMainProfiCardApplication(row)) {
        return row.company?.companyName;
    } else if (isAdditionalProfiCardApplication(row)) {
        return row.mainCard?.companyName;
    } else if (isMainProjektWeltApplication(row)) {
        return '-';
    } else if (isAdditionalProjektWeltApplication(row)) {
        return '-';
    }
    // @ts-ignore
    throw new Error('Unsupported type: ' + row.applicationType);
}

export default function ApplicationsListPage() {
    const {t} = useTranslation();

    return (<AppContent label={<Typography variant="h4">{t('application.multiple')}</Typography>}>
                <Grid container>
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            marginTop: '-40px',
                            marginBottom: '30px'
                        }}>
                            <NewApplicationButton/>
                        </Box>
                        <ApplicationSearchFields/>
                        <br/>
                        <ApplicationTable/>
                    </Grid>
                </Grid>
            </AppContent>
    );
}

function NewApplicationButton() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {user} = useUserContext();

    const items = useMemo(() => {
        const items = [];
        if (user.countryDe) {
            items.push({
                label: t('application.type.profiCard.de.main'),
                onClick: () => navigate(ROUTES.applications.profiCard.main.create.de)
            });
            items.push({
                label: t('application.type.profiCard.de.additional'),
                onClick: () => navigate(ROUTES.applications.profiCard.additional.create.de)
            });
            AppConfig.projektWeltEnabled && items.push({
                label: t('application.type.projektWelt.de.main'),
                onClick: () => navigate(ROUTES.applications.projektWelt.main.create)
            });
            AppConfig.projektWeltEnabled && items.push({
                label: t('application.type.projektWelt.de.additional'),
                onClick: () => navigate(ROUTES.applications.projektWelt.additional.create)
            });
        }
        if (user.countryLu) {
            items.push({
                label: t('application.type.profiCard.lu.main'),
                onClick: () => navigate(ROUTES.applications.profiCard.main.create.lu)
            });
            items.push({
                label: t('application.type.profiCard.lu.additional'),
                onClick: () => navigate(ROUTES.applications.profiCard.additional.create.lu)
            });
        }
        if (user.countryCh) {
            items.push({
                label: t('application.type.profiCard.ch.main'),
                onClick: () => navigate(ROUTES.applications.profiCard.main.create.ch)
            });
            items.push({
                label: t('application.type.profiCard.ch.additional'),
                onClick: () => navigate(ROUTES.applications.profiCard.additional.create.ch)
            });
        }
        return items;
    }, [user, t, navigate]);

    return (<DropdownButton icon={<PostAdd/>}
                            label={t('application.button.add-new')}
                            items={items}
    />);
}

const searchDataSchema = z.object({
    applicationNumber: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    companyName: z.string().optional(),
    creationDate: getDateSchema(false),
    cardHolderDateOfBirth: getDateSchema(false),
    applicationStatus: z.string().optional().nullable(),
    applicationType: z.nativeEnum(ApplicationTypeDto).optional().nullable(),
    expandSearchToEntireCountry: z.boolean().optional().nullable(),
});
type SearchData = z.infer<typeof searchDataSchema>;

function ApplicationSearchFields() {
    const {t} = useTranslation();
    const {user} = useUserContext();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    function isAdvancedSearchEnabled(user: User) {
        if (hasRole(user, UserRole.HORNBACH)) {
            return false;
        }
        if (hasAnyRole(user, [UserRole.HORNBACH_CENTRAL, UserRole.KNISTR])) {
            return true;
        }
        throw new Error(`Unrecognised user role: ${user.role}`);
    }

    const showAdvancedFields = isAdvancedSearchEnabled(user);

    const formData = useAppForm<SearchData>(searchDataSchema, {});

    const setValue = formData.setValue;
    useEffect(() => {
        const searchData: SearchData = getApplicationSearchCriteria(searchParams);
        let key: keyof typeof searchData;
        for (key in searchData) {
            setValue(key, searchData[key]);
        }
    }, [setValue, searchParams]);

    const handleSubmit = formData.handleSubmit;

    const processSearch = (searchData: SearchData) => {
        invalidateGetApplicationCache();
        navigate(ROUTES.applications.search(searchData));
    };

    const language = getCurrentLanguage();
    const isFrench = language === Language.FR;

    return (<FormProvider {...formData}>
        <form onSubmit={handleSubmit(processSearch)}>
            <FormRow>
                <AppTextField<SearchData> breakpoint={2}
                                          label={t('search.text.applicationNumber')}
                                          fieldPath={'applicationNumber'}
                                          size="small"/>
                <AppTextField<SearchData> breakpoint={2}
                                          label={t('search.text.firstName')}
                                          fieldPath={'firstName'}
                                          size="small"/>
                <AppTextField<SearchData> breakpoint={2}
                                          label={t('search.text.lastName')}
                                          fieldPath={'lastName'}
                                          size="small"/>
                <AppTextField<SearchData> breakpoint={4}
                                          label={t('search.text.companyName')}
                                          fieldPath={'companyName'}
                                          size="small"/>
                <AppDatePickerField<SearchData> breakpoint={2}
                                                label={t('search.date.creation')}
                                                fieldPath={'creationDate'}
                                                size="small"/>
            </FormRow>
            <Grid container sx={{flexWrap: {lg: 'nowrap'}}} columnSpacing={2} rowGap={2} flexBasis={12}>
                <Grid item container columns={{xs: 12, lg: 9}} columnSpacing={2}
                      sx={theme => ({
                          marginTop: theme.spacing(1), flexWrap: 'nowrap', maxWidth: {lg: '76.5%'}
                      })}>
                    {!showAdvancedFields &&
                            <AppCheckboxField<SearchData> breakpoint={4}
                                                          sx={{marginTop: 0}}
                                                          fieldPath={'expandSearchToEntireCountry'}
                                                          label={t('search.expandSearchToEntireCountry')}/>}
                    {showAdvancedFields &&
                            <AppDatePickerField<SearchData> breakpoint={2}
                                                            label={t('common.form.person.dateOfBirth')}
                                                            fieldPath={'cardHolderDateOfBirth'}
                                                            size="small"/>}
                    {showAdvancedFields &&
                            <ApplicationStatusSelectField<SearchData> breakpoint={4}
                                                                      fieldPath={'applicationStatus'}
                                                                      size="small"/>}
                    {showAdvancedFields &&
                            <ApplicationTypeSelectField<SearchData> breakpoint={3}
                                                                    fieldPath={'applicationType'}
                                                                    size="small"/>}
                </Grid>
                <Grid item container direction="row" columnSpacing={1}
                      justifyContent="flex-end" columns={3}
                      alignItems="flex-end"
                      xs={true}
                      sx={theme => ({
                          paddingBottom: theme.spacing(0.25),
                          flexWrap: 'nowrap',
                          width: 'auto',
                      })}>
                    <Grid item> <AppFormButton
                            icon={<Search fontSize='small'/>}
                            sx={{
                                fontSize: isFrench ? '0.75rem' : '0.875rem',
                                padding: {lg: isFrench ? '8px 10px' : '6px 10px'}
                            }}>
                        {t('application.button.search')}
                    </AppFormButton></Grid>
                    <Grid item><Button
                            startIcon={<BackspaceOutlined
                                    fontSize='small'/>}
                            type={'reset'}
                            onClick={() => {
                                formData.reset();
                                processSearch(formData.getValues());
                            }}
                            sx={{fontSize: isFrench ? '0.75rem' : '0.875rem'}}
                    >
                        {t('application.button.searchReset')}
                    </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    </FormProvider>);
}
