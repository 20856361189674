import {Currency, MoneyDto} from '../../../api';
import React, {FC} from 'react';

import EmptyValue from './EmptyValue';

interface PrintMoneyProps {
    money: MoneyDto;
}

const PrintMoney: FC<PrintMoneyProps> = ({money}) => {
    if (money === undefined || money === null) {
        return <EmptyValue/>;
    }
    return <>{money.amount + ' ' + (money.currency === Currency.EUR ? '€' : money.currency)}</>;
};

export default PrintMoney;
