import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    createEmptyMainProjektWeltApplication,
    MainProjektWeltApplication
} from '../../domain/application/projektWelt/main/model';
import {MainProjektWeltApplicationForm} from '../../domain/application/projektWelt/main/MainProjektWeltApplicationForm';

export default function NewMainProjektWeltApplicationPage() {
    return (<NewApplicationPage<MainProjektWeltApplication>
            renderApplicationForm={MainProjektWeltApplicationForm}
            createEmptyApplication={createEmptyMainProjektWeltApplication}/>);
}
