import styled from '@emotion/styled';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    TableCell,
    TableRow,
    TableCellProps,
    Typography
} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import {FieldPath, FormProvider, useFormContext, FieldError} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {z} from 'zod';
import {ErrorPanel} from '../../components/ErrorPanel';
import {AppCheckboxField} from '../../components/form/AppCheckboxField';
import {AppFormButton} from '../../components/form/AppFormButton';
import {AppTextField} from '../../components/form/AppTextField';
import {FormRow} from '../../components/form/FormRow';
import {useAppForm} from '../../components/form/useAppForm';
import {markLabelAsMandatory} from '../../components/form/utils';
import {useSnackbar} from '../../components/SnackbarHelper';
import {StickyHeaderTable} from '../../components/StickyHeaderTable';
import {CountrySelectField} from '../reference/CountrySelectField';
import {optionEntrySchema} from '../reference/model';
import {Address, AddressSuggestion, AddressSuggestions, AddressWithAdditionalLine, isErrorOption} from './model';
import {createEmptyAddress, getAddressSuggestions} from './service';
import {ConstraintViolationError} from '../../utils/api';
import {DictionaryValueDto} from '../../api';

interface AddressPickerWithAdditionalLineProps {
    address: AddressWithAdditionalLine;
    showAdditionalLine: true;

    onAddressSelect(selectedAddress: AddressWithAdditionalLine): void;
}

interface AddressPickerWithoutAdditionalLineProps {
    address: Address;
    showAdditionalLine?: false;

    onAddressSelect(selectedAddress: Address): void;
}

interface AddressPickerPropsInternal<TFieldValues extends FieldValues = FieldValues> {
    id: string;
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    address: AddressWithAdditionalLine;
    disableCountry?: boolean;
    availableCountries?: DictionaryValueDto[];
    clearable?: boolean;
    showAcceptUnverifiedAddress?: boolean,
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
}

type AddressPickerProps<TFieldValues extends FieldValues = FieldValues> =
        AddressPickerPropsInternal<TFieldValues>
        & (AddressPickerWithAdditionalLineProps | AddressPickerWithoutAdditionalLineProps);

export function AddressPicker<TFieldValues extends FieldValues = FieldValues>(props: AddressPickerProps<TFieldValues>) {
    const {t} = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogClosedTime, setDialogClosedTime] = useState(0);
    const [hasError, setHasError] = useState(false);

    const form = useFormContext();
    const error = form.getFieldState(props.fieldPath).error;
    const hasErrorInternal = error !== undefined;
    useEffect(() => {
        setHasError(hasErrorInternal);
    }, [hasErrorInternal]);
    const address = props.address ?? createEmptyAddress(props.showAdditionalLine);

    const handleOpenAddressPickerDialog = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        setIsDialogOpen(true);
    }, []);

    const onAddressSelect = (selectedAddress: Address | AddressWithAdditionalLine) => {
        setIsDialogOpen(false);
        props.onAddressSelect(selectedAddress);
        setHasError(false);
        form.clearErrors(props.fieldPath);
        onDialogClose();
    };

    const onAddressClear = () => {
        setIsDialogOpen(false);
        const emptyAddress = createEmptyAddress(props.showAdditionalLine);
        const selectedAddress = {...emptyAddress, ...{country: address.country}};
        props.onAddressSelect(selectedAddress);
    };

    const isAddressEmpty = !(address.street || address.houseNumber || address.zipCode || address.city);
    const clearable = props.clearable ?? true;

    const showAcceptUnverifiedAddress = address.unverifiedAddressAccepted || props.showAcceptUnverifiedAddress;

    const onDialogClose = () => {
        setIsDialogOpen(false);
        setDialogClosedTime(new Date().getTime());
    };

    const onButtonFocus = () => {
        const current = new Date().getTime();
        const diff = current - dialogClosedTime;
        if (diff > 500) {
            setIsDialogOpen(true);
        }
    };

    const getAddressError = (error: FieldError | undefined): string | null => {
        if (!error) {
            return null;
        }

        if (isAddressEmpty) {
            return t('common.form.field.required');
        } else if (typeof error.message === 'string') {
            return error.message;
        } else if (typeof error === 'object') {
            // display specific error message from the first field with error inside address modal

            const [firstError] = Object.entries(error);
            const [_, errorObject] = firstError || [];
            if (isErrorOption(errorObject)) {
                return errorObject.message ?? null;
            }
            return t('validation.condition.addressError');
        }

        return t('validation.condition.addressError');
    };

    return (<Grid item xs={props.breakpoint}>
                <InputLabel
                        error={hasError}>{markLabelAsMandatory(t('address.picker.preview.label'), props.required)}</InputLabel>
                <Card sx={theme => ({
                    backgroundColor: theme.palette.background.paper,
                    border: hasError ? '1px solid ' + theme.palette.error.main : '1px solid #c4c4c4',
                })}>
                    <CardContent sx={theme => ({
                        maddingBottom: theme.spacing(1),
                    })} id={props.id + '-address-picker-content'}>
                        {isAddressEmpty || !clearable ? null : <IconButton onClick={onAddressClear}
                                                                           disabled={props.readOnly ?? props.disabled}
                                                                           id={props.id + '-address-picker-clear-button'}
                                                                           sx={{
                                                                               float: 'right'
                                                                           }}
                                                                           title={t('address.picker.preview.clearButton.title')}>
                            <DeleteIcon/>
                        </IconButton>}
                        {isAddressEmpty && <Typography
                                sx={theme => ({
                                    color: hasError ? theme.palette.error.main : theme.palette.warning.main,
                                })}>{t('address.picker.preview.noAddressSelected')}</Typography>}

                        {!isAddressEmpty && <>
                            <Typography sx={{fontSize: 16}} color="text.primary">
                                {address.additionalLine}
                                {address.additionalLine && <br/>}
                                {address.street} {address.houseNumber}
                                <br/>
                                {address.zipCode} {address.city}
                                <br/>
                                {address.country?.value}
                            </Typography>
                            {address.verified === true &&
                                    <Alert severity={'success'}>{t('address.picker.preview.addressVerifiedText')}</Alert>}

                            {address.verified === false && <Alert severity={'warning'}>
                                <AlertTitle>{t('address.picker.preview.addressUnverifiedTitle')}</AlertTitle>
                                {!showAcceptUnverifiedAddress && t('address.picker.preview.addressUnverifiedText')}
                                {showAcceptUnverifiedAddress && <AppCheckboxField<TFieldValues>
                                        fieldPath={(props.fieldPath + '.unverifiedAddressAccepted') as any}
                                        label={t('address.picker.preview.unverifiedAddressAccepted')}/>}
                            </Alert>}
                        </>}
                    </CardContent>
                    <CardActions>
                        <AppFormButton sx={{width: '100%'}}
                                       id={props.id + '-address-picker-button'}
                                       type={'button'}
                                       hasError={hasError}
                                       onFocus={onButtonFocus}
                                       onClick={handleOpenAddressPickerDialog}
                                       disabled={props.readOnly ?? props.disabled}

                        >{isAddressEmpty ? t('address.picker.showDialogButton.new') : t('address.picker.showDialogButton.change')}</AppFormButton>
                    </CardActions>
                </Card>
                {hasError && <FormHelperText id={props.id + '-error'}
                                             sx={theme => ({
                                                 marginLeft: theme.spacing(2),
                                                 color: theme.palette.error.main,
                                             })}>{getAddressError(error)}</FormHelperText>}

                <AddressPickerDialog searchAddressData={address} isDialogOpen={isDialogOpen}
                                     onCloseDialog={onDialogClose}
                                     onAddressSelect={onAddressSelect}
                                     disableCountry={props.disableCountry}
                                     availableCountries={props.availableCountries}
                                     showAdditionalLine={props.showAdditionalLine}
                                     readOnly={props.readOnly}
                                     fieldError={error}
                />
            </Grid>
    );
}

const addressSearchFormDataSchema = z.object({
    additionalLine: z.string().optional(),
    street: z.string().min(1),
    houseNumber: z.string().min(1),
    zipCode: z.string(),
    city: z.string(),
    country: optionEntrySchema,
})
        .superRefine((addressSearchData, ctx) => {
            if (!addressSearchData.zipCode && !addressSearchData.city) {
                const message = 'address.picker.dialog.form.error.zipCodeOrCityRequired';
                ctx.addIssue({
                    code: 'custom',
                    message: message,
                    path: ['zipCode'],
                });
                ctx.addIssue({
                    code: 'custom',
                    message: message,
                    path: ['city'],
                });
            }
        });
export type AddressSearchFormData = z.infer<typeof addressSearchFormDataSchema>;

interface AddressPickerDialogProps {
    searchAddressData: AddressSearchFormData;
    isDialogOpen: boolean;
    disableCountry?: boolean;
    availableCountries?: DictionaryValueDto[];
    showAdditionalLine?: boolean;
    readOnly?: boolean;

    onCloseDialog(): void;

    onAddressSelect(selectedAddress: Address | AddressWithAdditionalLine): void;

    fieldError?: FieldError;
}

export const AddressPickerDialog: FC<AddressPickerDialogProps> = (props) => {
    const {t} = useTranslation();
    const [isAddressSearchingInProgress, setIsAddressSearchingInProgress] = useState(false);
    const initialSearchData = props.searchAddressData;
    const [searchData, setSearchData] = useState<AddressSearchFormData>(initialSearchData);
    const [searchResult, setSearchResult] = useState<AddressSuggestions>();
    const [searchResultErrorMessage, setSearchResultErrorMessage] = useState<{
        title: string,
        message: ReactNode;
    }>();
    const showSnackbar = useSnackbar();

    const handleCloseAddressPickerDialog = () => {
        props.onCloseDialog();
    };


    const formData = useAppForm<AddressSearchFormData>(addressSearchFormDataSchema, props.searchAddressData);

    const handleSubmit = formData.handleSubmit;
    const wrapPromiseToProcessForm = formData.wrapPromise;
    const setFormValue = formData.setValue;
    const setFocus = formData.setFocus;

    const showAdditionalLine = props.showAdditionalLine ?? false;
    const dialogOpen = props.isDialogOpen;

    useEffect(() => {
        if (dialogOpen) {
            const handler = setTimeout(() => {
                setFocus(showAdditionalLine ? 'additionalLine' : 'street', {shouldSelect: true});
            }, 100);
            return () => clearTimeout(handler);
        }
    }, [setFocus, dialogOpen, showAdditionalLine]);

    useEffect(() => {
        Object.entries(initialSearchData)
                .forEach(([key, value]) => {
                    setFormValue(key as any, value);
                });
        setSearchResult(undefined);
        setSearchResultErrorMessage(undefined);
    }, [initialSearchData, setFormValue]);

    const handleAddressSearchClick = () => {
        const searchData = formData.getValues();
        setSearchData(searchData);
        setIsAddressSearchingInProgress(true);
        setSearchResult(undefined);
        setSearchResultErrorMessage(undefined);

        wrapPromiseToProcessForm(getAddressSuggestions(searchData))
                .then((response) => {
                    const suggestions: AddressSuggestion[] = response.suggestions.map(address => ({
                        ...address,
                        additionalLine: searchData.additionalLine
                    }));
                    const result: AddressSuggestions = {
                        suggestions,
                    };
                    return result;
                })
                .then((response) => {
                    if (response.suggestions.length === 0) {
                        setSearchResultErrorMessage({
                            title: t('address.picker.dialog.alert.searchNoResult.title'),
                            message: <UnverifiedAddressErrorPanel address={mapToUnverifiedAddressSuggestion(searchData)}
                                                                  additionalMessage={t('address.picker.dialog.alert.searchNoResult.message')}
                                                                  onAddressSelected={onAddressSelected}/>,
                        });
                    } else if (response.suggestions.length === 1 && isSearchDataEqualToAddressSuggestion(searchData, response.suggestions[0])) {
                        props.onAddressSelect({...response.suggestions[0]} as Address);
                        showSnackbar({
                            message: t('address.picker.dialog.toast.onlyMatchedResultSelected'),
                            severity: 'success',
                        });
                        return;
                    }
                    setSearchResult(response);
                })
                .catch((reason: any) => {
                    // show accept unverified address in case of no validation error or if `validation.condition.Address` errorCode is in the response
                    if (!reason?.body?.errors
                            || (reason?.body as ConstraintViolationError)?.errors?.find(item => item.errorCode === 'validation.condition.Address') !== undefined) {
                        console.warn('address validation failed, the service might be unavailable');
                        setSearchResultErrorMessage({
                            title: t('address.picker.dialog.alert.searchFailed.title'),
                            message: <UnverifiedAddressErrorPanel address={mapToUnverifiedAddressSuggestion(searchData)}
                                                                  additionalMessage={t('address.picker.dialog.alert.searchFailed.message')}
                                                                  onAddressSelected={onAddressSelected}/>,
                        });
                    }
                    showSnackbar({
                        message: t('address.picker.dialog.toast.searchFailed'),
                        severity: 'error',
                    });
                })
                .finally(() => {
                    setIsAddressSearchingInProgress(false);
                });
    };

    const onAddressSelected = (suggestion: AddressSuggestion) => {
        props.onAddressSelect(mapAddressSuggestionToAddress(suggestion, showAdditionalLine));
    };

    const areResultsAvailable = searchResultErrorMessage === undefined && searchResult?.suggestions?.length !== undefined && searchResult.suggestions.length > 0;
    const inlineCancelButton = !(areResultsAvailable || searchResult?.suggestions.length === 0);
    const {clearErrors, setError} = formData;

    useEffect(() => {
        typeof props?.fieldError === 'object' && Object.entries(props.fieldError)?.map(([key, error]) => {
            const validKey = key as keyof AddressSearchFormData;

            if (initialSearchData[validKey] === '' || !initialSearchData[validKey]) {
                clearErrors(validKey);
            } else if (isErrorOption(error)) {
                setError(validKey, error);
            }
        });
    }, [props.fieldError, initialSearchData, setError, clearErrors]);

    return (<Dialog open={dialogOpen} onClose={handleCloseAddressPickerDialog}
                    PaperProps={{sx: {minWidth: '800px'}}}>
        <FormProvider {...formData}>
            <form onSubmit={event => {
                event.stopPropagation();
                return handleSubmit(handleAddressSearchClick)(event);
            }}>
                <DialogTitle>{t('address.picker.dialog.title')}</DialogTitle>
                <DialogContent>
                    {showAdditionalLine && <FormRow>
                        <AppTextField<AddressSearchFormData> breakpoint={12}
                                                             label={t('common.form.address.additionalLine')}
                                                             fieldPath={'additionalLine'}
                                                             readOnly={props.readOnly}
                        />
                    </FormRow>}
                    <FormRow>
                        <AppTextField<AddressSearchFormData> breakpoint={8}
                                                             required={true}
                                                             label={t('common.form.address.street')}
                                                             fieldPath={'street'}
                                                             readOnly={props.readOnly}
                        />
                        <AppTextField<AddressSearchFormData> breakpoint={4}
                                                             required={true}
                                                             label={t('common.form.address.houseNumber')}
                                                             fieldPath={'houseNumber'}
                                                             readOnly={props.readOnly}
                        />
                    </FormRow>
                    <FormRow>
                        <AppTextField<AddressSearchFormData> breakpoint={2}
                                                             required={true}
                                                             label={t('common.form.address.zipCode')}
                                                             fieldPath={'zipCode'}
                                                             readOnly={props.readOnly}
                        />
                        <AppTextField<AddressSearchFormData> breakpoint={6}
                                                             required={true}
                                                             label={t('common.form.address.city')}
                                                             fieldPath={'city'}
                                                             readOnly={props.readOnly}
                        />
                        <CountrySelectField<AddressSearchFormData> breakpoint={4}
                                                                   required={true}
                                                                   fieldPath={'country'}
                                                                   disabled={props.disableCountry}
                                                                   availableCountries={props.availableCountries}
                                                                   readOnly={props.readOnly}
                        />
                    </FormRow>
                </DialogContent>
                <DialogActions>
                    {inlineCancelButton &&
                            <Button onClick={handleCloseAddressPickerDialog}>{t('common.dialog.cancel')}</Button>}
                    <AppFormButton outlined={false}
                                   inProgress={isAddressSearchingInProgress}
                                   icon={
                                       <SearchIcon/>}>{t('address.picker.dialog.searchButton')}</AppFormButton>
                </DialogActions>
                {isAddressSearchingInProgress &&
                        <DialogContent>
                            <LinearProgress/>
                        </DialogContent>}
                {searchResultErrorMessage !== undefined &&
                        <DialogContent>
                            <ErrorPanel {...searchResultErrorMessage}/>
                        </DialogContent>}
                {areResultsAvailable &&
                        <DialogContent>
                            <AddressSuggestionsListResult searchResult={searchResult}
                                                          searchData={searchData}
                                                          showAdditionalLine={showAdditionalLine}
                                                          onAddressSelected={onAddressSelected}/>
                        </DialogContent>}
                {!inlineCancelButton &&
                        <DialogActions>
                            <Button onClick={handleCloseAddressPickerDialog}>{t('common.dialog.cancel')}</Button>
                        </DialogActions>}
            </form>
        </FormProvider>
    </Dialog>);
};

function mapToUnverifiedAddressSuggestion(searchData: AddressSearchFormData): AddressSuggestion {
    return {
        ...searchData,
        country: searchData.country!!,
        verified: false
    };
}

interface UnverifiedAddressErrorPanelProps {
    address: AddressSuggestion;
    additionalMessage: string;

    onAddressSelected(selectedAddress: AddressSuggestion): void;
}

const UnverifiedAddressErrorPanel: FC<UnverifiedAddressErrorPanelProps> = ({
                                                                               address,
                                                                               additionalMessage,
                                                                               onAddressSelected
                                                                           }) => {
    const {t} = useTranslation();
    return (<>
        <Typography variant={'body2'}>{additionalMessage}</Typography>
        <Typography variant={'body2'}>{t('address.picker.dialog.unverifiedAddress.warningText')}</Typography>
        <Typography variant={'body1'} sx={theme => ({marginTop: theme.spacing(1)})}>
            {address.additionalLine ?? ''}
            {address.additionalLine && <br/>}
            {address.street} {address.houseNumber}
            <br/>
            {address.zipCode} {address.city}
            <br/>
            {address.country?.value}
        </Typography>
        <MissingDataTextOrSelectAddressButton address={address}
                                              button={
                                                  <SelectAddressButton suggestion={address}
                                                                       onAddressSelected={onAddressSelected}/>}
        />
    </>);
};

interface MissingDataTextOrSelectAddressButtonProps {
    button: ReactNode;
    address: AddressSuggestion;
}

const MissingDataTextOrSelectAddressButton: FC<MissingDataTextOrSelectAddressButtonProps> = ({button, address}) => {
    const {t} = useTranslation();

    const missingProperties = Object.entries(address)
            .filter(([, value]) => value === undefined || value === null || value === '')
            .map(([key]) => key)
            .filter(key => !['additionalLine', 'verified'].includes(key))
            .map(key => t(('common.form.address.' + key) as any).toString());

    if (missingProperties.length > 0) {
        return (<FormHelperText sx={theme => ({color: theme.palette.error.main})}>
            {t('address.picker.dialog.alert.missingProperties')}&nbsp;
            {missingProperties.join(',')}.
        </FormHelperText>);
    } else {
        return <>{button}</>;
    }
};

interface AddressSuggestionsListResultProps {
    searchResult: AddressSuggestions;
    searchData: AddressSearchFormData;
    showAdditionalLine?: boolean;

    onAddressSelected(selectedAddress: AddressSuggestion): void;
}

const AddressSuggestionsListResult: FC<AddressSuggestionsListResultProps> = ({
                                                                                 searchResult,
                                                                                 searchData,
                                                                                 onAddressSelected,
                                                                                 showAdditionalLine,
                                                                             }) => {
    const {t} = useTranslation();

    const shouldRenderAdditionalLine = !!showAdditionalLine && !!searchData.additionalLine && searchData.additionalLine.trim().length > 0;

    return (
            <StickyHeaderTable maxHeight={'400px'}
                               headerRow={
                                   <TableRow>
                                       <TableCell>{t('common.form.address.street')}</TableCell>
                                       <TableCell>{t('common.form.address.houseNumber')}</TableCell>
                                       <TableCell>{t('common.form.address.zipCode')}</TableCell>
                                       <TableCell>{t('common.form.address.city')}</TableCell>
                                       <TableCell>{t('common.form.address.country')}</TableCell>
                                       <TableCell></TableCell>
                                   </TableRow>}>
                {searchResult.suggestions.map(suggestion =>
                        <TableRowWithVerifiedAddress key={'suggestion-' + Object.values(suggestion).join('-')}
                                                     shouldRenderAdditionalLine={shouldRenderAdditionalLine}
                                                     searchData={searchData}
                                                     suggestion={suggestion}
                                                     onAddressSelected={onAddressSelected}/>)
                }
                <TableRowWithUnverifiedAddress shouldRenderAdditionalLine={shouldRenderAdditionalLine}
                                               searchData={searchData}
                                               onAddressSelected={onAddressSelected}/>
            </StickyHeaderTable>);
};

interface SelectAddressButtonProps {
    suggestion: AddressSuggestion;

    onAddressSelected(addressSuggestion: AddressSuggestion): void;
}

const SelectAddressButton: FC<SelectAddressButtonProps> = ({
                                                               suggestion,
                                                               onAddressSelected
                                                           }) => {
    const {t} = useTranslation();
    return (<Button
            variant="contained"
            type="button"
            color="secondary"
            onClick={() => onAddressSelected(suggestion)}
    >{t('common.form.address.row.selectButton')}</Button>);
};

const TableCellWithAddressLine = styled(TableCell)({
    borderBottom: 'none',
    paddingBottom: 0
});

interface TableCellBelowAddressLineProps {
    isAdditionalLineVisible: boolean;
}

type StyledTableCellProps = TableCellBelowAddressLineProps & TableCellProps;

const TableCellBelowAddressLine = styled(({isAdditionalLineVisible, ...props}: StyledTableCellProps) =>
        <TableCell {...props}/>)(({isAdditionalLineVisible}) => ({
    paddingTop: isAdditionalLineVisible ? '8px' : undefined
}));

interface TableRowWithVerifiedAddressProps {
    shouldRenderAdditionalLine: boolean;
    searchData: AddressSearchFormData;
    suggestion: AddressSuggestion;

    onAddressSelected(suggestion: AddressSuggestion): void;
}

const TableRowWithVerifiedAddress: FC<TableRowWithVerifiedAddressProps> = ({
                                                                               shouldRenderAdditionalLine,
                                                                               searchData,
                                                                               suggestion,
                                                                               onAddressSelected,
                                                                           }) => {
    return (<>
        {shouldRenderAdditionalLine && <TableRow className={'rowA'}>
            <TableCellWithAddressLine colSpan={5}>{searchData.additionalLine}</TableCellWithAddressLine>
            <TableCell rowSpan={2}><SelectAddressButton suggestion={suggestion} onAddressSelected={onAddressSelected}/></TableCell>
        </TableRow>}

        <TableRow className={shouldRenderAdditionalLine ? 'rowB' : undefined}>
            <TableCellBelowAddressLine
                    isAdditionalLineVisible={shouldRenderAdditionalLine}>{emphasizeDifferenceOrDefault(suggestion.street, searchData.street)}</TableCellBelowAddressLine>
            <TableCellBelowAddressLine
                    isAdditionalLineVisible={shouldRenderAdditionalLine}>{emphasizeDifferenceOrDefault(suggestion.houseNumber, searchData.houseNumber)}</TableCellBelowAddressLine>
            <TableCellBelowAddressLine
                    isAdditionalLineVisible={shouldRenderAdditionalLine}>{emphasizeDifferenceOrDefault(suggestion.zipCode, searchData.zipCode)}</TableCellBelowAddressLine>
            <TableCellBelowAddressLine
                    isAdditionalLineVisible={shouldRenderAdditionalLine}>{emphasizeDifferenceOrDefault(suggestion.city, searchData.city)}</TableCellBelowAddressLine>
            <TableCellBelowAddressLine
                    isAdditionalLineVisible={shouldRenderAdditionalLine}>{emphasizeDifferenceOrDefault(suggestion.country?.value, searchData.country?.value)}</TableCellBelowAddressLine>
            {!shouldRenderAdditionalLine &&
                    <TableCell><SelectAddressButton suggestion={suggestion}
                                                    onAddressSelected={onAddressSelected}/></TableCell>}
        </TableRow>
    </>);
};

const TableCellWithUnverifiedAddressWithWarning = styled(TableCell)({
    backgroundColor: '#ffdca8',
});

const TableCellWithUnverifiedAddress = styled(TableCellWithUnverifiedAddressWithWarning)({
    paddingTop: 0,
});

interface TableRowWithUnverifiedAddressProps {
    shouldRenderAdditionalLine: boolean;
    searchData: AddressSearchFormData;

    onAddressSelected(suggestion: AddressSuggestion): void;
}

const TableRowWithUnverifiedAddress: FC<TableRowWithUnverifiedAddressProps> = ({
                                                                                   shouldRenderAdditionalLine,
                                                                                   searchData,
                                                                                   onAddressSelected,
                                                                               }) => {
    const {t} = useTranslation();

    const unverifiedAddress = {
        ...searchData as AddressSuggestion,
        verified: false,
    };

    return (<>
        <TableRow>
            <TableCellWithUnverifiedAddressWithWarning colSpan={6} sx={{
                borderBottom: 'none',
            }}>
                <Alert severity={'warning'}>{t('address.picker.dialog.unverifiedAddress.warningText')}</Alert>
            </TableCellWithUnverifiedAddressWithWarning>
        </TableRow>
        {shouldRenderAdditionalLine && <TableRow>
            <TableCellWithUnverifiedAddress colSpan={6} sx={{
                paddingTop: 0,
                paddingBottom: 0
            }}>{searchData.additionalLine}</TableCellWithUnverifiedAddress>
        </TableRow>}
        <TableRow>
            <TableCellWithUnverifiedAddress>{searchData.street}</TableCellWithUnverifiedAddress>
            <TableCellWithUnverifiedAddress>{searchData.houseNumber}</TableCellWithUnverifiedAddress>
            <TableCellWithUnverifiedAddress>
                <ValueOrMissingInformation value={searchData.zipCode}/>
            </TableCellWithUnverifiedAddress>
            <TableCellWithUnverifiedAddress>
                <ValueOrMissingInformation value={searchData.city}/>
            </TableCellWithUnverifiedAddress>
            <TableCellWithUnverifiedAddress>{searchData.country?.value}</TableCellWithUnverifiedAddress>
            <TableCellWithUnverifiedAddress width={120}>
                <MissingDataTextOrSelectAddressButton address={unverifiedAddress}
                                                      button={
                                                          <SelectAddressButton suggestion={unverifiedAddress}
                                                                               onAddressSelected={onAddressSelected}/>
                                                      }/>
            </TableCellWithUnverifiedAddress>
        </TableRow>
    </>);
};

const MissingElement = styled('em')(props => ({
    color: props.theme.palette.warning.main,
}));

interface ValueOrMissingInformationProps {
    value: string | undefined | null;
}

const ValueOrMissingInformation: FC<ValueOrMissingInformationProps> = ({value}) => {
    const {t} = useTranslation();
    if (value) {
        return (<>{value}</>);
    }
    return <MissingElement>{t('address.picker.dialog.unverifiedAddress.missingInformation')}</MissingElement>;
};

function isSearchDataEqualToAddressSuggestion(searchData: AddressSearchFormData, suggestion: AddressSuggestion) {
    return equalsIgnoreCase(searchData.street, suggestion.street)
            && equalsIgnoreCase(searchData.houseNumber, suggestion.houseNumber)
            && equalsIgnoreCase(searchData.zipCode, suggestion.zipCode)
            && equalsIgnoreCase(searchData.city, suggestion.city)
            && equalsIgnoreCase(searchData.country?.key, suggestion.country?.key.toString());
}

function mapAddressSuggestionToAddress(suggestion: AddressSuggestion, includeAdditionalLine: boolean): Address | AddressWithAdditionalLine {
    return {
        additionalLine: !includeAdditionalLine ? undefined : (suggestion.additionalLine ?? ''),
        street: suggestion.street ?? '',
        houseNumber: suggestion.houseNumber ?? '',
        zipCode: suggestion.zipCode ?? '',
        city: suggestion.city ?? '',
        country: suggestion.country ?? null,
        verified: suggestion.verified,
    };
}

function emphasizeDifferenceOrDefault(suggestedValue: string | undefined | null, searchedValue: string | undefined) {
    return suggestedValue === searchedValue ? suggestedValue : <b>{suggestedValue}</b>;
}

function equalsIgnoreCase(text1: string | undefined, text2: string | undefined) {
    return text1?.toLowerCase() === text2?.toLowerCase();
}
