import CreditScoreIcon from '@mui/icons-material/CreditScore';
import FeedIcon from '@mui/icons-material/Feed';
import SummarizeIcon from '@mui/icons-material/Summarize';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import {AppContent} from '../../components/AppContent';
import {ErrorPanel} from '../../components/ErrorPanel';
import {usePageLoaderContext} from '../../components/PageLoader';
import {RoutingTabs} from '../../components/tab/RoutingTabs';
import {useGetApplicationByApplicationNumber} from '../../domain/application/service';
import {UserRole} from '../../domain/user/model';
import {useUserContext} from '../../domain/user/UserProvider';
import {ROUTES} from '../../router';
import {ApplicationCrefoTab} from './ApplicationCrefoTab';
import {ApplicationEditTab} from './ApplicationEditTab';
import {MainProfiCardApplicationOverviewTab} from './MainProfiCardApplicationOverviewTab';
import {ApplicationPageLabel} from './ApplicationPageLabel';
import {AdditionalProfiCardApplicationOverviewTab} from './AdditionalProfiCardApplicationOverviewTab';
import HistoryIcon from '@mui/icons-material/History';
import {ApplicationHistoryTab} from './ApplicationHistoryTab';
import {
    AdditionalProfiCardApplication,
    isAdditionalProfiCardApplication
} from '../../domain/application/profiCard/additional/model';
import {Originator, useOriginatorContext} from '../../utils/OriginatorProvider';
import {hasAnyRole, hasRole} from '../../domain/user/utils';
import {BaseMainProfiCardApplicationUnion} from '../../domain/application/profiCard/model';
import {isMainProfiCardApplication} from '../../domain/application/profiCard/utils';
import {MainProjektWeltApplicationOverviewTab} from './MainProjektWeltApplicationOverviewTab';
import {
    isMainProjektWeltApplication,
    MainProjektWeltApplication
} from '../../domain/application/projektWelt/main/model';
import {
    AdditionalProjektWeltApplication,
    isAdditionalProjektWeltApplication
} from '../../domain/application/projektWelt/additional/model';
import {AdditionalProjektWeltApplicationOverviewTab} from './AdditionalProjektWeltApplicationOverviewTab';
import {ApiError} from '../../api';
import {ApplicationSchufaTab} from './ApplicationSchufaTab';

export const isApiErrorType = (obj: any): obj is ApiError => {
    if (obj === null || typeof obj !== 'object') {
        return false;
    }
    return 'message' in obj && 'status' in obj && 'body' in obj;
};

export default function ApplicationDetailsPage() {
    const {setContextApplicationType} = useOriginatorContext();
    const {user} = useUserContext();
    const {t} = useTranslation();
    const {applicationNumber: applicationNumberParam} = useParams();
    const applicationNumber = validateApplicationNumber(applicationNumberParam);

    const {
        application,
        isError,
        isNotFoundError,
        isFetching,
        error
    } = useGetApplicationByApplicationNumber(applicationNumber);
    const {setIsLoading} = usePageLoaderContext();

    useEffect(() => {
        if (isError) {
            setIsLoading(false);
        } else {
            setIsLoading(isFetching);
        }
    }, [setIsLoading, isFetching, isError]);

    const applicationType = application?.applicationType;
    useEffect(() => {
        if (applicationType !== undefined) {
            setContextApplicationType(applicationType);
        }
    }, [applicationType, setContextApplicationType]);

    const isForbiddenError = isApiErrorType(error) && error.status === 403;
    const isOtherError = isError && !isNotFoundError && !isForbiddenError;

    if (!isFetching && isForbiddenError) {
        return <AppContent
                label=""><ErrorPanel
                title={t('application.error.forbidden', {applicationNumber: applicationNumberParam})}
                message={<Link
                        to={ROUTES.applications.list}>{t('application.error.goToApplicationList')}</Link>}/></AppContent>;
    }

    if (!isFetching && (applicationType == undefined || !Originator.current || isNotFoundError)) {
        console.warn(`ApplicationType or originator is unavailable: ${applicationType}, ${Originator.current}
  Or application with the number: ${applicationNumber} was not found`);
        return <AppContent
                label=""><ErrorPanel
                title={t('application.error.notFound', {applicationNumber: applicationNumberParam})}
                message={<Link
                        to={ROUTES.applications.list}>{t('application.error.goToApplicationList')}</Link>}/></AppContent>;
    }

    if (!isFetching && isOtherError) {
        return <AppContent
                label=""><ErrorPanel title={t('application.error.otherError')}
                                     message={<Link
                                             to={ROUTES.applications.list}>{t('application.error.goToApplicationList')}</Link>}/></AppContent>;
    }

    return (<AppContent label={application === undefined ? '' : <ApplicationPageLabel application={application}/>}>
        <RoutingTabs tabDefinitions={[
            {
                url: ROUTES.applications.edit(applicationNumber),
                label: t('application.details.tab.form'),
                icon: <FeedIcon/>,
                content: <ApplicationEditTab application={application}
                                             applicationNumber={applicationNumber}/>,
            },
            {
                url: ROUTES.applications.overview(applicationNumber),
                label: t('application.details.tab.overview'),
                isVisible: hasRole(user, UserRole.KNISTR) && isMainProfiCardApplication(application),
                icon: <SummarizeIcon/>,
                content: application === undefined ? null :
                        <MainProfiCardApplicationOverviewTab
                                application={application as BaseMainProfiCardApplicationUnion}/>,
            },
            {
                url: ROUTES.applications.overview(applicationNumber),
                label: t('application.details.tab.overview'),
                isVisible: hasRole(user, UserRole.KNISTR) && isAdditionalProfiCardApplication(application),
                icon: <SummarizeIcon/>,
                content: application === undefined ? null :
                        <AdditionalProfiCardApplicationOverviewTab
                                application={application as AdditionalProfiCardApplication}/>,
            },
            {
                url: ROUTES.applications.overview(applicationNumber),
                label: t('application.details.tab.overview'),
                isVisible: hasRole(user, UserRole.KNISTR) && isMainProjektWeltApplication(application),
                icon: <SummarizeIcon/>,
                content: application === undefined ? null :
                        <MainProjektWeltApplicationOverviewTab
                                application={application as MainProjektWeltApplication}/>,
            },
            {
                url: ROUTES.applications.overview(applicationNumber),
                label: t('application.details.tab.overview'),
                isVisible: hasRole(user, UserRole.KNISTR) && isAdditionalProjektWeltApplication(application),
                icon: <SummarizeIcon/>,
                content: application === undefined ? null :
                        <AdditionalProjektWeltApplicationOverviewTab
                                application={application as AdditionalProjektWeltApplication}/>,
            },
            {
                url: ROUTES.applications.crefo(applicationNumber),
                label: t('application.details.tab.creditProcessing'),
                isVisible: hasRole(user, UserRole.KNISTR) && isMainProfiCardApplication(application),
                icon: <CreditScoreIcon/>,
                content: !isMainProfiCardApplication(application) ? null :
                        <ApplicationCrefoTab application={application}/>,
            },
            {
                url: ROUTES.applications.schufa(applicationNumber),
                label: t('application.details.tab.creditProcessing'),
                isVisible: hasRole(user, UserRole.KNISTR) && isMainProjektWeltApplication(application),
                icon: <CreditScoreIcon/>,
                content: !isMainProjektWeltApplication(application) ? null :
                        <ApplicationSchufaTab application={application}
                                              applicationNumber={applicationNumber}/>,
            },
            {
                url: ROUTES.applications.history(applicationNumber),
                label: t('application.details.tab.history'),
                isVisible: hasAnyRole(user, [UserRole.HORNBACH_CENTRAL, UserRole.KNISTR]) ?? false,
                icon: <HistoryIcon/>,
                content: application === undefined ? null :
                        <ApplicationHistoryTab applicationNumber={applicationNumber}/>,
            },
        ]}/>
    </AppContent>);
}

export function validateApplicationNumber(applicationNumber: string | undefined) {
    if (applicationNumber === undefined) {
        throw new Error('Application number must be defined!');
    }
    return applicationNumber;
}
