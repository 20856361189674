import {z} from 'zod';

export const invalidOptionSchema = z.object({
    value: z.string(),
});

export type InvalidOption = z.infer<typeof invalidOptionSchema>;

export function isInvalidOption(obj: any) {
    return invalidOptionSchema.safeParse(obj).success;
}

export function createInvalidOption(value: string): InvalidOption {
    return {value};
}
