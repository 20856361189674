import {z} from 'zod';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {moneySchema} from '../money/model';
import {BaseAdditionalProfiCardApplicationUnion, BaseMainProfiCardApplicationUnion} from './profiCard/model';
import {AdditionalProjektWeltApplication} from './projektWelt/additional/model';
import {MainProjektWeltApplication} from './projektWelt/main/model';

export enum ApplicationStatus {
    CREATED = 'CREATED',
    RECORDED = 'RECORDED',
    LEGITIMIZED = 'LEGITIMIZED',
    CREDIT_CHECK = 'CREDIT_CHECK',
    NEEDS_MANUAL_INTERVENTION = 'NEEDS_MANUAL_INTERVENTION',
    REJECTED = 'REJECTED',
    READY = 'READY',
    SUCCESSFUL_COMPLETED = 'SUCCESSFUL_COMPLETED',
    WITHDRAWN = 'WITHDRAWN',
}

const creationDateSchema = z.string().optional();
export type CreationDate = z.infer<typeof creationDateSchema>;

export const baseApplicationSchema = z.object({
    applicationNumber: z.string().optional(),
    version: z.number(),
    status: z.nativeEnum(ApplicationStatus),
    processingErrorReason: z.nativeEnum(ApplicationProcessingErrorReasonDto).nullable().optional(),
    creationDate: creationDateSchema.optional().nullable(),
    accountNumber: z.string().optional().nullable(),
    cardNumber: z.string().optional().nullable(),
    accountLimit: moneySchema,
});
export type BaseApplication = BaseMainProfiCardApplicationUnion | BaseAdditionalProfiCardApplicationUnion | MainProjektWeltApplication | AdditionalProjektWeltApplication;

export type ApplicationNumberAndVersion = Pick<BaseApplication, 'applicationNumber' | 'version'>;
