/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum GrossHouseholdIncomePerYearDto {
    UP_TO_18000_EUR = 'UP_TO_18000_EUR',
    UP_TO_30000_EUR = 'UP_TO_30000_EUR',
    UP_TO_45000_EUR = 'UP_TO_45000_EUR',
    OVER_45000_EUR = 'OVER_45000_EUR',
}
