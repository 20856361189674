import {MandatoryChildren} from '../utils/utils';
import {Box} from '@mui/material';
import React, {PropsWithChildren} from 'react';

interface ActiveBoxProps extends MandatoryChildren {
    isActive: boolean;
}

export function ActiveBox({isActive, children}: PropsWithChildren<ActiveBoxProps>) {
    return (<Box sx={{
        opacity: isActive ? '0.4' : undefined,
        position: 'relative',
    }}>
        {isActive && <Box sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: '999999',
        }}>
        </Box>}
        {children}
    </Box>);
}
