import {FieldValues} from 'react-hook-form/dist/types';
import {FieldPath, useFormContext} from 'react-hook-form';
import {useEffect} from 'react';

export function useClearDependentFormFields<TFieldValues extends FieldValues = FieldValues>(sourcePath: FieldPath<TFieldValues>,
                                                                                            dependantPath: FieldPath<TFieldValues>) {
    const {watch, setValue} = useFormContext<TFieldValues>();
    const sourceValue = watch(sourcePath);
    useEffect(() => {
        setValue(dependantPath, null as any);
    }, [setValue, sourceValue, dependantPath]);
}
