import {Container, Grid, Toolbar} from '@mui/material';
import React from 'react';
import {BackButton} from './form/BackButton';
import {useStartPageUrl} from '../router';

export function AppBreadcrumbs() {
    const {isDefaultRoute} = useStartPageUrl();

    return (<>
        <Grid sx={theme => ({
            backgroundColor: theme.palette.background.paper,
            zIndex: 100,
            position: 'fixed',
            width: '100%',
            height: '3rem'
        })}>
            <Container>
                <Grid container>
                    <Grid item>
                        {!isDefaultRoute && <BackButton/>}
                    </Grid>
                </Grid>
            </Container>
        </Grid>
        <Toolbar/>
    </>);
}
