import React, {PropsWithChildren, ReactNode, useCallback, useMemo, useState} from 'react';
import {MandatoryChildren} from '../../utils/utils';
import {ApiError, ApplicationProcessingErrorReasonDto} from '../../api';
import {useSnackbar} from '../../components/SnackbarHelper';
import {useTranslation} from 'react-i18next';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {isProcessCreditCheckFailureResult, ProcessCreditCheckFailureResult} from './service';

export type ProcessResponseErrorContextType = {
    processModificationResponseError(reason: ApiError, applicationNumber: string | undefined | null): void;
};

export const ProcessResponseErrorContext = React.createContext<ProcessResponseErrorContextType>({} as ProcessResponseErrorContextType);

export function ProcessResponseErrorContextProvider({children}: PropsWithChildren<MandatoryChildren>) {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const [dialog, setDialog] = useState<ReactNode | undefined>();

    const closeDialog = () => {
        setDialog(undefined);
    };

    const processModificationResponseError = useCallback((reason: ApiError | ProcessCreditCheckFailureResult, applicationNumber: string | undefined | null) => {
        if (reason.status === 404) {
            showSnackbar({
                message: t('application.error.notFound', {applicationNumber}),
                severity: 'error',
            });
        } else if (reason.status === 400) {
            // bad request -> nothing to do here, errors will be presented on the form
        } else if (reason.status === 409) {
            showSnackbar({
                message: t('application.error.conflict', {applicationNumber}),
                severity: 'error',
            });
            setDialog(<ConflictDialog onCloseDialog={closeDialog}/>);
        } else if (isProcessCreditCheckFailureResult(reason)) {
            switch (reason.reason) {
                case ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE:
                    showSnackbar({
                        message: t('application.actions.retryCreateContract.errorMessage'),
                        severity: 'error',
                    });
                    break;
                case ApplicationProcessingErrorReasonDto.DUPLICATED:
                    showSnackbar({
                        message: t('application.history.processingErrorReason.DUPLICATED'),
                        severity: 'warning',
                    });
                    break;
                case ApplicationProcessingErrorReasonDto.DUPLICATED_COMMUNICATION_FAILURE:
                    showSnackbar({
                        message: t('application.history.processingErrorReason.DUPLICATED_COMMUNICATION_FAILURE'),
                        severity: 'error',
                    });
                    break;
                case ApplicationProcessingErrorReasonDto.INVALID_ADDRESS:
                    showSnackbar({
                        message: t('application.history.processingErrorReason.INVALID_ADDRESS'),
                        severity: 'warning',
                    });
                    break;
                case ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION:
                    showSnackbar({
                        message: t('application.history.processingErrorReason.CREDIT_INSTITUTION'),
                        severity: 'warning',
                    });
                    break;
                case ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION_SEARCH_FAILED:
                    showSnackbar({
                        message: t('application.history.processingErrorReason.CREDIT_INSTITUTION_SEARCH_FAILED'),
                        severity: 'error',
                    });
                    break;
            }
        } else {
            showSnackbar({
                message: t('application.error.otherError', {applicationNumber}),
                severity: 'error',
            });
        }
    }, [showSnackbar, t]);

    const processResponseErrorContextValue = useMemo(() => ({processModificationResponseError}), [processModificationResponseError]);

    return (<ProcessResponseErrorContext.Provider value={processResponseErrorContextValue}>
        {children}
        <Dialog open={dialog !== undefined} onClose={closeDialog}>
            {dialog}
        </Dialog>
    </ProcessResponseErrorContext.Provider>);
}

export function useProcessModificationResponseError() {
    return React.useContext(ProcessResponseErrorContext).processModificationResponseError;
}

interface DialogProps {
    onCloseDialog(): void;
}

function ConflictDialog({onCloseDialog}: PropsWithChildren<DialogProps>) {
    const {t} = useTranslation();

    const onRefreshPage = () => {
        location.reload();
    };

    return (<>
        <DialogTitle>{t('application.error.dialog.conflict.title')}</DialogTitle>
        <DialogContent>{t('application.error.dialog.conflict.content')}</DialogContent>
        <DialogActions>
            <Button onClick={onCloseDialog}>{t('common.dialog.cancel')}</Button>
            <Button onClick={onRefreshPage}
                    autoFocus
                    color={'secondary'}
                    variant={'contained'}><RefreshIcon/>{t('application.error.dialog.conflict.button.refresh')}</Button>
        </DialogActions>
    </>);
}
