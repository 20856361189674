import {RefinementCtx, z} from 'zod';
import {ApplicationTypeDto, GrossHouseholdIncomePerYearDto} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {toSchemaWithoutValidationRules} from '../../../../utils/toSchemaWithoutValidationRules';
import {getDateSchema, getSchemaForDateOfBirthAndAdultCheck, maxToday} from '../../../../utils/utils';
import {addressWithAdditionalLineSchema} from '../../../auxiliary/model';
import {createEmptyAddressWithAdditionalLine} from '../../../auxiliary/service';
import {optionalEmailSchema} from '../../../common/model';
import {CountryUtils} from '../../../country';
import {moneySchema} from '../../../money/model';
import {optionalOptionEntry} from '../../../reference/model';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {User} from '../../../user/model';
import {addValidationErrorToPath} from '../../../util';
import {bankDetailsSchema, ibanSchema, PaymentMethod} from '../../iban/model';
import {marketDataSchema} from '../../marketData/model';
import {ApplicationStatus, BaseApplication} from '../../model';
import {baseProjektWeltApplicationSchema,} from '../model';

const cardHolderSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getSchemaForDateOfBirthAndAdultCheck(),
    placeOfBirth: z.string().max(40),
    nationality: optionalOptionEntry,
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
    phoneNumber: z.string().max(38).nullable().optional(),
    address: z.optional(addressWithAdditionalLineSchema),
});
type MainProjektWeltCardHolder = z.infer<typeof cardHolderSchema>;

const legitimationSchema = z.object({
    idCardNumber: z.string().max(30),
    issuingAuthority: z.string().max(40),
    employeeName: z.string(),
    dateOfIssue: getDateSchema(false, zodDate => zodDate.max(maxToday())),
});
type MainProjektWeltLegitimation = z.infer<typeof legitimationSchema>;


export const additionalCardHolderSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(27).optional(),
    lastName: z.string().max(27).optional(),
    dateOfBirth: z.date().optional(),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38).optional(),
});

export type AdditionalCardHolder = z.infer<typeof additionalCardHolderSchema>;

const optionalAdditionalCardHolderSchema = toSchemaWithoutValidationRules(additionalCardHolderSchema);

export const mainProjektWeltApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROJEKT_WELT_MAIN),
    cardHolder: cardHolderSchema,
    grossHouseholdIncomePerYear: z.nativeEnum(GrossHouseholdIncomePerYearDto).optional().nullable(),
    bankDetails: bankDetailsSchema.optional(),
    marketData: marketDataSchema.optional(),
    legitimation: legitimationSchema.optional(),
    consents: z.boolean().optional().transform(value => !!value),
    advertisementConsent: z.boolean().optional().transform(value => !!value),
    creditValidatorLimit: moneySchema,
    hasAdditionalCardData: z.boolean().optional(),
    additionalCardData: z.optional(optionalAdditionalCardHolderSchema),
    ignoreDuplicates: z.boolean().optional().transform(value => !!value),
})
    .merge(baseProjektWeltApplicationSchema)
    .superRefine((data, ctx) => {
        if (data.hasAdditionalCardData) {
            addValidationErrorToPath(['additionalCardOwner'], additionalCardHolderSchema.safeParse(data.additionalCardData), ctx);
        }
    })
    .superRefine(async (data, ctx) => {
        await validateIbanNumber(data, ctx);
    });
export type MainProjektWeltApplication = z.infer<typeof mainProjektWeltApplicationSchema>;

async function validateIbanNumber(data: MainProjektWeltApplication, ctx: RefinementCtx) {
    if (data.bankDetails /* it is needed only for compiler's type detection */) {
        const bankDetailsKey = 'bankDetails';
        const ibanKey = 'iban';
        const iban = data[bankDetailsKey][ibanKey];
        const validationResult = await ibanSchema.safeParseAsync(iban);
        addValidationErrorToPath([bankDetailsKey, ibanKey], validationResult, ctx);
    }
}

export function isMainProjektWeltApplication(application: BaseApplication | undefined): application is MainProjektWeltApplication {
    return application?.applicationType === ApplicationTypeDto.PROJEKT_WELT_MAIN;
}

export const mainProjektWeltLegitimizationFormSchema = z.object({
    principalCardholderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    accountOwnerSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    employeeSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});
export const mainProjektWeltWithAdditionalCardLegitimizationFormSchema = z.object({
    principalCardholderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    accountOwnerSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    employeeSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    additionalCardHolderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type MainProjektWeltLegitimizationForm = z.infer<typeof mainProjektWeltLegitimizationFormSchema>;

export async function createEmptyMainProjektWeltApplication(user: User): Promise<MainProjektWeltApplication> {
    const applicationType = ApplicationTypeDto.PROJEKT_WELT_MAIN;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const references = await getReferences(originator);
    return {
        applicationType: applicationType,
        version: 0,
        status: ApplicationStatus.CREATED,
        cardHolder: {
            firstName: '',
            lastName: '',
            placeOfBirth: '',
            cellPhoneNumber: '',
            address: {
                ...createEmptyAddressWithAdditionalLine(),
                country: {
                    key: CountryUtils.DE.code,
                    value: 'Deutschland',
                },
            },
        },
        grossHouseholdIncomePerYear: null,
        bankDetails: {
            paymentMethod: PaymentMethod.DEBIT_CHARGE,
            iban: '',
        },
        marketData: {
            masterStore: findOptionEntryByKey(references.getShops(applicationType), user.store?.key),
            pinForWebShop: true,
        },
        legitimation: {
            employeeName: [user.firstName, user.lastName].filter(str => !!str).join(' ') ?? user.userName,
            idCardNumber: '',
            issuingAuthority: '',
            dateOfIssue: null,
        },
        consents: false,
        advertisementConsent: true,
        additionalCardData: {
            firstName: '',
            lastName: '',
            cellPhoneNumber: '',
            email: ''
        },
        ignoreDuplicates: false,
    };
}
