import React, {PropsWithChildren, ReactNode} from 'react';
import {Box, IconButton, Popover} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

interface InfoIconPopoverProps {
    content: string | ReactNode;
}

export function InfoIconPopover({content}: PropsWithChildren<InfoIconPopoverProps>) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (<>
        <IconButton aria-describedby={id} aria-label="info" onClick={handleClick}>
            <InfoIcon/>
        </IconButton>
        <Popover id={id}
                 open={open}
                 anchorEl={anchorEl}
                 onClose={handleClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                 }}
                 sx={{minWidth: '300px', maxWidth: '800px'}}
        >
            <Box sx={theme => ({
                margin: theme.spacing(1),
            })}>{content}</Box>
        </Popover>
    </>);
}
