/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ApplicationStatusDto {
    CREATED = 'CREATED',
    RECORDED = 'RECORDED',
    LEGITIMIZED = 'LEGITIMIZED',
    CREDIT_CHECK = 'CREDIT_CHECK',
    NEEDS_MANUAL_INTERVENTION = 'NEEDS_MANUAL_INTERVENTION',
    REJECTED = 'REJECTED',
    READY = 'READY',
    SUCCESSFUL_COMPLETED = 'SUCCESSFUL_COMPLETED',
    WITHDRAWN = 'WITHDRAWN',
}
