import {Grid, Typography} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {User} from './model';
import {UserStatusLabel} from './UserStatusLabel';

interface UserPageLabelProps {
    user: User;
}

export const UserPageLabel: FC<UserPageLabelProps> = ({user}) => {
    const {t} = useTranslation();
    const username = user?.userName;
    const isPersistent = username !== undefined;


    return (<Grid container sx={theme => ({marginBottom: theme.spacing(1)})}>
        <Grid item xs={6}>
            <Typography variant="h4">
                {isPersistent ? t('user.management.form.header.edit', {username}) : t('user.management.form.header.new')}
            </Typography>
        </Grid>
        {isPersistent &&
            <Grid item>
                <UserStatusLabel isActive={user.isActive}/>
            </Grid>}
    </Grid>);
};
