import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppAmountField} from '../../../../../components/form/AppAmountField';
import {AppCheckboxField} from '../../../../../components/form/AppCheckboxField';
import {FormRow} from '../../../../../components/form/FormRow';
import {FormSection} from '../../../../../components/form/FormSection';
import {IbanField} from '../../../iban/IbanField';
import {
    createEmptyMainProfiCardDeApplication,
    MainProfiCardDeApplication,
    mainProfiCardDeApplicationSchema,
    MainProfiCardDeLegitimizationDebitChargeForm,
    mainProfiCardDeLegitimizationDebitChargeFormSchema,
    mainProfiCardDeLegitimizationTransferFormSchema
} from './model';
import {PaymentMethodRadioButtonField} from '../PaymentMethodRadioButtonField';
import {ReadOnlyProps} from '../../../ApplicationForm';
import {ApplicationFormProps} from '../../../../../pages/application/NewApplicationPage';
import {
    BillingAddressFormSection,
    CardDispatchFormSection,
    CompanyDataFormSection,
    CompanyOwnerFormSection,
    LegitimationFormSection,
    MainCardholderFormSection
} from '../commonMainProfiCardApplicationFormComponents';
import {SmartSignUpWithApplication} from '../SmartSignUpWithApplication';
import {ApplicationTypeDto} from '../../../../../api';
import {PaymentMethod} from '../../../iban/model';
import {ConsentsFormSection} from '../../../ConsentsFormSection';
import {MarketDataFormSection} from '../../../marketData/MarketDataFormSection';

interface MainProfiCardDeApplicationFormProps extends ApplicationFormProps<MainProfiCardDeApplication> {
}

export function MainProfiCardDeApplicationForm(props: MainProfiCardDeApplicationFormProps) {
    return (<SmartSignUpWithApplication<MainProfiCardDeApplication>
            createEmptyMainProfiCardApplication={createEmptyMainProfiCardDeApplication}
            application={props.application}
            applicationSchema={mainProfiCardDeApplicationSchema}
            afterDraftSaved={props.afterDraftSaved}
            afterLegitimized={props.afterLegitimized}
            renderFields={isReadOnly =>
                    <MainProfiCardDeApplicationFields isReadOnly={isReadOnly} application={props.application}/>}
            showLegitimizeDialog={props.showLegitimizeDialog}
            legitimizationFormSchema={getMainProfiCardLegitimizationSchema}
            renderLegitimizationCheckList={application => <LegitimizeCheckList application={application}/>}
    />);
}

function getMainProfiCardLegitimizationSchema(application: MainProfiCardDeApplication) {
    const isAccountOwnerSignatureMandatory = application.bankDetails?.paymentMethod === PaymentMethod.DEBIT_CHARGE;
    return isAccountOwnerSignatureMandatory ? mainProfiCardDeLegitimizationDebitChargeFormSchema : mainProfiCardDeLegitimizationTransferFormSchema;
}

interface MainProfiCardApplicationFieldsProps {
    isReadOnly: boolean;
    application: MainProfiCardDeApplication;
}

const MainProfiCardDeApplicationFields: FC<MainProfiCardApplicationFieldsProps> = ({isReadOnly, application}) => (<>
    <CompanyDataFormSection readOnly={isReadOnly} application={application}/>
    <BillingAddressFormSection readOnly={isReadOnly}/>
    <MainCardholderFormSection readOnly={isReadOnly}/>
    <CompanyOwnerFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROFI_CARD_DE_MAIN}/>
    <BankDetailsFormSection readOnly={isReadOnly}/>
    <MarketDataFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROFI_CARD_DE_MAIN}/>
    <CardDispatchFormSection readOnly={isReadOnly}/>
    <LegitimationFormSection readOnly={isReadOnly}/>
    <ConsentsFormSection readOnly={isReadOnly} applicationType={application.applicationType}/>
</>);

interface LegitimizeCheckListProps {
    application: MainProfiCardDeApplication;
}

const LegitimizeCheckList: FC<LegitimizeCheckListProps> = (props) => {
    const {t} = useTranslation();
    const isAccountOwnerSignatureMandatory = props.application.bankDetails?.paymentMethod === PaymentMethod.DEBIT_CHARGE;

    return (<>
        <AppCheckboxField<MainProfiCardDeLegitimizationDebitChargeForm> fieldPath={'companySignature'}
                                                                        label={t('application.dialog.legitimization.companySignature')}/>
        <AppCheckboxField<MainProfiCardDeLegitimizationDebitChargeForm> fieldPath={'principalCardholderSignature'}
                                                                        label={t('application.dialog.legitimization.mainCardHolderSignature')}/>
        {isAccountOwnerSignatureMandatory &&
                <AppCheckboxField<MainProfiCardDeLegitimizationDebitChargeForm> fieldPath={'accountOwnerSignature'}
                                                                                label={t('application.dialog.legitimization.accountOwnerSignature')}/>}
        <AppCheckboxField<MainProfiCardDeLegitimizationDebitChargeForm> fieldPath={'employeeSignature'}
                                                                        label={t('application.dialog.legitimization.employeeSignature')}/>
    </>);
};

const BankDetailsFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();

    return (<FormSection title={t('application.form.bankDetails.main.sectionTitle')}>
        <FormRow>
            <PaymentMethodRadioButtonField required={true} fieldPath={'bankDetails.paymentMethod'}
                                           readOnly={props.readOnly}/>
        </FormRow>
        <IbanField readOnly={props.readOnly}/>
        <FormRow>
            <AppAmountField<MainProfiCardDeApplication> breakpoint={3}
                                                        label={t('application.form.desiredLimit.sectionTitle')}
                                                        fieldPath={'desiredLimit'}
                                                        readOnly={props.readOnly}
                                                        required={true}
            />
        </FormRow>
    </FormSection>);
};
