import React, {PropsWithChildren} from 'react';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppAutocompleteField} from '../../components/form/AppAutocompleteField';
import {ReferenceSelectFieldProps} from '../reference/ReferenceSelectFieldProps';
import {Language} from '../../api';

export function UserPreferredLanguageSelectField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    return (<AppAutocompleteField<string, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                        options={Object.values(Language)}
                                                        getLabel={option => option}
                                                        getId={option => option}
                                                        fieldPath={props.fieldPath}
                                                        disabled={props.disabled}
                                                        label={t('user.management.list.header.preferredLanguage')}
                                                        required={true}
                                                        readOnly={props.readOnly}
                                                        size={props.size}
    />);

}
