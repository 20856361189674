import {Backdrop, CircularProgress} from '@mui/material';
import React, {PropsWithChildren, useMemo, useState} from 'react';
import {MandatoryChildren} from '../utils/utils';

interface PageLoaderProps extends MandatoryChildren {
    isLoading: boolean;
}

export function PageLoader(props: PropsWithChildren<PageLoaderProps>) {
    return (
        <div style={{position: 'relative'}}>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 999,
                }}
                open={props.isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            {props.children}
        </div>);
}

export type PageLoaderContextType = {
    isLoading: boolean;
    setIsLoading(isLoading: boolean): void;
};

export const PageLoaderContext = React.createContext<PageLoaderContextType>({} as PageLoaderContextType);

export function PageLoaderContextProvider({children}: PropsWithChildren<MandatoryChildren>) {
    const [isLoading, setIsLoading] = useState(false);
    const pageLoaderContextValue = useMemo(() => ({isLoading, setIsLoading}), [isLoading, setIsLoading]);

    return (<PageLoaderContext.Provider value={pageLoaderContextValue}>
        <PageLoader isLoading={isLoading}>
            {children}
        </PageLoader>
    </PageLoaderContext.Provider>);
}

export function usePageLoaderContext(): PageLoaderContextType {
    return React.useContext(PageLoaderContext);
}
