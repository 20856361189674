import {useTranslation} from 'react-i18next';
import {ReferenceSelectFieldProps} from '../../../../reference/ReferenceSelectFieldProps';
import {FieldValues} from 'react-hook-form/dist/types';
import {AppAutocompleteField} from '../../../../../components/form/AppAutocompleteField';
import React, {PropsWithChildren} from 'react';
import {CorrespondenceLanguageDto} from '../../../../../api';

const correspondenceLanguages = [CorrespondenceLanguageDto.GERMAN, CorrespondenceLanguageDto.FRENCH];

export function CorrespondenceLanguageSelect<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    return <AppAutocompleteField<CorrespondenceLanguageDto, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                                          options={correspondenceLanguages}
                                                                          getLabel={option => t('application.form.correspondenceLanguage.option.' + (option ?? 'empty') as any)}
                                                                          getId={option => option}
                                                                          fieldPath={props.fieldPath}
                                                                          disabled={props.disabled}
                                                                          label={t('application.form.correspondenceLanguage.label')}
                                                                          required={props.required}
                                                                          size={props.size}
                                                                          readOnly={props.readOnly}
    />;

}
