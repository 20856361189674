import {useTranslation} from 'react-i18next';
import {CardActions, CardContent, CardHeader, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React, {FC} from 'react';
import {AppCard} from '../../components/SectionCard';
import {RetryCreateContractButton} from './ActionButtons';
import {BaseApplication} from './model';

interface CreateContractFailureSectionProps {
    application: BaseApplication;
}

export const CreateContractFailureSection: FC<CreateContractFailureSectionProps> = ({application}) => {
    const {t} = useTranslation();

    return (<AppCard>
        <CardHeader title={
            <Typography variant={'h5'}
                        sx={theme => ({color: theme.palette.error.main})}>
                <ErrorIcon/><span> {t('application.overview.createContract.title')}</span>
            </Typography>}
        />
        <CardContent>
            <Typography variant={'subtitle1'}
                        sx={theme => ({color: theme.palette.common.black})}>
                <span>{t('application.overview.createContract.message')}</span>
            </Typography>
        </CardContent>
        <CardActions>
            <RetryCreateContractButton application={application}/>
        </CardActions>
    </AppCard>);
};
