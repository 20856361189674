import styled from '@emotion/styled';
import {
    Cancel,
    ContactEmergencyOutlined,
    CurrencyExchangeOutlined,
    DoneAllOutlined,
    DoneOutlined,
    PanToolOutlined,
    RuleOutlined,
    ThumbDownAltOutlined,
    ThumbUpAltOutlined
} from '@mui/icons-material';
import {Chip} from '@mui/material';
import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {ApplicationStatus} from './model';

interface ApplicationStatusLabelProps {
    status?: ApplicationStatus;
    size?: 'medium' | 'small';
}

const StyledChip = styled(Chip)<ApplicationStatusLabelProps>(({size}) => ({
    textTransform: 'uppercase',
    fontSize: size === 'small' ? '0.75rem' : '0.8125rem',
    padding: '0.5rem 0.125rem',

    '& svg': {
        width: size === 'small' ? '0.875rem' : '1.5rem',
        height: size === 'small' ? '0.875rem' : '1.5rem',
    }
}));


export const ApplicationStatusLabel: FC<ApplicationStatusLabelProps> = ({status, size = 'medium'}) => {
    const {t} = useTranslation();
    if (status === undefined) {
        console.log('Application status is empty - no label rendered');
        return null;
    }
    const text = t(`application.status.${status}` as const);

    switch (status) {
        case ApplicationStatus.CREATED:
            return <StyledChip label={text}
                               variant="outlined"
                               color="primary"
                               size={size}
                               icon={<RuleOutlined color="primary"/>}
            />;
        case ApplicationStatus.RECORDED:
            return <StyledChip label={text}
                               variant="outlined"
                               color="warning"
                               size={size}
                               icon={<ThumbUpAltOutlined color="warning"/>}
            />;
        case ApplicationStatus.LEGITIMIZED:
            return <StyledChip label={text}
                               variant="outlined"
                               color="warning"
                               size={size}
                               icon={<ContactEmergencyOutlined color="warning"/>}
            />;
        case ApplicationStatus.CREDIT_CHECK:
            return <StyledChip label={text}
                               variant="outlined"
                               color="info"
                               size={size}
                               icon={<CurrencyExchangeOutlined color="info"/>}
            />;
        case ApplicationStatus.NEEDS_MANUAL_INTERVENTION:
            return <StyledChip label={text}
                               variant="outlined"
                               color="warning"
                               size={size}
                               icon={<PanToolOutlined color="warning"/>}
            />;
        case ApplicationStatus.REJECTED:
            return <StyledChip label={text}
                               variant="outlined"
                               color="error"
                               size={size}
                               icon={<ThumbDownAltOutlined color="error"/>}
            />;
        case ApplicationStatus.READY:
            return <StyledChip label={text}
                               variant="outlined"
                               color="success"
                               size={size}
                               icon={<DoneOutlined color="success"/>}
            />;
        case ApplicationStatus.SUCCESSFUL_COMPLETED:
            return <StyledChip label={text}
                               variant="outlined"
                               color="success"
                               size={size}
                               icon={<DoneAllOutlined color="success"/>}
            />;
        case ApplicationStatus.WITHDRAWN:
            return <StyledChip label={text}
                               variant="outlined"
                               color="success"
                               size={size}
                               icon={<Cancel color="success"/>}
            />;
        default:
            throw new Error(`Invalid application status: ${status}`);
    }
};
