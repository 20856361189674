import {useTranslation} from 'react-i18next';
import {AppTextField} from '../../components/form/AppTextField';
import {trimAndCapitalizeFirstLetter} from '../../utils/utils';
import React, {ReactNode, useState, FC} from 'react';
import {User, userSchema} from './model';
import {FormProvider} from 'react-hook-form';
import {useAppForm} from '../../components/form/useAppForm';
import {FormRow} from '../../components/form/FormRow';
import {SendPasswordUpdateButton, UserStatusButton} from './UserManagementButtons';
import {UserRoleAndLocationFields} from './UserRoleAndLocationFields';
import {UserPreferredLanguageSelectField} from './UserPreferredLanguage';
import {AppCard} from '../../components/SectionCard';
import {Alert, CardActions} from '@mui/material';
import {useSnackbar} from '../../components/SnackbarHelper';
import {usePageLoaderContext} from '../../components/PageLoader';
import {createOrUpdateUser} from './service';
import {processUserModificationResponseError} from './utils';
import {AppFormButton} from '../../components/form/AppFormButton';
import SaveIcon from '@mui/icons-material/Save';
import {useNavigate} from 'react-router-dom';
import {ROUTES} from '../../router';
import {ApiError} from '../../api';
import {ConstraintViolationError} from '../../utils/api';
import {queryClient} from '../../QueryHookClientProvider';

interface UserFormProps {
    user: User;
    readOnly?: boolean;
}

export const UserForm: FC<UserFormProps> = (props) => {
    validateUser(props.user);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const isPersistent = !!props.user.uid;
    const showSnackbarMessage = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const [isUserOperationInProgress, setIsUserOperationInProgress] = useState(false);
    const formData = useAppForm<User>(userSchema, props.user);
    const [errorAlert, setErrorAlert] = useState<ReactNode>();

    const handleSaveUser = (user: User) => {
        setIsLoading(true);
        setIsUserOperationInProgress(true);
        setErrorAlert(undefined);

        const latestUserData = queryClient.getQueryData<User>(['username', user.userName]);
        const mergedUser = {
            ...user,
            isActive: latestUserData?.isActive ?? user.isActive
        };

        formData.wrapPromise(createOrUpdateUser(mergedUser), errorFieldMapper)
                .then(status => {
                    if (status.created) {
                        showSnackbarMessage({
                            message: t('user.management.create.success'),
                            severity: 'success',
                        });
                        navigate(ROUTES.users.edit(status.user?.userName), {replace: true});
                    } else if (status.updated) {
                        showSnackbarMessage({
                            message: t('user.management.update.success'),
                            severity: 'success',
                        });
                    }
                    return status;
                })
                .catch(processUserModificationResponseError(user.userName, showSnackbarMessage))
                .catch((apiError: ApiError) => {
                    if (apiError?.status === 400 && apiError?.body) {
                        const validationErrors = apiError.body as ConstraintViolationError;
                        const userNameError = validationErrors?.errors?.find(field => field.path === 'userName')?.errorCode;
                        setErrorAlert(userNameError ? t(userNameError as any) : t('common.error.form'));
                    }
                })
                .finally(() => {
                    setIsUserOperationInProgress(false);
                    setIsLoading(false);
                });
    };

    const handleSubmit = formData.handleSubmit;
    const processSubmit = (user: User) => {
        handleSaveUser(user);
    };

    return (
            <FormProvider {...formData}>
                <form onSubmit={handleSubmit(processSubmit)}>
                    {errorAlert && <Alert severity={'error'}>{errorAlert}</Alert>}
                    <FormRow>
                        {/*Username*/}
                        <AppTextField<User> breakpoint={3}
                                            required={true}
                                            label={t('user.management.form.username')}
                                            fieldPath={'userName'}
                                            disabled={isPersistent}/>
                        {/*Email*/}
                        <AppTextField<User> breakpoint={3}
                                            required={true}
                                            label={t('common.form.contact.email')}
                                            fieldPath={'emailAddress'}
                                            readOnly={props.readOnly}/>
                    </FormRow>
                    <FormRow>
                        {/*First Name*/}
                        <AppTextField<User> breakpoint={3}
                                            required={true}
                                            label={t('common.form.person.firstName')}
                                            fieldPath={'firstName'}
                                            transformValue={trimAndCapitalizeFirstLetter}
                                            readOnly={props.readOnly}/>

                        {/*Last name*/}
                        <AppTextField<User> breakpoint={3}
                                            required={true}
                                            label={t('common.form.person.lastName')}
                                            fieldPath={'lastName'}
                                            transformValue={trimAndCapitalizeFirstLetter}
                                            readOnly={props.readOnly}/>
                    </FormRow>
                    {/*Role and Location*/}
                    <UserRoleAndLocationFields readOnly={props.readOnly}
                                               label={t('user.management.form.admin.checkbox')}/>
                    <FormRow>
                        <UserPreferredLanguageSelectField<User> breakpoint={3}
                                                                fieldPath={'preferredLanguage'}
                                                                readOnly={props.readOnly}/>
                    </FormRow>
                    <FormRow>
                        {/*Save and Block/Reactivate Buttons*/}
                        <AppCard>
                            <CardActions>
                                <AppFormButton
                                        type={'submit'}
                                        disabled={false}
                                        icon={<SaveIcon/>}
                                        inProgress={isUserOperationInProgress}
                                        color={'secondary'}>{t('application.form.save')}</AppFormButton>
                                {isPersistent && <UserStatusButton user={props.user}/>}
                                {isPersistent && <SendPasswordUpdateButton user={props.user}/>}
                            </CardActions>
                        </AppCard>
                    </FormRow>
                </form>
            </FormProvider>
    );
};

function validateUser(user: User | undefined) {
    if (user === undefined) {
        throw new Error('User must be defined!');
    }
}

function errorFieldMapper(path: string) {
    switch (path) {
        case 'roles':
            return 'role';
        case 'storeId':
            return 'store';
    }
}
