import {z} from 'zod';
import {getSchemaForDateOfBirthAndAdultCheck} from '../../../utils/utils';
import {optionalCardExpirationDateSchema} from '../../auxiliary/model';
import {optionalEmailSchema} from '../../common/model';
import {moneySchema} from '../../money/model';
import {optionalOptionEntry} from '../../reference/model';

export const mainCardAddressSchema = z.object({
    additionalLine: z.string().max(80).optional(),
    street: z.string().max(80),
    houseNumber: z.string().max(19),
    zipCode: z.string().max(19),
    city: z.string().max(80),
    country: optionalOptionEntry,
});
export type MainCardAddress = z.infer<typeof mainCardAddressSchema>;

export const mainCardSchema = z.object({
    mainCardNumber: z.string().max(50),
    expiryDate: optionalCardExpirationDateSchema,
    cardLimit: moneySchema,
    companyName: z.string().max(120),
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getSchemaForDateOfBirthAndAdultCheck(),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
    phoneNumber: z.string().max(38),
    address: mainCardAddressSchema,
});
export type MainCard = z.infer<typeof mainCardSchema>;

export const cardHolderSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getSchemaForDateOfBirthAndAdultCheck(),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
});
export type CardHolder = z.infer<typeof cardHolderSchema>;
