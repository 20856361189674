import {useTranslation} from 'react-i18next';
import {CardActions, CardContent, CardHeader, Typography} from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import React, {FC} from 'react';
import {AppCard} from '../../../../components/SectionCard';
import {BaseApplication} from '../../model';
import {RetryDuplicateCheckButton} from '../../ActionButtons';

type ApplicationWithIgnoreDuplicates = BaseApplication & { ignoreDuplicates: boolean | undefined };

interface DuplicateServiceOutageProblemSectionProps {
    application: ApplicationWithIgnoreDuplicates;
}

export const DuplicateServiceOutageProblemSection: FC<DuplicateServiceOutageProblemSectionProps> = ({application}) => {
    const {t} = useTranslation();

    return (<AppCard>
        <CardHeader title={
            <Typography variant={'h5'}
                        sx={theme => ({color: theme.palette.error.main})}>
                <ErrorIcon/><span> {t('application.overview.duplicateCheckServiceOutage.title')}</span>
            </Typography>}
        />
        <CardContent>
            <Typography variant={'subtitle1'}
                        sx={theme => ({color: theme.palette.common.black})}>
                <span>{t('application.overview.duplicateCheckServiceOutage.message')}</span>
            </Typography>
        </CardContent>
        <CardActions>
            <RetryDuplicateCheckButton application={application}/>
        </CardActions>
    </AppCard>);
};
