import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppCheckboxField} from '../../../../../components/form/AppCheckboxField';
import {AppTextField} from '../../../../../components/form/AppTextField';
import {DateOfBirthPickerField} from '../../../../../components/form/DateOfBirthPickerField';
import {FormRow} from '../../../../../components/form/FormRow';
import {FormSection} from '../../../../../components/form/FormSection';
import {trimAndCapitalizeFirstLetter} from '../../../../../utils/utils';
import {PersonTitleSelectField} from '../../../../reference/PersonTitleSelectField';
import {SalutationSelectField} from '../../../../reference/SalutationSelectField';
import {
    createEmptyMainProfiCardChApplication,
    MainProfiCardChApplication,
    mainProfiCardChApplicationSchema,
    MainProfiCardChLegitimizationForm,
    mainProfiCardChLegitimizationFormSchema,
} from './model';
import {ReadOnlyProps} from '../../../ApplicationForm';
import {ApplicationFormProps} from '../../../../../pages/application/NewApplicationPage';
import {
    BillingAddressFormSection,
    CardDispatchFormSection,
    CompanyDataFormSection,
    CompanyOwnerFormSection,
    DesiredLimitFormSection,
    LegitimationFormSection
} from '../commonMainProfiCardApplicationFormComponents';
import {CorrespondenceLanguageSelect} from './CorrespondenceLanguageSelect';
import {ApplicationTypeDto, Currency} from '../../../../../api';
import {SmartSignUpWithApplication} from '../SmartSignUpWithApplication';
import {BaseMainProfiCardApplicationUnion} from '../../model';
import {ContactForm} from '../../../ContactForm';
import {ConsentsFormSection} from '../../../ConsentsFormSection';
import {MarketDataFormSection} from '../../../marketData/MarketDataFormSection';

interface MainProfiCardChApplicationFormProps extends ApplicationFormProps<MainProfiCardChApplication> {
}

export function MainProfiCardChApplicationForm(props: MainProfiCardChApplicationFormProps) {
    return (<SmartSignUpWithApplication<MainProfiCardChApplication>
            createEmptyMainProfiCardApplication={createEmptyMainProfiCardChApplication}
            application={props.application}
            applicationSchema={mainProfiCardChApplicationSchema}
            afterDraftSaved={props.afterDraftSaved}
            afterLegitimized={props.afterLegitimized}
            renderFields={isReadOnly =>
                    <MainProfiCardChApplicationFields isReadOnly={isReadOnly} application={props.application}/>}
            showLegitimizeDialog={props.showLegitimizeDialog}
            legitimizationFormSchema={getMainProfiCardLegitimizationSchema}
            renderLegitimizationCheckList={() => <LegitimizeCheckList/>}
    />);
}

function getMainProfiCardLegitimizationSchema() {
    return mainProfiCardChLegitimizationFormSchema;
}

interface MainProfiCardApplicationFieldsProps {
    isReadOnly: boolean;
    application: MainProfiCardChApplication;
}

const MainProfiCardChApplicationFields: FC<MainProfiCardApplicationFieldsProps> = ({
                                                                                       isReadOnly,
                                                                                       application
                                                                                   }) => (<>
    <CompanyDataFormSection readOnly={isReadOnly} application={application}/>
    <BillingAddressFormSection readOnly={isReadOnly}/>
    <MainCardholderChFormSection readOnly={isReadOnly}/>
    <CompanyOwnerFormSection readOnly={isReadOnly} applicationType={application.applicationType}/>
    <DesiredLimitFormSection readOnly={isReadOnly} defaultCurrency={Currency.CHF}/>
    <MarketDataFormSection readOnly={isReadOnly} applicationType={ApplicationTypeDto.PROFI_CARD_CH_MAIN}/>
    <CardDispatchFormSection readOnly={isReadOnly}/>
    <LegitimationFormSection readOnly={isReadOnly}/>
    <ConsentsFormSection readOnly={isReadOnly} applicationType={application.applicationType}/>
</>);

function LegitimizeCheckList() {
    const {t} = useTranslation();

    return (<>
        <AppCheckboxField<MainProfiCardChLegitimizationForm> fieldPath={'companySignature'}
                                                             label={t('application.dialog.legitimization.companySignature')}/>
        <AppCheckboxField<MainProfiCardChLegitimizationForm> fieldPath={'principalCardholderSignature'}
                                                             label={t('application.dialog.legitimization.mainCardHolderSignature')}/>
        <AppCheckboxField<MainProfiCardChLegitimizationForm> fieldPath={'employeeSignature'}
                                                             label={t('application.dialog.legitimization.employeeSignature')}/>
    </>);
}

const MainCardholderChFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.cardHolder.main.sectionTitle')}>
        <FormRow>
            <SalutationSelectField<BaseMainProfiCardApplicationUnion>
                    breakpoint={3}
                    fieldPath={'cardHolder.salutation'}
                    required={true}
                    readOnly={props.readOnly}/>
            <PersonTitleSelectField<BaseMainProfiCardApplicationUnion>
                    breakpoint={3}
                    fieldPath={'cardHolder.title'}
                    readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             label={t('common.form.person.firstName')}
                                                             fieldPath={'cardHolder.firstName'}
                                                             transformValue={trimAndCapitalizeFirstLetter}
                                                             readOnly={props.readOnly}/>
            <AppTextField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                             required={true}
                                                             label={t('common.form.person.lastName')}
                                                             fieldPath={'cardHolder.lastName'}
                                                             transformValue={trimAndCapitalizeFirstLetter}
                                                             readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                       required={true}
                                                                       readOnly={props.readOnly}
                                                                       fieldPath={'cardHolder.dateOfBirth'}/>
        </FormRow>
        <FormRow>
            <CorrespondenceLanguageSelect<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                             required={true}
                                                                             readOnly={props.readOnly}
                                                                             fieldPath={'correspondenceLanguage'}/>
        </FormRow>
        <ContactForm<BaseMainProfiCardApplicationUnion> basePath={'cardHolder'} readOnly={props.readOnly}/>
    </FormSection>);
};
