/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserListResponse } from '../models/UserListResponse';
import type { UserOperationRequest } from '../models/UserOperationRequest';
import type { UserOperationStatus } from '../models/UserOperationStatus';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserResourceService {
    /**
     * @param country
     * @param enabled
     * @param firstName
     * @param isAdmin
     * @param lastName
     * @param pageIndex
     * @param pageSize
     * @param role
     * @param storeKey
     * @param username
     * @returns UserListResponse OK
     * @throws ApiError
     */
    public static getUsers(
        country?: string,
        enabled?: boolean,
        firstName?: string,
        isAdmin?: boolean,
        lastName?: string,
        pageIndex?: number,
        pageSize?: number,
        role?: string,
        storeKey?: string,
        username?: string,
    ): CancelablePromise<UserListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users',
            query: {
                'country': country,
                'enabled': enabled,
                'firstName': firstName,
                'isAdmin': isAdmin,
                'lastName': lastName,
                'pageIndex': pageIndex,
                'pageSize': pageSize,
                'role': role,
                'storeKey': storeKey,
                'username': username,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UserOperationStatus OK
     * @throws ApiError
     */
    public static createOrUpdateUser(
        requestBody?: UserOperationRequest,
    ): CancelablePromise<UserOperationStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param uid
     * @returns UserOperationStatus OK
     * @throws ApiError
     */
    public static activate(
        uid: string,
    ): CancelablePromise<UserOperationStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/activate/{uid}',
            path: {
                'uid': uid,
            },
        });
    }
    /**
     * @param uid
     * @returns UserOperationStatus OK
     * @throws ApiError
     */
    public static block(
        uid: string,
    ): CancelablePromise<UserOperationStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/block/{uid}',
            path: {
                'uid': uid,
            },
        });
    }
    /**
     * @param username
     * @returns User OK
     * @throws ApiError
     */
    public static search(
        username?: string,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/search',
            query: {
                'username': username,
            },
        });
    }
    /**
     * @param exactMatch
     * @param username
     * @returns User OK
     * @throws ApiError
     */
    public static searchByUsername(
        exactMatch: boolean = false,
        username?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/users/searchByUsername',
            query: {
                'exactMatch': exactMatch,
                'username': username,
            },
        });
    }
    /**
     * @param uid
     * @returns UserOperationStatus OK
     * @throws ApiError
     */
    public static sendPasswordUpdate(
        uid: string,
    ): CancelablePromise<UserOperationStatus> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/users/sendPasswordUpdate/{uid}',
            path: {
                'uid': uid,
            },
        });
    }
}
