/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CrefoDetailsDto } from '../models/CrefoDetailsDto';
import type { CrefoInteractionDto } from '../models/CrefoInteractionDto';
import type { SmartSignUpQueryDto } from '../models/SmartSignUpQueryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CrefoResourceService {
    /**
     * @param detailsId
     * @returns CrefoDetailsDto OK
     * @throws ApiError
     */
    public static retrieveCrefoHitDetails(
        detailsId: number,
    ): CancelablePromise<CrefoDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crefo/details/{detailsId}',
            path: {
                'detailsId': detailsId,
            },
        });
    }
    /**
     * @param applicationNumber
     * @returns CrefoInteractionDto OK
     * @throws ApiError
     */
    public static getInteractionsByApplicationNumber(
        applicationNumber: string,
    ): CancelablePromise<Array<CrefoInteractionDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/crefo/interactions/{applicationNumber}',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CrefoInteractionDto OK
     * @throws ApiError
     */
    public static smartSignUp(
        requestBody?: SmartSignUpQueryDto,
    ): CancelablePromise<CrefoInteractionDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/crefo/smartsignup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
