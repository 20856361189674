import {useTranslation} from 'react-i18next';
import {FieldValues} from 'react-hook-form/dist/types';
import React, {PropsWithChildren} from 'react';
import {GrossHouseholdIncomePerYearDto} from '../../../../api';
import {capitalizeFirstLetter} from '../../../../utils/utils';
import {ReferenceSelectFieldProps} from '../../../reference/ReferenceSelectFieldProps';
import {AppAutocompleteField} from '../../../../components/form/AppAutocompleteField';

export function GrossHouseholdIncomePerYearSelect<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<ReferenceSelectFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    const options: string[] = Object.keys(GrossHouseholdIncomePerYearDto);

    return <AppAutocompleteField<string, TFieldValues> breakpoint={props.breakpoint ?? 6}
                                                       options={options}
                                                       getLabel={option => capitalizeFirstLetter(t('application.form.projektWelt.grossHouseholdIncomePerYear.item.' + option as any)) ?? option}
                                                       getId={option => option}
                                                       fieldPath={props.fieldPath}
                                                       disabled={props.disabled}
                                                       label={''}
                                                       size={props.size}
                                                       required={props.required}
                                                       readOnly={props.readOnly}
    />;

}
