import {useTranslation} from 'react-i18next';
import React, {FC, useState} from 'react';
import {ApplicationStatus, BaseApplication} from './model';
import {AppFormButton} from '../../components/form/AppFormButton';
import AddCardOutlinedIcon from '@mui/icons-material/AddCardOutlined';
import {setDefaultAccountLimit} from './service';
import {
    Alert,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    TableCell,
    TableRow
} from '@mui/material';
import {GenericActionButtonProps, getUnresolvedDuplicate} from './ActionButtons';
import {useSnackbar} from '../../components/SnackbarHelper';
import {useProcessModificationResponseError} from './processResponseError';
import {usePageLoaderContext} from '../../components/PageLoader';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {Warning} from '@mui/icons-material';

const AutoAssignLimitSection: FC<GenericActionButtonProps> = ({application}) => {
    const {t} = useTranslation();
    const isUnresolvedDuplicate = getUnresolvedDuplicate(application);
    const validStatuses = [ApplicationStatus.NEEDS_MANUAL_INTERVENTION];
    const hasInvalidStatus = !validStatuses.includes(application.status);
    const [isLimitDialogOpen, setIsLimitDialogOpen] = useState(false);

    const disabled = hasInvalidStatus || isUnresolvedDuplicate;

    const onClick = () => {
        setIsLimitDialogOpen(true);
    };

    const handleLimitDialogClose = () => {
        setIsLimitDialogOpen(false);
    };

    if (application.applicationNumber === undefined) {
        throw new Error('Application number must be defined');
    }

    return (
            <>
                <TableRow>
                    <TableCell colSpan={6} sx={{backgroundColor: '#ffdca8'}}>
                        <Grid container justifyContent='space-between' alignItems='center' flexWrap='nowrap'
                              spacing={4}>
                            <Grid item><Alert
                                    severity={'warning'}>{t('application.overview.actions.autoAssignLimit.message')}</Alert></Grid>
                            <Grid item sx={{textAlign: 'right'}}><AppFormButton
                                    type="button"
                                    color="secondary"
                                    disabled={disabled}
                                    icon={<AddCardOutlinedIcon/>}
                                    sx={{whiteSpace: 'nowrap'}}
                                    onClick={onClick}>{t('application.overview.actions.autoAssignLimit.button')}</AppFormButton></Grid>
                        </Grid>
                    </TableCell>
                </TableRow>
                {isLimitDialogOpen &&
                        <LimitDialog open={isLimitDialogOpen}
                                     onClose={handleLimitDialogClose}
                                     application={application}
                        />}
            </>
    );
};

interface LimitDialogProps {
    open: boolean;
    application: BaseApplication;

    onClose(): void;
}

const LimitDialog: FC<LimitDialogProps> = ({open, onClose, application}) => {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const {setIsLoading} = usePageLoaderContext();
    const processModificationResponseError = useProcessModificationResponseError();
    const [isActionLoading, setIsActionLoading] = useState(false);


    const autoAssignLimit = () => {
        setIsActionLoading(true);
        if (application.applicationNumber === undefined) {
            throw new Error('Application number must be defined');
        }
        setDefaultAccountLimit(application)
                .then(() => {
                    showSnackbar({
                        severity: 'success',
                        message: t('application.overview.actions.limit.successMessage'),
                    });
                    if (application.status === ApplicationStatus.NEEDS_MANUAL_INTERVENTION) {
                        if (application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE) {
                            showSnackbar({
                                message: t('application.actions.retryCreateContract.errorMessage'),
                                severity: 'error',
                            });
                        }
                    }
                    return application;
                })
                .catch((reason) => processModificationResponseError(reason, application.applicationNumber))
                .finally(() => {
                    setIsLoading(false);
                    setIsActionLoading(false);
                });
    };

    return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>{t('application.overview.actions.autoAssignLimit.modalTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{t('application.overview.actions.autoAssignLimit.modalText')}</DialogContentText>
                </DialogContent>
                <DialogActions sx={{padding: '10px 24px 20px'}}>
                    <Box display="flex" justifyContent="end" width="100%" alignItems='stretch'
                         sx={{gap: '0.5rem'}}>
                        <AppFormButton
                                onClick={onClose}
                                outlined>{t('common.dialog.cancel')}</AppFormButton>
                        <AppFormButton inProgress={isActionLoading} onClick={autoAssignLimit}>
                            <Warning
                                    fontSize='small'
                                    sx={{marginRight: '0.25rem'}}/>{t('application.overview.actions.autoAssignLimit.confirm')}
                        </AppFormButton>
                    </Box>
                </DialogActions>
            </Dialog>
    );
};

export default AutoAssignLimitSection;


