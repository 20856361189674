import {Box, CircularProgress} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {SxProps} from '@mui/system';
import React, {PropsWithChildren} from 'react';

interface ProgressProps {
    size?: number;
    sx?: SxProps<Theme>;
}

export function Progress(props: PropsWithChildren<ProgressProps>) {
    return (<Box sx={{
        marginTop: '20vh',
        marginBottom: '20vh',
        textAlign: 'center',
        minHeight: 128,
        ...props.sx,
    }} data-testid='service-tool-progress'>
        <CircularProgress size={props.size}/>
    </Box>);
}
