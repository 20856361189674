import {z} from 'zod';
import {Currency} from '../../api';

export const moneySchema = z.object({
    amount: z.number().min(0.01)
        .optional()
        .nullable(),
    currency: z.nativeEnum(Currency).optional(),
}).nullable().optional();

export type Money = z.infer<typeof moneySchema>;
