import {ReadOnlyProps} from '../ApplicationForm';
import {ApplicationTypeDto} from '../../../api';
import {useTranslation} from 'react-i18next';
import AppConfig from '../../../config';
import {FormSection} from '../../../components/form/FormSection';
import {FormRow} from '../../../components/form/FormRow';
import {MasterMarketSelectField} from '../../reference/MasterMarketSelectedField';
import {BaseMainProfiCardApplicationUnion} from '../profiCard/model';
import {AppCheckboxField} from '../../../components/form/AppCheckboxField';
import React from 'react';

export function MarketDataFormSection(props: ReadOnlyProps & { applicationType: ApplicationTypeDto }) {
    const {t} = useTranslation();

    let isPinForWebShopVisible = true;
    if (props.applicationType === ApplicationTypeDto.PROFI_CARD_LU_MAIN) {
        isPinForWebShopVisible = AppConfig.pinForWebShopEnabledForLU;
    }
    if (props.applicationType === ApplicationTypeDto.PROFI_CARD_CH_MAIN) {
        isPinForWebShopVisible = AppConfig.pinForWebShopEnabledForCH;
    }
    return (<FormSection title={''}>
        <FormRow>
            <MasterMarketSelectField<BaseMainProfiCardApplicationUnion> fieldPath={'marketData.masterStore'}
                                                                        required={true}
                                                                        readOnly={props.readOnly}/>
            {isPinForWebShopVisible && <AppCheckboxField<BaseMainProfiCardApplicationUnion> breakpoint={3}
                                                                                            readOnly={props.readOnly}
                                                                                            fieldPath={'marketData.pinForWebShop'}
                                                                                            label={t('application.form.marketData.pinForWebshop')}/>}
        </FormRow>
    </FormSection>);
}
