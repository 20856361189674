import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    AdditionalProjektWeltApplication,
    createEmptyAdditionalProjektWeltApplication
} from '../../domain/application/projektWelt/additional/model';
import {
    AdditionalProjektWeltApplicationForm
} from '../../domain/application/projektWelt/additional/AdditionalProjektWeltApplicationForm';

export default function NewAdditionalProjektWeltApplicationPage() {
    return (<NewApplicationPage<AdditionalProjektWeltApplication>
            renderApplicationForm={AdditionalProjektWeltApplicationForm}
            createEmptyApplication={createEmptyAdditionalProjektWeltApplication}/>);
}

