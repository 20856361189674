import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {AppCheckboxField} from '../../../../components/form/AppCheckboxField';
import {AppTextField} from '../../../../components/form/AppTextField';
import {DateOfBirthPickerField} from '../../../../components/form/DateOfBirthPickerField';
import {FormRow} from '../../../../components/form/FormRow';
import {FormSection} from '../../../../components/form/FormSection';
import {formatLocalDate, trimAndCapitalizeFirstLetter} from '../../../../utils/utils';
import {PersonTitleSelectField} from '../../../reference/PersonTitleSelectField';
import {SalutationSelectField} from '../../../reference/SalutationSelectField';
import {ApplicationForm, ReadOnlyProps} from '../../ApplicationForm';
import {ApplicationFormProps} from '../../../../pages/application/NewApplicationPage';
import {useSnackbar} from '../../../../components/SnackbarHelper';
import {ApplicationTypeDto} from '../../../../api';
import {
    AdditionalProjektWeltApplication,
    additionalProjektWeltApplicationSchema,
    AdditionalProjektWeltLegitimizationForm,
    additionalProjektWeltLegitimizationFormSchema,
} from './model';
import {saveApplication} from '../../service';
import {MainCardSearchSection} from '../../mainCard/MainCardSearchSection';
import {MarketDataFormSection} from '../../marketData/MarketDataFormSection';
import {AppCard} from '../../../../components/SectionCard';
import {CardContent, Grid, InputLabel, Typography} from '@mui/material';
import {LabelAndValue} from '../../../../components/LabelAndValue';
import {useFormContext} from 'react-hook-form';
import PrintMoney from '../../../common/components/PrintMoney';

interface AdditionalProjektWeltApplicationFormProps extends ApplicationFormProps<AdditionalProjektWeltApplication> {
}

export function AdditionalProjektWeltApplicationForm(props: AdditionalProjektWeltApplicationFormProps) {
    const {t} = useTranslation();
    const showSnackbar = useSnackbar();
    const application = props.application;

    if (!application.mainCard?.mainCardNumber) {
        return (<MainCardSearchSection<AdditionalProjektWeltApplication> onMainCardDataRetrieved={mainCard => {
            return saveApplication({...application, mainCard})
                    .then(application => {
                        showSnackbar({
                            message: t('application.save.draft.success'),
                            severity: 'success',
                        });
                        return application;
                    })
                    .then(props.afterDraftSaved);
        }}/>);
    }

    return (<ApplicationForm<AdditionalProjektWeltApplication>
            application={application}
            applicationSchema={additionalProjektWeltApplicationSchema}
            afterDraftSaved={props.afterDraftSaved}
            afterLegitimized={props.afterLegitimized}
            renderFields={isReadOnly => <AdditionalProjektWeltApplicationFields isReadOnly={isReadOnly}/>}
            showLegitimizeDialog={props.showLegitimizeDialog}
            legitimizationFormSchema={getAdditionalProjektWeltLegitimizationSchema}
            renderLegitimizationCheckList={LegitimizeCheckList}
    />);
}

function getAdditionalProjektWeltLegitimizationSchema() {
    return additionalProjektWeltLegitimizationFormSchema;
}

interface AdditionalProjektWeltApplicationFieldsProps {
    isReadOnly: boolean;
}

const AdditionalProjektWeltApplicationFields: FC<AdditionalProjektWeltApplicationFieldsProps> = ({
                                                                                                     isReadOnly
                                                                                                 }) => (<>
    <MainCardFormSection/>
    <AdditionalCardholderFormSection readOnly={isReadOnly}/>
    <MarketDataFormSection applicationType={ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL} readOnly={isReadOnly}/>
</>);

function LegitimizeCheckList() {
    const {t} = useTranslation();

    return (<>
        <AppCheckboxField<AdditionalProjektWeltLegitimizationForm> fieldPath={'mainCardHolderSignature'}
                                                                   label={t('application.dialog.legitimization.mainCardHolderSignature')}/>
        <AppCheckboxField<AdditionalProjektWeltLegitimizationForm> fieldPath={'additionalCardHolderSignature'}
                                                                   label={t('application.dialog.legitimization.additionalCardHolderSignature')}/>
    </>);
}

function MainCardFormSection() {
    const {t} = useTranslation();
    const {watch} = useFormContext<AdditionalProjektWeltApplication>();
    const mainCard = watch('mainCard');
    const {
        mainCardNumber,
        expiryDate,
        cardLimit,
        salutation,
        title,
        firstName,
        lastName,
        dateOfBirth,
        email,
        cellPhoneNumber,
        address,
    } = mainCard || {};

    return (
            <AppCard>
                <CardContent>
                    <Grid container xs={12} columnSpacing={2}>
                        <>
                            <Grid item xs={5}>
                                <LabelAndValue
                                        label={t('application.form.additionalCard.mainCard.search.cardNumber')}
                                        title={t('application.form.additionalCard.mainCard.search.cardNumber')}
                                        value={<strong>{mainCardNumber}</strong>}
                                />
                                <LabelAndValue
                                        label={t('application.form.additionalCard.mainCard.search.expirationDate')}
                                        title={t('application.form.additionalCard.mainCard.search.expirationDate')}
                                        value={`${expiryDate?.month}/${expiryDate?.year}`}
                                />
                                <LabelAndValue
                                        label={t('application.form.additionalCard.mainCard.accountLimit.label')}
                                        title={t('application.form.additionalCard.mainCard.accountLimit.label')}
                                        value={
                                                cardLimit?.amount &&
                                                cardLimit?.currency && (
                                                        <PrintMoney
                                                                money={{
                                                                    amount: cardLimit.amount,
                                                                    currency: cardLimit.currency,
                                                                }}
                                                        />
                                                )
                                        }
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <InputLabel
                                        title={t('application.cardHolder.data')}
                                        sx={{paddingLeft: 0}}>{t('application.cardHolder.data')}:</InputLabel>
                                <Typography>
                                    {salutation?.value} {title?.value} {firstName} {lastName}
                                    <br/>
                                    <strong>{t('common.form.person.dateOfBirth')}</strong>: {formatLocalDate(dateOfBirth)}
                                    <br/>
                                    <strong>{t('common.form.contact.email')}</strong>: {email}
                                    <br/>
                                    <strong>{t('common.form.contact.phoneNumber')}</strong>: {cellPhoneNumber}
                                </Typography>
                            </Grid>

                            {address && (
                                    <Grid item xs={3}>
                                        <InputLabel
                                                title={t('application.cardHolder.address')}
                                                sx={{paddingLeft: 0}}>{t('application.cardHolder.address')}:</InputLabel>
                                        <Typography sx={{fontSize: 16}} color="text.primary">
                                            {address.additionalLine ? <>
                                                {address.additionalLine} <br/></> : null
                                            }
                                            {address.street} {address.houseNumber}
                                            <br/>
                                            {address.zipCode} {address.city}
                                            <br/>
                                            {address.country?.value}
                                        </Typography>
                                    </Grid>
                            )}
                        </>
                    </Grid>
                </CardContent>
            </AppCard>
    );
}

const AdditionalCardholderFormSection: FC<ReadOnlyProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={t('application.form.cardHolder.additional.sectionTitle')}>
        <FormRow>
            <SalutationSelectField<AdditionalProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.salutation'}
                    required={true}
                    readOnly={props.readOnly}/>
            <PersonTitleSelectField<AdditionalProjektWeltApplication>
                    breakpoint={3}
                    fieldPath={'cardHolder.title'}
                    readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProjektWeltApplication> breakpoint={3}
                                                            required={true}
                                                            label={t('common.form.person.firstName')}
                                                            fieldPath={'cardHolder.firstName'}
                                                            transformValue={trimAndCapitalizeFirstLetter}
                                                            readOnly={props.readOnly}/>
            <AppTextField<AdditionalProjektWeltApplication> breakpoint={3}
                                                            required={true}
                                                            label={t('common.form.person.lastName')}
                                                            fieldPath={'cardHolder.lastName'}
                                                            transformValue={trimAndCapitalizeFirstLetter}
                                                            readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <DateOfBirthPickerField<AdditionalProjektWeltApplication> breakpoint={3}
                                                                      readOnly={props.readOnly}
                                                                      fieldPath={'cardHolder.dateOfBirth'}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProjektWeltApplication> breakpoint={6}
                                                            label={t('common.form.contact.email')}
                                                            fieldPath={'cardHolder.email'}
                                                            readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppTextField<AdditionalProjektWeltApplication> breakpoint={3}
                                                            label={t('common.form.contact.cellPhoneNumber')}
                                                            fieldPath={'cardHolder.cellPhoneNumber'}
                                                            readOnly={props.readOnly}/>
        </FormRow>
    </FormSection>);
};
