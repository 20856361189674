import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    createEmptyMainProfiCardChApplication,
    MainProfiCardChApplication
} from '../../domain/application/profiCard/main/ch/model';
import {
    MainProfiCardChApplicationForm
} from '../../domain/application/profiCard/main/ch/MainProfiCardChApplicationForm';

export default function NewMainProfiCardChApplicationPage() {
    return (<NewApplicationPage<MainProfiCardChApplication>
            renderApplicationForm={MainProfiCardChApplicationForm}
            createEmptyApplication={createEmptyMainProfiCardChApplication}/>);
}
