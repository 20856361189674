import React from 'react';
import {MainProfiCardDeApplicationForm} from '../../domain/application/profiCard/main/de/MainProfiCardDeApplicationForm';
import {
    createEmptyMainProfiCardDeApplication,
    MainProfiCardDeApplication
} from '../../domain/application/profiCard/main/de/model';
import NewApplicationPage from './NewApplicationPage';

export default function NewMainProfiCardDeApplicationPage() {
    return (<NewApplicationPage<MainProfiCardDeApplication>
            renderApplicationForm={MainProfiCardDeApplicationForm}
            createEmptyApplication={createEmptyMainProfiCardDeApplication}/>);
}
