/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApplicationHistoryListResponse } from '../models/ApplicationHistoryListResponse';
import type { ApplicationListResponse } from '../models/ApplicationListResponse';
import type { ApplicationTypeDto } from '../models/ApplicationTypeDto';
import type { BaseApplicationDto } from '../models/BaseApplicationDto';
import type { BaseMainProfiCardApplicationDto } from '../models/BaseMainProfiCardApplicationDto';
import type { CreateApplicationRequest } from '../models/CreateApplicationRequest';
import type { LegitimizeRequest } from '../models/LegitimizeRequest';
import type { UpdateAccountLimitRequest } from '../models/UpdateAccountLimitRequest';
import type { UpdateApplicationRequest } from '../models/UpdateApplicationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ApplicationResourceService {
    /**
     * @param applicationNumber
     * @param applicationStatus
     * @param applicationType
     * @param cardHolderDateOfBirth
     * @param companyName
     * @param creationDate
     * @param firstName
     * @param lastName
     * @param pageIndex
     * @param pageSize
     * @param storeKey
     * @returns ApplicationListResponse OK
     * @throws ApiError
     */
    public static getApplications(
        applicationNumber?: string,
        applicationStatus?: string,
        applicationType?: ApplicationTypeDto,
        cardHolderDateOfBirth?: string,
        companyName?: string,
        creationDate?: string,
        firstName?: string,
        lastName?: string,
        pageIndex?: number,
        pageSize?: number,
        storeKey?: string,
    ): CancelablePromise<ApplicationListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/applications',
            query: {
                'applicationNumber': applicationNumber,
                'applicationStatus': applicationStatus,
                'applicationType': applicationType,
                'cardHolderDateOfBirth': cardHolderDateOfBirth,
                'companyName': companyName,
                'creationDate': creationDate,
                'firstName': firstName,
                'lastName': lastName,
                'pageIndex': pageIndex,
                'pageSize': pageSize,
                'storeKey': storeKey,
            },
        });
    }
    /**
     * @param requestBody
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static updateApplication(
        requestBody?: UpdateApplicationRequest,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static createApplication(
        requestBody?: CreateApplicationRequest,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/applications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static legitimize(
        requestBody?: LegitimizeRequest,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/legitimize',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param applicationNumber
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static getByApplicationNumber(
        applicationNumber: string,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/applications/{applicationNumber}',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
    /**
     * @param applicationNumber
     * @returns void
     * @throws ApiError
     */
    public static delete(
        applicationNumber: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/applications/{applicationNumber}',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
    /**
     * @param applicationNumber
     * @returns ApplicationHistoryListResponse OK
     * @throws ApiError
     */
    public static getApplicationHistoryByApplicationNumber(
        applicationNumber: string,
    ): CancelablePromise<ApplicationHistoryListResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/applications/{applicationNumber}/history',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
    /**
     * @param applicationNumber
     * @returns binary OK
     * @throws ApiError
     */
    public static printWelcomeLetter(
        applicationNumber: string,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/applications/{applicationNumber}/welcome-letter',
            path: {
                'applicationNumber': applicationNumber,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @param requestBody
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static manuallySetAccountLimit(
        applicationNumber: string,
        version: number,
        requestBody?: UpdateAccountLimitRequest,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/account-limit',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static processCreditCheck(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/credit-check',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param crefoDetailsId
     * @param version
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static selectCrefoDetails(
        applicationNumber: string,
        crefoDetailsId: number,
        version: number,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/crefo-details/{crefoDetailsId}',
            path: {
                'applicationNumber': applicationNumber,
                'crefoDetailsId': crefoDetailsId,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns BaseMainProfiCardApplicationDto OK
     * @throws ApiError
     */
    public static forceDefaultAccountLimitCalculation(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<BaseMainProfiCardApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/force-default-account-limit',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns binary OK
     * @throws ApiError
     */
    public static printApplication(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/applications/{applicationNumber}/{version}/print',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static reactivateApplication(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/reactivate',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static rejectApplication(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/reject',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
    /**
     * @param applicationNumber
     * @param version
     * @returns BaseApplicationDto OK
     * @throws ApiError
     */
    public static withdrawApplication(
        applicationNumber: string,
        version: number,
    ): CancelablePromise<BaseApplicationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/applications/{applicationNumber}/{version}/withdraw',
            path: {
                'applicationNumber': applicationNumber,
                'version': version,
            },
        });
    }
}
