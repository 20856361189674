import {
    ApplicationTypeDto,
    CreateMainProfiCardChApplicationRequest,
    FormAction,
    MainProfiCardChApplicationDto,
    UpdateMainProfiCardChApplicationRequest
} from '../../../../../api';
import {MainProfiCardApplicationWithCrefoDetailsId} from '../../model';
import {mapDtoToBaseMainProfiCardModel, mapModelToCreateBaseMainProfiCardApplicationRequest} from '../mapper';
import {MainProfiCardChApplication} from './model';

export function mapModelToCreateMainProfiCardChApplicationRequest(action: FormAction, data: MainProfiCardApplicationWithCrefoDetailsId<MainProfiCardChApplication>): CreateMainProfiCardChApplicationRequest {
    const baseMainProfiCardRequest = mapModelToCreateBaseMainProfiCardApplicationRequest(action, data);
    return {
        ...baseMainProfiCardRequest,
        applicationType: ApplicationTypeDto.PROFI_CARD_CH_MAIN,
        correspondenceLanguage: data.correspondenceLanguage ?? undefined,
    };
}

export function mapModelToUpdateMainProfiCardChApplicationRequest(action: FormAction, data: MainProfiCardChApplication): UpdateMainProfiCardChApplicationRequest {
    return {
        ...mapModelToCreateMainProfiCardChApplicationRequest(action, data),
        applicationNumber: data.applicationNumber!!,
        version: data.version,
        applicationType: ApplicationTypeDto.PROFI_CARD_CH_MAIN,
    };
}

export async function mapDtoToMainProfiCardChModel(data: MainProfiCardChApplicationDto): Promise<MainProfiCardChApplication> {
    const baseMainProfiCardApplication = await mapDtoToBaseMainProfiCardModel(data);
    const application: MainProfiCardChApplication = {
        ...baseMainProfiCardApplication,
        correspondenceLanguage: data.correspondenceLanguage ?? undefined,
    };
    return Promise.resolve(application);
}
