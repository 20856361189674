import {keepPreviousData, useQuery} from '@tanstack/react-query';
import IBAN from 'iban';
import {
    AddressSearchDataRequest,
    AddressSuggestionDto,
    AddressSuggestionsResponse,
    AuxiliaryResourceService,
    BankDetails,
} from '../../api';
import {queryClient} from '../../QueryHookClientProvider';
import {withAbortSignal} from '../../utils/  withAbortSignal';
import {Originator} from '../../utils/OriginatorProvider';
import {parseLocalDate} from '../../utils/utils';
import {IBAN_PREFIX} from '../application/iban/model';
import {MainCard} from '../application/mainCard/model';
import {findOptionEntryByKey, getReferences} from '../reference/service';
import {
    Address,
    AddressSuggestion,
    AddressSuggestions,
    AddressWithAdditionalLine,
    MainCardSearch,
    OptionalAddress
} from './model';

interface IbanValidationResult extends BankDetails {
    success: boolean;
}

export function validateIban(ibanText?: string) {
    if (ibanText === undefined || ibanText === null || ibanText === '' || ibanText === IBAN_PREFIX) {
        return Promise.resolve(true);
    }
    const iban = ibanText ?? '';
    return queryClient.fetchQuery({
        queryKey: ['ibanValidator', Originator.current, iban],
        queryFn: ({signal}) => validateIbanInternal(iban, signal),
        retry: false,
    })
        .then(value => value?.success ?? false)
        .catch(() => false);
}

export function setBankDetailsToCache(iban: string | undefined, result: IbanValidationResult) {
    queryClient.setQueryData(['ibanValidator', iban ?? ''], result);
}

function normalizeIbanValue(ibanText: string) {
    return ibanText.replaceAll(/\s+/g, '');
}

function validateIbanInternal(ibanText?: string, signal?: AbortSignal) {
    if (ibanText === undefined || ibanText === null || ibanText === '' || !IBAN.isValid(normalizeIbanValue(ibanText))) {
        return Promise.resolve({
            success: false,
        } as IbanValidationResult);
    }
    return withAbortSignal(() => AuxiliaryResourceService.bankDetails(normalizeIbanValue(ibanText), Originator.current!!), signal)
        .then(value => ({
            ...value,
            success: true,
        } as IbanValidationResult))
        .catch((reason) => {
            if (reason.status >= 500) {
                throw new Error('Error while attempting to retrieve information about... bank by IBAN. LsAPI might return an error.');
            }
            return ({
                success: false,
            } as IbanValidationResult);
        });
}

export function useIbanValidator(ibanText?: string) {
    const iban = ibanText ?? '';
    const {data, isLoadingError, isFetching} = useQuery({
        queryKey: ['ibanValidator', iban],
        queryFn: ({signal}) => validateIbanInternal(iban, signal),
        placeholderData: keepPreviousData,
        retry: false,
    });
    return {
        bankDetails: data,
        isFetching,
        isLoadingError,
    };
}

function validateAddressInternal(address?: OptionalAddress, signal?: AbortSignal) {
    const request: AddressSearchDataRequest = {
        street: address?.street!!,
        houseNumber: address?.houseNumber,
        zipCode: address?.zipCode,
        city: address?.city,
        country: (address?.country?.key !== undefined ? parseInt(address.country?.key) : undefined)!!,
    };
    return withAbortSignal(() => AuxiliaryResourceService.getAddressSuggestions(Originator.current!!, request), signal)
        .then(mapAddressSuggestionsResponseToModel);
}

export function getAddressSuggestions(address?: OptionalAddress) {
    const addressKey = JSON.stringify(address ?? {});
    console.log('addressKey', addressKey);
    return queryClient.fetchQuery({
        queryKey: ['address', Originator.current, addressKey],
        queryFn: ({signal}) => validateAddressInternal(address, signal),
        retry: false,
    });
}

async function mapAddressSuggestionsResponseToModel(response: AddressSuggestionsResponse): Promise<AddressSuggestions> {
    const suggestions: AddressSuggestion[] = [];
    if (response.addressSuggestionList) {
        for (const dto of response.addressSuggestionList) {
            const suggestion = await mapAddressSuggestionDtoToModel(dto);
            suggestions.push(suggestion);
        }
    }
    const result: AddressSuggestions = {
        suggestions,
    };
    return Promise.resolve(result);
}

async function mapAddressSuggestionDtoToModel(dto: AddressSuggestionDto) {
    const references = await getReferences(Originator.current!!);
    const suggestion: AddressSuggestion = {
        verified: true,
        street: dto.streetName,
        houseNumber: dto.houseNumber,
        zipCode: dto.zipCode,
        city: dto.city,
        country: findOptionEntryByKey(references.countryCodes, dto.countryCode?.toString()),
    };
    return Promise.resolve(suggestion);
}

export function createEmptyAddressWithAdditionalLine(): AddressWithAdditionalLine {
    return {
        additionalLine: '',
        ...createEmptyAddress(),
    };
}

export function createEmptyAddress(withAdditionalList?: boolean): Address {
    if (withAdditionalList === true) {
        return createEmptyAddressWithAdditionalLine();
    }
    return {
        street: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        country: null,
        verified: false,
        unverifiedAddressAccepted: false,
    };
}

export async function searchMainCard(cardData: MainCardSearch): Promise<MainCard> {
    const cardNumber = cardData.cardNumberPrefix + cardData.cardNumber;
    const originator = Originator.current!!;
    const {salutations, titles, countryCodes} = await getReferences(originator);
    return AuxiliaryResourceService.customerInfo(cardNumber, originator)
        .then(data => ({
            mainCardNumber: cardNumber,
            expiryDate: {
                year: data.expiryDate?.year,
                month: data.expiryDate?.month,
            },
            cardLimit: {
                amount: data.accountLimit?.amount,
                currency: data.accountLimit?.currency,
            },
            companyName: data.companyName ?? '',
            salutation: findOptionEntryByKey(salutations, data.salutationId ?? undefined),
            title: findOptionEntryByKey(titles, data.titleId ?? undefined),
            firstName: data.firstName ?? '',
            lastName: data.lastName ?? '',
            email: data.email ?? '',
            cellPhoneNumber: data.cellPhoneNumber ?? '',
            phoneNumber: data.phoneNumber ?? '',
            dateOfBirth: parseLocalDate(data.dateOfBirth),
            address: {
                additionalLine: data.address?.additionalLine ?? '',
                street: data.address?.street ?? '',
                houseNumber: data.address?.houseNumber ?? '',
                zipCode: data.address?.zipCode ?? '',
                city: data.address?.city ?? '',
                country: findOptionEntryByKey(countryCodes, data.address?.country?.key),
            },
        }));
}
