import React from 'react';
import {Outlet} from 'react-router-dom';
import {AppBreadcrumbs} from '../../components/AppBreadcrumbs';
import {TopBar} from '../../components/TopBar';
import {ProcessResponseErrorContextProvider} from '../../domain/application/processResponseError';

export default function SharedApplicationLayout() {
    return (<>
        <TopBar/>
        <AppBreadcrumbs/>
        <ProcessResponseErrorContextProvider>
            <Outlet/>
        </ProcessResponseErrorContextProvider>
    </>);
}
