import React from 'react';
import NewApplicationPage from './NewApplicationPage';
import {
    createEmptyMainProfiCardLuApplication,
    MainProfiCardLuApplication
} from '../../domain/application/profiCard/main/lu/model';
import {
    MainProfiCardLuApplicationForm
} from '../../domain/application/profiCard/main/lu/MainProfiCardLuApplicationForm';

export default function NewMainProfiCardLuApplicationPage() {
    return (<NewApplicationPage<MainProfiCardLuApplication>
            renderApplicationForm={MainProfiCardLuApplicationForm}
            createEmptyApplication={createEmptyMainProfiCardLuApplication}/>);
}
