import {FieldValues} from 'react-hook-form/dist/types';


export function getObjectProperty<T extends FieldValues = FieldValues>(object: T | null | undefined, path: string) {
    if (object === null || object === undefined) {
        return object;
    }
    const parts = path.split('.');
    return parts.reduce((object, key) => object?.[key], object);
}

export function setObjectProperty<T extends FieldValues = FieldValues>(object: T | null | undefined, path: string, newValue: any) {
    if (object === null || object === undefined) {
        return object;
    }
    const parts = path.split('.');
    const obj = parts.slice(0, parts.length - 1).reduce((object, key) => object?.[key], object) as any;
    const lastKey = parts[parts.length - 1];
    obj[lastKey] = newValue;
}