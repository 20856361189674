export interface FieldError {
    path: string;
    errorCode: string;
    parameters?: Record<string, string>;
}

export interface ConstraintViolationError {
    errors?: FieldError[];
}

export function isRequestAborted(error: any) {
    return error?.name === 'AbortError' || error?.cause?.name === 'AbortError';
}
