import {
    z,
    ZodBoolean,
    ZodDate,
    ZodFirstPartyTypeKind,
    ZodNullable,
    ZodNumber,
    ZodObject,
    ZodOptional,
    ZodString,
    ZodUnion
} from 'zod';
import {ZodEffects, ZodTypeAny} from 'zod/lib/types';

type SupportedZodTypes =
    ZodString
    | ZodNumber
    | ZodObject<any>
    | ZodEffects<any>
    | ZodBoolean
    | ZodDate
    | ZodOptional<any>
    | ZodNullable<any>
    | ZodUnion<any>;

export function toSchemaWithoutValidationRules<P extends { [k: string]: ZodTypeAny }>(schema: ZodObject<P>) {
    const shape = schema.shape;
    const shapeWithoutValidation = toShapeWithoutValidationRules(shape);
    return z.object(shapeWithoutValidation).deepPartial();
}

function toShapeWithoutValidationRules<T>(shape: T): T {
    const newShapeWithoutValidationRules = {} as any;
    Object.entries(shape as any).forEach(entry => {
        const key = entry[0];
        const value = entry[1] as SupportedZodTypes;
        newShapeWithoutValidationRules[key] = createZodTypeWithoutValidationRule(value);
    });
    return newShapeWithoutValidationRules;
}

function createZodTypeWithoutValidationRule(type: SupportedZodTypes): SupportedZodTypes {
    const typeName = type._def.typeName;
    if (typeName === ZodFirstPartyTypeKind.ZodString) {
        return z.string().nullable();
    } else if (typeName === ZodFirstPartyTypeKind.ZodNumber) {
        return z.number().nullable();
    } else if (typeName === ZodFirstPartyTypeKind.ZodBoolean) {
        return z.boolean().nullable();
    } else if (typeName === ZodFirstPartyTypeKind.ZodDate) {
        return z.date().nullable();
    } else if (typeName === ZodFirstPartyTypeKind.ZodObject) {
        return z.object(toShapeWithoutValidationRules(type._def.shape())).nullable();
    } else if (typeName === ZodFirstPartyTypeKind.ZodUnion) {
        return type.nullable().optional();
    } else if (typeName === ZodFirstPartyTypeKind.ZodEffects
        || typeName === ZodFirstPartyTypeKind.ZodOptional
        || typeName === ZodFirstPartyTypeKind.ZodNullable) {
        return createZodTypeWithoutValidationRule(unwrap(type as ZodEffects<any> | ZodNullable<any> | ZodOptional<any>));
    }
    console.error('Unsupported zod type', type);
    throw new Error('Unsupported zod type: ' + type);
}

function unwrap(schemaType: ZodEffects<any> | ZodNullable<any> | ZodOptional<any>): SupportedZodTypes {
    const typeName = schemaType._def.typeName;
    if (typeName === ZodFirstPartyTypeKind.ZodEffects) {
        return unwrap((schemaType as ZodEffects<any>).innerType());
    } else if (typeName === ZodFirstPartyTypeKind.ZodOptional) {
        return unwrap((schemaType as ZodOptional<any>).unwrap());
    } else if (typeName === ZodFirstPartyTypeKind.ZodNullable) {
        return unwrap((schemaType as ZodNullable<any>).unwrap());
    }
    return schemaType;
}
