import {z} from 'zod';
import {ApplicationTypeDto} from '../../../../api';
import {mapApplicationTypeToOriginator} from '../../../../utils/OriginatorProvider';
import {getDateSchema, maxToday} from '../../../../utils/utils';
import {optionalEmailSchema} from '../../../common/model';
import {optionalOptionEntry} from '../../../reference/model';
import {findOptionEntryByKey, getReferences} from '../../../reference/service';
import {User} from '../../../user/model';
import {mainCardSchema} from '../../mainCard/model';
import {marketDataSchema} from '../../marketData/model';
import {ApplicationStatus, BaseApplication} from '../../model';
import {baseProjektWeltApplicationSchema} from '../model';

const ownerOfAdditionalCardSchema = z.object({
    salutation: optionalOptionEntry,
    title: optionalOptionEntry,
    firstName: z.string().max(40),
    lastName: z.string().max(40),
    dateOfBirth: getDateSchema(false, zodDate => zodDate.max(maxToday())),
    email: optionalEmailSchema,
    cellPhoneNumber: z.string().max(38),
});
export const additionalProjektWeltApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL),
    mainCard: z.optional(mainCardSchema),
    cardHolder: z.optional(ownerOfAdditionalCardSchema),
    marketData: z.optional(marketDataSchema),
})
    .merge(baseProjektWeltApplicationSchema);

export type AdditionalProjektWeltApplication = z.infer<typeof additionalProjektWeltApplicationSchema>;

export function isAdditionalProjektWeltApplication(application: BaseApplication | undefined): application is AdditionalProjektWeltApplication {
    if (application === undefined) {
        console.log('Application is undefined');
        return false;
    }
    return application.applicationType === ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL;
}

export const additionalProjektWeltLegitimizationFormSchema = z.object({
    mainCardHolderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    additionalCardHolderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type AdditionalProjektWeltLegitimizationForm = z.infer<typeof additionalProjektWeltLegitimizationFormSchema>;

export async function createEmptyAdditionalProjektWeltApplication(user: User): Promise<AdditionalProjektWeltApplication> {
    const applicationType = ApplicationTypeDto.PROJEKT_WELT_ADDITIONAL;
    const originator = mapApplicationTypeToOriginator(applicationType);
    const {getShops} = await getReferences(originator);
    return {
        version: 1,
        applicationType: applicationType,
        status: ApplicationStatus.CREATED,
        cardHolder: {
            salutation: null,
            title: null,
            firstName: '',
            lastName: '',
            dateOfBirth: null,
            email: '',
            cellPhoneNumber: '',
        },
        marketData: {
            pinForWebShop: true,
            masterStore: findOptionEntryByKey(getShops(applicationType), user.store?.key),
        },
    };
}
