import {ErrorOption} from 'react-hook-form';
import {z} from 'zod';
import i18n from '../../i18n';
import {toSchemaWithoutValidationRules} from '../../utils/toSchemaWithoutValidationRules';
import {optionalOptionEntry, OptionalOptionEntry} from '../reference/model';

export interface AddressSuggestion {
    additionalLine?: string;
    street?: string;
    houseNumber?: string;
    zipCode?: string;
    city?: string;
    country: OptionalOptionEntry;
    verified: boolean;
}

export interface AddressSuggestions {
    suggestions: AddressSuggestion[];
}

export const addressSchema = z.object({
    street: z.string().max(80),
    houseNumber: z.string().max(19),
    zipCode: z.string().max(15),
    city: z.string().max(80),
    country: optionalOptionEntry,
    verified: z.boolean().optional().nullable(),
    unverifiedAddressAccepted: z.boolean().optional().nullable(),
});
export type Address = z.infer<typeof addressSchema>;

export const optionalAddressSchema = toSchemaWithoutValidationRules(addressSchema);
export type OptionalAddress = z.infer<typeof optionalAddressSchema>;

export const addressWithAdditionalLineSchema = z.object({
    additionalLine: z.string().max(80).optional(),
}).merge(addressSchema);
export type AddressWithAdditionalLine = z.infer<typeof addressWithAdditionalLineSchema>;

///// Card Expiration Date

export const cardExpirationDateSchema = z.object({
    year: z.number().min(1900).max(3000)
        .nullable()
        .refine(input => input != null, 'common.form.field.required'),
    month: z.number().min(1).max(12)
        .nullable()
        .refine(input => {
            return input != null;
        }, 'common.form.field.required'),
});
type CardExpirationDate = z.infer<typeof cardExpirationDateSchema>;

export const optionalCardExpirationDateSchema = toSchemaWithoutValidationRules(cardExpirationDateSchema);
export type OptionalCardExpirationDate = z.infer<typeof optionalCardExpirationDateSchema>;

export const mainCardSearchSchema = z.object({
    cardNumberPrefix: z.string().min(1),
    cardNumber: z.string().min(8).max(8).regex(/^\d*$/, i18n.t('zod:errors.custom')),
});
export type MainCardSearch = z.infer<typeof mainCardSearchSchema>;

export const isErrorOption = (obj: any): obj is ErrorOption => {
    if (obj === null || typeof obj !== 'object') {
        return false;
    }
    return 'message' in obj;
};
