import {useQuery} from '@tanstack/react-query';
import {
    ApiError,
    ApplicationProcessingErrorReasonDto,
    ApplicationResourceService,
    ApplicationTypeDto,
    CrefoDetailsDto,
    CrefoInteractionDto,
    CrefoProductTypeDto,
    CrefoResourceService,
    Currency,
    FormAction,
    LegitimizeRequest,
    SchufaResourceService,
} from '../../api';
import AppConfig from '../../config';
import {queryClient} from '../../QueryHookClientProvider';
import {withAbortSignal} from '../../utils/  withAbortSignal';
import {InvalidOption} from '../../utils/invalidOptionSchema';
import {formatLocalDate, parseLocalDate, toIntSafe} from '../../utils/utils';
import {
    mapDtoToApplicationModel,
    mapModelToCreateApplicationRequest,
    mapModelToUpdateApplicationRequest
} from './mapper';
import {ApplicationNumberAndVersion, ApplicationStatus, BaseApplication} from './model';

export interface ApplicationSearchQueryParameters {
    pageIndex?: number;
    pageSize?: number;
    applicationNumber?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    creationDate?: Date | null | InvalidOption;
    cardHolderDateOfBirth?: Date | null | InvalidOption;
    applicationStatus?: string | null;
    applicationType?: ApplicationTypeDto | null;
    expandSearchToEntireCountry?: boolean | null;
    storeKey?: string | null;
}

export function getApplicationSearchCriteria(searchParams: URLSearchParams): ApplicationSearchQueryParameters {
    return {
        applicationNumber: searchParams.get('applicationNumber') ?? '',
        firstName: searchParams.get('firstName') ?? '',
        lastName: searchParams.get('lastName') ?? '',
        companyName: searchParams.get('companyName') ?? '',
        creationDate: parseLocalDate(searchParams.get('creationDate')) ?? undefined,
        cardHolderDateOfBirth: parseLocalDate(searchParams.get('cardHolderDateOfBirth')) ?? undefined,
        applicationStatus: searchParams.get('applicationStatus'),
        applicationType: searchParams.get('applicationType') as ApplicationTypeDto ?? null,
        pageIndex: toIntSafe(searchParams.get('pageIndex')),
        pageSize: toIntSafe(searchParams.get('pageSize')) ?? AppConfig.applicationListPageSize,
        expandSearchToEntireCountry: searchParams.get('expandSearchToEntireCountry')?.toLowerCase() === 'true',
    };
}

export interface ApplicationsResponse {
    applications: BaseApplication[];
    totalCount: number;
}

function getApplications(query: ApplicationSearchQueryParameters | undefined, signal?: AbortSignal): Promise<ApplicationsResponse> {
    return withAbortSignal(() => ApplicationResourceService.getApplications(
        query?.applicationNumber,
        query?.applicationStatus ?? undefined,
        query?.applicationType ?? undefined,
        formatLocalDate(query?.cardHolderDateOfBirth),
        query?.companyName,
        formatLocalDate(query?.creationDate),
        query?.firstName,
        query?.lastName,
        (query?.pageIndex ?? 1) - 1,
        query?.pageSize ?? AppConfig.applicationListPageSize,
        query?.expandSearchToEntireCountry ? undefined : (query?.storeKey ?? undefined)
    ), signal)
        .then((applicationListResponse) => {
            const mappedApplicationPromises = applicationListResponse.applications?.map(mapDtoToApplicationModel);
            if (mappedApplicationPromises === undefined) {
                const applicationsResponse: ApplicationsResponse = {
                    applications: [],
                    totalCount: 0,
                };
                return applicationsResponse;
            }
            return Promise.all(mappedApplicationPromises).then(applications => {
                const sortedApplications = [...applications].sort((a, b) =>
                    (b.applicationNumber?.toLowerCase() ?? '').localeCompare(a.applicationNumber?.toLowerCase() ?? ''));
                const applicationsResponse: ApplicationsResponse = {
                    applications: sortedApplications,
                    totalCount: applicationListResponse.totalCount ?? 0,
                };
                return applicationsResponse;
            });
        });
}

export function useGetApplications(query: ApplicationSearchQueryParameters | undefined) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['application-list', query],
        queryFn: ({signal, queryKey}) => getApplications(queryKey[1] as ApplicationSearchQueryParameters, signal),
        retry: false,
    });

    return {
        applicationsResponse: data,
        isFetching,
        isFetched,
        isError,
        error,
    };
}

export function invalidateGetApplicationCache<T>(arg?: T) {
    queryClient.invalidateQueries({queryKey: ['application-list']});
    return arg;
}

function getApplicationByApplicationNumber(applicationNumber: string, signal?: AbortSignal) {
    return withAbortSignal(() => ApplicationResourceService.getByApplicationNumber(applicationNumber), signal)
        .then(mapDtoToApplicationModel);
}

export function useGetApplicationByApplicationNumber(applicationNumber: string) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['application', applicationNumber],
        queryFn: ({signal, queryKey}) => getApplicationByApplicationNumber(queryKey[1], signal),
        retry: false,
    });

    return {
        application: data,
        isFetching,
        isFetched,
        isError,
        error,
        isNotFoundError: (error as ApiError)?.status === 404,
    };
}

function getApplicationHistoryByApplicationNumber(applicationNumber: string, signal?: AbortSignal) {
    return withAbortSignal(() => ApplicationResourceService.getApplicationHistoryByApplicationNumber(applicationNumber), signal);
}

export function useGetApplicationHistoryByApplicationNumber(applicationNumber: string) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['application-history', applicationNumber],
        queryFn: ({signal, queryKey}) => getApplicationHistoryByApplicationNumber(queryKey[1], signal),
        retry: false,
    });

    return {
        history: data,
        isFetching,
        isFetched,
        isError,
        error,
        isNotFoundError: (error as ApiError)?.status === 404,
    };
}

function invalidateGetApplicationHistoryCache<T>(arg?: T) {
    queryClient.invalidateQueries({queryKey: ['application-history']});
    return arg;
}

export function saveApplication<T extends BaseApplication = BaseApplication>(data: T): Promise<T> {
    console.error(data.version);
    if (data.applicationNumber === undefined) {
        return ApplicationResourceService.createApplication(mapModelToCreateApplicationRequest(FormAction.SAVE_DRAFT, data))
            .then(mapDtoToApplicationModel)
            .then(updateApplicationInCache)
            .then(application => application as T);
    } else {
        return ApplicationResourceService.updateApplication(mapModelToUpdateApplicationRequest(FormAction.SAVE_DRAFT, data))
            .then(mapDtoToApplicationModel)
            .then(updateApplicationInCache)
            .then(invalidateSchufaInteractionCache)
            .then(application => application as T);
    }
}

export function recordApplication<T extends BaseApplication = BaseApplication>(data: T): Promise<T> {
    if (data.applicationNumber === undefined) {
        return ApplicationResourceService.createApplication(mapModelToCreateApplicationRequest(FormAction.SUBMIT, data))
            .then(mapDtoToApplicationModel)
            .then(updateApplicationInCache)
            .then(invalidateCrefoInteractionCache)
            .then(application => application as T);
    } else {
        return ApplicationResourceService.updateApplication(mapModelToUpdateApplicationRequest(FormAction.SUBMIT, data))
            .then(mapDtoToApplicationModel)
            .then(updateApplicationInCache)
            .then(invalidateCrefoInteractionCache)
            .then(invalidateSchufaInteractionCache)
            .then(application => application as T);
    }
}

export function legitimizeApplication<T extends BaseApplication = BaseApplication>(request: LegitimizeRequest): Promise<T> {
    return ApplicationResourceService.legitimize(request)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache)
        .then(invalidateCrefoInteractionCache)
        .then(application => application as T);
}

export function retrieveCrefoHitDetails(applicationNumber: string, crefoDetailsId: number) {
    return CrefoResourceService.retrieveCrefoHitDetails(crefoDetailsId)
        .then(crefoDetails => {
            if (crefoDetails.productType === CrefoProductTypeDto.WIRTSCHAFTSAUSKUNFT) {
                // WIRTSCHAFTSAUSKUNFT processing path adds new CrefoDetails, so we have to invalidate crefo interaction cache to fetch the data
                return invalidateCrefoInteractionCache({applicationNumber: applicationNumber})
                    .then(() => crefoDetails);
            } else {
                return updateCrefoDetailsInCache(applicationNumber, crefoDetails);
            }
        });
}

export function selectCrefoDetails(applicationId: ApplicationNumberAndVersion, crefoDetailsId: number) {
    return ApplicationResourceService.selectCrefoDetails(applicationId.applicationNumber!!, crefoDetailsId, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(invalidateCrefoInteractionCache)  // we have to re-fetch crefo interactions first while the component is still visible (it may disappear depending on application status: see: `MainApplicationOverviewTab.tsx`)
        .then(updateApplicationInCache)
        .then(rejectWhenManualInterventionIsNeeded);
}

export function smartSignUp(searchTerm: string, signal?: AbortSignal) {
    return withAbortSignal(() => CrefoResourceService.smartSignUp({searchTerm}), signal);
}

function updateCrefoDetailsInCache(applicationNumber: string, crefoDetails: CrefoDetailsDto) {
    const processCrefoDetailsDto = (item: CrefoDetailsDto) => {
        if (item.id === crefoDetails.id) {
            return {...crefoDetails} as CrefoDetailsDto;
        }
        return item;
    };

    const processCrefoInteractionDto = (interaction: CrefoInteractionDto) => {
        return {
            ...interaction,
            details: interaction.details?.map(processCrefoDetailsDto)
        } as CrefoInteractionDto;
    };

    queryClient.setQueryData<CrefoInteractionDto[]>(['crefo-interactions', applicationNumber], crefoInteractionList => crefoInteractionList?.map(processCrefoInteractionDto));

    return crefoDetails;
}

async function invalidateCrefoInteractionCache<T extends BaseApplication | Pick<BaseApplication, 'applicationNumber'>>(application: T) {
    await queryClient.invalidateQueries({queryKey: ['crefo-interactions', application.applicationNumber]});
    return application;
}

export async function invalidateSchufaInteractionCache<T extends BaseApplication | Pick<BaseApplication, 'applicationNumber'>>(application: T) {
    await queryClient.invalidateQueries({queryKey: ['schufa-interactions', application.applicationNumber]});
    return application;
}

function updateApplicationInCache(application: BaseApplication) {
    queryClient.setQueryData(['application', application.applicationNumber], application);
    invalidateGetApplicationCache();
    invalidateGetApplicationHistoryCache();
    return Promise.resolve(application);
}

export function deleteApplication(applicationNumber: string) {
    return ApplicationResourceService.delete(applicationNumber)
        .then(invalidateGetApplicationCache);
}

export function reactivateApplication(applicationId: ApplicationNumberAndVersion) {
    return ApplicationResourceService.reactivateApplication(applicationId.applicationNumber!!, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache);
}

export function withdrawApplication(applicationId: ApplicationNumberAndVersion) {
    return ApplicationResourceService.withdrawApplication(applicationId.applicationNumber!!, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache);
}

export function rejectApplication(applicationId: ApplicationNumberAndVersion) {
    return ApplicationResourceService.rejectApplication(applicationId.applicationNumber!!, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache);
}

export function processCreditCheck(applicationId: ApplicationNumberAndVersion) {
    return ApplicationResourceService.processCreditCheck(applicationId.applicationNumber!!, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache)
        .then(invalidateCrefoInteractionCache)
        .then(invalidateSchufaInteractionCache)
        .then(rejectWhenManualInterventionIsNeeded);
}

export function setAccountLimit(applicationId: ApplicationNumberAndVersion, newCurrency: Currency, newLimit: number) {
    return ApplicationResourceService.manuallySetAccountLimit(applicationId.applicationNumber!!, applicationId.version, {
        moneyDto: {
            currency: newCurrency,
            amount: newLimit
        }
    })
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache);
}

export function setDefaultAccountLimit(applicationId: ApplicationNumberAndVersion) {
    return ApplicationResourceService.forceDefaultAccountLimitCalculation(applicationId.applicationNumber!!, applicationId.version)
        .then(mapDtoToApplicationModel)
        .then(updateApplicationInCache);
}

function getCrefoInteractions(applicationNumber: string, signal?: AbortSignal) {
    return withAbortSignal(() => CrefoResourceService.getInteractionsByApplicationNumber(applicationNumber), signal);
}

function getSchufaInteractionsByApplicationNumber(applicationNumber: string, signal?: AbortSignal) {
    return withAbortSignal(() => SchufaResourceService.getInteractionsByApplicationNumber(applicationNumber), signal);
}

export function useGetCrefoInteractions(applicationNumber: string) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['crefo-interactions', applicationNumber],
        queryFn: ({signal, queryKey}) => getCrefoInteractions(queryKey[1], signal),
        retry: false,
        gcTime: 60_000,
    });

    return {
        crefoInteractions: data,
        isFetching,
        isFetched,
        isError,
        error,
        isNotFoundError: (error as ApiError)?.status === 404,
    };
}

export function useGetSchufaInteractions(applicationNumber: string) {
    const {data, isFetching, isFetched, isError, error} = useQuery({
        queryKey: ['schufa-interactions', applicationNumber],
        queryFn: ({signal, queryKey}) => getSchufaInteractionsByApplicationNumber(applicationNumber, signal),
        retry: false,
        gcTime: 60_000,
    });

    return {
        schufaInteractions: data,
        isFetching,
        isFetched,
        isError,
        error,
        isNotFoundError: (error as ApiError)?.status === 404,
    };
}

export interface ProcessCreditCheckFailureResult {
    type: 'ProcessCreditCheckFailureResult';
    application: BaseApplication;
    status: ApplicationStatus;
    reason: ApplicationProcessingErrorReasonDto | undefined | null;
}

export function isProcessCreditCheckFailureResult(object?: any): object is ProcessCreditCheckFailureResult {
    return object?.type === 'ProcessCreditCheckFailureResult';
}

function rejectWhenManualInterventionIsNeeded(application: BaseApplication) {
    if (application.status === ApplicationStatus.NEEDS_MANUAL_INTERVENTION) {
        const reason: ProcessCreditCheckFailureResult = {
            type: 'ProcessCreditCheckFailureResult',
            application: application,
            status: application.status,
            reason: application.processingErrorReason,
        };
        return Promise.reject(reason);
    }
    return application;
}

