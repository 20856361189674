import React, {FC} from 'react';
import {ActionButtonsSection,} from '../../domain/application/ActionButtons';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {CreateContractFailureSection} from '../../domain/application/CreateContractFailureSection';
import {
    AdditionalProjektWeltApplicationOverviewSection
} from '../../domain/application/projektWelt/additional/AdditionalProjektWeltApplicationOverviewSection';
import {AdditionalProjektWeltApplication} from '../../domain/application/projektWelt/additional/model';

interface AdditionalProjektWeltApplicationOverviewTabProps {
    application: AdditionalProjektWeltApplication;
}

export const AdditionalProjektWeltApplicationOverviewTab: FC<AdditionalProjektWeltApplicationOverviewTabProps> = ({application}) => (<>
    <AdditionalProjektWeltApplicationOverviewSection application={application}/>
    <ActionButtonsSection application={application}/>
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE &&
            <CreateContractFailureSection application={application}/>}
</>);

