import {GridSize} from '@mui/material/Grid/Grid';
import React, {PropsWithChildren} from 'react';
import {FieldPath} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {AppDatePickerField} from './AppDatePickerField';

interface DateOfBirthPickerFieldProps<TFieldValues extends FieldValues = FieldValues> {
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
}

export function DateOfBirthPickerField<TFieldValues extends FieldValues = FieldValues>(props: PropsWithChildren<DateOfBirthPickerFieldProps<TFieldValues>>) {
    const {t} = useTranslation();

    return (<AppDatePickerField<TFieldValues> breakpoint={props.breakpoint ?? 6}
                                              fieldPath={props.fieldPath}
                                              label={t('common.form.person.dateOfBirth')}
                                              disableFuture={true}
                                              required={props.required}
                                              readOnly={props.readOnly}
                                              disabled={props.disabled}
    />);

}
