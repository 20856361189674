import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import React from 'react';

const StyledEmptyValue = styled('em')({
    fontFamily: 'monospace',
});

function EmptyValue() {
    const {t} = useTranslation();
    return <StyledEmptyValue>{t('application.history.empty')}</StyledEmptyValue>;
}

export default EmptyValue;
