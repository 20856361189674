import React, {FC} from 'react';
import {MainProjektWeltApplication} from '../../domain/application/projektWelt/main/model';
import {
    MainProjektWeltApplicationOverviewSection
} from '../../domain/application/projektWelt/main/MainProjektWeltApplicationOverviewSection';
import {ActionButtonsSection} from '../../domain/application/ActionButtons';
import {ApplicationProcessingErrorReasonDto} from '../../api';
import {
    DuplicateApplicationProblemSection
} from '../../domain/application/profiCard/main/DuplicateApplicationProblemSection';
import {CreateContractFailureSection} from '../../domain/application/CreateContractFailureSection';
import {
    MainProjektWeltApplicationAddressProblemSection
} from '../../domain/application/projektWelt/main/MainProjektWeltApplicationAddressProblemSection';
import {
    DuplicateServiceOutageProblemSection
} from '../../domain/application/profiCard/main/DuplicateServiceOutageProblemSection';
import {SchufaLatestInteractionSection} from '../../domain/application/projektWelt/main/SchufaLatestInteractionSection';

interface MainProjektWeltApplicationOverviewTabProps {
    application: MainProjektWeltApplication;
}

export const MainProjektWeltApplicationOverviewTab: FC<MainProjektWeltApplicationOverviewTabProps> = ({application}) => (<>
    <MainProjektWeltApplicationOverviewSection application={application}/>
    <ActionButtonsSection application={application}/>
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.INVALID_ADDRESS &&
            <MainProjektWeltApplicationAddressProblemSection application={application}/>}
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.DUPLICATED &&
            <DuplicateApplicationProblemSection application={application}/>}
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.DUPLICATED_COMMUNICATION_FAILURE &&
            <DuplicateServiceOutageProblemSection application={application}/>}
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREDIT_INSTITUTION &&
            <SchufaLatestInteractionSection application={application}/>}
    {application.processingErrorReason === ApplicationProcessingErrorReasonDto.CREATE_CONTRACT_FAILURE &&
            <CreateContractFailureSection application={application}/>}
</>);
