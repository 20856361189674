import {Autocomplete, CircularProgress, Grid, InputAdornment, InputLabel, TextField} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import {SelectProps} from '@mui/material/Select/Select';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {useTranslation} from 'react-i18next';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId, markLabelAsMandatory} from './utils';

import {getCurrentLanguage} from '../../i18n';

// OptionsOptional and OptionsMandatory are the types to force
interface OptionsOptional<TOption = string> {
    options?: TOption[];
    isLoading: boolean;
}

interface OptionsMandatory<TOption = string> {
    options: TOption[];
    isLoading?: boolean;
}

interface AppAutocompleteFieldPropsInternal<TOption = string, TFieldValues extends FieldValues = FieldValues> extends SelectProps<TOption> {
    fieldPath: FieldPath<TFieldValues>;
    label: string;
    helperText?: React.ReactNode;
    breakpoint?: boolean | GridSize;
    required?: boolean;
    readOnly?: boolean;

    getLabel?(option: TOption): string;

    getId?(option: TOption): string;
}

type AppAutocompleteFieldProps<TOption = string, TFieldValues extends FieldValues = FieldValues> =
        AppAutocompleteFieldPropsInternal<TOption, TFieldValues>
        & (OptionsOptional<TOption> | OptionsMandatory<TOption>);

export function AppAutocompleteField<TOption, TFieldValues extends FieldValues = FieldValues>(props: AppAutocompleteFieldProps<TOption, TFieldValues>) {
    const {t} = useTranslation();
    const [inputValue, setInputValue] = useState<string>();

    const fieldPath = props.fieldPath;
    const isLoading = props.isLoading;
    const optionsFromProps = props.options;
    const options = useMemo(() => optionsFromProps ?? [], [optionsFromProps]);

    const getIdFromProps = props.getId;
    const getLabelFromProps = props.getLabel;
    const getLabel = useCallback((option?: TOption | null) => {
        if (option === undefined || option === null) {
            return '';
        }
        const label = getLabelFromProps?.(option) ?? option.toString();
        const hasMultipleSameLabels = options?.filter(o => getLabelFromProps?.(o).toString() === label).length > 1;
        if (hasMultipleSameLabels) {
            return label + ' (' + (getIdFromProps?.(option) ?? option.toString()) + ')';
        }
        return label;
    }, [getLabelFromProps, getIdFromProps, options]);

    const getId = useCallback((option?: TOption | null) => {
        if (option === undefined || option === null) {
            return '';
        }
        return getIdFromProps?.(option) ?? getLabel(option);
    }, [getIdFromProps, getLabel]);

    const {control, watch, setValue} = useFormContext<TFieldValues>();

    // the following useEffect is responsible for translating an option's label when user changes a language
    // the mechanism of ie: useReferences fetches the references list with the expected translations, but the value assigned to application stays in the old language.
    // The solution is to detect that the currently selected option label is not on the list of newly translated options. If it is there it is fine.
    // If not -> populate the form field with the option value
    const currentValue = watch(fieldPath);
    const lang = getCurrentLanguage();
    useEffect(() => {
        if (currentValue === undefined || currentValue === null || options === undefined || options === null || options.length === 0) {
            return;
        }
        const currentLabel = getLabel(currentValue);
        const isLabelChanged = options.find(option => getLabel(option) === currentLabel) === undefined;
        if (isLabelChanged) {
            const currentId = getId(currentValue);
            const equivalentOption = options.find(option => getId(option) === currentId);
            setValue(fieldPath, equivalentOption as any);
        }
    }, [currentValue, fieldPath, lang, setValue, options, getId, getLabel]);

    return (
        <Grid item xs={props.breakpoint}>
            <Controller
                control={control}
                name={fieldPath}
                render={({field: {ref, onChange, value, ...field}, fieldState: {error}}) => {
                    const hasError = error !== undefined;
                    return (<Autocomplete<TOption>
                        id={fieldPathToId(fieldPath)}
                        clearText={t('common.form.autocomplete.clearText')}
                        loading={isLoading}
                        readOnly={props.readOnly}
                        disabled={isLoading ?? props.disabled}
                        loadingText={t('common.form.autocomplete.loadingText').toString()}
                        options={options}
                        value={value ?? null}   // `null` prevents component to change mode to uncontrolled in case of `value === undefined`
                        onChange={(_, newValue) => onChange(newValue)}
                        getOptionLabel={getLabel}
                        isOptionEqualToValue={(option: TOption, value: TOption) => {
                            return getId(option) === getId(value);
                        }}
                        onInputChange={(_, inputValue) => {
                            setInputValue(inputValue);
                        }}
                        onBlur={() => {
                            const matchedOption = options.find(option => getLabel(option).toLowerCase() === inputValue?.toLowerCase());
                            if (matchedOption !== undefined) {
                                onChange(matchedOption);
                            }
                            field.onBlur();
                        }}
                        onKeyDown={event => {
                            if (event.key === 'Enter') {
                                const matchedOption = options.find(option => getLabel(option).toLowerCase() === inputValue?.toLowerCase());
                                if (matchedOption !== undefined) {
                                    onChange(matchedOption);
                                }
                            }
                        }}
                        renderInput={params => {
                            if (isLoading) {    // in case of loading data show the circular loading icon
                                params.InputProps.endAdornment = <>
                                    <InputAdornment position="end"><CircularProgress size={20}/></InputAdornment>
                                    {params.InputProps.endAdornment}
                                </>;
                            }
                            const {InputLabelProps, ...textFieldParams} = params;
                            return <>
                                <InputLabel error={hasError} htmlFor={fieldPathToId(fieldPath)}>
                                    {markLabelAsMandatory(props.label, props.required)}
                                </InputLabel>
                                <TextField {...textFieldParams}
                                           {...field}
                                           disabled={props.disabled}
                                           fullWidth={true}
                                           inputRef={ref}
                                           variant="outlined"
                                           size={props.size}
                                           error={hasError}
                                           helperText={hasError ? getErrorLabel(error) : props.helperText}
                                />
                            </>;
                        }}
                    />);
                }}
            />
        </Grid>
    );
}
