import {RefinementCtx, z} from 'zod';
import {ApplicationTypeDto} from '../../../../../api';
import {CountryUtils} from '../../../../country';
import {User} from '../../../../user/model';
import {addValidationErrorToPath} from '../../../../util';
import {bankDetailsSchema, ibanSchema, PaymentMethod} from '../../../iban/model';
import {BaseApplication} from '../../../model';
import {baseMainProfiCardApplicationSchema, createEmptyBaseMainProfiCardApplication} from '../model';

export const mainProfiCardDeApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_DE_MAIN),
    bankDetails: bankDetailsSchema.optional(),
})
    .merge(baseMainProfiCardApplicationSchema.sourceType())
    .superRefine(async (data, ctx) => {
        await validateIbanNumber(data, ctx);
    });
export type MainProfiCardDeApplication = z.infer<typeof mainProfiCardDeApplicationSchema>;

export function isIbanRequired(data: MainProfiCardDeApplication) {
    return data.bankDetails?.paymentMethod === PaymentMethod.DEBIT_CHARGE;
}

async function validateIbanNumber(data: MainProfiCardDeApplication, ctx: RefinementCtx) {
    if (isIbanRequired(data) && data.bankDetails /* it is needed only for compiler's type detection */) {
        const bankDetailsKey = 'bankDetails';
        const ibanKey = 'iban';
        const iban = data[bankDetailsKey][ibanKey];
        const validationResult = await ibanSchema.safeParseAsync(iban);
        addValidationErrorToPath([bankDetailsKey, ibanKey], validationResult, ctx);
    }
}

export function isMainProfiCardDeApplication(application: BaseApplication | undefined): application is MainProfiCardDeApplication {
    return application?.applicationType === ApplicationTypeDto.PROFI_CARD_DE_MAIN;
}

export const mainProfiCardDeLegitimizationTransferFormSchema = z.object({
    companySignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    principalCardholderSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
    employeeSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
});

export type MainProfiCardDeLegitimizationTransferForm = z.infer<typeof mainProfiCardDeLegitimizationTransferFormSchema>;
export const mainProfiCardDeLegitimizationDebitChargeFormSchema = z.object({
    accountOwnerSignature: z.boolean().refine(isChecked => isChecked, 'common.form.field.required'),
}).merge(mainProfiCardDeLegitimizationTransferFormSchema);

export type MainProfiCardDeLegitimizationDebitChargeForm = z.infer<typeof mainProfiCardDeLegitimizationDebitChargeFormSchema>;

export async function createEmptyMainProfiCardDeApplication(user: User): Promise<MainProfiCardDeApplication> {
    const applicationType = ApplicationTypeDto.PROFI_CARD_DE_MAIN;
    const baseMainProfiCardApplicationPromise = await createEmptyBaseMainProfiCardApplication(user, applicationType, {
        key: CountryUtils.DE.code,
        value: 'Deutschland',
    });
    return {
        ...baseMainProfiCardApplicationPromise,
        applicationType: applicationType,
        bankDetails: {
            paymentMethod: null,
            iban: '',
        },
    };
}
