import {z} from 'zod';
import {ApplicationTypeDto} from '../../../api';
import {moneySchema} from '../../money/model';
import {baseApplicationSchema} from '../model';
import {AdditionalProfiCardApplication} from './additional/model';
import {MainProfiCardChApplication} from './main/ch/model';
import {MainProfiCardDeApplication} from './main/de/model';
import {MainProfiCardLuApplication} from './main/lu/model';
import {baseMainProfiCardApplicationSchema} from './main/model';

export const baseProfiCardApplicationSchema = z.object({
    applicationType: z.literal(ApplicationTypeDto.PROFI_CARD_DE_MAIN).or(z.literal(ApplicationTypeDto.PROFI_CARD_LU_MAIN)).or(z.literal(ApplicationTypeDto.PROFI_CARD_CH_MAIN))
        .or(z.literal(ApplicationTypeDto.PROFI_CARD_DE_ADDITIONAL)).or(z.literal(ApplicationTypeDto.PROFI_CARD_LU_ADDITIONAL)).or(z.literal(ApplicationTypeDto.PROFI_CARD_CH_ADDITIONAL)),
    desiredLimit: moneySchema,
})
    .merge(baseApplicationSchema);

export type BaseProfiCardApplication = z.infer<typeof baseMainProfiCardApplicationSchema>;

export type BaseMainProfiCardApplicationUnion =
    MainProfiCardDeApplication
    | MainProfiCardLuApplication
    | MainProfiCardChApplication;
export type BaseAdditionalProfiCardApplicationUnion = AdditionalProfiCardApplication;
export type MainProfiCardApplicationWithCrefoDetailsId<T extends BaseMainProfiCardApplicationUnion> = T & {
    crefoDetailsId?: number
};
