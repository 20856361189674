import {
    Container,
    createTheme,
    CssBaseline,
    Grid,
    ThemeOptions,
    ThemeProvider,
    Typography,
    Box
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {ReactKeycloakProvider, useKeycloak} from '@react-keycloak/web';
import {Router as RemixRouter} from '@remix-run/router/dist/router';
import React, {useEffect, useState} from 'react';
import GitInfo from 'react-git-info/macro';
import {createBrowserRouter, RouterProvider,} from 'react-router-dom';
import {VersionDto, VersionResourceService} from './api';
import {PageLoader, PageLoaderContextProvider} from './components/PageLoader';
import {SnackbarHelperContextProvider} from './components/SnackbarHelper';
import {UserContextProvider} from './domain/user/UserProvider';
import keycloak from './keycloak';
import {QueryHookClientProvider} from './QueryHookClientProvider';
import {router} from './router';
import {OriginatorProvider} from './utils/OriginatorProvider';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorBoundaryPage} from './pages/error/ErrorPage';
import {AppContent} from './components/AppContent';

export const FONT_PROMINENT = 'BadHouse';

function inputLabelStyles(props: { theme: Omit<Theme, 'components'> } & Record<string, unknown>) {
    return {
        color: props.theme.palette.primary.main,
        fontWeight: 'bold',
        paddingLeft: props.theme.spacing(0.5),
    };
}

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#333333',
            light: '#f2f2f2',
        },
        secondary: {
            main: '#f99d27',
        },
        background: {
            paper: '#ffffff',
            default: '#f2f2f2',
        },
        text: {
            primary: '#333333',
        },
        error: {
            main: '#cc0000',
            light: '#ffbfbf',
        },
        info: {
            main: '#4d4d4d',
            light: '#f2f2f2',
            contrastText: '#333333',
        },
        success: {
            main: '#008800',
            light: '#bfffbf',
            dark: '#333333',
        },
    },
    typography: {
        h1: {
            fontFamily: FONT_PROMINENT,
        },
        h2: {
            fontFamily: FONT_PROMINENT,
        },
        h3: {
            fontFamily: FONT_PROMINENT,
        },
        h4: {
            fontFamily: FONT_PROMINENT,
        },
        h5: {
            fontFamily: FONT_PROMINENT,
        },
        h6: {
            fontFamily: FONT_PROMINENT,
        },
    },
    components: {
        MuiAppBar: {
            defaultProps: {
                color: 'inherit',
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#5c5c5c',
                    color: '#fff',
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: inputLabelStyles,
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: inputLabelStyles,
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled:not(p)': {
                        backgroundColor: '#f2f2f2',
                    },
                },
            }
        }
    },
};
const theme = createTheme(themeOptions);

function App() {
    return (
            <ReactKeycloakProvider authClient={keycloak} initOptions={{
                onLoad: 'login-required',
                checkLoginIframe: !document.hasStorageAccess,   // if the browser support partitioned storage feature, the login checking feature doesn't work in keycloak and can be disabled; see: https://www.keycloak.org/docs/latest/securing_apps/#_modern_browsers
            }} LoadingComponent={<KeycloakLoader/>}>
                <React.StrictMode>
                    <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
                        <CssBaseline>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <SnackbarHelperContextProvider>
                                        <PageLoaderContextProvider>
                                            <QueryHookClientProvider>
                                                <UserContextProvider>
                                                    <OriginatorProvider>
                                                        <AppRouterProvider/>
                                                        <VersionInfoFooter/>
                                                    </OriginatorProvider>
                                                </UserContextProvider>
                                            </QueryHookClientProvider>
                                        </PageLoaderContextProvider>
                                    </SnackbarHelperContextProvider>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </CssBaseline>
                    </ErrorBoundary>
                </React.StrictMode>
            </ReactKeycloakProvider>
    );
}

function AppRouterProvider() {
    const {keycloak, initialized} = useKeycloak();
    const [appRouter, setAppRouter] = useState<RemixRouter>();

    const isAuthenticated = initialized && keycloak.authenticated;

    useEffect(() => {
        if (appRouter === undefined) {
            setAppRouter(createBrowserRouter(router));
        }
    }, [isAuthenticated, appRouter]);

    if (appRouter === undefined) {
        return null;
    }
    return <RouterProvider router={appRouter}/>;
}

function KeycloakLoader() {
    const gitInfo = GitInfo();
    return (
            <Box sx={{paddingTop: '4rem'}}>
                <AppContent label={''}>
                    <PageLoader isLoading={true}>
                        <Typography variant={'subtitle2'} color={'#333'}>
                            Authenticating...
                            <br/>
                            <br/>
                            <small><b>FE</b> #{gitInfo.commit.shortHash} ({gitInfo.commit.date})</small>
                        </Typography>
                    </PageLoader>
                </AppContent>
            </Box>
    );
}

function VersionInfoFooter() {
    const gitInfo = GitInfo();
    const [backendVersion, setBackendVersion] = useState<VersionDto>();
    let backendVersionTag;

    useEffect(() => {
        VersionResourceService.getVersion()
                .then(versionResponse => {
                    setBackendVersion(versionResponse);
                })
                .catch((error) => {
                    console.error(error);
                });
    }, []);

    if (backendVersion !== undefined) {
        backendVersionTag = '#' + backendVersion?.commitIdAbbrev + ' (' + backendVersion?.buildTime + ')';
    } else {
        backendVersionTag = 'Not connected';
    }

    return (<Container>
        <Grid container>
            <Grid item sx={theme => ({marginTop: theme.spacing(2)})}>
                <Typography variant="body1" sx={{fontSize: '11px'}}>
                    <b>FE</b> #{gitInfo.commit.shortHash} ({gitInfo.commit.date})
                    &nbsp;|&nbsp;
                    <b>BE</b> {backendVersionTag}
                </Typography>
            </Grid>
        </Grid>
    </Container>);
}

export default App;
