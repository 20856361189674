import {styled} from '@mui/material/styles';
import {Trans, useTranslation} from 'react-i18next';
import React, {FC} from 'react';
import {ReadOnlyProps} from './ApplicationForm';
import {FormSection} from '../../components/form/FormSection';
import {FormRow} from '../../components/form/FormRow';
import {AppCheckboxField} from '../../components/form/AppCheckboxField';
import {BaseMainProfiCardApplicationUnion} from './profiCard/model';
import {ApplicationTypeDto} from '../../api';

const StyledEmail = styled('a')({
    fontWeight: 'bold',
});
const StyledBold = styled('em')({
    fontWeight: 'bold',
});

interface AdvertisementConsentPopoverContentProps {
    applicationType: ApplicationTypeDto;
}

const AdvertisementConsentPopoverContent: FC<AdvertisementConsentPopoverContentProps> = ({applicationType}) => {
    const getTranslationKey = (applicationType: ApplicationTypeDto) => {
        if (applicationType === ApplicationTypeDto.PROFI_CARD_CH_MAIN) {
            return 'application.form.advertisementConsentInfoText.CH';
        }
        if (applicationType === ApplicationTypeDto.PROFI_CARD_LU_MAIN) {
            return 'application.form.advertisementConsentInfoText.LU';
        }
        return 'application.form.advertisementConsentInfoText.DE';
    };

    return (<Trans i18nKey={getTranslationKey(applicationType)}
                   components={[
                       <StyledEmail key="email"/>,
                       <StyledBold key="bold"/>,
                   ]}/>);
};

interface ConsentsFormSectionProps extends ReadOnlyProps {
    applicationType: ApplicationTypeDto
}

export const ConsentsFormSection: FC<ConsentsFormSectionProps> = (props) => {
    const {t} = useTranslation();
    return (<FormSection title={''}>
        <FormRow>
            <AppCheckboxField<BaseMainProfiCardApplicationUnion> breakpoint={12}
                                                                 required={true}
                                                                 fieldPath={'consents'}
                                                                 label={t('application.form.consents')}
                                                                 readOnly={props.readOnly}/>
        </FormRow>
        <FormRow>
            <AppCheckboxField<BaseMainProfiCardApplicationUnion> breakpoint={12}
                                                                 sx={{marginTop: '-16px'}}
                                                                 fieldPath={'advertisementConsent'}
                                                                 label={t('application.form.advertisementConsent')}
                                                                 infoPopoverText={<AdvertisementConsentPopoverContent
                                                                         applicationType={props.applicationType}/>}
                                                                 readOnly={props.readOnly}/>
        </FormRow>
    </FormSection>);
};
