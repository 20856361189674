import {CircularProgress, Grid, InputAdornment, InputLabel, TextField, Theme} from '@mui/material';
import {GridSize} from '@mui/material/Grid/Grid';
import {TextFieldPropsSizeOverrides} from '@mui/material/TextField/TextField';
import {OverridableStringUnion} from '@mui/types';
import React, {PropsWithChildren} from 'react';
import {Controller, FieldPath, useFormContext} from 'react-hook-form';
import {FieldValues} from 'react-hook-form/dist/types';
import {getErrorLabel} from './useAppForm';
import {fieldPathToId, markLabelAsMandatory} from './utils';
import {SxProps} from '@mui/system';

interface AppTextFieldProps<TFieldValues extends FieldValues = FieldValues> {
    label: string;
    fieldPath: FieldPath<TFieldValues>;
    breakpoint?: boolean | GridSize;
    helperText?: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', TextFieldPropsSizeOverrides>;
    required?: boolean;
    readOnly?: boolean;
    startAdornment?: React.ReactNode;
    startAdornmentSx?: SxProps<Theme>;

    transformValue?(value: string | undefined | null): string | undefined | null;
}

export function AppTextField<TFieldValues extends FieldValues = FieldValues>(
        props: PropsWithChildren<AppTextFieldProps<TFieldValues>>,
) {
    const {control} = useFormContext();
    return (
        <Grid item xs={props.breakpoint}>
            <Controller control={control}
                        name={props.fieldPath}
                        render={({field, fieldState: {error}}) => {
                            const hasError = error !== undefined;

                            const transformOnBlur = () => {
                                const value = props.transformValue?.(field.value) ?? field.value;
                                field.onChange(value);
                                field.onBlur();
                            };

                            return (<>
                                <InputLabel error={hasError} htmlFor={fieldPathToId(props.fieldPath)}>
                                    {markLabelAsMandatory(props.label, props.required)}
                                </InputLabel>
                                <TextField {...field}
                                           id={fieldPathToId(props.fieldPath)}
                                           onBlur={transformOnBlur}
                                           value={field.value ?? ''}
                                           inputRef={field.ref}
                                           ref={undefined}
                                           fullWidth={true}
                                           disabled={props.disabled ?? false}
                                           size={props.size}
                                           error={hasError}
                                           helperText={hasError ? getErrorLabel(error) : props.helperText}
                                           InputProps={{
                                               readOnly: props.readOnly,
                                               endAdornment: props.isLoading && <InputAdornment position="end"><CircularProgress size={20}/></InputAdornment>,
                                               startAdornment: props.startAdornment ? <InputAdornment position="start" sx={props.startAdornmentSx}>{props.startAdornment}</InputAdornment> : undefined,
                                           }}
                                           variant="outlined"
                                />
                            </>);
                        }}
            />
        </Grid>
    );
}
