import React from 'react';
import {AppFormButton} from './form/AppFormButton';
import {Menu, MenuItem} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface DropdownButtonItem {
    label: string;

    onClick(): void;
}

interface DropdownButtonProps {
    icon?: React.ReactElement;
    label: React.ReactNode;
    items: DropdownButtonItem[];
}

export const DropdownButton: React.FC<DropdownButtonProps> = (props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState<number>();
    const open = Boolean(anchorEl);

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (index: number) => {
        setSelectedIndex(index);
        setAnchorEl(null);
        props.items[index].onClick();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<>
        <AppFormButton onClick={handleClickListItem}
                       icon={props.icon}
                       color={'secondary'}>
            {props.label}
            <ArrowDropDownIcon/>
        </AppFormButton>
        <Menu anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                  role: 'listbox',
              }}
        >
            {props.items.map((item, index) => (
                    <MenuItem key={item.label}
                              selected={index === selectedIndex}
                              onClick={() => handleMenuItemClick(index)}
                    >
                        {item.label}
                    </MenuItem>
            ))}
        </Menu>
    </>);
};
