import {ApiError} from '../api';
import {ApiRequestOptions} from '../api/core/ApiRequestOptions';
import {ApiResult} from '../api/core/ApiResult';
import keycloak from '../keycloak';

export function downloadFile(data: Blob, filename: string) {
    const blob = new Blob([data]);
    const objectUrl = URL.createObjectURL(blob);
    if (objectUrl !== undefined) {
        const alink = document.createElement('a');
        alink.href = objectUrl;
        alink.download = filename;
        alink.click();
        alink.remove();
        URL.revokeObjectURL(objectUrl);
    } else {
        throw new Error(`Unable to download a file: '${filename}'`);
    }
}

export function fetchBlob(url: string) {
    const token = keycloak.token;
    const method = 'GET';
    const requestOptions: ApiRequestOptions = {url, method};

    const headers = new Headers();
    headers.set('Authorization', `Bearer ${token}`);

    // ideally we could use: ApplicationResourceService.printApplication() or ReportResourceService.getXXXReport()
    // however there are a few issues with open schema's generated code:
    // * Cannot get blob from non-json responses - https://github.com/ferdikoomen/openapi-typescript-codegen/issues/981
    // * Fetch request is always sent with Accept: application/json header - https://github.com/ferdikoomen/openapi-typescript-codegen/issues/477
    // the solution could be to use custom `request.ts` file (https://github.com/ferdikoomen/openapi-typescript-codegen/blob/HEAD/docs/custom-request-file.md)
    // but it might cause a lot of problem during library upgrade
    return fetch(url, {headers, method})
        .then(response => {
            if (response.ok) {
                return response.blob();
            }
            return buildApiError(requestOptions, response).then(apiError => Promise.reject(apiError));
        });
}

async function buildApiError(requestOptions: ApiRequestOptions, response: Response) {
    if (response.ok) {
        throw new Error('Illegal status ' + response.status);
    }

    let body;
    const contentType = response.headers.get('Content-Type');
    if (contentType) {
        const jsonTypes = ['application/json', 'application/problem+json'];
        const isJSON = jsonTypes.some(type => contentType.toLowerCase().startsWith(type));
        if (isJSON) {
            body = await response.json();
        } else {
            body = await response.text();
        }
    }

    const result: ApiResult = {
        url: requestOptions.url,
        ok: response.ok,
        status: response.status,
        statusText: response.statusText,
        body,
    };
    return new ApiError(requestOptions, result, 'Download file failed');
}
