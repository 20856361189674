import {z} from 'zod';
import {Country} from '../../api';
import {optionEntrySchema} from '../reference/model';

export enum UserRole {
    HORNBACH = 'HORNBACH',
    HORNBACH_CENTRAL = 'HORNBACH_CENTRAL',
    KNISTR = 'KNISTR',
    HRB_SERVICE_TOOL = 'HRB_SERVICE_TOOL',
}

export enum UserCountry {
    DE = 'DE',
    LU = 'LU',
    CH = 'CH',
}

export enum UserSearchStatus {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    ALL= 'ALL',
}

export const userSchema = z.object({
    uid: z.string().optional(),
    userName: z.string(),
    emailAddress: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    role: z.nativeEnum(UserRole).optional().nullable(), // TODO Mandatory
    store: optionEntrySchema.optional().nullable(),
    hornbachUserCountry: z.nativeEnum(Country).optional().nullable(),
    countryDe: z.boolean().optional(),
    countryLu: z.boolean().optional(),
    countryCh: z.boolean().optional(),
    preferredLanguage: z.string().optional(),
    userAdminRights: z.boolean().optional().nullable(),
    isActive: z.boolean().optional(),
});

export type User = z.infer<typeof userSchema>;


