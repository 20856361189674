import {GridSize} from '@mui/material/Grid/Grid';
import React, {FC} from 'react';
import {FieldPath} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {AppRadioButtonField} from '../../../../components/form/AppRadioButtonField';
import {markLabelAsMandatory} from '../../../../components/form/utils';
import {MainProfiCardDeApplication} from './de/model';
import {PaymentMethod} from '../../iban/model';

interface PaymentMethodRadioButtonFieldProps {
    fieldPath: FieldPath<MainProfiCardDeApplication>;
    breakpoint?: boolean | GridSize;
    required?: boolean;
    readOnly?: boolean;
}

export const PaymentMethodRadioButtonField: FC<PaymentMethodRadioButtonFieldProps> = (props) => {
    const {t} = useTranslation();

    return (<AppRadioButtonField<PaymentMethod, MainProfiCardDeApplication>
            label={markLabelAsMandatory(t('application.form.bankDetails.paymentMethod'), props.required)}
            readOnly={props.readOnly}
            breakpoint={props.breakpoint}
            fieldPath={props.fieldPath}
            options={[
                {
                    label: t('application.form.bankDetails.paymentMethodOptions.DEBIT_CHARGE'),
                    value: PaymentMethod.DEBIT_CHARGE,
                },
                {
                    label: t('application.form.bankDetails.paymentMethodOptions.TRANSFER'),
                    value: PaymentMethod.TRANSFER,
                },
            ]}
    />);
};
